import { useTheme } from "@mui/material";
import { createTheme, ThemeOptions, ThemeProvider, TypographyVariant } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { useEffect, useMemo } from "react";
import useSettings from "src/hooks/useSettings";
import { pxToRem, remToPx, responsiveFontSizes } from "src/utils/getFontValue";
import typography from "./typography";
import _ from "lodash";
import { useSelector } from "react-redux";
import { StoreState } from "src/old/src/redux/reducers";
import { IUserProfileData } from "src/models/profile/IUserProfileData";
import { EUserSettings } from "src/pages/profile/settings/SettingsPage";
import equal from "fast-deep-equal";

const fontVariants: TypographyVariant[] = [
   "h1",
   "h2",
   "h3",
   "h4",
   "h5",
   "h6",
   "subtitle1",
   "subtitle2",
   "body1",
   "body2",
   "caption",
   "button",
   "overline",
];

const changeFontSizeByIncrement = (increment: number) => {
   const currentTypography = _.cloneDeep(typography);
   for (const variant of fontVariants) {
      const baseValue = remToPx(currentTypography[variant]!.fontSize as string);
      const newValue = baseValue + increment;
      currentTypography[variant] = {
         ...currentTypography[variant],
         fontSize: pxToRem(newValue),
         ...responsiveFontSizes({ sm: newValue, md: newValue, lg: newValue }),
      };
   }
   return currentTypography;
};

const DynamicFontSizeProvider: React.FC = ({ children }) => {
   const currentTheme = useTheme();
   const dynamicFontSizeChange = useSelector<StoreState.All, string | undefined>(
      (state) =>
         (state.common.userSettings as IUserProfileData[]).find(
            (setting) => setting.Name === EUserSettings.DynamicFontSizeChange
         )?.Value,
      equal
   );

   const themeOptions: ThemeOptions = useMemo(() => {
      const increment = dynamicFontSizeChange ? _.parseInt(dynamicFontSizeChange) : 0;
      return {
         typography: changeFontSizeByIncrement(increment),
      };
   }, [currentTheme.palette.mode, dynamicFontSizeChange]);

   // Former theme values must be merge `@themeOptions`.
   // Otherwise all styles overrides is resetting.
   const theme = createTheme({
      ...currentTheme,
      ...themeOptions,
      components: {
         ...currentTheme.components,
         MuiTooltip: {
            styleOverrides: {
               tooltip: {
                  ...(themeOptions.typography as TypographyOptions).caption,
               },
            },
         },
      },
   });
   return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default DynamicFontSizeProvider;
