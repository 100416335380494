import { ActiveUser } from '../../models/active-user.model';
import { LoginModel, LoginWithMultiFactorModel } from '../../models/login.model';
import { CapthaRequestModel } from '../../models/captha.request.model';
import baseService from '../base.service';
import * as CommonActions from '../../old/src/redux/common/action';
import * as SystemHealthActions from '../../old/src/redux/actions/systemhealth';

import UserSettings from 'src/old/src/base/UserSettings';
import { socketApi } from 'src/old/src/redux/webSocket/gipPlanningWebSocket';
import stores from 'src/old/src/redux/stores';
import { clearClosedBannerIdsFromLocalStorage } from 'src/utils';

export const login = async (loginData: LoginModel) => {
  try {
    if (loginData.userName.replace(/\s/g, '').length != 0) {
      const response = await baseService.post('/Login/Login', loginData);
      return response.data;
    }
    return '';
  } catch (error) {
    throw error;
  }
};
export const verifyCaptcha = async (Code: CapthaRequestModel) => {
  try {
    const response = await baseService.post('/Captcha/VerifyCaptcha', { Code: Code });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getCaptchaImage = async () => {
  try {
    const response = await baseService.post('/Captcha/GetCaptcha');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout = async (shouldRetry: boolean = true) => {
  try {
    await baseService.post('/Login/Logout', {});
  } catch (error) {
    if (shouldRetry) {
      logout(false);
    }
  }
};

export const resetAllUserSettings = async () => {
  CommonActions.SetIsUserLoggedIn(false);
  CommonActions.setUserInformation(0, '', '', '');
  CommonActions.setUserPermissions({
    companies: [],
    powerplants: [],
    groups: [],
  });
  CommonActions.setUserSettings([]);
  await stores.dispatch(socketApi.util.resetApiState());


  SystemHealthActions.clearSystemHealth();
  UserSettings.clear();

  clearClosedBannerIdsFromLocalStorage();
};

export const getActiveUser = () => {
  const activeUser = CommonActions.getUserInfo();
  if (!activeUser && !window.location.href.includes('auth/login') && !window.location.href.includes('auth/changepassword')) {
      window.location.replace('#/auth/login');
  }
  return activeUser;
};

export const setActiveUser = (activeUser: ActiveUser) => {
  CommonActions.setUserRegion(
    activeUser.UserProfileData.find((x) => x.Name == 'regionName')?.Value
  );
  CommonActions.setUserRegionTimezone(
    activeUser.UserProfileData.find((x) => x.Name == 'regionTimeZone')?.Value
  );
  CommonActions.setUserCompanies(activeUser.Permissions.companies);
};

export const checkUserLoggedIn = async () => {
  try {
    const response = await baseService.post('Login/CheckUserLoggedIn');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFlags = async () => {
  try {
    const response = await baseService.get('Login/GetFlags');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginWithMultiFactor = async (loginData: LoginWithMultiFactorModel) => {
  try {
    const response = await baseService.post('/Login/LoginWithMultiFactor', loginData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
