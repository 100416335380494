import {
   Box,
   Button,
   Checkbox,
   FormControlLabel,
   IconButton,
   InputAdornment,
   MenuItem,
   MenuList,
   Modal,
   Radio,
   RadioGroup,
   SxProps,
   Tooltip,
   Typography,
   useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { BLACK_V2, GRAY_V2, PRIMARY_V2, RED_V2 } from "src/theme/palette";
import { PersonIcon, PasswordIcon, SettingsIcon, InfoIcon } from "src/old/src/components/icons";
import {
   getCultures,
   getTimeZoneDefinitions,
   getUserProfile,
   setUserProfile,
   setUserSettings,
} from "src/services/configuration/configuration.service";
import { UserProfileDataModel } from "src/models/user-profile.model";
import { FormattedMessage, useIntl } from "react-intl";
import {
   AddCircleOutline,
   GridView,
   LockOutlined,
   VisibilityOffOutlined,
   VisibilityOutlined,
} from "@mui/icons-material";
import {
   CancelModalButton,
   CustomTextField,
   ModalContainer,
   OkModalButton,
   TabContainer,
   TabItem,
} from "./SettingsStyledComponents";
import moment from "moment";
import IOSSwitch from "src/components/base/switch/IOSSwitch";
import * as Utils from "src/utils";
import * as CommonActions from "src/old/src/redux/common/action";
import * as CustomerManagementService from "src/services/customerManagement/customerManagement.service";
import * as AlertingService from "src/services/alerting/alerting.service";
import ViewArea from "./ViewArea";
// import IOSSlider from "src/components/base/slider/IOSSlider";
import { saveUserProfileData } from "src/services/profile/profile.service";
import { ISaveUserProfileRequest } from "src/models/configurations.model";
import { IUserProfileData } from "src/models/profile/IUserProfileData";
import EllipsisTypography from "src/components/base/typography/EllipsisTypography";
import IOSSlider from "src/components/base/slider/IOSSlider";
import _ from "lodash";
import { Mark } from '@mui/base/useSlider';
import { useIntOutsideComponent } from "src/pages/ProductionForecast/utils";
import AutorenewIcon from '@mui/icons-material/Autorenew';

type ValueOptionType = { label: string; value: string };

export enum EUserSettings {
   TimeOutValue = "timeOutValue",
   HourSettings = "hourSettings",
   CsvViewType = "csvViewType",
   SmartBotSettings = "smartBotSettings",
   DynamicColorAppearance = "dynamicColorAppearance",
   DynamicFontSizeChange = "dynamicFontSizeChange",
}

interface IUserInfo {
   name: string;
   lastName: string;
   mail: string;
   setupCode: string;
   multiFactorProvider: number | null;
   currentIP: string;
   allowedIpAddresses: string;
   currentTimeZone: string;
   sampleOfCurrentTime: Date;
   netPositionDisplay: string;
   userSettings: UserProfileDataModel[];
   defaultSmartBotSharedStatus: number;
   smartbotDefaultOfferCancel: boolean;
   selectedMuniteValue?: string;
   mailCheckedAuth: boolean;
   unit: string;
   proposalConfirmationPopupOption: string;
   currentPassword: string;
   newPassword: string;
   newPasswordAgain: string;
   passwordExpiration: number;
   checkedAuth: boolean | null;
   hasMultiFactor: boolean;
   timeZone: ValueOptionType | undefined;
   cultureInfo: ValueOptionType | undefined;
   hourSettings: string;
   csvViewType: string;
   smartBotSettings: boolean;
   dynamicColorAppearance: boolean;
   dynamicFontSizeChange: number;
}

const muniteValues = {
   1: 15,
   2: 30,
   3: 60,
   4: 120,
   5: 240,
   6: 480,
};

const modalSlotProps = {
   backdrop: {
      style: {
         backgroundColor: "black",
         opacity: "0.6",
      },
   },
};

const DEFAULT_FONT_SIZE = 13;
const FONT_MARKS: Mark[] = _.range(12, 19)
   .map(x => {
      const labelValue = x - DEFAULT_FONT_SIZE;
      return {
         value: x,
         label: useIntOutsideComponent().formatNumber(labelValue, { signDisplay: "exceptZero" })
      }
   });

const SettingsPage = () => {
   const { palette } = useTheme();
   const { formatMessage } = useIntl();

   const [userInfo, setUserInfo] = useState<IUserInfo>();
   const [timeZoneOptions, setTimeZoneOptions] = useState<Array<ValueOptionType>>([]);
   const [cultureInfoOptions, setCultureInfoOptions] = useState<Array<ValueOptionType>>([]);
   const [selectedArea, setSelectedArea] = useState(0);
   const [settingsTab, setSettingsTab] = useState(0);
   const [showCurrentPassword, setShowCurrentPassword] = useState(false);
   const [showNewPassword, setShowNewPassword] = useState(false);
   const [showRepeatPassword, setShowRepeatPassword] = useState(false);
   const [forceMultiFactorCode, setforceMultiFactorCode] = useState(false);
   const [isConfirmMultiFactorCode, setIsConfirmMultiFactorCode] = useState(false);
   const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
   const [usercode, setUserCode] = useState<string | null>(null);
   const [formIsReaded, setFormIsReaded] = useState(false);
   const [userTimeOutCode, setUserTimeOutCode] = useState("");
   const [mailCodeisCorrect, setMailCodeisCorrect] = useState(false);
   const [passwordPolicyMessage, setPasswordPolicyMessage] = useState("");
   const [profileData, setProfileData] = useState<IUserProfileData[]>([]);
   const [profileData2, setProfileData2] = useState<IUserProfileData[]>([]);
   const [formModalOpen, setFormModalOpen] = useState(false);
   const [openSaveModal, setOpenSaveModal] = useState(false);
   const [openPasswordAndSecuritySaveModal, setOpenPasswordAndSecuritySaveModal] = useState(false);

   const userBotThatTimerTriggered = useRef<{ Id: number; Name: string; Mode: string }[]>([]);
   const prevUserInfo = useRef<IUserInfo>();

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   const userState = CommonActions.getUserInfo() as any;

   const isLight = palette.mode === "light";

   const isChangeMultiFactorAuth = prevUserInfo.current?.checkedAuth !== userInfo?.checkedAuth;
   const isChangePassward =
      prevUserInfo.current?.currentPassword !== userInfo?.currentPassword ||
      prevUserInfo.current?.newPassword !== userInfo?.newPassword ||
      prevUserInfo.current?.newPasswordAgain !== userInfo?.newPasswordAgain;

   const changeInfo = [
      [
         {
            isChange: prevUserInfo.current?.name !== userInfo?.name,
            messageKey: "global.name",
         },
         {
            isChange: prevUserInfo.current?.lastName !== userInfo?.lastName,
            messageKey: "global.surname",
         },
         {
            isChange: prevUserInfo.current?.mail !== userInfo?.mail,
            messageKey: "global.mail",
         },
      ],
      [
         {
            isChange: prevUserInfo.current?.unit !== userInfo?.unit,
            messageKey: "global.unitsettings",
         },
         {
            isChange: prevUserInfo.current?.timeZone !== userInfo?.timeZone,
            messageKey: "global.timezone",
         },
         {
            isChange: prevUserInfo.current?.cultureInfo !== userInfo?.cultureInfo,
            messageKey: "global.applicationLanguage",
         },
         {
            isChange: prevUserInfo.current?.csvViewType !== userInfo?.csvViewType,
            messageKey: "global.fileManagerCSVType",
         },
         {
            isChange: prevUserInfo.current?.netPositionDisplay !== userInfo?.netPositionDisplay,
            messageKey: "global.netpositiondisplay",
         },
         {
            isChange:
               prevUserInfo.current?.proposalConfirmationPopupOption !== userInfo?.proposalConfirmationPopupOption,
            messageKey: "global.proposalconfirmationpopupoption",
         },
         {
            isChange: prevUserInfo.current?.defaultSmartBotSharedStatus !== userInfo?.defaultSmartBotSharedStatus,
            messageKey: "smartbot.select.share",
         },
         {
            isChange: prevUserInfo.current?.hourSettings !== userInfo?.hourSettings,
            messageKey: "global.timeSettings",
         },
         {
            isChange: prevUserInfo.current?.smartBotSettings !== userInfo?.smartBotSettings,
            messageKey: "intradayplanning.smartbotDublicateCheck",
         },
         {
            isChange: prevUserInfo.current?.dynamicColorAppearance !== userInfo?.dynamicColorAppearance,
            messageKey: "global.dynamiccolorappearance",
         },
         {
            isChange: prevUserInfo.current?.dynamicFontSizeChange !== userInfo?.dynamicFontSizeChange,
            messageKey: "settings.newgipplanning.dynamicfontsizechange.title",
         }
      ],
      [
         { isChange: isChangePassward, messageKey: "global.password" },
         { isChange: isChangeMultiFactorAuth, messageKey: "div.multiauthstatus" },
         {
            isChange: prevUserInfo.current?.selectedMuniteValue !== userInfo?.selectedMuniteValue,
            messageKey: "screenTimeout",
         },
         {
            isChange: prevUserInfo.current?.passwordExpiration !== userInfo?.passwordExpiration,
            messageKey: "global.validitydate",
         },
         {
            isChange: prevUserInfo.current?.allowedIpAddresses !== userInfo?.allowedIpAddresses,
            messageKey: "customeronboarding.allowedIpAddresses",
         },
      ],
   ];

   useEffect(() => {
      const componentMount = async () => {
         Utils.ShowLoadingOverlay();
         try {
            const responseTimeZone = await getTimeZoneDefinitions();
            const zones = responseTimeZone.ReplyObject || [];
            const newTimeZoneOptions = zones.map((x) => ({ label: x.displayName, value: x.zoneId }));
            setTimeZoneOptions(newTimeZoneOptions);

            const responseCultures = await getCultures();
            const cultures = responseCultures.ReplyObject || [];
            const newCultureInfoOptions = cultures.map((x) => ({ label: x.name, value: x.culture }));
            setCultureInfoOptions(newCultureInfoOptions);

            await fetchAndLoadUserProfile(newTimeZoneOptions, newCultureInfoOptions);
         } catch (error) {
            Utils.showErrorMessage(error as string);
         }
         Utils.HideLoadingOverlay();
      };

      componentMount();
   }, []);

   const fetchAndLoadUserProfile = async (timeZones = timeZoneOptions, cultureInfos = cultureInfoOptions) => {
      try {
         const response = await getUserProfile();
         if (!response || response.isError) throw Error("Kullanıcı bilgileri çekilemedi");

         const timeZoneId: string = CommonActions.getUserProfileDataWithKey("timezone.id") || "";
         const applicationLanguage: string = CommonActions.getUserProfileDataWithKey("appculture.id") || "";
         const userSettings = response.UserSettings;
         const timeOutValue = userSettings && userSettings.find((x) => x.Name == EUserSettings.TimeOutValue)?.Value;
         const hourSetting = userSettings && userSettings.find((x) => x.Name == EUserSettings.HourSettings)?.Value;
         const csvViewType = userSettings && userSettings.find((x) => x.Name == EUserSettings.CsvViewType)?.Value;
         const smartBotSettings = userSettings && userSettings.find((x) => x.Name == EUserSettings.SmartBotSettings)?.Value;
         const dynamicColorAppearance = userSettings && userSettings.find((x) => x.Name == EUserSettings.DynamicColorAppearance)?.Value;
         const dynamicFontSizeChange = userSettings && userSettings.find((x) => x.Name == EUserSettings.DynamicFontSizeChange)?.Value;

         const newUserInfo: IUserInfo = {
            name: response.Name,
            lastName: response.LastName,
            mail: response.Mail,
            setupCode: response.SetupCode,
            multiFactorProvider: response.MultiFactorProvider ?? 2,
            currentIP: response.CurrentIpAddress,
            allowedIpAddresses: response.AllowedIpAddresses,
            currentTimeZone: response.CurrentTimeZone,
            sampleOfCurrentTime: response.SampleOfCurrentTime,
            netPositionDisplay: response.NetPositionDisplay,
            userSettings,
            defaultSmartBotSharedStatus: response.DefaultSmartBotSharedStatus,
            smartbotDefaultOfferCancel: Boolean(response.SmartbotDefaultOfferCancel),
            selectedMuniteValue: timeOutValue ?? "0",
            mailCheckedAuth: timeOutValue ? true : false,
            unit: CommonActions.getUserProfileDataWithKey("global.unit") || "mwh",
            proposalConfirmationPopupOption:
               CommonActions.getUserProfileDataWithKey("intradayplanning_proposalconfirmationpopupoption") ?? "1",
            currentPassword: "",
            newPassword: "",
            newPasswordAgain: "",
            passwordExpiration: 6,
            checkedAuth: Boolean(response.TwoAuth),
            hasMultiFactor: Boolean(response.TwoAuth),
            timeZone: timeZoneId ? timeZones.find((t) => t.value === timeZoneId) : undefined,
            cultureInfo: applicationLanguage ? cultureInfos.find((c) => c.value === applicationLanguage) : undefined,
            hourSettings: hourSetting === null || hourSetting === undefined ? "false" : hourSetting,
            csvViewType: csvViewType == null || csvViewType == undefined ? "0" : csvViewType,
            smartBotSettings:
               smartBotSettings == null || smartBotSettings == undefined ? true : JSON.parse(smartBotSettings),
            dynamicColorAppearance: dynamicColorAppearance ? JSON.parse(dynamicColorAppearance) : true,
            dynamicFontSizeChange: dynamicFontSizeChange && _.isNumber(_.parseInt(dynamicFontSizeChange)) ? _.parseInt(dynamicFontSizeChange) : 0,  
         };

         prevUserInfo.current = newUserInfo;
         setUserInfo(newUserInfo);
      } catch (error) {
         Utils.showErrorMessage(error as string);
      }
   };

   const enableMultiFactorAuth = async (
      checkedAuth: boolean,
      multiFactorProvider = userInfo?.multiFactorProvider ?? 2
   ) => {
      if (!checkedAuth) {
         setQrCodeUrl(null);
         setforceMultiFactorCode(true);
         return;
      }

      try {
         const response = await CustomerManagementService.enableMultiFactorAuth({
            Email: userInfo?.mail ?? "",
            Provider: multiFactorProvider,
         });
         setQrCodeUrl(response.SetupInfo.QrCodeUrl);
         setforceMultiFactorCode(true);
      } catch (error) {
         Utils.showErrorMessage(error as string);
      }
   };

   const checkMultiFACode = async () => {
      try {
         const response: any = await CustomerManagementService.validateMultiFactorCode({
            Code: usercode ? Number(usercode) : 0,
         });

         if (response && !response.isError) {
            Utils.showSuccessMessage(formatMessage({ id: "global.validcode" }));
            setforceMultiFactorCode(false);
            setIsConfirmMultiFactorCode(true);
            setUserInfo((prev) => prev && { ...prev, hasMultiFactor: Boolean(prev.checkedAuth) });
         }
         else {
            Utils.showErrorMessage(formatMessage({ id: "global.notvalidcode" }));
         }
      } catch (error) {
         Utils.showErrorMessage(error as string);
      }
   };

   const sendNewCode = async () => {
      if (userInfo?.mail?.length == 0 || userInfo?.mail == "") {
         Utils.showErrorMessage(formatMessage({ id: "div.pphqexp15" }));
         return;
      }

      try {
         await CustomerManagementService.sendCodeMail({
            Email: userInfo?.mail ?? "",
            Provider: userInfo?.multiFactorProvider ?? 2,
         });
         Utils.showSuccessMessage(formatMessage({ id: "customeronboarding.newcodesent" }));
      } catch (error) {
         Utils.showErrorMessage(error as string);
      }
   };

   const checkMultiFACodeforTimeOut = async () => {
      if (!userTimeOutCode) {
         Utils.showErrorMessage(formatMessage({ id: "div.somefieldsareempty" }));
         return;
      }

      try {
         const response = await CustomerManagementService.validateMultiFactorCode({
            Code: Number(userTimeOutCode),
         });
         if (response) {
            Utils.showSuccessMessage(formatMessage({ id: "global.validcode" }));
            setMailCodeisCorrect(true);
         } else {
            Utils.showErrorMessage(formatMessage({ id: "global.notvalidcode" }));
         }
      } catch (error) {
         Utils.showErrorMessage(error as string);
      }
   };

   const getLibraryScriptsThatTimerTriggered = async () => {
      try {
         const response = await AlertingService.getTimeTriggeredAlertRules();
         userBotThatTimerTriggered.current = response.AlertRules.map((botInfo) => ({
            Id: botInfo.Id,
            Name: botInfo.Name,
            Mode: botInfo.BotTypeText,
         }));
      } catch (error) {
         Utils.showErrorMessage(error as string);
      }
   };

   const saveUserTimeOutSettings = async () => {
      if (userInfo?.selectedMuniteValue == "0") {
         Utils.showErrorMessage(formatMessage({ id: "mustSelectforSave" }));
         return;
      }

      const newTimeOutValue = {
         Name: "timeOutValue",
         Value: `${userInfo?.selectedMuniteValue}`,
      };

      try {
         await setUserSettings([newTimeOutValue]);
         const currentUserSettings = Array.isArray(userState?.userSettings) ? userState.userSettings : [];
         const currentTimeOutValue = currentUserSettings.find((setting) => setting.Name === EUserSettings.TimeOutValue);
         if (currentTimeOutValue) {
            currentTimeOutValue.Value = `${userInfo?.selectedMuniteValue}`;
         } else {
            currentUserSettings.push(newTimeOutValue);
         }
         CommonActions.setUserSettings(currentUserSettings);
      } catch (error) {
         Utils.showErrorMessage(formatMessage({ id: "div.pphqexp3" }));
      }
   };

   const savePasswordAndSecurity = async () => {
      if (!prevUserInfo.current || !userInfo) {
         Utils.showErrorMessage(formatMessage({ id: "errors.userProfileMandatoryFieldsAreEmpty" }));
         return;
      }

      let changePass = false;

      if (isChangePassward) {
         if (userInfo.currentPassword != "" && userInfo.newPassword != "" && userInfo.newPasswordAgain != "") {
            if (userInfo.newPassword == userInfo.newPasswordAgain) {
               changePass = true;
            } else {
               Utils.showErrorMessage(formatMessage({ id: "div.passwordmismatch" }));
               return;
            }
         } else {
            Utils.showErrorMessage(formatMessage({ id: "div.somefieldsareempty" }));
            return;
         }
      }

      if (isChangeMultiFactorAuth) {
         if (userInfo.checkedAuth) {
            if (usercode == "" || usercode == null || usercode == undefined) {
               Utils.showErrorMessage(formatMessage({ id: "div.somefieldsareempty" }));
               return;
            }
         }
         if (!isConfirmMultiFactorCode && userInfo.checkedAuth) {
            Utils.showErrorMessage(formatMessage({ id: "div.somefieldsareempty" }));
            return;
         }
      }

      const requestData: ISaveUserProfileRequest = {
         Name: prevUserInfo.current.name,
         LastName: prevUserInfo.current.lastName,
         Mail: prevUserInfo.current.mail,
         PassChange: changePass,
         OldPass: userInfo.currentPassword,
         NewPass: userInfo.newPassword,
         NewPassRepeat: userInfo.newPasswordAgain,
         TwoAuth: Boolean(userInfo.checkedAuth),
         MultiFactorProvider: userInfo.multiFactorProvider,
         PasswordExpirationPeriod: userInfo.passwordExpiration,
         AllowedIpAddresses: userInfo.allowedIpAddresses,
      };

      Utils.ShowLoadingOverlay();

      try {
         if (userInfo?.selectedMuniteValue != prevUserInfo.current.selectedMuniteValue) await saveUserTimeOutSettings();

         const response = await setUserProfile(requestData);

         let passwordPolicyMessage = "";

         if (response.isError) {
            if (response.PasswordValidationResult != null || response.PasswordValidationResult != undefined) {
               response.PasswordValidationResult.split(",").forEach((passwordError) => {
                  passwordPolicyMessage += "* " + formatMessage({ id: passwordError }) + "\n";
               });
            } else {
               Utils.showErrorMessage(response.ErrorMessage);
            }
         } else {
            Utils.showSuccessMessage(formatMessage({ id: "global.saved." }));
         }

         setPasswordPolicyMessage(passwordPolicyMessage);
      } catch (error) {
         Utils.showErrorMessage(error as string);
      }

      Utils.HideLoadingOverlay();
   };

   const saveUserSettings = async () => {
      if (!userInfo) {
         Utils.showErrorMessage(formatMessage({ id: "errors.userProfileMandatoryFieldsAreEmpty" }));
         return;
      }

      const profileData = [
         { Name: "global.unit", Value: userInfo.unit || "mwh" },
         { Name: "timezone.id", Value: userInfo.timeZone?.value || "" },
         {
            Name: "netpositiondisplay",
            Value: userInfo.netPositionDisplay || "0",
         },
         {
            Name: "intradayplanning_proposalconfirmationpopupoption",
            Value: userInfo.proposalConfirmationPopupOption || "1",
         },
         {
            Name: "appculture.id",
            Value: userInfo.cultureInfo?.value || "",
         },
         {
            Name: "defaultSmartBotSharedStatus",
            Value: String(userInfo.defaultSmartBotSharedStatus) || "",
         },
         {
            Name: "smartBotDefaultOfferCancel",
            Value: String(userInfo.smartbotDefaultOfferCancel) || "",
         },
      ];

      setProfileData(profileData);

      if (localStorage.getItem("UserTimeZoneId") != userInfo.timeZone?.value) {
         try {
            await getLibraryScriptsThatTimerTriggered();
            setOpenSaveModal(true);
         } catch (error) {
            Utils.showErrorMessage(formatMessage({ id: "global.error.fetch" }, { name: "AlertList" }));
         }
      } else {
         try {
            const temp = [...CommonActions.getUserProfileData()];

            for (const item of profileData) {
               const storage = temp.find((a) => a.Name === item.Name);

               if (storage != undefined) {
                  storage.Value = item.Value;
               } else {
                  temp.push(item);
               }
            }

            await saveUserProfileData(temp);
            CommonActions.setUserProfileData(temp);

            if (profileData2.length > 0) {
               await setUserSettings(profileData2);
               CommonActions.setUserSettings(profileData2);
            }

            localStorage.setItem("UserTimeZoneId", userInfo.timeZone?.value ?? "");
            await fetchAndLoadUserProfile();
            Utils.showSuccessMessage(formatMessage({ id: "global.warning.saved" }));
         } catch (error) {
            Utils.showErrorMessage(formatMessage({ id: "global.erroroccuredwhilesaving" }));
         }
      }
   };

   const saveGeneralInfo = async () => {
      if (!prevUserInfo.current || !userInfo || userInfo.name == "" || userInfo.lastName == "" || userInfo.mail == "") {
         Utils.showErrorMessage(formatMessage({ id: "errors.userProfileMandatoryFieldsAreEmpty" }));
         return;
      }

      const requestData: ISaveUserProfileRequest = {
         Name: userInfo.name,
         LastName: userInfo.lastName,
         Mail: userInfo.mail,
         PassChange: false,
         OldPass: prevUserInfo.current?.currentPassword,
         NewPass: prevUserInfo.current?.newPasswordAgain,
         NewPassRepeat: prevUserInfo.current?.newPasswordAgain,
         TwoAuth: prevUserInfo.current?.hasMultiFactor,
         MultiFactorProvider: prevUserInfo.current?.multiFactorProvider,
         PasswordExpirationPeriod: prevUserInfo.current?.passwordExpiration,
         AllowedIpAddresses: prevUserInfo.current?.allowedIpAddresses,
      };

      try {
         const response = await setUserProfile(requestData);
         if (response.isError) {
            Utils.showErrorMessage(response.ErrorMessage);
         } else {
            Utils.showSuccessMessage(formatMessage({ id: "global.saved." }));
         }
      } catch (error) {
         Utils.showErrorMessage(error as string);
      }
   };

   const confirmSettings = () => {
      if (changeInfo[selectedArea].some((x) => x.isChange)) setOpenPasswordAndSecuritySaveModal(true);
      else Utils.showWarningMessage(formatMessage({ id: "bilateralagreement.contract.noanychange" }));
   };

   const saveSettings = async () => {
      Utils.ShowLoadingOverlay();
      switch (selectedArea) {
         case 0:
            await saveGeneralInfo();
            break;
         case 1:
            await saveUserSettings();
            break;
         case 2:
            await savePasswordAndSecurity();
            break;
         default:
            break;
      }
      Utils.HideLoadingOverlay();
   };

   const formatDateTimeWithOffset = (): string => {
      const momentDate = moment().parseZone();
      return `${momentDate.format("DD.MM.YYYY HH:mm ([UTC] Z)")}`;
   };

   const handleSmartBotSettingsChange = (switchValue: boolean) => {
      const changedUserSettings = userInfo?.userSettings ?? [];
      const index = changedUserSettings.findIndex((x) => x.Name == EUserSettings.SmartBotSettings);

      if (index !== -1) {
         changedUserSettings[index].Value = `${switchValue}`;
      } else {
         changedUserSettings.push({ Name: EUserSettings.SmartBotSettings, Value: `${switchValue}` });
      }

      setUserInfo((prev) => prev && { ...prev, userSettings: changedUserSettings, smartBotSettings: switchValue });
      updateProfileData(EUserSettings.SmartBotSettings, `${switchValue}`);
   };

   const handleDynamicColorAppearance = (switchValue: boolean) => {
      const changedUserSettings = userInfo?.userSettings ?? [];
      const index = changedUserSettings.findIndex((x) => x.Name == EUserSettings.DynamicColorAppearance);

      if (index !== -1) {
         changedUserSettings[index].Value = `${switchValue}`;
      } else {
         changedUserSettings.push({ Name: EUserSettings.DynamicColorAppearance, Value: `${switchValue}` });
      }

      setUserInfo((prev) => prev && { ...prev, userSettings: changedUserSettings, dynamicColorAppearance: switchValue });
      updateProfileData(EUserSettings.DynamicColorAppearance, `${switchValue}`);
   };

   const updateProfileData = (key: string, value: string) => {
      const data = profileData2.find((x) => x.Name == key);
      if (data) {
         data.Value = value;
         setProfileData2(_.cloneDeep(profileData2));
      } else {
         setProfileData2((prev) => [...prev, { Name: key, Value: value }]);
      }
   };

   const styles = {
      userBox: {
         borderRadius: "8px",
         border: `1px solid ${isLight ? GRAY_V2[300] : BLACK_V2[700]}`,
         backgroundColor: isLight ? GRAY_V2[50] : BLACK_V2[500],
         display: "flex",
         alignItems: "center",
         gap: "8px",
         height: "60px",
         minWidth: "270px",
         px: "16px",
         overflow: "hidden",
         whiteSpace: "nowrap",
      } as SxProps,
      headerBox: {
         borderRadius: "8px",
         border: `1px solid ${isLight ? GRAY_V2[300] : BLACK_V2[700]}`,
         backgroundColor: isLight ? GRAY_V2[50] : BLACK_V2[500],
         height: "60px",
         display: "flex",
         alignItems: "center",
         px: "16px",
         gap: "8px",
         flexGrow: 1,
      } as SxProps,
      menuBox: {
         borderRadius: "8px",
         border: `1px solid ${isLight ? GRAY_V2[300] : BLACK_V2[700]}`,
         backgroundColor: isLight ? GRAY_V2[50] : BLACK_V2[500],
         minWidth: "270px",
         display: "flex",
         flexDirection: "column",
      } as SxProps,
      tabBox: {
         borderRadius: "8px",
         border: `1px solid ${isLight ? GRAY_V2[300] : BLACK_V2[700]}`,
         backgroundColor: isLight ? GRAY_V2[50] : BLACK_V2[500],
         p: "24px",
         flexGrow: 1,
      } as SxProps,
      personIcon: { width: 32, height: 32 },
      textInputProps: {
         style: {
            height: "48px",
            paddingLeft: "12px",
            paddingRight: "12px",
            color: GRAY_V2[isLight ? 900 : 500],
         },
      },
      saveButton: {
         minWidth: "100px",
         boxShadow: "none",
         height: "36px",
         color: PRIMARY_V2[isLight ? 500 : 400],
         borderColor: PRIMARY_V2[isLight ? 500 : 400],
      } as SxProps,
      headerIcon: { color: GRAY_V2[isLight ? 900 : 500] },
      selectBaseItem: { pointerEvents: "none", color: `${PRIMARY_V2[isLight ? 500 : 400]} !important` },
      selectProps: {
         MenuProps: {
            PaperProps: {
               sx: {
                  backgroundColor: `${isLight ? GRAY_V2[50] : BLACK_V2[600]} !important`,
                  "& .Mui-selected": {
                     backgroundColor: `${isLight ? GRAY_V2[100] : BLACK_V2[700]} !important`,
                  },
                  "& .MuiMenuItem-root": {
                     fontSize: 14,
                     color: GRAY_V2[isLight ? 900 : 500],
                  },
               },
            },
         },
      },
   };

   return (
      <Box display="flex" flexDirection="column" gap="24px" color={GRAY_V2[isLight ? 900 : 500]}>
         <Box display="flex" gap="24px" width="100%">
            <Box sx={styles.userBox}>
               <PersonIcon sx={styles.personIcon} />
               <Box display="flex" flexDirection="column">
                  <Typography fontSize="16px">{userInfo?.name + " " + userInfo?.lastName}</Typography>
                  <Typography fontSize="11px" mt="-2px">
                     {userInfo?.mail}
                  </Typography>
               </Box>
            </Box>
            <Box sx={styles.headerBox}>
               {selectedArea === 0 && (
                  <>
                     <GeneralInfoIcon color={GRAY_V2[isLight ? 900 : 500]} />
                     <Typography fontSize="20px" fontWeight={600}>
                        <FormattedMessage id="global.generalinformation" />
                     </Typography>
                  </>
               )}
               {selectedArea === 1 && (
                  <>
                     <SettingsIcon sx={styles.headerIcon} />
                     <Typography fontSize="20px" fontWeight={600}>
                        <FormattedMessage id="global.settings" />
                     </Typography>
                  </>
               )}
               {selectedArea === 2 && (
                  <>
                     <PasswordIcon sx={styles.headerIcon} />
                     <Typography fontSize="20px" fontWeight={600}>
                        <FormattedMessage id="global.passwordandsecurity" />
                     </Typography>
                  </>
               )}
               {selectedArea === 3 && (
                  <>
                     <GridView sx={styles.headerIcon} />
                     <Typography fontSize="20px" fontWeight={600}>
                        <FormattedMessage id="plantTracking.view" />
                     </Typography>
                  </>
               )}
            </Box>
         </Box>
         <Box display="flex" gap="24px" alignItems="flex-start" width="100%">
            <Box sx={styles.menuBox}>
               <MenuList>
                  <MenuItem onClick={() => setSelectedArea(0)}>
                     <GeneralInfoIcon
                        color={selectedArea === 0 ? PRIMARY_V2[isLight ? 500 : 400] : GRAY_V2[isLight ? 900 : 500]}
                     />
                     <Typography
                        ml="8px"
                        fontSize="16px"
                        fontWeight={selectedArea === 0 ? 700 : 400}
                        color={selectedArea === 0 ? PRIMARY_V2[isLight ? 500 : 400] : "inherit"}
                     >
                        <FormattedMessage id="global.generalinformation" />
                     </Typography>
                  </MenuItem>
               </MenuList>
               <MenuList>
                  <MenuItem onClick={() => setSelectedArea(1)}>
                     <SettingsIcon sx={{ color: selectedArea === 1 ? PRIMARY_V2[isLight ? 500 : 400] : "inherit" }} />
                     <Typography
                        ml="8px"
                        fontSize="16px"
                        fontWeight={selectedArea === 1 ? 700 : 400}
                        color={selectedArea === 1 ? PRIMARY_V2[isLight ? 500 : 400] : "inherit"}
                     >
                        <FormattedMessage id="global.settings" />
                     </Typography>
                  </MenuItem>
               </MenuList>
               <MenuList>
                  <MenuItem onClick={() => setSelectedArea(2)}>
                     <PasswordIcon sx={{ color: selectedArea === 2 ? PRIMARY_V2[isLight ? 500 : 400] : "inherit" }} />
                     <Typography
                        ml="8px"
                        fontSize="16px"
                        fontWeight={selectedArea === 2 ? 700 : 400}
                        color={selectedArea === 2 ? PRIMARY_V2[isLight ? 500 : 400] : "inherit"}
                     >
                        <FormattedMessage id="global.passwordandsecurity" />
                     </Typography>
                  </MenuItem>
               </MenuList>
               <MenuList>
                  <MenuItem onClick={() => setSelectedArea(3)}>
                     <GridView sx={{ color: selectedArea === 3 ? PRIMARY_V2[isLight ? 500 : 400] : "inherit" }} />
                     <Typography
                        ml="8px"
                        fontSize="16px"
                        fontWeight={selectedArea === 3 ? 700 : 400}
                        color={selectedArea === 3 ? PRIMARY_V2[isLight ? 500 : 400] : "inherit"}
                     >
                        <FormattedMessage id="plantTracking.appearance" />
                     </Typography>
                  </MenuItem>
               </MenuList>
            </Box>
            <Box sx={styles.tabBox}>
               {selectedArea === 0 && (
                  <Box display="flex" flexDirection="column">
                     <Typography fontSize="20px" color={PRIMARY_V2[isLight ? 500 : 400]} mb="32px">
                        <FormattedMessage id="customeronboarding.userinformations" />
                     </Typography>
                     <Box display="flex" flexWrap="wrap" gap="32px">
                        <Box width="450px">
                           <Typography mb="12px" color={GRAY_V2[isLight ? 500 : 900]}>
                              <FormattedMessage id="global.group" />
                              {"*"}
                           </Typography>
                           <CustomTextField
                              disabled
                              InputProps={styles.textInputProps}
                              value={(userState.permissions?.groups || [{ name: "Unknown" }])[0].name}
                           />
                        </Box>
                        <Box width="450px">
                           <Typography mb="12px">
                              <FormattedMessage id="global.name" />
                              {"*"}
                           </Typography>
                           <CustomTextField
                              InputProps={styles.textInputProps}
                              value={userInfo?.name ?? ""}
                              onChange={(e) => setUserInfo((prev) => prev && { ...prev, name: e.target.value })}
                           />
                        </Box>
                        <Box width="450px">
                           <Typography mb="12px">
                              <FormattedMessage id="global.surname" />
                              {"*"}
                           </Typography>
                           <CustomTextField
                              InputProps={styles.textInputProps}
                              value={userInfo?.lastName ?? ""}
                              onChange={(e) => setUserInfo((prev) => prev && { ...prev, lastName: e.target.value })}
                           />
                        </Box>
                        <Box width="450px">
                           <Typography mb="12px">
                              <FormattedMessage id="global.mail" />
                           </Typography>
                           <CustomTextField
                              InputProps={styles.textInputProps}
                              value={userInfo?.mail ?? ""}
                              onChange={(e) => setUserInfo((prev) => prev && { ...prev, mail: e.target.value })}
                           />
                        </Box>
                     </Box>
                  </Box>
               )}
               {selectedArea === 1 && (
                  <Box>
                     <TabContainer>
                        <TabItem isSelected={settingsTab === 0} onClick={() => setSettingsTab(0)}>
                           <FormattedMessage id="global.generalsettings" />
                        </TabItem>
                        <TabItem isSelected={settingsTab === 1} onClick={() => setSettingsTab(1)}>
                           <FormattedMessage id="menu.group.text.proposalplanning" />
                        </TabItem>
                     </TabContainer>
                     {settingsTab === 0 && (
                        <>
                           <Box display="flex" flexWrap="wrap" gap="32px">
                              <Box width="450px">
                                 <Typography mb="12px">
                                    <FormattedMessage id="global.unitsettings" />
                                 </Typography>
                                 <CustomTextField
                                    select
                                    InputProps={styles.textInputProps}
                                    SelectProps={styles.selectProps}
                                    value={userInfo?.unit ?? "mwh"}
                                    onChange={(e) => setUserInfo((prev) => prev && { ...prev, unit: e.target.value })}
                                 >
                                    <MenuItem sx={styles.selectBaseItem}>
                                       <FormattedMessage id="global.unitsettings" />
                                    </MenuItem>
                                    <MenuItem key="mwh" value="mwh">
                                       <FormattedMessage id="global.mwh" />
                                    </MenuItem>
                                    <MenuItem key="kwh" value="kwh">
                                       <FormattedMessage id="global.kwh" />
                                    </MenuItem>
                                 </CustomTextField>
                              </Box>
                              <Box width="450px" color={GRAY_V2[isLight ? 500 : 900]}>
                                 <Typography mb="12px">GraphQL API Key</Typography>
                                 <CustomTextField
                                    disabled
                                    InputProps={styles.textInputProps}
                                    value={userState?.permissions?.graphQlAuthKey ?? ""}
                                 />
                              </Box>
                              <Box width="450px">
                                 <Box display="flex" gap="4px" alignItems="center" mb="12px">
                                    <Typography>
                                       <FormattedMessage id="global.timezone" />
                                    </Typography>
                                    <Tooltip title={<FormattedMessage id="global.timezone.info.tooltip" />}>
                                       <InfoIcon fontSize="small" />
                                    </Tooltip>
                                 </Box>
                                 <CustomTextField
                                    select
                                    InputProps={styles.textInputProps}
                                    SelectProps={styles.selectProps}
                                    value={userInfo?.timeZone?.value ?? ""}
                                    onChange={(e) => {
                                       const newValue = timeZoneOptions.find((x) => x.value === e.target.value);
                                       setUserInfo((prev) => prev && { ...prev, timeZone: newValue });
                                    }}
                                 >
                                    <MenuItem sx={styles.selectBaseItem}>
                                       <FormattedMessage id="global.timezone" />
                                    </MenuItem>
                                    {timeZoneOptions.map((x) => (
                                       <MenuItem key={x.value} value={x.value}>
                                          {x.label}
                                       </MenuItem>
                                    ))}
                                 </CustomTextField>
                                 <Typography fontSize="10px" mt="5px">
                                    {formatMessage(
                                       { id: "global.timezone.title.sample.time" },
                                       { sampletime: formatDateTimeWithOffset() }
                                    )}
                                 </Typography>
                              </Box>
                              <Box width="450px">
                                 <Typography mb="12px">
                                    <FormattedMessage id="global.applicationLanguage" />
                                 </Typography>
                                 <CustomTextField
                                    select
                                    InputProps={styles.textInputProps}
                                    SelectProps={styles.selectProps}
                                    value={userInfo?.cultureInfo?.value ?? ""}
                                    onChange={(e) => {
                                       const newValue = cultureInfoOptions.find((x) => x.value === e.target.value);
                                       setUserInfo((prev) => prev && { ...prev, cultureInfo: newValue });
                                    }}
                                 >
                                    <MenuItem sx={styles.selectBaseItem}>
                                       <FormattedMessage id="global.applicationLanguage" />
                                    </MenuItem>
                                    {cultureInfoOptions.map((x) => (
                                       <MenuItem key={x.value} value={x.value}>
                                          {x.label}
                                       </MenuItem>
                                    ))}
                                 </CustomTextField>
                              </Box>
                              <Box width="450px">
                                 <Typography mb="12px">
                                    <FormattedMessage id="global.fileManagerCSVType" />
                                 </Typography>
                                 <CustomTextField
                                    select
                                    InputProps={styles.textInputProps}
                                    SelectProps={styles.selectProps}
                                    value={userInfo?.csvViewType ?? "0"}
                                    onChange={(e) => {
                                       setUserInfo((prev) => prev && { ...prev, csvViewType: e.target.value });
                                       updateProfileData("csvViewType", e.target.value);
                                    }}
                                 >
                                    <MenuItem sx={styles.selectBaseItem}>
                                       <FormattedMessage id="global.fileManagerCSVType" />
                                    </MenuItem>
                                    <MenuItem key="texteditor-0" value="0">
                                       Text Editor
                                    </MenuItem>
                                    <MenuItem key="table-1" value="1">
                                       <FormattedMessage id="global.table" />
                                    </MenuItem>
                                 </CustomTextField>
                              </Box>
                           </Box>
                        </>
                     )}
                     {settingsTab === 1 && (
                        <>
                           <Box display="flex" flexWrap="wrap" alignItems="flex-end" gap="32px">
                              <Box width="450px">
                                 <Typography mb="12px">
                                    <FormattedMessage id="global.netpositiondisplay" />
                                 </Typography>
                                 <CustomTextField
                                    select
                                    InputProps={styles.textInputProps}
                                    SelectProps={styles.selectProps}
                                    value={userInfo?.netPositionDisplay ?? "0"}
                                    onChange={(e) =>
                                       setUserInfo((prev) => prev && { ...prev, netPositionDisplay: e.target.value })
                                    }
                                 >
                                    <MenuItem sx={styles.selectBaseItem}>
                                       <FormattedMessage id="global.netpositiondisplay" />
                                    </MenuItem>
                                    <MenuItem key="default-0" value="0">
                                       <FormattedMessage id="global.netpositiondisplay.default" />
                                    </MenuItem>
                                    <MenuItem key="buysellpriceposition-1" value="1">
                                       <FormattedMessage id="global.netpositiondisplay.buysellpriceposition" />
                                    </MenuItem>
                                 </CustomTextField>
                              </Box>
                              <Box width="450px">
                                 <EllipsisTypography 
                                    text={<FormattedMessage id="global.proposalconfirmationpopupoption" />} 
                                    sx={{ marginBottom: "12px" }}
                                 />
                                 <CustomTextField
                                    select
                                    InputProps={styles.textInputProps}
                                    SelectProps={styles.selectProps}
                                    value={userInfo?.proposalConfirmationPopupOption ?? "1"}
                                    onChange={(e) =>
                                       setUserInfo(
                                          (prev) => prev && { ...prev, proposalConfirmationPopupOption: e.target.value }
                                       )
                                    }
                                 >
                                    <MenuItem sx={styles.selectBaseItem}>
                                       <FormattedMessage id="global.proposalconfirmationpopupoption" />
                                    </MenuItem>
                                    <MenuItem key="off-0" value="0">
                                       <FormattedMessage id="global.off" />
                                    </MenuItem>
                                    <MenuItem key="on-1" value="1">
                                       <FormattedMessage id="global.on" />
                                    </MenuItem>
                                 </CustomTextField>
                              </Box>
                              <Box width="450px">
                                 <Tooltip title={<FormattedMessage id="div.dynamiccolorappearance" />}>
                                    <Box display="flex" alignItems="center" gap="4px">
                                    <Typography>
                                       <FormattedMessage id="global.dynamiccolorappearance" />
                                    </Typography>
                                    <InfoIcon sx={{ width: 16, height: 16 }} />
                                 </Box>
                                 </Tooltip>
                                 <Box display="flex" alignItems="center" height="48px">
                                    <IOSSwitch 
                                       checked={userInfo?.dynamicColorAppearance ?? true} 
                                       onChange={(e) => handleDynamicColorAppearance(e.target.checked)}
                                    />
                                 </Box>
                              </Box>
                           </Box>
                           <Box display="flex" flexDirection="column" gap="12px" mt="32px">
                              <Box display="flex" alignItems="center" gap="4px">
                                 <Typography>
                                    <FormattedMessage id="settings.newgipplanning.dynamicfontsizechange.title" />
                                 </Typography>
                                 <Tooltip title={<FormattedMessage id="settings.newgipplanning.dynamicfontsizechange.tooltip" />}>
                                    <InfoIcon sx={{ width: 16, height: 16 }} />
                                 </Tooltip>
                              </Box>
                              <Typography fontSize={`${DEFAULT_FONT_SIZE + (userInfo?.dynamicFontSizeChange ?? 0)}px`}>
                                 <FormattedMessage id="div.settingsfontinfo" />
                              </Typography>
                              <Box display="flex" alignItems="center" gap="12px">
                                 <Typography fontSize="12px">A</Typography>
                                 <Box display="flex" width="180px">
                                    <IOSSlider
                                       size="medium"
                                       value={DEFAULT_FONT_SIZE + (userInfo?.dynamicFontSizeChange ?? 0)}
                                       step={1}
                                       marks={FONT_MARKS}
                                       min={12}
                                       max={18}
                                       onChange={(_, newValue) => {
                                          const newFontSizeChange = (newValue as number) - DEFAULT_FONT_SIZE;
                                          setUserInfo(prev => prev && ({
                                             ...(prev ?? {}),
                                             dynamicFontSizeChange: newFontSizeChange
                                          }));
                                          updateProfileData(EUserSettings.DynamicFontSizeChange, newFontSizeChange.toString());
                                       }}
                                    />
                                 </Box>
                                 <Typography fontSize="18px">A</Typography>
                                 <Tooltip title={<FormattedMessage id="settings.newgipplanning.dynamicfontsizechange.resettodefault" />}>
                                       <IconButton 
                                          onClick={() => {
                                             if (userInfo?.dynamicFontSizeChange === 0) return;
                                             setUserInfo(prev => prev && ({
                                                ...(prev ?? {}),
                                                dynamicFontSizeChange: 0
                                             }));
                                             updateProfileData(EUserSettings.DynamicFontSizeChange, "0");
                                          }}
                                          sx={{ alignSelf: "baseline" }}
                                       >
                                          <AutorenewIcon />
                                       </IconButton>
                                 </Tooltip>
                              </Box>
                           </Box>
                           <Typography fontSize="20px" color={PRIMARY_V2[isLight ? 500 : 400]} mb="24px" mt="24px">
                              <FormattedMessage id="global.botsettings" />
                           </Typography>
                           <Box display="flex" flexWrap="wrap" alignItems="flex-end" gap="32px">
                              <Box width="450px">
                                 <Typography mb="12px">
                                    <FormattedMessage id="smartbot.select.share" />
                                 </Typography>
                                 <CustomTextField
                                    select
                                    InputProps={styles.textInputProps}
                                    SelectProps={styles.selectProps}
                                    value={userInfo?.defaultSmartBotSharedStatus ?? 0}
                                    onChange={(e) =>
                                       setUserInfo(
                                          (prev) =>
                                             prev && { ...prev, defaultSmartBotSharedStatus: Number(e.target.value) }
                                       )
                                    }
                                 >
                                    <MenuItem sx={styles.selectBaseItem}>
                                       <FormattedMessage id="smartbot.select.share" />
                                    </MenuItem>
                                    <MenuItem key="none-0" value={0}>
                                       <FormattedMessage id="smartbot.share.none" />
                                    </MenuItem>
                                    <MenuItem key="close-1" value={1}>
                                       <FormattedMessage id="smartbot.share.close" />
                                    </MenuItem>
                                    <MenuItem key="all-2" value={2}>
                                       <FormattedMessage id="smartbot.share.all" />
                                    </MenuItem>
                                 </CustomTextField>
                              </Box>
                              <Box width="450px">
                                 <Typography mb="12px">
                                    <FormattedMessage id="global.timeSettings" />
                                 </Typography>
                                 <Box display="flex" height="48px">
                                    <RadioGroup
                                       row
                                       value={userInfo?.hourSettings}
                                       onChange={(e) => {
                                          setUserInfo((prev) => prev && { ...prev, hourSettings: e.target.value });
                                          updateProfileData("hourSettings", e.target.value);
                                       }}
                                    >
                                       <FormControlLabel
                                          value="false"
                                          sx={{ fontSize: "16px" }}
                                          control={
                                             <Radio
                                                sx={{ "&.Mui-checked": { color: PRIMARY_V2[isLight ? 500 : 400] } }}
                                             />
                                          }
                                          label={
                                             <Typography fontSize="16px">
                                                {formatMessage({ id: "global.number" }) + " (1,2,3,4)"}
                                             </Typography>
                                          }
                                       />
                                       <FormControlLabel
                                          value="true"
                                          control={
                                             <Radio
                                                sx={{ "&.Mui-checked": { color: PRIMARY_V2[isLight ? 500 : 400] } }}
                                             />
                                          }
                                          label={
                                             <Typography fontSize="16px">
                                                {formatMessage({ id: "global.hType" }) + " (H1,H2,H3,H4)"}
                                             </Typography>
                                          }
                                       />
                                    </RadioGroup>
                                 </Box>
                              </Box>
                              <Box width="450px">
                                 <Tooltip
                                    // title={formatMessage({ id: "intradayplanning.smartbotDublicateCheck" })}
                                    title={"intradayplanning.smartbotDublicateCheck"}
                                    enterDelay={5}
                                 >
                                    <Box display="flex" alignItems="center" gap="4px" mb="12px">
                                       <Typography>
                                          <FormattedMessage id="global.smartBotControl" />
                                       </Typography>
                                       <InfoIcon sx={{ width: 16, height: 16 }} />
                                    </Box>
                                 </Tooltip>
                                 <Box display="flex" alignItems="center" height="48px">
                                    <IOSSwitch
                                       checked={userInfo?.smartBotSettings}
                                       onChange={(e) => handleSmartBotSettingsChange(e.target.checked)}
                                    />
                                 </Box>
                              </Box>
                           </Box>
                        </>
                     )}
                  </Box>
               )}
               {selectedArea === 2 && (
                  <Box>
                     <Typography fontSize="20px" color={PRIMARY_V2[isLight ? 500 : 400]} mb="24px">
                        <FormattedMessage id="global.password" />
                     </Typography>
                     <Box display="flex" flexWrap="wrap" gap="32px">
                        <Box width="450px">
                           <Typography mb="12px">
                              <FormattedMessage id="customeronboarding.currentpassword" />
                           </Typography>
                           <CustomTextField
                              type={showCurrentPassword ? "text" : "password"}
                              InputProps={{
                                 ...styles.textInputProps,
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <LockOutlined />
                                    </InputAdornment>
                                 ),
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <IconButton onClick={() => setShowCurrentPassword((prev) => !prev)} edge="end">
                                          {showCurrentPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                       </IconButton>
                                    </InputAdornment>
                                 ),
                                 style: {
                                    ...styles.textInputProps.style,
                                    fontSize: showCurrentPassword ? "inherit" : "28px",
                                 },
                              }}
                              value={userInfo?.currentPassword ?? ""}
                              onChange={(e) =>
                                 setUserInfo((prev) => prev && { ...prev, currentPassword: e.target.value })
                              }
                           />
                        </Box>
                        <Box width="450px">
                           <Typography mb="12px">
                              <FormattedMessage id="customeronboarding.newpassword" />
                           </Typography>
                           <CustomTextField
                              type={showNewPassword ? "text" : "password"}
                              InputProps={{
                                 ...styles.textInputProps,
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <LockOutlined />
                                    </InputAdornment>
                                 ),
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <IconButton onClick={() => setShowNewPassword((prev) => !prev)} edge="end">
                                          {showNewPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                       </IconButton>
                                    </InputAdornment>
                                 ),
                                 style: {
                                    ...styles.textInputProps.style,
                                    fontSize: showNewPassword ? "inherit" : "28px",
                                 },
                              }}
                              value={userInfo?.newPassword ?? ""}
                              onChange={(e) => {
                                 setforceMultiFactorCode(Boolean(userInfo?.hasMultiFactor && e.target.value != ""));
                                 setUserInfo((prev) => prev && { ...prev, newPassword: e.target.value });
                              }}
                           />
                        </Box>
                        <Box width="450px">
                           <Typography mb="12px">
                              <FormattedMessage id="customeronboarding.passwordrepeat" />
                           </Typography>
                           <CustomTextField
                              type={showRepeatPassword ? "text" : "password"}
                              InputProps={{
                                 ...styles.textInputProps,
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <LockOutlined />
                                    </InputAdornment>
                                 ),
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <IconButton onClick={() => setShowRepeatPassword((prev) => !prev)} edge="end">
                                          {showRepeatPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                       </IconButton>
                                    </InputAdornment>
                                 ),
                                 style: {
                                    ...styles.textInputProps.style,
                                    fontSize: showRepeatPassword ? "inherit" : "28px",
                                 },
                              }}
                              value={userInfo?.newPasswordAgain ?? ""}
                              onChange={(e) => {
                                 if (userInfo?.newPassword == e.target.value && userInfo?.multiFactorProvider == 2) {
                                    sendNewCode();
                                 }
                                 setUserInfo((prev) => prev && { ...prev, newPasswordAgain: e.target.value });
                              }}
                           />
                        </Box>
                        <Box width="450px">
                           <Typography mb="12px">
                              <FormattedMessage id="global.validitydate" />
                           </Typography>
                           <CustomTextField
                              select
                              InputProps={styles.textInputProps}
                              SelectProps={styles.selectProps}
                              value={userInfo?.passwordExpiration || 6}
                              onChange={(e) =>
                                 setUserInfo((prev) => prev && { ...prev, passwordExpiration: Number(e.target.value) })
                              }
                           >
                              <MenuItem sx={styles.selectBaseItem}>
                                 <FormattedMessage id="global.validitydate" />
                              </MenuItem>
                              <MenuItem key="month-1" value={1}>
                                 {"1 "}
                                 <FormattedMessage id="global.month" />
                              </MenuItem>
                              <MenuItem key="month-3" value={3}>
                                 {"3 "}
                                 <FormattedMessage id="global.months" />
                              </MenuItem>
                              <MenuItem key="month-6" value={6}>
                                 {"6 "}
                                 <FormattedMessage id="global.months" />
                              </MenuItem>
                           </CustomTextField>
                        </Box>
                        <Box width="450px">
                           <Box display="flex" alignItems="center" justifyContent="space-between" mb="12px">
                              <Box maxWidth="420px" display="flex" alignItems="center" gap="4px">
                                 <EllipsisTypography text={<FormattedMessage id="customeronboarding.currentipaddress" />} />
                                 <Typography fontWeight={700} color={PRIMARY_V2[isLight ? 500 : 400]}>
                                    {userInfo?.currentIP}
                                 </Typography>
                                 <Tooltip title={<FormattedMessage id="global.ipaddresstooltip" />}>
                                    <InfoIcon sx={{ width: 16, height: 16 }} />
                                 </Tooltip>
                              </Box>
                              <IconButton
                                 onClick={() => {
                                    let allowedIpAddresses = userInfo?.allowedIpAddresses;
                                    if (allowedIpAddresses == null || allowedIpAddresses.length == 0) {
                                       allowedIpAddresses = userInfo?.currentIP;
                                    } else if (
                                       userInfo?.currentIP &&
                                       allowedIpAddresses.indexOf(userInfo?.currentIP) < 0
                                    ) {
                                       allowedIpAddresses += ", " + userInfo?.currentIP;
                                    }
                                    setUserInfo(
                                       (prev) => prev && { ...prev, allowedIpAddresses: allowedIpAddresses || "" }
                                    );
                                 }}
                                 sx={{ p: 0, color: PRIMARY_V2[isLight ? 500 : 400] }}
                              >
                                 <AddCircleOutline />
                              </IconButton>
                           </Box>
                           <CustomTextField
                              InputProps={styles.textInputProps}
                              placeholder={formatMessage({ id: "customeronboarding.allowedIpAddresses" })}
                              value={userInfo?.allowedIpAddresses ?? ""}
                              onChange={(e) =>
                                 setUserInfo((prev) => prev && { ...prev, allowedIpAddresses: e.target.value })
                              }
                           />
                        </Box>
                     </Box>
                     <Typography color={[RED_V2[isLight ? 500 : 400]]} mt="12px">
                        {passwordPolicyMessage}
                     </Typography>
                     <Typography fontSize="20px" color={PRIMARY_V2[isLight ? 500 : 400]} mb="24px" mt="24px">
                        <FormattedMessage id="global.security" />
                     </Typography>
                     <Box display="flex" alignItems="center" gap="8px" mb="24px">
                        <Typography>
                           <FormattedMessage id="div.multiauthstatus" />
                        </Typography>
                        <IOSSwitch
                           checked={Boolean(userInfo?.checkedAuth)}
                           onChange={async () => {
                              const newCheckedAuth = !userInfo?.checkedAuth;
                              setUserInfo((prev) => prev && { ...prev, checkedAuth: newCheckedAuth });
                              setIsConfirmMultiFactorCode(false);
                              await enableMultiFactorAuth(newCheckedAuth);
                           }}
                        />
                     </Box>
                     <Box display="flex" flexWrap="wrap" gap="32px">
                        <Box width="450px">
                           <CustomTextField
                              select
                              disabled={!userInfo?.checkedAuth}
                              InputProps={styles.textInputProps}
                              value={userInfo?.multiFactorProvider ?? 2}
                              onChange={async (e) => {
                                 setUserInfo(
                                    (prev) => prev && { ...prev, multiFactorProvider: Number(e.target.value) }
                                 );
                                 await enableMultiFactorAuth(true, Number(e.target.value));
                              }}
                           >
                              <MenuItem key="authenticator" value={1}>
                                 Authenticator
                              </MenuItem>
                              <MenuItem key="mail" value={2}>
                                 <FormattedMessage id="smartbot.audiencetype_2" />
                              </MenuItem>
                           </CustomTextField>
                        </Box>
                        <Box width="450px">
                           <CustomTextField
                              placeholder={`${formatMessage({ id: "global.authenticationcode" })} *`}
                              InputProps={styles.textInputProps}
                              onChange={(e) => setUserCode(e.target.value)}
                           />
                        </Box>
                     </Box>
                     {userInfo?.checkedAuth && qrCodeUrl && (
                        <Box>
                           <img src={qrCodeUrl} width="300px" height="300px" />
                           <Typography ml="20px">
                              1-
                              <FormattedMessage id="div.googlestep1" />
                              <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=tr&gl=US">
                                 Android
                              </a>{" "}
                              &nbsp;
                              <a href="https://apps.apple.com/tr/app/google-authenticator/id388497605">iOS</a>
                           </Typography>
                           <Typography ml="20px">
                              2-
                              <FormattedMessage id="div.googlestep2" />
                           </Typography>
                           <Typography ml="20px">
                              3-
                              <FormattedMessage id="div.googlestep3" />
                           </Typography>
                        </Box>
                     )}
                     <Box display="flex" flexWrap="wrap-reverse" gap="32px" mt="12px">
                        <Box width="450px">
                           {forceMultiFactorCode && userInfo?.multiFactorProvider == 2 && (
                              <Box display="inline-flex" mr="16px">
                                 <Button variant="contained" sx={styles.saveButton} onClick={sendNewCode}>
                                    <Typography
                                       fontWeight={600}
                                       fontSize="16px"
                                       textTransform="none"
                                       color={GRAY_V2[50]}
                                    >
                                       <FormattedMessage id="customeronboarding.sendnewcode" />
                                    </Typography>
                                 </Button>
                              </Box>
                           )}
                           <Button
                              disabled={!userInfo?.checkedAuth}
                              variant="contained"
                              sx={styles.saveButton}
                              onClick={checkMultiFACode}
                           >
                              <Typography fontWeight={600} fontSize="16px" textTransform="none" color={GRAY_V2[50]}>
                                 <FormattedMessage id="intradayplanning.check" />
                              </Typography>
                           </Button>
                        </Box>
                        <Typography color={[RED_V2[isLight ? 500 : 400]]}>
                           <FormattedMessage id="customeronboarding.mustentermultifacode" />
                        </Typography>
                     </Box>
                     <Typography fontSize="20px" color={PRIMARY_V2[isLight ? 500 : 400]} mb="32px" mt="32px">
                        <FormattedMessage id="screenTimeout" />
                     </Typography>
                     <Typography fontWeight={700} mb="32px">
                        <FormattedMessage id="global.authtext" />
                     </Typography>
                     <Box display="flex" alignItems="center" gap="4px" mb="32px">
                        <Typography
                           fontWeight={700}
                           sx={{ textDecoration: "underline" }}
                           onClick={() => setFormModalOpen(true)}
                        >
                           <FormattedMessage id="global.formtext" />
                        </Typography>
                        <Typography>
                           <FormattedMessage id="global.authtext2" />
                        </Typography>
                        <Checkbox
                           size="small"
                           sx={{ "&.Mui-checked": { color: PRIMARY_V2[isLight ? 500 : 400] } }}
                           disabled={userInfo?.mailCheckedAuth ? false : !formIsReaded}
                           checked={userInfo?.mailCheckedAuth}
                           onChange={() =>
                              setUserInfo((prev) => prev && { ...prev, mailCheckedAuth: !prev.mailCheckedAuth })
                           }
                        />
                     </Box>
                     <Box display="flex" flexWrap="wrap" gap="32px">
                        <Box width="450px">
                           <Typography mb="12px">
                              <FormattedMessage id="global.sentemailcode" />
                           </Typography>
                           <CustomTextField
                              InputProps={styles.textInputProps}
                              placeholder={formatMessage({ id: "global.authenticationcode" }) + "*"}
                              value={userTimeOutCode}
                              onChange={(e) => setUserTimeOutCode(e.target.value)}
                           />
                        </Box>
                        <Box width="450px">
                           <Typography mb="12px">
                              <FormattedMessage id="pleaseSelectItem" />
                           </Typography>
                           <CustomTextField
                              select
                              disabled={!mailCodeisCorrect}
                              InputProps={styles.textInputProps}
                              SelectProps={styles.selectProps}
                              value={userInfo?.selectedMuniteValue}
                              onChange={(e) =>
                                 setUserInfo((prev) => prev && { ...prev, selectedMuniteValue: e.target.value })
                              }
                           >
                              <MenuItem sx={styles.selectBaseItem}>
                                 <FormattedMessage id="pleaseSelectItem" />
                              </MenuItem>
                              {Object.entries(muniteValues).map(([key, value]) => (
                                 <MenuItem key={"muniteValue-" + key} value={value}>
                                    {value / 60 > 1
                                       ? `${value / 60} ${formatMessage({ id: "global.hour" })}`
                                       : `${value} ${formatMessage({ id: "global.minute" })}`}
                                 </MenuItem>
                              ))}
                           </CustomTextField>
                        </Box>
                     </Box>
                     <Box display="flex" gap="16px" mt="32px">
                        <Button
                           variant="outlined"
                           disabled={!userInfo?.mailCheckedAuth}
                           sx={styles.saveButton}
                           onClick={sendNewCode}
                        >
                           <Typography fontWeight={600} fontSize="16px" textTransform="none">
                              <FormattedMessage id="customeronboarding.sendnewcode" />
                           </Typography>
                        </Button>

                        <Button
                           variant="contained"
                           sx={styles.saveButton}
                           disabled={!userInfo?.mailCheckedAuth}
                           onClick={checkMultiFACodeforTimeOut}
                        >
                           <Typography fontWeight={600} fontSize="16px" textTransform="none" color={GRAY_V2[50]}>
                              <FormattedMessage id="intradayplanning.check" />
                           </Typography>
                        </Button>
                     </Box>
                     <Typography fontSize="20px" color={PRIMARY_V2[isLight ? 500 : 400]} mb="32px" mt="32px">
                        <FormattedMessage id="global.authorizations" />
                     </Typography>
                     <Box display="flex" flexWrap="wrap" gap="32px">
                        {userState?.permissions?.companies.map((c) => (
                           <Box key={c.id} width="450px">
                              <CustomTextField InputProps={styles.textInputProps} value={c.fullname} />
                           </Box>
                        ))}
                     </Box>
                  </Box>
               )}
               {selectedArea === 3 && <ViewArea />}
               {selectedArea !== 3 && (
                  <Box display="flex" justifyContent="flex-end" mt="32px">
                     <Button variant="contained" sx={styles.saveButton} onClick={confirmSettings}>
                        <Typography fontWeight={600} fontSize="16px" textTransform="none" color={GRAY_V2[50]}>
                           <FormattedMessage id="global.save" />
                        </Typography>
                     </Button>
                  </Box>
               )}
            </Box>
         </Box>

         <Modal slotProps={modalSlotProps} open={formModalOpen} onClose={() => setFormModalOpen(false)}>
            <ModalContainer>
               <Typography color={GRAY_V2[isLight ? 900 : 500]}>
                  <FormattedMessage id="global.informationtext" />
               </Typography>
               <Box display="flex" gap="16px" mt="32px" flexDirection="row-reverse">
                  <OkModalButton
                     sx={{ width: "50%" }}
                     onClick={() => {
                        setFormIsReaded(true);
                        setFormModalOpen(false);
                     }}
                  >
                     <FormattedMessage id="global.ok" />
                  </OkModalButton>
               </Box>
            </ModalContainer>
         </Modal>

         <Modal slotProps={modalSlotProps} open={openSaveModal} onClose={() => setOpenSaveModal(false)}>
            <ModalContainer>
               <Box maxHeight="700px" overflow="scroll">
                  {userBotThatTimerTriggered.current.map((item) => (
                     <Box key={"auth-" + item.Id} display="flex" mb="32px" color={GRAY_V2[isLight ? 900 : 500]}>
                        <Typography width="70px">{item.Id}</Typography>
                        <Typography width="150px" ml="4px" mr="50px">
                           {item.Mode}
                        </Typography>
                        <Typography>{item.Name}</Typography>
                     </Box>
                  ))}
               </Box>
               <Box display="flex">
                  <Box width="100%" />
                  <OkModalButton
                     fullWidth
                     onClick={async () => {
                        Utils.ShowLoadingOverlay();
                        try {
                           const temp = [...CommonActions.getUserProfileData()];

                           for (const item of profileData) {
                              const storage = temp.find((a) => a.Name === item.Name);

                              if (storage != undefined) {
                                 storage.Value = item.Value;
                              } else {
                                 temp.push(item);
                              }
                           }

                           await saveUserProfileData(temp);
                           CommonActions.setUserProfileData(temp);
                           localStorage.setItem("UserTimeZoneId", userInfo?.timeZone?.value ?? "");
                           await fetchAndLoadUserProfile();
                           Utils.showSuccessMessage(formatMessage({ id: "global.warning.saved" }));
                        } catch (error) {
                           Utils.showErrorMessage(formatMessage({ id: "global.erroroccuredwhilesaving" }));
                        }
                        setOpenSaveModal(false);
                        Utils.HideLoadingOverlay();
                     }}
                  >
                     <FormattedMessage id="global.ok" />
                  </OkModalButton>
               </Box>
            </ModalContainer>
         </Modal>

         {selectedArea !== 3 && (
            <Modal
               slotProps={modalSlotProps}
               open={openPasswordAndSecuritySaveModal}
               onClose={() => setOpenPasswordAndSecuritySaveModal(false)}
            >
               <ModalContainer>
                  <Typography color={GRAY_V2[isLight ? 900 : 500]}>
                     <FormattedMessage id="div.fieldstoupdate" />;
                  </Typography>
                  <Box display="flex" flexDirection="column" gap="16px" mt="16px" ml="24px">
                     {changeInfo[selectedArea]
                        .filter((x) => x.isChange)
                        .map((x) => (
                           <Typography id={x.messageKey} display="list-item" color={PRIMARY_V2[isLight ? 500 : 400]}>
                              <FormattedMessage id={x.messageKey} />
                           </Typography>
                        ))}
                  </Box>
                  <Box display="flex" gap="16px" mt="32px">
                     <CancelModalButton fullWidth onClick={() => setOpenPasswordAndSecuritySaveModal(false)}>
                        <FormattedMessage id="global.abandon" />
                     </CancelModalButton>
                     <OkModalButton
                        fullWidth
                        onClick={async () => {
                           await saveSettings();
                           setOpenPasswordAndSecuritySaveModal(false);
                        }}
                     >
                        <FormattedMessage id="global.ok" />
                     </OkModalButton>
                  </Box>
               </ModalContainer>
            </Modal>
         )}
      </Box>
   );
};

export default SettingsPage;

const GeneralInfoIcon = (props: { color: string }) => (
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
         d="M2 5.995C2 5.445 2.446 5 2.995 5H11.005C11.2689 5 11.522 5.10483 11.7086 5.29143C11.8952 5.47803 12 5.73111 12 5.995C12 6.25889 11.8952 6.51197 11.7086 6.69857C11.522 6.88517 11.2689 6.99 11.005 6.99H2.995C2.73111 6.99 2.47803 6.88517 2.29143 6.69857C2.10483 6.51197 2 6.25889 2 5.995ZM2 12C2 11.45 2.446 11.005 2.995 11.005H21.005C21.2689 11.005 21.522 11.1098 21.7086 11.2964C21.8952 11.483 22 11.7361 22 12C22 12.2639 21.8952 12.517 21.7086 12.7036C21.522 12.8902 21.2689 12.995 21.005 12.995H2.995C2.73111 12.995 2.47803 12.8902 2.29143 12.7036C2.10483 12.517 2 12.2639 2 12ZM2.995 17.01C2.73111 17.01 2.47803 17.1148 2.29143 17.3014C2.10483 17.488 2 17.7411 2 18.005C2 18.2689 2.10483 18.522 2.29143 18.7086C2.47803 18.8952 2.73111 19 2.995 19H15.005C15.2689 19 15.522 18.8952 15.7086 18.7086C15.8952 18.522 16 18.2689 16 18.005C16 17.7411 15.8952 17.488 15.7086 17.3014C15.522 17.1148 15.2689 17.01 15.005 17.01H2.995Z"
         fill={props.color}
      />
   </svg>
);
