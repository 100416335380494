import baseService from "../base.service";
import AlertRuleDetailListRequest from "src/models/smartbot/AlertRuleDetailListRequest";
import AlertRuleDetailListResponse from "src/models/smartbot/AlertRuleDetailListResponse";
import VersionHistoryRequest from "src/models/smartbot/VersionHistoryRequest";
import DeleteAlertRuleRequest from "src/models/smartbot/DeleteAlertRuleRequest";
import ChangeAlertRuleEnabledRequest from "src/models/smartbot/ChangeAlertRuleEnabledRequest";
import AlertRuleActionParamsRequest from "src/models/smartbot/AlertRuleActionParamsRequest";
import AlertRuleActionParamsResponse from "src/models/smartbot/AlertRuleActionParamsResponse";
import VersionHistoryResponse from "src/models/smartbot/VersionHistoryResponse";
import AlertRuleActiveProposalRequest from "src/models/smartbot/AlertRuleActiveProposalRequest";
import GetConsumptionForecastBotsRequest from "src/models/smartbot/GetConsumptionForecastBotsRequest";
import BotTypeEnum from "src/enums/bot-type.enum";
import {
   IAlertRuleCompactDetailResponse,
   IAlertRuleCompactSaveResponse,
   IAlertRuleLibraryScriptDetailResponse,
   IAlertRuleLibraryScriptParameterSetSaveResponse,
   IAlertRuleListenerResponse,
   IAlertRuleListResponse,
   IGetActionCompletersResponse,
   IListenerSourceResponse,
} from "src/models/smartbot/AlertRuleResponses";
import {
   ISaveAlertRuleLibraryScriptParameterSetRequest,
   ISaveCompactAlertRuleRequest,
} from "src/models/smartbot/AlertRuleRequests";
import ActivateAlertRuleRequest from "src/models/smartbot/ActivateAlertRuleRequest";

export const getAlertRuleDetailList = async (requestData: AlertRuleDetailListRequest) => {
   const url = `Alerting/GetAlertRuleDetailList`;
   const response = await baseService.post<AlertRuleDetailListResponse>(url, requestData);
   return response.data;
};

export const getAlertRuleActionParams = async (requestData: AlertRuleActionParamsRequest) => {
   const url = `Alerting/GetAlertRuleActionParams`;
   const response = await baseService.post<AlertRuleActionParamsResponse>(url, requestData);
   return response.data;
};

export const getVersionHistory = async (requestData: VersionHistoryRequest) => {
   const url = `Alerting/GetAlertRuleVersions`;
   const response = await baseService.post<VersionHistoryResponse>(url, requestData);
   return response.data;
};

export const deleteAlertRule = async (requestData: DeleteAlertRuleRequest) => {
   const response = await baseService.post("Alerting/DeleteAlertRule", requestData);
   return response.data;
};

export const changeAlertRuleEnabled = async (requestData: ChangeAlertRuleEnabledRequest) => {
   const response = await baseService.post("Alerting/ChangeAlertRuleEnabled", requestData);
   return response.data;
};

export const getActiveProposalsByAlertRule = async (requestData: AlertRuleActiveProposalRequest) => {
   const response = await baseService.post("Alerting/GetAlertRuleActiveProposals", requestData);
   return response.data;
};

export const getActiveProposalsByAlertRuleListener = async (requestData: AlertRuleActiveProposalRequest) => {
   const response = await baseService.post("Alerting/GetAlertRuleActiveProposals", requestData);
   return response.data;
};

export const getConsumptionForecastBots = async (requestData: GetConsumptionForecastBotsRequest) => {
   const response = await baseService.post("Alerting/GetConsumptionForecastBots", requestData);
   return response.data;
};

export const getListenerSources = async (botType: BotTypeEnum) => {
   const url = `Alerting/GetListenerSources?botType=${botType}`;
   const response = await baseService.post<IListenerSourceResponse>(url);
   return response.data;
};

export const getNewCompactAlertRuleDetail = async (botType: BotTypeEnum) => {
   const url = `Alerting/GetNewCompactAlertRuleDetail`;
   const response = await baseService.post<IAlertRuleCompactDetailResponse>(url, { BotType: botType });
   return response.data;
};

export const getAlertRuleCompactListener = async (sourceId: number) => {
   const url = `Alerting/GetAlertRuleCompactListener?sourceId=${sourceId}`;
   const response = await baseService.post<IAlertRuleListenerResponse>(url);
   return response.data;
};

export const getCompactAlertRuleDetail = async (alertRuleId: number) => {
   const url = `Alerting/GetCompactAlertRuleDetail`;
   const response = await baseService.post<IAlertRuleCompactDetailResponse>(url, { Id: alertRuleId });
   return response.data;
};

export const getAlertRuleScriptDetail = async (libraryScriptId: number) => {
   const url = `Alerting/GetAlertRuleScriptDetail`;
   const response = await baseService.post<IAlertRuleLibraryScriptDetailResponse>(url, { id: libraryScriptId });
   return response.data;
};

export const saveCompactAlertRule = async (requestData: ISaveCompactAlertRuleRequest) => {
   const url = `Alerting/SaveCompactAlertRule`;
   const response = await baseService.post<IAlertRuleCompactSaveResponse>(url, requestData);
   return response.data;
};

export const saveAlertRuleLibraryScriptParameterSet = async (
   requestData: ISaveAlertRuleLibraryScriptParameterSetRequest
) => {
   const url = `Alerting/SaveAlertRuleLibraryScriptParameterSet`;
   const response = await baseService.post<IAlertRuleLibraryScriptParameterSetSaveResponse>(url, requestData);
   return response.data;
};

export const getActionCompleters = async (botType: number) => {
   const url = `Alerting/GetActionCompleters?botType=${botType}`;
   const response = await baseService.post<IGetActionCompletersResponse>(url);
   return response.data;
};

export const activateAlertRule = async (requestData: ActivateAlertRuleRequest) => {
   const response = await baseService.post("Alerting/ActivateAlertRule", requestData);
   return response.data;
};
export const GetCurrentlyRunAlertRuleDetailList = async (requestData: AlertRuleDetailListRequest) => {
   const url = `Alerting/GetCurrentlyRunAlertRuleDetailList`;
   const response = await baseService.post<AlertRuleDetailListResponse>(url, requestData);
   return response.data;
};

export const getTimeTriggeredAlertRules = async () => {
   const url = `Alerting/GetTimeTriggeredAlertRules`;
   const response = await baseService.post<IAlertRuleListResponse>(url, {});
   return response.data;
};
