import React from "react";

export interface IApplicationRouteInfo {
    path: string,
    exact?: boolean, //default: true
    componentPath?: string,
    component: React.LazyExoticComponent<React.ComponentType<any>>,
    params?: object,
    entry?: boolean, //default: true
}

export interface IApplicationDefinition {
    id: number,
    name: string,
    routeInfos: IApplicationRouteInfo[],
    idleTimeout?: number | (() => (number | undefined))
}

const calcIdleTimeout = (asMinute: number) => {
    return asMinute * 60 * 1000;
}

export const ExperimentalAppId: number = -1001;

export const ApplicationDefinitions: IApplicationDefinition[] = [
    {
        id: ExperimentalAppId,
        name: "menu.app.text.experimental",
        routeInfos: [
            {
                path: "/experimental",
                component: React.lazy(() => import("../../../../../src/pages/experimental/host.tsx")),
            }
        ]
    },
    {
        id: 1001,
        name: "menu.app.text.homepage",
        routeInfos: [
            {
                path: "/",
                component: React.lazy(() => import("../../pages/home")),
                entry: false,
            },
            {
                path: "/home",
                component: React.lazy(() => import("../../pages/home")),
            },
            // {
            //     path: "/profile",
            //     component: React.lazy(() => import("../../../../../src/pages/profile/Profile")),
            // }
        ]
    },
    {
        id: 1002,
        name: "menu.app.text.dashboard",
        routeInfos: [
            {
                path: "/dashboard",
                component: React.lazy(() => import("../../pages/dashboard")),
            }
        ]
    },
    {
        id: 2001,
        name: "menu.app.text.productionforecasts",
        routeInfos: [
            {
                path: "/gopprediction",
                component: React.lazy(() => import("../../../../old/src/pages/gopprediction")),
            }
        ]
    },
    {
        id: 2111,
        name: "menu.app.text.productionforecasts.new",
        routeInfos: [
            {
                path: "/production-forecast",
                component: React.lazy(() => import("../../../../pages/ProductionForecast")),
            }
        ]
    },
    {
        id: 2009,
        name: "menu.app.text.planthourlydataentry",
        routeInfos: [
            {
                path: "/powerplanthourlyquestioninput",
                component: React.lazy(() => import("../../pages/plantquestion/powerplanthourlyquestioninput")),
            }
        ]
    },
    {
        id: 18004,
        name: "menu.app.text.consumptionfacilityquestioninput",
        routeInfos: [
            {
                path: "/facilityquesitoninput",
                component: React.lazy(() => import("../../pages/facilityquestion/facilityquestioninput")),
            }
        ]
    },
    {
        id: 2004,
        name: "menu.app.text.plantforecastcomparison",
        routeInfos: [
            {
                path: "/predictioncomparison",
                component: React.lazy(() => import("../../pages/predictioncomparison.jsx")),
            }
        ]
    },
    {
        id: 2005,
        name: "menu.app.text.servicecontrolresults",
        routeInfos: [
            {
                path: "/servicerunlog",
                component: React.lazy(() => import("../../pages/servicerunlog/servicerunlog_OLD.jsx")),
                //component: React.lazy(() => import("../../pages/servicerunlog/index")), //TypeScript version
            }
        ]
    },
    {
        id: 2006,
        name: "menu.app.text.expertprediction",
        routeInfos: [
            {
                path: "/expertprediction",
                component: React.lazy(() => import("../../pages/expertprediction")),
            }
        ]
    },
    {
        id: 2007,
        name: "menu.app.text.turbinesettings",
        routeInfos: [
            {
                path: "/turbinesettings",
                component: React.lazy(() => import("../../pages/turbinesettings")),
            }
        ]
    },
    {
        id: 2010,
        name: "menu.app.text.predictionreliability",
        routeInfos: [
            {
                path: "/trustedproviders",
                component: React.lazy(() => import("../../pages/trustedproviders")),
            }
        ]
    },
    {
        id: 2008,
        name: "menu.app.text.turbinefault",
        routeInfos: [
            {
                path: "/turbinefault",
                component: React.lazy(() => import("../../pages/turbinefault")),
            }
        ]
    },
    {
        id: 2011,
        name: "menu.app.text.powerplantdatadistribution",
        routeInfos: [
            {
                path: "/powerplantbalancinginfo",
                component: React.lazy(() => import("../../pages/powerPlantBalancingInfo")),
            }
        ]
    },
    {
        id: 2101,
        name: "menu.app.text.dayaheadnomination",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/dayaheadnomination",
                component: React.lazy(() => import("../../pages/dayaheadnomination")),
            }
        ]
    },
    {
        id: 2102,
        name: "menu.app.text.dayaheadplanning",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/gopPlanning",
                component: React.lazy(() => import("../../pages/gopplanning/planning/gopPlanning")),
            }
        ]
    },
    {
        id: 2103,
        name: "menu.app.text.bilateralagreement",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/bilateralagreement",
                component: React.lazy(() => import("../../pages/bilateralagreement")),
            }
        ]
    },
    {
        id: 2104,
        name: "menu.app.text.consumerforecastupdate",
        routeInfos: [
            {
                path: "/updatedata/ST",
                component: React.lazy(() => import("../../pages/updatedata")),
                params: { type: "ST" }
            }
        ]
    },
    {
        id: 2105,
        name: "menu.app.text.bilateralagreementupdate",
        routeInfos: [
            {
                path: "/updatedata/IA",
                component: React.lazy(() => import("../../pages/updatedata")),
                params: { type: "IA" }
            }
        ]
    },
    {
        id: 2107,
        name: "menu.app.text.extraposition",
        routeInfos: [
            {
                path: "/extraposition",
                component: React.lazy(() => import("../../pages/extraposition")),
            }
        ]
    },
    {
        id: 2106,
        name: "menu.app.text.counterpartyba",
        routeInfos: [
            {
                path: "/counterpartyba",
                component: React.lazy(() => import("../../pages/counterpartyba")),
            }
        ]
    },
    {
        id: 2201,
        name: "menu.app.text.eaknotification",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/eaksending",
                component: React.lazy(() => import("../../pages/eaksending")),
            }
        ]
    },
    {
        id: 2202,
        name: "menu.app.text.kgupnotification",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/kgupsending",
                component: React.lazy(() => import("../../pages/kgupsending")),
            }
        ]
    },
    {
        id: 2205,
        name: "menu.app.text.mkudsending",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/mkudsending",
                component: React.lazy(() => import("../../pages/mkudsending")),
            }
        ]
    },
    {
        id: 2204,
        name: "menu.app.text.dgpoffer",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/dgpoffer",
                component: React.lazy(() => import("../../pages/dgpoffer")),
            }
        ]
    },
    {
        id: 2206,
        name: "menu.app.text.pfkoffer",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/pfkofferdata",
                component: React.lazy(() => import("../../pages/pfkofferdata")),
            }
        ]
    },
    {
        id: 2207,
        name: "menu.app.text.pfknomination",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/pfknomination",
                component: React.lazy(() => import("../../pages/pfknomination")),
            }
        ]
    },
    {
        id: 2208,
        name: "menu.app.text.sfkoffer",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/sfkofferdata",
                component: React.lazy(() => import("../../pages/sfkofferdata")),
            }
        ]
    },
    {
        id: 2203,
        name: "menu.app.text.automaticnotificationpowerplant",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/autosendingpowerplant",
                component: React.lazy(() => import("../../pages/autosendingpowerplant")),
            }
        ]
    },
    {
        id: 2209,
        name: "menu.app.text.automaticnotificationcompany",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/autosendingcompany",
                component: React.lazy(() => import("../../pages/autosendingcompany")),
            }
        ]
    },
    {
        id: 2303,
        name: "menu.app.text.smpandmtpforecast",
        routeInfos: [
            {
                path: "/ptfSmfPrediction",
                component: React.lazy(() => import("../../pages/ptfSmfPrediction")),
            }
        ]
    },
    {
        id: 2304,
        name: "menu.app.text.marketdata",
        routeInfos: [
            {
                path: "/marketData",
                component: React.lazy(() => import("../../pages/mcpsmp/estimation")),
            }
        ]
    },
    {
        id: 2401,
        name: "menu.app.text.intradayplanning",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/intradayplanning",
                component: React.lazy(() => import("../../pages/intraday/intradayplanning")),
            },
            {
                path: "/intradayplanning/capacities",
                component: React.lazy(() => import("../../pages/intraday/Capacity/Capacities")),
            }
        ]
    },
    {
        id: 2404,
        name: "menu.app.text.intradayplanning_new",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/intraday-planning",
                component: React.lazy(() => import("../../../../pages/intraday/IntradayPlanningPage.js")),
            },

            {
                path: "/intraday-planning/capacities",
                component: React.lazy(() => import("../../../../components/gip-planning/RegionCapacitiesWidget.js")),
            }
        ]
    },
    {
        id: 2406,
        name: "menu.app.text.IntradayTransactionReport",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [

            {
                path: "/intraday-transaction-report",
                component: React.lazy(() => import("../../../../pages/intraday/IntradayTransactionReportPage.js")),
            }
        ]
    },
    {
        id: 2402,
        name: "menu.app.text.quickproposal",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/instantofferlist",
                component: React.lazy(() => import("../../pages/instantoffer/list/list")),
            },
            {
                path: "/instantoffernew",
                component: React.lazy(() => import("../../pages/instantoffer/new/new")),
                entry: false
            },
            {
                path: "/instantoffer/detail/:id",
                component: React.lazy(() => import("../../pages/instantoffer/detail/detail")),
                entry: false
            }
        ]
    },
    {
        id: 2403,
        name: "menu.app.text.adminintraday",
        routeInfos: [
            {
                path: "/adminintraday",
                component: React.lazy(() => import("../../pages/adminintraday")),
            }
        ]
    },
    {
        id: 2405,
        name: "menu.app.text.intradayproposallimit",
        routeInfos: [
            {
                path: "/intradayproposallimit",
                component: React.lazy(() => import("../../pages/intradaylimit/LimitDefinitionPage")),
            }
        ]
    },
    {
        id: 2501,
        name: "menu.app.text.tradebots",
        routeInfos: [
            {
                path: "/alerting/list/1",
                component: React.lazy(() => import("src/pages/smartbots/TradeBotsPage")),
                params: { type: 1 }
            },
            {
                path: "/alerting/new/:type",
                component: React.lazy(() => import("../../pages/alerting/detail/detail")),
                params: { type: 1 },
                entry: false
            },
            {
                path: "/alerting/detail/:id",
                component: React.lazy(() => import("../../pages/alerting/detail/detail")),
                params: { type: 1 },
                entry: false
            },
            {
                path: "/alerting/detail/:id/:v",
                component: React.lazy(() => import("../../pages/alerting/detail/detail")),
                params: { type: 1 },
                entry: false
            },
            {
                path: "/alerting/report/:id",
                component: React.lazy(() => import("../../pages/alerting/report/report")),
                entry: false
            }
        ]
    },
    {
        id: 2906,
        name: "menu.app.text.generationbots",
        routeInfos: [
            {
                path: "/alerting/list/2",
                component: React.lazy(() => import("src/pages/smartbots/ForecastBotsPage")),
                params: { type: 2 }
            },
            {
                path: "/alerting/new/:type",
                component: React.lazy(() => import("../../pages/alerting/detail/detail")),
                params: { type: 2 },
                entry: false
            },
            {
                path: "/alerting/report/:id",
                component: React.lazy(() => import("../../pages/alerting/report/report")),
                entry: false
            }
        ]
    },
    {
        id: 18006,
        name: "menu.app.text.consumptionbots",
        routeInfos: [
            {
                path: "/alerting/list/64",
                component: React.lazy(() => import("src/pages/smartbots/ForecastBotsPage")),
                params: { type: 64 }
            },
            {
                path: "/alerting/new/:type",
                component: React.lazy(() => import("../../pages/alerting/detail/detail")),
                params: { type: 64 },
                entry: false
            },
            {
                path: "/alerting/report/:id",
                component: React.lazy(() => import("../../pages/alerting/report/report")),
                entry: false
            }
        ]
    },
    {
        id: 2503,
        name: "menu.app.text.operationbots",
        routeInfos: [
            {
                path: "/alerting/list/3",
                component: React.lazy(() => import("../../pages/alerting/list/list")),
                params: { type: 3 }
            },
            {
                path: "/alerting/new/3",
                component: React.lazy(() => import("../../pages/alerting/detail/detail")),
                params: { type: 3 },
                entry: false
            },
            {
                path: "/alerting/report/:id",
                component: React.lazy(() => import("../../pages/alerting/report/report")),
                entry: false
            }
        ]
    },
    {
        id: 18007,
        name: "menu.app.text.botsuperviser",
        routeInfos: [
            {
                path: "/alerting/botsuperviser",
                component: React.lazy(() => import("src/pages/smartbots/BotSuperviserPage")),
            },
            {
                path: "/alerting/report/:id",
                component: React.lazy(() => import("../../pages/alerting/report/report")),
                entry: false
            }
        ]
    },
    {
        id: 2502,
        name: "menu.app.text.console",
        routeInfos: [
            {
                path: "/alerting/consoleall",
                component: React.lazy(() => import("../../pages/alerting/console/console")),
            },
            {
                path: "/alerting/console/:id",
                component: React.lazy(() => import("../../pages/alerting/console/console")),
                entry: false
            },
            {
                path: "/alerting/report/:id",
                component: React.lazy(() => import("../../pages/alerting/report/report")),
                entry: false
            },
            {
                path: "/alerting/backtest/:id",
                component: React.lazy(() => import("../../pages/alerting/backtest/report")),
                entry: false
            }
        ]
    },
    {
        id: 2907,
        name: "menu.app.text.library",
        routeInfos: [
            {
                path: "/alerting/library/list",
                component: React.lazy(() => import("../../pages/alerting/library/list")),
            },
            {
                path: "/alerting/library/new",
                component: React.lazy(() => import("../../pages/alerting/library/detail")),
                entry: false
            },
            {
                path: "/alerting/library/detail/:id",
                component: React.lazy(() => import("../../pages/alerting/library/detail")),
                entry: false
            },
        ]
    },
    {
        id: 4001,
        name: "menu.app.text.administrativebots",
        routeInfos: [
            {
                path: "/alerting/list/16",
                component: React.lazy(() => import("../../pages/alerting/list/list")),
                params: { type: 16 }
            },
            {
                path: "/alerting/new/:type",
                component: React.lazy(() => import("../../pages/alerting/detail/detail")),
                params: { type: 16 },
                entry: false
            },
            {
                path: "/alerting/detail/:id",
                component: React.lazy(() => import("../../pages/alerting/detail/detail")),
                params: { type: 16 },
                entry: false
            },
            {
                path: "/alerting/detail/:id/:v",
                component: React.lazy(() => import("../../pages/alerting/detail/detail")),
                params: { type: 16 },
                entry: false
            },
            {
                path: "/alerting/report/:id",
                component: React.lazy(() => import("../../pages/alerting/report/report")),
                entry: false
            }
        ]
    },
    {
        id: 3005,
        name: "menu.app.text.contractitems",
        routeInfos: [
            {
                path: "/alerting/list/4",
                component: React.lazy(() => import("../../pages/alerting/list/list")),
                params: { type: 4 }
            },
            {
                path: "/alerting/new/4",
                component: React.lazy(() => import("../../pages/alerting/detail/detail")),
                params: { type: 4 },
                entry: false
            },
            {
                path: "/alerting/report/:id",
                component: React.lazy(() => import("../../pages/alerting/report/report")),
                entry: false
            }
        ]
    },
    {
        id: 2911,
        name: "menu.app.text.product",
        routeInfos: [
            {
                path: "/alerting/product",
                component: React.lazy(() => import("../../pages/alerting/product/product")),
            },
            {
                path: "/alerting/productdetail/:id",
                component: React.lazy(() => import("../../pages/alerting/product/productdetail")),
                entry: false
            },
            {
                path: "/alerting/product/new/:type",
                component: React.lazy(() => import("../../pages/alerting/product/productdetail")),
                entry: false
            }
        ]
    },
    {
        id: 2912,
        name: "menu.app.text.contracts",
        routeInfos: [
            {
                path: "/alerting/contract",
                component: React.lazy(() => import("../../pages/alerting/product/contract")),
            },
            {
                path: "/alerting/contract/new/:type",
                component: React.lazy(() => import("../../pages/alerting/product/contractdetail")),
                entry: false
            },
            {
                path: "/alerting/contractdetail/:id",
                component: React.lazy(() => import("../../pages/alerting/product/contractdetail")),
                entry: false
            },
        ]
    },
    {
        id: 2913,
        name: "menu.app.text.executecontracts",
        routeInfos: [
            {
                path: "/alerting/contract/executecontracts",
                component: React.lazy(() => import("../../pages/alerting/product/executecontracts")),
            }
        ]
    },
    {
        id: 2914,
        name: "menu.app.text.contractresults",
        routeInfos: [
            {
                path: "/alerting/contract/contractresults",
                component: React.lazy(() => import("../../pages/alerting/product/contractresults")),
            }
        ]
    },
    {
        id: 2915,
        name: "menu.app.text.exceltemplates",
        routeInfos: [
            {
                path: "/alerting/contract/exceltemplates",
                component: React.lazy(() => import("../../pages/alerting/product/exceltemplates")),
            }
        ]
    },
    {
        id: 3006,
        name: "menu.app.text.contractdatamanagement",
        routeInfos: [
            {
                path: "/alerting/contract/contractdatamanagement",
                component: React.lazy(() => import("../../pages/alerting/product/contractdatamanagement")),
            }
        ]
    },
    {
        id: 3007,
        name: "menu.app.text.contractimportdata",
        routeInfos: [
            {
                path: "/alerting/contract/importdata",
                component: React.lazy(() => import("../../pages/alerting/product/importdata")),
            }
        ]
    },
    {
        id: 3008,
        name: "menu.app.text.definecompanies",
        routeInfos: [
            {
                path: "/alerting/contract/definecompanies",
                component: React.lazy(() => import("../../pages/alerting/product/definecompanies")),
            }
        ]
    },
    {
        id: 2601,
        name: "menu.app.text.fdpprevision",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/kguprevision",
                component: React.lazy(() => import("../../pages/kguprevision")),
            }
        ]
    },
    // {
    //     id: 2602,
    //     name: "menu.app.text.intradayandkrsbot",
    //     idleTimeout: calcIdleTimeout(20),
    //     routeInfos: [
    //         {
    //             path: "/kguprevisionsettings",
    //             component: React.lazy(() => import("../../pages/kguprevisionsettings")),
    //         }
    //     ]
    // },
    {
        id: 2603,
        name: "menu.app.text.intradayandkrsbotNew",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/gipkrsSettingsNew",
                component: React.lazy(() => import("../../pages/gipkrsSettingsNew")),
            }
        ]
    },
    {
        id: 26030,
        name: "menu.app.text.intradayandkrsbotNew",
        idleTimeout: calcIdleTimeout(20),
        routeInfos: [
            {
                path: "/gipkrsSettings",
                component: React.lazy(() => import("../../pages/gipkrsSettings")),
            }
        ]
    },
    {
        id: 3001,
        name: "menu.app.text.marketpaymentschedule",
        routeInfos: [
            {
                path: "/marketpaymentschedule",
                component: React.lazy(() => import("../../pages/marketpaymentschedule")),
            }
        ]
    },
    {
        id: 3002,
        name: "menu.app.text.marketpaymentpercentage",
        routeInfos: [
            {
                path: "/marketpaymentpercentage",
                component: React.lazy(() => import("../../pages/marketpaymentpercentage")),
            }
        ]
    },
    {
        id: 3003,
        name: "menu.app.text.marketotherpayments",
        routeInfos: [
            {
                path: "/marketotherpayments",
                component: React.lazy(() => import("../../pages/marketotherpayments")),
            }
        ]
    },
    {
        id: 3004,
        name: "menu.app.text.marketpaymentflow",
        routeInfos: [
            {
                path: "/marketpaymentflow",
                component: React.lazy(() => import("../../pages/marketpaymentflow")),
            }
        ]
    },
    {
        id: 2701,
        name: "menu.app.text.dynamicreports",
        routeInfos: [
            {
                path: "/groupreport/:id",
                component: React.lazy(() => import("../../pages/groupreport")),
                entry: false
            },
            {
                path: "/groupreport",
                component: React.lazy(() => import("../../pages/groupreport")),
            }
        ]
    },
    {
        id: 2705,
        name: "menu.app.text.dayaheadposition",
        idleTimeout: calcIdleTimeout(120), // 2sa
        routeInfos: [
            {
                path: "/dayaheadposition",
                component: React.lazy(() => import("../../pages/dayaheadposition")),
            }
        ]
    },
    {
        id: 2706,
        name: "menu.app.text.systemlogs",
        routeInfos: [
            {
                path: "/systemlogs",
                component: React.lazy(() => import("../../pages/systemlogs")),
            }
        ]
    },
    {
        id: 2707,
        name: "menu.app.text.scheduledtasks",
        routeInfos: [
            {
                path: "/schedulers",
                component: React.lazy(() => import("../../pages/scheduler/list/list")),
            },
            {
                path: "/schedulers/report/:id",
                component: React.lazy(() => import("../../pages/scheduler/report/report")),
                entry: false
            },
        ]
    },
    {
        id: 2920,
        name: "menu.app.text.planttracking",
        routeInfos: [
            {
                path: "/planttracking",
                component: React.lazy(() => import("../../pages/planttracking/index")),
            },
        ]
    },
    {
        id: 2921,
        name: "menu.app.text.dataterminal",
        routeInfos: [
            {
                path: "/dataterminal",
                component: React.lazy(() => import("../../pages/dataterminal/reports/list")),
            },
        ]
    },
    {
        id: 2922,
        name: "ftp.client",
        routeInfos: [
            {
                path: "/ftp",
                component: React.lazy(() => import("../../pages/ftp"))
            }
        ]
    },
    {
        id: 2923,
        name: "menu.app.text.externalInvoice",
        routeInfos: [
            {
                path: "/external/invoice",
                component: React.lazy(() => import("../../pages/external/invoice"))
            }
        ]
    },
    {
        id: 2710,
        name: "menu.app.text.pfkfinalobligation",
        routeInfos: [
            {
                path: "/pfkFinalObligation",
                component: React.lazy(() => import("../../pages/pfkFinalObligationReport")),
            },
        ]
    },
    {
        id: 2711,
        name: "menu.app.text.sfkfinalobligation",
        routeInfos: [
            {
                path: "/sfkFinalObligation",
                component: React.lazy(() => import("../../pages/sfkFinalObligationReport")),
            },
        ]
    },
    {
        id: 2901,
        name: "menu.app.text.pricesetdefinition",
        routeInfos: [
            {
                path: "/priceSetDefinition",
                component: React.lazy(() => import("../../pages/pricesetdefinition")),
            },
        ]
    },
    {
        id: 2925,
        name: "menu.app.text.portfoliomanagement",
        routeInfos: [
            {
                path: "/portfolioManagement",
                component: React.lazy(() => import("../../pages/portfoliomanagement/index")),
            },
        ]
    },
    {
        id: 2902,
        name: "menu.app.text.plantlimitdefinition",
        routeInfos: [
            {
                path: "/powerplantlimits",
                component: React.lazy(() => import("../../pages/powerplantlimits")),
            },
        ]
    },
    {
        id: 2904,
        name: "menu.app.text.othersettings",
        routeInfos: [
            {
                path: "/settings",
                component: React.lazy(() => import("../../pages/settings")),
            },
        ]
    },
    {
        id: 2905,
        name: "menu.app.text.meterdefinition",
        routeInfos: [
            {
                path: "/meterIdentification",
                component: React.lazy(() => import("../../pages/meterIdentification")),
            },
        ]
    },
    {
        id: 2908,
        name: "menu.app.text.grouplicensing",
        routeInfos: [
            {
                path: "/grouplicensing",
                component: React.lazy(() => import("../../pages/grouplicensing/list")),
            },
        ]
    },
    {
        id: 2910,
        name: "menu.app.text.usermanagement",
        routeInfos: [
            {
                path: "/user-management",
                component: React.lazy(() => import("../../pages/customeronboarding/list/list")),
            }
        ]
    },
    {
        id: 2916,
        name: "menu.app.text.groupmanagement",
        routeInfos: [
            {
                path: "/group-management",
                component: React.lazy(() => import("../../pages/customeronboarding/groupmanagements/index")),
            }
        ]
    },
    {
        id: 2919,
        name: "Servis Kontrol",
        routeInfos: [
            {
                path: "/servicemanagement",
                component: React.lazy(() => import("../../pages/servicemanagement")),
            }
        ]
    },
    {
        id: 5001,
        name: "menu.app.text.baagreementcontract",
        routeInfos: [
            {
                path: "/agreementlist",
                component: React.lazy(() => import("../../pages/bilateralagreement/Entry/AgreementListPage")),
            },
            {
                path: "/agreementlist/agreement",
                component: React.lazy(() => import("../../pages/bilateralagreement/Entry/AgreementEditorPage")),
            }
        ]
    },
    {
        id: 5002,
        name: "menu.app.text.baagreementnomination",
        routeInfos: [
            {
                path: "/agreementnomination",
                component: React.lazy(() => import("../../pages/bilateralagreement/Nomination/AgreementNominationPage")),
            },
            {
                path: "/agreementnomination/agreement",
                component: React.lazy(() => import("../../pages/bilateralagreement/Entry/AgreementEditorPage")),
            }
        ]
    },
    {
        id: 5003,
        name: "menu.app.text.bamarketparticipants",
        routeInfos: [
            {
                path: "/baparticipant",
                component: React.lazy(() => import("../../pages/bilateralagreement/ParticipantsListPage")),
            }
        ]
    },
    {
        id: 5101,
        name: "menu.app.text.dgpinstructiontracing",
        routeInfos: [
            {
                path: "/dgpinstructiontracing",
                component: React.lazy(() => import("../../pages/dgpinstructiontracing/dgpinstructiontracing")),
            }
        ]
    },
    {
        id: 5100,
        name: "menu.app.text.bpmconsolidatednotification",
        routeInfos: [
            {
                path: "/bpmconsolidatednotification",
                component: React.lazy(() => import("../../pages/bpmnotification/BpmConsolidatedNotification.jsx")),
            }
        ]
    },
    {
        id: 10001,
        name: "menu.app.text.market",
        routeInfos: []
    },
    {
        id: 10002,
        name: "menu.app.text.bpm",
        routeInfos: []
    },
    {
        id: 10003,
        name: "menu.app.text.idm",
        routeInfos: []
    },
    {
        id: 10004,
        name: "menu.app.text.idmtransaction",
        routeInfos: []
    },
    {
        id: 10005,
        name: "menu.app.text.monitor",
        routeInfos: []
    },
    {
        id: 10006,
        name: "menu.app.text.report",
        routeInfos: []
    },
    {
        id: 10007,
        name: "menu.app.text.forecastentry",
        routeInfos: []
    },
    {
        id: 10008,
        name: "menu.app.text.basicmonitor",
        routeInfos: []
    },
    {
        id: 10009,
        name: "menu.app.text.mobile.dataentry",
        routeInfos: []
    },
    {
        id: 10010,
        name: "menu.app.text.mobile.dashboard",
        routeInfos: []
    },
    {
        id: 10011,
        name: "menu.app.text.mobile.managementreports",
        routeInfos: []
    },
    {
        id: 10012,
        name: "menu.app.text.mobile.forecastcomparison",
        routeInfos: []
    },
    {
        id: 10013,
        name: "menu.app.text.mobile.energystatus",
        routeInfos: []
    },
    {
        id: 10014,
        name: "menu.app.text.mobile.extraposition",
        routeInfos: []
    },
    {
        id: 10026,
        name: "menu.app.text.mobile.consumptionforecast",
        routeInfos: []
    },
    {
        id: 18001,
        name: "menu.app.text.mobile.eaknotification",
        routeInfos: []
    },
    {
        id: 18002,
        name: "menu.app.text.mobile.servicecontrolresults",
        routeInfos: []
    },
    {
        id: 18003,
        name: "menu.app.text.mobile.bpmconsolidatednotification",
        routeInfos: []
    },
    {
        id: 10027,
        name: "menu.app.text.consumptionforecasts",
        routeInfos: [
            {
                path: "/consumptionForecast",
                component: React.lazy(() => import("../../../../pages/consumptionForecast/ConsumptionForecast")),
            }
        ]
    },
    {
        id: 10028,
        name: "menu.app.text.consumptionfacility",
        routeInfos: [
            {
                path: "/facilityDefinition",
                component: React.lazy(() => import("../../../../pages/consumptionForecast/FacilityDefinition")),
            }
        ]
    },
    {
        id: 10029,
        name: "menu.app.text.consumptionfacilitylock",
        routeInfos: [
            {
                path: "/ConsumptionForecastLock",
                component: React.lazy(() => import("../../../../pages/consumptionForecast/ConsumptionForecastLock")),
            }
        ]
    },
    {
        id: 2108,
        name: "menu.app.text.orderauctionmanagement",
        routeInfos: [
            {
                path: "/auctionorder",
                component: React.lazy(() => import("../../../../pages/auctionOrder/Index.tsx")),
            }
        ]
    },
    {
        id: 2109,
        name: "Automated Declaration Settings",
        routeInfos: [
            {
                path: "/autosendingsettings",
                component: React.lazy(() => import("../../../../pages/auctionOrder/notification/AuctionAutoNotification.tsx")),
            }
        ],
        idleTimeout: calcIdleTimeout(20),
    },
    
];

export default ApplicationDefinitions;