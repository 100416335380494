import React, { useCallback, } from "react";
import { NumericInput, NumericInputComponentOptions, NumericInputProps } from "./Base/NumericInput.tsx";
import NumericCellBaseElement from "./Base/NumericCellBaseElement.tsx";
import { CustomCellEditorProps } from "@ag-grid-community/react";
import { NullableNumber, } from "./Base/Types.ts";
import { debugError, } from "./Utils.ts";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { ColDefinition } from "src/components/grid/Types.ts";
import { getExtenderContext } from "src/components/grid/Extenders/AgGridUtils.ts";

export interface NumericCellEditorProps extends NumericInputComponentOptions,
    Omit<NumericInputProps, 'inputRef' | 'value' | 'onChange' | 'inputComponent' | 'inputComponentProps'>,
    CustomCellEditorProps<any, NullableNumber> {
}

const NumericCellEditor = React.forwardRef<HTMLDivElement, NumericCellEditorProps>(
    (props, ref) => {
        const {
            allowDecimal: allowDecimalByCellEditor,
            allowMinusValue: allowMinusValueByCellEditor,
            minValue: minValueByCellEditor,
            maxValue: maxValueByCellEditor,
            defaultCulture: defaultCultureByCellEditor,
            clearButtonPosition,
            showClearOnlyFocus,

            // changing callback by grid provided 
            onValueChange,

            // grid provided props
            value,

            //@ts-ignore
            InputProps,

            //@ts-ignore
            inputProps,

            ...rest
        } = props;

        const cellEditorProps = props as Pick<NumericCellEditorProps, keyof CustomCellEditorProps<any, NullableNumber>>;

        // culture -> colDef
        const { api, colDef, context } = cellEditorProps;
        const colDefinition = colDef as ColDefinition;
        const { defaultCulture: defaultCultureByColDef, columnDataType } = colDefinition;

        // numeric options -> colDef
        let allowDecimalByColDef: boolean | undefined;
        let allowMinusValueByColDef: boolean | undefined;
        let minValueByColDef: NullableNumber | undefined;
        let maxValueByColDef: NullableNumber | undefined;

        if (columnDataType === 'number' && colDefinition.columnDataOptions) {
            const { allowDecimal, allowMinusValue, minValue, maxValue, } = colDefinition.columnDataOptions;

            allowDecimalByColDef = allowDecimal;
            allowMinusValueByColDef = allowMinusValue;
            minValueByColDef = minValue;
            maxValueByColDef = maxValue;
        }

        // culture -> gridOptions
        const { customOptions: gridOptions } = getExtenderContext({ api: api }, context);
        const defaultCultureByGrid = gridOptions?.defaultCulture;

        // editör ayarları bir kaç noktadan belirlenebilir. Öncelik sırası: cellEditor -> colDef -> gridOptions
        const currentCulture = defaultCultureByCellEditor ?? defaultCultureByColDef ?? defaultCultureByGrid;
        const currentAllowDecimal = allowDecimalByCellEditor ?? allowDecimalByColDef;
        const currentAllowMinusValue = allowMinusValueByCellEditor ?? allowMinusValueByColDef;
        const currentMinValue = minValueByCellEditor ?? minValueByColDef;
        const currentMaxValue = maxValueByCellEditor ?? maxValueByColDef;

        const buildInputComponentProps = useCallback((): NumericInputComponentOptions => {
                return {
                    allowDecimal: currentAllowDecimal,
                    allowMinusValue: currentAllowMinusValue,
                    minValue: currentMinValue,
                    maxValue: currentMaxValue,
                    clearButtonPosition: clearButtonPosition,
                    defaultCulture: currentCulture,
                    showClearOnlyFocus: showClearOnlyFocus,

                    onValueChanged(args) {
                        try {
                            if (onValueChange) {
                                onValueChange(args.value);
                            }
                        } catch (error) {
                            debugError(error);
                        }
                    },

                    // grid provided 'value' convert to NullableNumber type
                    ...{ value: value ?? null },

                    // grid provided props
                    ...rest,
                };
            }, [currentCulture,
                currentAllowDecimal,
                currentAllowMinusValue,
                currentMinValue,
                currentMaxValue]
        );

        const cellInputProps: Partial<Omit<StandardInputProps, 'value'>> = InputProps ?? {
            disableUnderline: true,
            slotProps: {
                root: {
                    sx: {
                        marginTop: "0px",
                        font: 'inherit !important',
                    }
                },
                input:{
                    sx: {
                        padding: "0px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                    }
                }
            }
        } as Omit<StandardInputProps, 'value'>;

        return (
            <NumericInput
                ref={ref}
                inputComponent={NumericCellBaseElement as any}
                inputComponentProps={{ ...buildInputComponentProps() }}
                value={value ?? null}
                variant="standard"
                fullWidth={true}
                InputProps={{ ...cellInputProps }}
                margin={'none'}
                sx={{
                    marginTop: '5px',
                    marginBottom: '1px',
                    font: 'inherit !important',
                }}
            />
        );

    });

export default NumericCellEditor;