import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Stack, Drawer, svgIconClasses } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { NAVBAR } from "../../../config";
// components
import Logo from "../../../components/Logo";
import Scrollbar from "../../../components/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
//
import navConfig from "./NavConfig";
import LogoSmall from "src/components/LogoSmall";

import {
  IApplicationDefinition,
  ExperimentalAppId,
} from "../../../../src/old/src/containers/authenticated/applicationDefinitions";
import { MasterMenu, IMenuItem } from "../../../../src/old/src/containers/authenticated/menuDefinitions";
import { useStore } from "react-redux";
import useSettings from "src/hooks/useSettings";
import { VerticalMenuViewOption } from "src/components/settings/type";
import ArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Button } from "@mui/material";
import { BLACK_V2, GRAY_V2 } from "src/theme/palette";
import ThemeChangeButton from "./ThemeChangeButton";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const ChangeViewOptionIcon = styled(Button)(({ theme }) => ({
  borderRadius: 4,
  padding: 6,
  margin: 0,
  backgroundColor: theme.palette.mode === "light" ? GRAY_V2[50] : BLACK_V2[500],
  border: theme.palette.mode === "dark" ? "unset" : "1px solid " + GRAY_V2[300],
  height: 24,
  minWidth: 24,
  maxWidth: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: theme.palette.mode === "light" ? GRAY_V2[50] : BLACK_V2[500],
  },
  ["& ." + svgIconClasses.root]: {
    color: GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
    width: 16,
    height: 16,
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({ onCloseSidebar }: Props) {
  const theme = useTheme();
  const store = useStore();
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  const { verticalMenuViewOption, onChangeVerticalMenuViewOption } = useSettings();

  const allowExperimentalApp = (appId: number): boolean => {
    const experimentalEnv = import.meta.env["VITE_EXPERIMENTAL_DEV"];
    return (
      appId == ExperimentalAppId &&
      experimentalEnv &&
      experimentalEnv === "1" &&
      import.meta.env.DEV &&
      import.meta.env.MODE === "development"
    );
  };

  const changeViewOptionButton = (
    <ChangeViewOptionIcon
      disableRipple
      disableTouchRipple
      onClick={() => {
        onChangeVerticalMenuViewOption(
          verticalMenuViewOption === VerticalMenuViewOption.Open
            ? VerticalMenuViewOption.Collapsed
            : VerticalMenuViewOption.Open
        );
      }}
    >
      {verticalMenuViewOption === VerticalMenuViewOption.Open ? <ArrowLeftIcon /> : <ArrowRightIcon />}
    </ChangeViewOptionIcon>
  );

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, changeCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  const getAccessibleMenuItems = (menuItems: IMenuItem[], permittedScreenIds: number[]): IMenuItem[] => {
    const accessibleMenuItems: IMenuItem[] = [];

    for (const menuItem of menuItems) {
      const clonedMenuItem = { ...menuItem };

      if (clonedMenuItem.platform === "web") {
        if (clonedMenuItem.type === "application") {
          clonedMenuItem.details = { ...menuItem.details };

          const applicationDetail = clonedMenuItem.details as IApplicationDefinition;

          const isAllowedApp =
            permittedScreenIds.some((e) => e === applicationDetail.id) || allowExperimentalApp(applicationDetail.id);

          if (isAllowedApp) {
            accessibleMenuItems.push(clonedMenuItem);
          }
        } else {
          clonedMenuItem.details = getAccessibleMenuItems(clonedMenuItem.details as IMenuItem[], permittedScreenIds);
          if ((clonedMenuItem.details as IMenuItem[]).length !== 0) {
            accessibleMenuItems.push(clonedMenuItem);
          }
        }
      }
    }

    return accessibleMenuItems;
  };

  useEffect(() => {
    const accessibleMenuItems = getAccessibleMenuItems(
      MasterMenu || [],
      store.getState().common?.eligibility?.applications || []
    );
    navConfig[0].items = navConfig[0].items.slice(0, 1);

    for (let i = 1; i < accessibleMenuItems.length; i++) {
      const accessibleMenuItem = accessibleMenuItems[i];

      const menuItem = {
        title: accessibleMenuItem.textResourceId,
        children: [] as any,
        icon: accessibleMenuItem.iconName,
        path: "",
        tooltip: accessibleMenuItem.tooltipResourceId,
      };

      if (Array.isArray(accessibleMenuItem.details)) {
        for (let j = 0; j < (accessibleMenuItem.details as any).length; j++) {
          const detail = accessibleMenuItem.details[j];
          menuItem.children.push({
            title: detail.textResourceId,
            path: (detail.details as IApplicationDefinition).routeInfos[0].path,
            tooltip: detail.tooltipResourceId,
          });
        }
      } else {
        const detail = accessibleMenuItem.details as IApplicationDefinition;
        menuItem.path = detail.routeInfos[0].path;
      }

      navConfig[0].items.push(menuItem as any);
    }
  }, []);

  useEffect(() => {
    switch (verticalMenuViewOption) {
      case VerticalMenuViewOption.Collapsed:
        changeCollapse(true);
        break;
      case VerticalMenuViewOption.Open:
        changeCollapse(false);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, verticalMenuViewOption]);

  const isShownFullWidth = (verticalMenuViewOption === VerticalMenuViewOption.Open && isDesktop) || !isDesktop;

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: "16px",
          pb: "16px",
          px: verticalMenuViewOption === VerticalMenuViewOption.Collapsed ? "12px" : "24px",
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
          borderBottom: "2px solid " + GRAY_V2[theme.palette.mode === "light" ? 300 : 900],
          mb: 2,
        }}
      >
        <Stack
          direction={verticalMenuViewOption === VerticalMenuViewOption.Collapsed ? "column" : "row"}
          alignItems="center"
          justifyContent={verticalMenuViewOption === VerticalMenuViewOption.Collapsed ? "center" : "space-between"}
          gap={"8px"}
        >
          {!isShownFullWidth && <LogoSmall />}
          {isShownFullWidth && <Logo />}
          {isDesktop && changeViewOptionButton}
          {/* {
            isDesktop && !isCollapse && (
              <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
            )
          } */}
        </Stack>
      </Stack>

      <Box sx={{ maxHeight: "calc(100% - 82px)", overflowY: "auto", overflowX: "hidden" }}>
        <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
      </Box>

      <Box
        sx={{
          display: "flex",
          marginTop: "auto",
          flexDirection: "column",
          gap: "24px",
          height: "82px",
          justifyContent: "center",
          padding: isCollapse ? "12px" : "24px",
        }}
      >
        <ThemeChangeButton />
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={verticalMenuViewOption === VerticalMenuViewOption.Open}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open={verticalMenuViewOption !== VerticalMenuViewOption.Collapsed}
          variant={"permanent"}
          // onMouseEnter={onHoverEnter}
          // onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              bgcolor: "background.default",
              borderRight: "2px solid" + GRAY_V2[theme.palette.mode === "light" ? 300 : 900],
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
