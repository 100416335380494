import {ElementType, lazy, Suspense, useEffect, useState} from 'react';
import {Navigate, useLocation, useParams, useRoutes} from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

import * as AuthService from '../services/auth/auth.service';
import DockView from '../pages/test/DockView';
import GridLayoutPage from '../pages/test/GridLayoutPage';
import GipPlanningTest from '../pages/test/GipPlanningTest';
import {useStore} from 'react-redux';
import ApplicationDefinitions, {
    ExperimentalAppId,
    IApplicationDefinition,
} from 'src/old/src/containers/authenticated/applicationDefinitions';
import ConsumptionForecast from 'src/pages/consumptionForecast/ConsumptionForecast';
import Page from 'src/components/Page';
import {injectIntl} from 'react-intl';
import {getHostEnvironmentOverrides} from 'src/utils/hostEnvironment';
import ConsumptionForecastLock from 'src/pages/consumptionForecast/ConsumptionForecastLock';
import IdleUser from "../old/src/components/Application/IdleUser";
import {Redirect} from "../components/Redirect";
import {Flags} from "../utils/Flags";
// ----------------------------------------------------------------------

const isNewSsoFlow = Flags.isNewSsoFlow();
const LOGIN_URL = isNewSsoFlow ? "/auth/login" : "/Login/Challenge";

const Loadable = (Component: ElementType) => (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {pathname} = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')}/>}>
            <Component
                {...props}
                match={{
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    params: useParams(),
                }}
            />
        </Suspense>
    );
};

const Router = () => {
    const store = useStore();
    const location = useLocation();

    const isErrorRedirect = new URLSearchParams(location.search).get('errorRedirect') === "true";
    const [isLoggedIn, setIsLoggedIn] = useState(isErrorRedirect)

    useEffect(() => {
        const login = AuthService.getActiveUser().appuserid != null;
        setIsLoggedIn(login);
    }, [location]);

    const baseRoutes = [
        {
            path: '',
            element: <Navigate to="/auth/login" replace/>,
        },
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: <Login/>,
                },
                {
                    path: 'ssologin',
                    element: <SsoLogin/>,
                },
                {
                    path: 'changepassword',
                    element: <ChangePassword/>,
                },
            ],
        },
        {
            path: '',
            element: isLoggedIn ? <DashboardLayout/> : <Navigate to={LOGIN_URL}/>,
            children: [
                {path: 'home', element: <Home/>},
                {path: 'dockview', element: <DockView/>},
                {path: 'gridlayout', element: <GridLayoutPage/>},
                {path: 'gipplanningtest', element: <GipPlanningTest/>},
                //{path: 'intraday-planning', element: <IntradayPlanningPage/>},
                {path: 'consumptionForecast', element: <ConsumptionForecast/>},
                {path: 'consumptionForecastLock', element: <ConsumptionForecastLock/>},
            ],
        },
        {
            path: 'profile',
            element: isLoggedIn ? <DashboardLayout/> : <Navigate to={LOGIN_URL}/>,
            children: [
                {path: 'settings', element: <Settings/>},
                // {path: '', element: <Profile/>},
            ],
        },
        {
            path: 'idlelogout',
            element: <IdleLogout/>
        },
        {
            path: '*',
            element: <LogoOnlyLayout/>,
            children: [
                {path: '404', element: <NotFound/>},
                {
                    path: '*',
                    element: (isNewSsoFlow ? <Redirect path={LOGIN_URL}/> : <Navigate to="/auth/login" replace/>)
                },
            ],
        },
        {path: '*', element: <Navigate to={LOGIN_URL} replace/>},
    ];

    const getRoutes = () => {
        const routes = baseRoutes;
        const accessibleApplications = getAccessibleApplications();

        for (const application of accessibleApplications) {
            for (const routeInfo of application.routeInfos) {
                const Element = Loadable(routeInfo.component);
                const path = routeInfo.path.substring(1);

                routes[2].children?.push({
                    path,
                    element: (
                        <Element/>
                    ),
                });
            }
        }

        return routes;
    };

    const allowExperimentalApp = (appId: number): boolean => {
        const experimentalEnv = import.meta.env["VITE_EXPERIMENTAL_DEV"];
        return appId == ExperimentalAppId && experimentalEnv && experimentalEnv === "1"
            && import.meta.env.DEV
            && import.meta.env.MODE === "development"
            ;
    };

    const getAccessibleApplications = (): IApplicationDefinition[] => {
        return ApplicationDefinitions
            .filter((e) =>
                (store.getState().common?.eligibility?.applications || []).some((s: number) => s === e.id) ||
                allowExperimentalApp(e.id)
            );
    }

    const getApplicationIdleTimeout = (): number | undefined => {
        const application = getAccessibleApplications()
            .find(app => app.routeInfos.some(i =>
                i.path.toLowerCase() === location.pathname.toLowerCase())
            );
        if (typeof application?.idleTimeout == "function")
            return application?.idleTimeout();
        return application?.idleTimeout;
    }

    const userTimeout = store.getState().common?.userSettings?.find(
        (setting: & { Name: string }) => setting.Name === "timeOutValue")?.Value;

    const applicationTimeOut = getApplicationIdleTimeout();

    const pageTimeout = (userTimeout && parseInt(userTimeout) * 60 * 1000) ?? applicationTimeOut;

    return (<>
        {store.getState().common?.IsUserLoggedIn && <IdleUser currentTimeout={pageTimeout}/>}
        <Page height="100%" title={getHostEnvironmentOverrides().displayName}>{useRoutes(getRoutes())}</Page>
    </>);
};

const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const SsoLogin = Loadable(lazy(() => import('../pages/auth/ssologin')));
const ChangePassword = Loadable(lazy(() => import('../old/src/pages/changepassword')));
const Home = Loadable(lazy(() => import('../pages/Home')));
const Settings = Loadable(lazy(() => import('../pages/profile/settings/SettingsPage')));
//const IntradayPlanningPage = Loadable(lazy(() => import('../pages/intraday/IntradayPlanningPage')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const IdleLogout = Loadable(lazy(() => import('../old/src/pages/idlelogout')));

export default injectIntl(Router);
