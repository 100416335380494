import { FormatNumberOptions } from "react-intl";
import { ColDefinition } from "src/components/grid/Types.ts";
import UserCultureFormatterProvider from "src/components/culture/UserCultureFormatterService.ts";

export const numberColumnFormatter = (
    cellValue: number,
    colDefinitionByColumn: ColDefinition,
    numberFormatter: UserCultureFormatterProvider,
    forClipboard: boolean = false,
): string => {
    if (colDefinitionByColumn.columnDataType !== 'number' &&
        colDefinitionByColumn.columnDataType !== 'currency') {
        return "";
    }

    let numberDisplayFormat: string | undefined;

    // Ondalık kısımdaki hanelerin de yapıştırma işlemi ile taşınabilmesi için..
    // Ekran için uygulanan format ile bu değerler göstermiyor olabilir.
    let clipboardFormatOptions: FormatNumberOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 8,
    };

    if (typeof colDefinitionByColumn.columnDisplayFormat === 'object') {
        const { displayFormat } = colDefinitionByColumn.columnDisplayFormat;
        numberDisplayFormat = displayFormat;
    } else {
        numberDisplayFormat = colDefinitionByColumn.columnDisplayFormat;
    }

    if (numberDisplayFormat === 'VolumeMwh' ||
        numberDisplayFormat === 'VolumeKwh' ||
        numberDisplayFormat === 'VolumeLot' ||
        numberDisplayFormat === 'Price') {

        const isVolumeValue = numberDisplayFormat !== 'Price';
        const isMwh = numberDisplayFormat === 'VolumeMwh';

        const defaultFormatOptions = forClipboard ? clipboardFormatOptions : undefined;

        return isVolumeValue
            ? numberFormatter.formatVolumeValue(cellValue, isMwh, defaultFormatOptions)
            : numberFormatter.formatPriceValue(cellValue, defaultFormatOptions);
    }

    const maskRegex = /[0-9\-+#]/;
    const hasPattern = numberDisplayFormat && numberDisplayFormat.search(maskRegex) >= 0;

    let formatOptions: FormatNumberOptions = {};

    if (hasPattern) {
        const patternDesc = numberFormatter.getNumberPatternParts(
            cellValue,
            (numberDisplayFormat ?? ''),
            {
                cultureLocale: numberFormatter.currentLocale,
            }
        );

        if (patternDesc?.metaOfValue) {
            let fractionDigits: number | undefined =
                patternDesc?.metaOfValue.fraction || patternDesc?.metaOfValue.fraction === ''
                    ? patternDesc?.metaOfValue.fraction.length
                    : undefined;

            formatOptions = {
                maximumFractionDigits: fractionDigits,
                minimumFractionDigits: fractionDigits,
            }
        }
    }

    return numberFormatter.formatNumericValue(
        cellValue,
        forClipboard ? clipboardFormatOptions : formatOptions
    );
}