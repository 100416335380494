import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Constants } from "src/constants";
import { EContractType } from "src/enums/contract-type.enum";
import {
   ContractDetailsLoadedStatus,
   GipPlanningWebSocketConnectionStatus,
   IAlertRule,
   IAlertRuleListener,
   IAlertRuleListenerContract,
   IBoardContract,
   IBoardContractConstant,
   IBoardContractSmartBotListenerInfo,
   IBoardContractUpdateModel,
   IContractRucValue,
   IContractRucValueCompany,
   IContractShortInfo,
   IContractTradeDetail,
   IDepthInfo,
   IFromUpDownItemModel,
   IGipPlanningCalculateRuc,
   IGipPlanningCalculateRucHour,
   IGipPlanningCalculatedValues,
   IGipPlanningCalculatedWallValues,
   IGipPlanningDynamicColumnDetail,
   IGipPlanningLiveSettings,
   IGipPlanningRadRiskInfo,
   IGipPlanningTickerInfo,
   IGipPlanningWebSocketCompany,
   IGipPlanningWebSocketStoreModel,
   ILibraryScript,
   IMyProposal,
   IMyProposalDetail,
   IPowerPlant,
   IProposalLimit,
   IRelatedGroupProposal,
   ISystemStatus,
   ISystemStatusServices,
   LoadingStateEnum,
   SocketConnectionType,
} from "../api/types/gipPlanningWebSocketTypes";
import _ from "lodash";
import moment from "moment-timezone";
import * as CommonActions from "src/old/src/redux/common/action";
import { isEligible } from "src/utils";
import { ETickerType } from "../../pages/intraday/enums/ETickerType";
import { v4 as uuidv4 } from "uuid";
import {
   clearGipPlanningDepthInfo,
   clearGipPlanningDepthInterval,
   clearGipPlanningMyProposals,
   clearGipPlanningRelatedGroupProposals,
   clearGipPlanningTickers,
   clearGipPlanningTrades,
   setGipPlanningTicker,
   setGipPlanningTickers,
   startGipPlanningDepthInterval,
   updateGipPlanningDepthInfo,
   updateGipPlanningMyProposals,
   updateGipPlanningRelatedGroupProposals,
   updateGipPlanningTrades,
   startTradeInterval,
   startMyoffersInterval,
   startRelatedGroupProposalsInterval,
   clearMyoffersInterval,
   clearTradeInterval,
   clearRelatedGroupProposalsInterval
} from "../reducers/gipPlanning/gipPlanningActions";
import { ERegion } from "src/enums/region.enum";
import { EContractState } from "src/enums/contract-state.enum";
import { services as SERVICE_CONSTANTS } from "../../pages/intraday/constants";
import { current } from "@reduxjs/toolkit";
import PQueue from "p-queue";
import { EIntradayPlanningTab } from "../../pages/intraday/enums/EIntradayPlanningTab";
import { UserDefaultBuySellCompany } from "src/models/gip-planning/UserDefaultBuySellCompanies";
import equal from "fast-deep-equal";
import { EProposalType } from "src/enums/proposal-type.enum";
import { EContractProductType } from "src/models/gip-planning/intraday.enum";
export enum GIP_WS_SUBJECTS {
   USER_GLOBAL_ERROR = "global?error",
   USER_GLOBAL_SESSIONID = "global?sessionid",
   USER_GLOBAL_SUCCESS = "global?success",
   USER_SYSTEM_MESSAGE = "system?message",
   USER_WALL_DATA = "smartpulse.intradayplanning?walldata",
   USER_WALL_UPDATE = "smartpulse.intradayplanning?wallupdate",
   USER_SERVER_TIME = "smartpulse.intradayplanningcontract?serverTime",
   USER_CONTRACT_DETAILS = "smartpulse.intradayplanningcontract?contractdetails",
   USER_SYSTEM_STATUS_UPDATE = "smartpulse.intradayplanning?systemstatusupdate",
   USER_TICKER = "volt.alerting?ticker",
   USER_DYNAMIC_COLUMN_EVAL = "smartpulse.intradayplanning?dynamiccolumneval",
   USER_VERSION_LISTENERS = "volt.alerting?version-listeners",
   USER_POWER_PLANT_DATA = "smartpulse.intradayplanning?powerplantdata",
   USER_MESSAGE_INFO = "smartpulse.idm.message?info",
   USER_MESSAGE_WARNING = "smartpulse.idm.message?warning",
   USER_SUBMIT_OFFER_ERROR = "intradayplanning.warning.submitoffererror",
   USER_PONG = "smartpulse.pinglistener?pong",
   USER_EPIAS_KOPI_INFO = "volt.epias.kopi?info",
   USER_RUC_RISK_DATA = "smartpulse.rucradreport?rucriskdata",
   USER_RAD_RISK_DATA = "smartpulse.rucradreport?radriskdata",
   GROUP_ENTER_USER = "global?enteruser",
   UNKNOWN = "UNKNOWN",
   USER_CONTRACT_DETAILS_LOADED = "smartpulse.intradayplanningcontract?contractdetailsLoaded",
   USER_EXIT = "global?exituser",
}
export const WS_HANDLE_NAMES = {
   intradayPlanning: {
      handleName: "smartpulse/v2/intradayplanning",
      method: {
         wallData: "walldata",
         wallUpdate: "wallupdate",
         contractDetails: "contractdetails",
         enterUser: "enteruser",
         powerPlantData: "powerplantdata",
         leaveWidget: "leaveWidget",
      },
   },
   intradayPlanningContract: {
      handleName: "smartpulse/v2/intradayplanningcontract",
      method: {
         selectContract: "selectcontract",
         leaveWidget: "leaveWidget",
      },
   },
   alerting: {
      handleName: "volt/alerting",
      method: {
         ticker: "ticker,version-listeners",
      },
   },
};
let quantityDivider = 1;
const twoDigitThreshold = 0.01;
const deliveryStartFormat = "DD/MM/YYYY HH:mm:ss";
const socketFetchUrl = `${import.meta.env.REACT_APP_WS_UR}`;
// const tickerLimit = 250;

const contractTypeSortOrder = {
   1: 0,
   3: 1,
   2: 2,
};

interface IWebSocketManager {
   socket: WebSocket | undefined;
   heartbeatChecker: NodeJS.Timeout | undefined;
   lastPingTime: moment.Moment | undefined;
}
const socketMap: Map<SocketConnectionType, IWebSocketManager> = new Map();

export async function socketSendMessage(
   widgetId: string,
   socketConnectionType: SocketConnectionType,
   to: string,
   subject: string,
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   body: any
): Promise<boolean> {
   const socketManager = await socketMap.get(socketConnectionType);
   if (socketManager && socketManager.socket && socketManager.socket.readyState === WebSocket.OPEN) {
      if (to == "smartpulse/pinglistener" && subject == "ping") {
         if (socketManager.lastPingTime) {
            const now = moment();
            const diff = now.diff(socketManager.lastPingTime, "seconds");
            if (diff <= 2) return true;
            socketManager.lastPingTime = now;
         }else{
            socketManager.lastPingTime= moment();
         }
      }
      socketManager.socket.send(
         JSON.stringify({
            to: to,
            subject: subject,
            body: { ...body, widgetId: widgetId },
         })
      );

      return true;
   }
   return false;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapProposal = (proposal: any): IMyProposalDetail => {
   return {
      createDate: proposal.CreateDate,
      companyId: proposal.CompanyId,
      proposalId: proposal.ProposalId,
      proposalXBidId: proposal.ProposalXBidId,
      duration: proposal.Duration,
      contractIndex: proposal.ContractIndex,
      proposalType: proposal.ProposalType,
      price: proposal.Price,
      quantity: proposal.Quantity,
      remainingQuantity: proposal.RemainingQuantity,
      matchedPrice: proposal.MatchedPrice,
      matchedQuantity: proposal.MatchedQuantity,
      totalMatchedQuantity: proposal.TotalMatchedQuantity,
      status: proposal.Status,
      explanation: proposal.Explanation,
      message: proposal.Message,
      matches:
         proposal.Matches?.map((z) => {
            return {
               quantity: z.Quantity,
               price: z.Price,
               createDate: z.CreateDate,
            };
         }) ?? [],
      createUser: proposal.CreateUser,
      createUserName: proposal.CreateUserName,
      source: proposal.Source,
      smartBotId: proposal.SmartBotId,
      relatedProposalId: proposal.RelatedProposalId,
      tags:
         proposal.Tags?.map((z) => {
            return {
               title: z.Title,
               value: z.Value,
            };
         }) ?? [],
      isIceberg: proposal.IsIceberg,
      optionType: proposal.OptionType,
      validityDate: proposal.ValidityDate,
      lastModifyTimestamp: proposal.LastModifyTimestamp,
      integrationUniqueId: proposal.IntegrationUniqueId,
      revisionNo: proposal.RevisionNo,
      totalQuantity: proposal.TotalQuantity,
      peakPriceDelta: proposal.PeakPriceDelta,
      clipSize: proposal.ClipSize,
      isHibernated: proposal.IsHibernated,
      privateTradeSyncFailed: proposal.PrivateTradeSyncFailed,
   };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapLibraryScript = (libraryScript: any): ILibraryScript => {
   const libraryScriptDraft: ILibraryScript = {
      id: libraryScript.Id,
      name: libraryScript.Name,
      parameters:
         libraryScript.Parameters?.map((x) => {
            return {
               id: x.Id,
               name: x.Name,
            };
         }) ?? [],
   };

   return libraryScriptDraft;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapAlertRule = (alertRule: any): IAlertRule => {
   const alertRuleDraft: IAlertRule = {
      id: alertRule.Id,
      name: alertRule.Name,
      createUser: alertRule.CreateUser,
      createUserName: alertRule.CreateUserName,
      mode: alertRule.Mode,
      modeText: alertRule.ModeText,
      sharedStatus: alertRule.SharedStatus,
      listeners:
         alertRule.Listeners?.map((x) => {
            return {
               id: x.Id,
               sourceId: x.SourceId,
               sourceName: x.SourceName,
               selectedContracts: (x.SelectedContracts ?? []).map((y) => {
                  return {
                     contractId: y.ContractId,
                     deliveryStart: y.DeliveryStart,
                  } as IAlertRuleListenerContract;
               }),
            } as IAlertRuleListener;
         }) ?? [],
      actions:
         alertRule.Actions?.map((x) => {
            return {
               id: x.Id,
               scriptType: x.ScriptType,
               alertRuleLibraryScriptId: x.AlertRuleLibraryScriptId,
               parameters:
                  x.Parameters?.map((y) => {
                     return {
                        parameterId: y.ParameterId,
                        expression: y.Expression,
                     };
                  }) ?? [],
               ruleText: x.RuleText,
               ruleAction: x.RuleAction,
               companyId: x.CompanyId,
            };
         }) ?? [],
   };
   return alertRuleDraft;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapInstrumentToBoardContract = (
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   instrument: any,
   contractTitleDisplayType: number
): IBoardContractUpdateModel => {
   let contractConstant: IBoardContractConstant | undefined = undefined;
   if (instrument.ContractInfo) {
      const contractDuration = moment(instrument.ContractInfo.DeliveryEnd, deliveryStartFormat).diff(
         moment(instrument.ContractInfo.DeliveryStart, deliveryStartFormat),
         "minutes"
      );
      const displayName =
         contractTitleDisplayType == 1 && instrument.ContractInfo.PeriodName
            ? //.regionId == ERegion.Tr
              instrument.ContractInfo.PeriodName
            : instrument.ContractInfo.ContractName;

      contractConstant = {
         contractDataKey: instrument.ContractDataKey,
         contractId: instrument.ContractInfo.ContractId ?? instrument.ContractDataKey,
         contractName: instrument.ContractInfo.ContractName,
         deliveryStart: instrument.ContractInfo.DeliveryStart,
         deliveryEnd: instrument.ContractInfo.DeliveryEnd,
         periodId: instrument.ContractInfo.PeriodId,
         periodName: instrument.ContractInfo.PeriodName,
         tradingPhaseEnd: instrument.ContractInfo.TradingPhaseEnd,
         productType: instrument.ContractInfo.ProductType,
         durationMinutes: contractDuration,
         contractType:
            contractDuration == 15
               ? EContractType.Quarterly
               : contractDuration == 30
                 ? EContractType.HalfHourly
                 : EContractType.Hourly,
         displayName: displayName,
         displayShortName:
            instrument.CurrentRegion === ERegion.Es
               ? displayName
               : instrument.ContractInfo.ContractName?.slice(0, 2) +
                 "-" +
                 instrument.ContractInfo.ContractName?.slice(-2),
      };
   }


   const contractDraft: IBoardContractUpdateModel = {
      contractDataKey: instrument.ContractDataKey,
      contractConstant: contractConstant,

      contractDynamic: instrument.ContractDynamic
         ? {
              contractDataKey: instrument.ContractDataKey,
              isOpenForTrade: instrument.ContractDynamic.IsOpenForTrade,
              remainingTime: instrument.ContractDynamic.RemainingTime,
              remainingTimeSeconds: instrument.ContractDynamic.RemainingTimeSeconds,
              remainingTimePercentage: instrument.ContractDynamic.RemainingTimePercentage,
              state: instrument.ContractDynamic.State,
           }
         : undefined,
      contractBests: instrument.ContractDynamic
         ? {
              contractDataKey: instrument.ContractDataKey,
              buyContractBestPrice: instrument.ContractDynamic.BuyContractBestPrice,
              buyContractBestQuantity: instrument.ContractDynamic.BuyContractBestQuantity,
              sellContractBestPrice: instrument.ContractDynamic.SellContractBestPrice,
              sellContractBestQuantity: instrument.ContractDynamic.SellContractBestQuantity,
           }
         : undefined,
      depthSummary: instrument?.DepthSummary
         ? {
              buyDepth:
                 instrument.DepthSummary.BuyDepth?.map((x) => {
                    return { index: x.Index, rating: x.Rating };
                 }) ?? [],
              sellDepth:
                 instrument.DepthSummary.SellDepth?.map((x) => {
                    return { index: x.Index, rating: x.Rating };
                 }) ?? [],
           }
         : undefined,
      marketInfo: instrument.MarketInfo
         ? {
              contractDataKey: instrument.ContractDataKey,
              downwardImbalancesPaymentPrice: instrument.MarketInfo.DownwardImbalancesPaymentPrice,
              downwardImbalancesPaymentQuantity: instrument.MarketInfo.DownwardImbalancesPaymentQuantity,
              intradayAuctionPrice: instrument.MarketInfo.IntradayAuctionPrice,
              intradayAuctionSessions: instrument.MarketInfo.IntradayAuctionSessions ?? [],
              mcp: instrument.MarketInfo.MCP,
              smp: instrument.MarketInfo.SMP,
              systemDirection: instrument.MarketInfo.SystemDirection,
              systemDirectionHistory:
                 instrument.MarketInfo.SystemDirectionHistory?.map((x) => {
                    return {
                       date: x.Date,
                       deloading: x.Deloading,
                       loading: x.Loading,
                       value: x.Value,
                    };
                 }) ?? [],
              systemDirectionType: instrument.MarketInfo.SystemDirectionType,
              systemMarginalPriceHistory: instrument.MarketInfo.SystemMarginalPriceHistory ?? [],
              upwardImbalancesPaymentPrice: instrument.MarketInfo.UpwardImbalancesPaymentPrice,
              upwardImbalancesPaymentQuantity: instrument.MarketInfo.UpwardImbalancesPaymentQuantity,
           }
         : undefined,


      positionInfo: {},
      productionInfo: instrument.ProductionInfo
         ? {
              actualProduction: _.round(instrument.ProductionInfo.ActualProduction, 2),
           }
         : undefined,
      consumptionInfo: instrument.ConsumptionInfo
         ? {
              actualProduction: instrument.ConsumptionInfo.ActualProduction,
           }
         : undefined,
      instructionInfo: instrument.InstructionInfo
         ? {
              deliveredDeloading: instrument.InstructionInfo.DeliveredDeloading,
              deliveredLoading: instrument.InstructionInfo.DeliveredLoading,
              deliveredMwhDeloading: instrument.InstructionInfo.DeliveredMWHDeloading,
              deliveredMwhLoading: instrument.InstructionInfo.DeliveredMWHLoading,
              totalDeloadingInstructionAmount: instrument.InstructionInfo.TotalDeloadingInstructionAmount,
              totalLoadingInstructionAmount: instrument.InstructionInfo.TotalLoadingInstructionAmount,
           }
         : undefined,
      proposalInfo: instrument.ProposalInfo
         ? {
              buyContractMyPrice: instrument.ProposalInfo.BuyContractMyPrice,
              buyContractMyQuantity: instrument.ProposalInfo.BuyContractMyQuantity,
              sellContractMyPrice: instrument.ProposalInfo.SellContractMyPrice,
              sellContractMyQuantity: instrument.ProposalInfo.SellContractMyQuantity,
           }
         : undefined,
      tradeInfo: instrument.TradeInfo
         ? {
              contractDataKey: instrument.ContractDataKey,
              lastTradeDate: instrument.TradeInfo.LastTradeDate,
              lastTradePrice: instrument.TradeInfo.LastTradePrice,
              lastTradePriceChange: instrument.TradeInfo.LastTradePriceChange,
              lastTradeQuantity: instrument.TradeInfo.LastTradeQuantity,
           }
         : undefined,
   };

   const wallKeys = Object.keys(instrument.PositionInfo??{});

   wallKeys.forEach((wallKey)=>{
      if(!contractDraft.positionInfo)contractDraft.positionInfo ={};
      contractDraft.positionInfo[wallKey] = {
         wallSelectionKey: instrument.PositionInfo[wallKey].WallSelectionKey,
         actualConsumption: instrument.PositionInfo[wallKey].ActualConsumption,
         biliteralAggreementQuantity: instrument.PositionInfo[wallKey].BiliteralAggreementQuantity,
         dayAheadBlockQuantity: instrument.PositionInfo[wallKey].DayAheadBlockQuantity,
         dayAheadDefaultQuantity: instrument.PositionInfo[wallKey].DayAheadDefaultQuantity,
         dayAheadElasticQuantity: instrument.PositionInfo[wallKey].DayAheadElasticQuantity,
         deliveredDeloading: instrument.PositionInfo[wallKey].DeliveredDeloading,
         deliveredLoading: instrument.PositionInfo[wallKey].DeliveredLoading,
         deliveredMwhDeloading: instrument.PositionInfo[wallKey].DeliveredMWHDeloading,
         deliveredMwhLoading: instrument.PositionInfo[wallKey].DeliveredMWHLoading,
         extraPosition: instrument.PositionInfo[wallKey].ExtraPosition,
         intradayBlockQuantity: instrument.PositionInfo[wallKey].IntradayBlockQuantity,
         intradayDefaultQuantity: instrument.PositionInfo[wallKey].IntradayDefaultQuantity,
         netBlockVolume: instrument.PositionInfo[wallKey].NetBlockVolume,
         netDefaultVolume: instrument.PositionInfo[wallKey].NetDefaultVolume,
         netPosition: instrument.PositionInfo[wallKey].NetPosition,
         productionPrediction: instrument.PositionInfo[wallKey].ProductionPrediction,
         retailQuantity: instrument.PositionInfo[wallKey].RetailQuantity,
         totalDeloadingInstructionAmount: instrument.PositionInfo[wallKey].TotalDeloadingInstructionAmount,
         totalLoadingInstructionAmount: instrument.PositionInfo[wallKey].TotalLoadingInstructionAmount,
         virtualBiliteralAggreementQuantity: instrument.PositionInfo[wallKey].VirtualBiliteralAggreementQuantity,
         fromUpDown: instrument.PositionInfo[wallKey].FromUpDown
            ? {
                 bA: mapFromUpDownItem(instrument.PositionInfo[wallKey].FromUpDown.BA),
                 consumptionForcast: mapFromUpDownItem(instrument.PositionInfo[wallKey].FromUpDown.ConsumptionForcast),
                 dayAhead: mapFromUpDownItem(instrument.PositionInfo[wallKey].FromUpDown.DayAhead),
                 extraPos: mapFromUpDownItem(instrument.PositionInfo[wallKey].FromUpDown.ExtraPos),
                 intraday: mapFromUpDownItem(instrument.PositionInfo[wallKey].FromUpDown.Intraday),
                 prdForcast: mapFromUpDownItem(instrument.PositionInfo[wallKey].FromUpDown.PrdForcast),
                 total: mapFromUpDownItem(instrument.PositionInfo[wallKey].FromUpDown.Total),
              }
            : undefined,
      }
   })
   return contractDraft;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapFromUpDownItem = (data?: any): IFromUpDownItemModel | undefined => {
   return data ? { down: data.Down, up: data.Up } : undefined;
};

const defaultRadValue: IGipPlanningCalculateRuc = {
   day1: {
      dateString: undefined,
      barProgress: 0,
      groupTotalNeedMatch: 0,
      isOpened: false,
      pureRad: 0,
   },
   day2: {
      dateString: undefined,
      barProgress: 0,
      groupTotalNeedMatch: 0,
      isOpened: false,
      pureRad: 0,
   },
   hourlyRucValues: [],
   rucRange: 0,
};

const getWallSubject = (fetchType: EIntradayPlanningTab) => {
   if (fetchType === EIntradayPlanningTab.ProductionForecast)
      return WS_HANDLE_NAMES.intradayPlanning.method.powerPlantData;

   return WS_HANDLE_NAMES.intradayPlanning.method.wallData;
};

const mapCalculatedRucHourToContractRucValue = (list: IGipPlanningCalculateRucHour[]) =>
   list.map<IContractRucValueCompany>((x) => ({
      companyId: x.companyId,
      companyName: x.companyName,
      ruc: x.ruc,
      totalNeedMatch: x.totalNeedMatch,
      remainingTransactionCount: x.remainingTransactionCount,
      tgs: x?.tgs,
   }));

export const PREVIOUS_MODEL_KEY = "web-socket-previous-model";

const getPreviousModel = () => {
   const value = window.sessionStorage.getItem(PREVIOUS_MODEL_KEY);
   if (_.isNil(value) || _.isEmpty(value)) return;
   try {
      return JSON.parse(value) as IGipPlanningWebSocketStoreModel;
   } catch {
      return;
   }
};

const setPreviousModel = (newModel: IGipPlanningWebSocketStoreModel | null | undefined) => {
   if (_.isNil(newModel)) return;
   window.sessionStorage.setItem(PREVIOUS_MODEL_KEY, JSON.stringify(newModel));
};

// Define a service using a base URL and expected endpoints
export const socketApi = createApi({
   reducerPath: "socketApi",
   baseQuery: fetchBaseQuery({ baseUrl: socketFetchUrl }),
   endpoints: (builder) => ({
      getMessages: builder.query<IGipPlanningWebSocketStoreModel, SocketConnectionType>({
         // eslint-disable-next-line @typescript-eslint/no-unused-vars
         queryFn: (socketConnectionType: SocketConnectionType) => {
            return {
               data: {
                  connectionStatus: GipPlanningWebSocketConnectionStatus.CLOSED,
                  heartbeat: true,
                  stateUpdatedStamp: moment().add(-10, "second").toDate(),
                  lastPongTime: moment().toDate(),
                  contracts: [],
                  powerPlants: [],
                  listedContractsInfo: [],
                  selectedWall: {},
                  selectedContractDetails: {},
                  calculatedWallValues: {},
                  calculatedValues: {
                     maxMcp: 0,
                     minMcp: 0,
                     maxDirection: 0,
                     minDirection: 0,
                  },
                  proposalLimits: [],
                  rucRiskData: {
                     unitData: [],
                     unitLabel: [],
                  },
                  contractProductTypes: [],
               },
            };
         },

         async onCacheEntryAdded(
            socketConnectionType,
            { cacheDataLoaded, cacheEntryRemoved, updateCachedData, getCacheEntry }
         ) {
            try {
               if (socketConnectionType == undefined) {
                  throw new Error("socketConnectionType is undefined");
               }
               let autoFetch = false;
               let tryingConnect = false;
               let tryingConnectCount = 0;
               const queue = new PQueue({ concurrency: 1 });

               // let queueActionCount = 0;
               // queue.on('active', () => {
               //    console.log(`Working on item #${++queueActionCount}.  Size: ${queue.size}  Pending: ${queue.pending}`);
               // });
               await startGipPlanningDepthInterval();
               startMyoffersInterval();
               startRelatedGroupProposalsInterval();
               startTradeInterval();
               const heartbeatCheck = async () => {
                  const { data: currentState } = getCacheEntry();
                  if (!currentState) return;
                  const lastPongTime = moment(currentState.lastPongTime);
                  const now = moment();
                  const diff = now.diff(lastPongTime, "seconds");
                  if (diff > 60) {
                     console.log("Heartbeat check failed. try reconnecting...");
                     connectSocket(true);
                  } else if (diff > 15) {
                     socketSendMessage(
                        Constants.intraday.defaultWidgetId,
                        socketConnectionType,
                        "smartpulse/pinglistener",
                        "ping",
                        {}
                     );
                  }
               };

               const clearSocketConnection = async () => {
                  if (socketMap.has(socketConnectionType)) {
                     const socket = socketMap.get(socketConnectionType)!;

                     if (socket.heartbeatChecker) {
                        await clearInterval(socket.heartbeatChecker);
                     }
                     if (socket.socket) {
                        socket.socket.onopen = null;
                        socket.socket.onclose = null;
                        socket.socket.onerror = null;
                        socket.socket.onmessage = null;
                        await socket.socket.close();
                     }
                  }
                  await socketMap.set(socketConnectionType, {
                     socket: undefined,
                     heartbeatChecker: undefined,
                     lastPingTime: undefined,
                  });
               };
               const clearReduxStates = async () => {
                  await clearGipPlanningDepthInterval();
                  clearTradeInterval();
                  clearMyoffersInterval();
                  clearRelatedGroupProposalsInterval();
                  await clearGipPlanningMyProposals();
                  await clearGipPlanningDepthInfo();
                  await clearGipPlanningTrades();
                  await clearGipPlanningTickers();
                  await clearGipPlanningRelatedGroupProposals();
               };
               const activeUserId = CommonActions.getUserInformation().userId;

               await cacheDataLoaded;
               const setTickerMessage = (ticker: IGipPlanningTickerInfo | IGipPlanningTickerInfo[]) => {
                  if (Array.isArray(ticker)) {
                     setGipPlanningTickers(ticker);
                     // draft.tickers = [...ticker, ...draft.tickers.slice(0, tickerLimit)];
                  } else {
                     setGipPlanningTicker(ticker);
                     // draft.tickers = [ticker, ...draft.tickers.slice(0, tickerLimit)];
                  }
               };

               // const socketMessagesThrottle = _.throttle(async (e) => await webSocketOnMessage(e), 500);
               // const socketContractDetailsThrottle = _.throttle(async (e) => await socketContractDetails(e), 0);
               // const setTickerMessageSmartBotThrottle = _.throttle(setTickerMessage, 1000);
               // const setTickerMessageThrottle = _.throttle(setTickerMessage, 1000);

               // eslint-disable-next-line @typescript-eslint/no-unused-vars
               const webSocketOnOpen = (event: Event) => {
                  tryingConnectCount = 0;

                  socketSendMessage(
                     Constants.intraday.defaultWidgetId,
                     socketConnectionType,
                     "smartpulse/pinglistener",
                     "ping",
                     {}
                  );

                  if (autoFetch) {
                     console.log("Auto fetch is enabled. Fetching data...");
                     autoFetch = false;
                     let { data: currentState } = getCacheEntry();
                     currentState ??= getPreviousModel();
                     const selectedWalls = Object.keys(currentState?.selectedWall ?? {}).map(
                        (x) => currentState?.selectedWall[x]
                     );
                     const selecteContracts = Object.keys(currentState?.selectedContractDetails ?? {}).map(
                        (x) => currentState?.selectedContractDetails[x]
                     );
                     for (let i = 0; i < selectedWalls.length; i++) {
                        const selectedWall = selectedWalls[i];
                        if (!selectedWall) continue;
                        socketSendMessage(
                           selectedWall.widgetId,
                           socketConnectionType,
                           WS_HANDLE_NAMES.intradayPlanning.handleName,
                           getWallSubject(selectedWall.entryType),
                           {
                              companyId: selectedWall.companyId,
                              portfolioId: selectedWall.portfolioId,
                              dateIndex: selectedWall.dateIndex,
                           }
                        );
                     }
                     for (let i = 0; i < selecteContracts.length; i++) {
                        const selectedContract = selecteContracts[i];
                        if (!selectedContract) continue;
                        socketSendMessage(
                           selectedContract.widgetId,
                           socketConnectionType,
                           WS_HANDLE_NAMES.intradayPlanningContract.handleName,
                           WS_HANDLE_NAMES.intradayPlanningContract.method.selectContract,
                           {
                              selectedInstrument: selectedContract.contractDataKey,
                              companyId: selectedContract.companyId,
                           }
                        );
                     }
                  }
               };
               const getCalculatedValues = (contracts: IBoardContract[]): IGipPlanningCalculatedValues => {
                  const mcpValues = contracts.filter((e) => e.marketInfo?.mcp > 0).map((item) => item.marketInfo.mcp);
                  const directionValues = contracts
                     .filter((e) => (e.marketInfo?.systemDirection ?? 0) !== 0)
                     .map((item) => item.marketInfo.systemDirection);

                  return {
                     maxMcp: _.max(mcpValues) ?? 0,
                     minMcp: _.min(mcpValues) ?? 0,
                     maxDirection: _.max(directionValues) ?? 0,
                     minDirection: _.min(directionValues) ?? 0,
                  };
               };

               const getCalculatedWallValues = (
                  contracts: IBoardContract[],
                  selectedWallKey: string
               ): IGipPlanningCalculatedWallValues => {
                  const { data: currentState } = getCacheEntry();

                  const netPositions = contracts
                     .filter((e) => e.contractDynamic?.isOpenForTrade)
                     .map((item) => Math.abs(item.positionInfo[selectedWallKey]?.netPosition ?? 0));
                  return {
                     ...{ radValue: defaultRadValue },
                     ...currentState!.calculatedWallValues[selectedWallKey],
                     maxNetPosition: _.max(netPositions) ?? 0,
                  };
               };

               const calculateRucRad = (
                  companyId: number,
                  radRiskInfo: IGipPlanningRadRiskInfo
               ): IGipPlanningCalculateRuc => {
                  const { data: currentState } = getCacheEntry();
                  if (!currentState) return defaultRadValue;
                  // const selectedCompanyId = currentState.selectedValues.selectedCompanyId;
                  //TODO: Ruc değerlerinin hesabı düşünülmeli
                  const selectedCompanyId = companyId;
                  if (radRiskInfo) {
                     const groupTotalNeedMatchDay1 = radRiskInfo.groupRucRadValues.groupTotalNeedMatchDay1;
                     const groupTotalNeedMatchDay2 = radRiskInfo.groupRucRadValues.groupTotalNeedMatchDay2;
                     const radDay1 = radRiskInfo.groupRucRadValues.groupRadDay1;
                     const radDay2 = radRiskInfo.groupRucRadValues.groupRadDay2;

                     const day1 = radRiskInfo.companyRucRadValues.companyRucList.find((x) => x.instrumentHour < 24);
                     const day1Date = !day1
                        ? undefined
                        : moment(day1.instrumentDate, "DD-MM-YYYY HH-mm-ss").format("DD.MM.YYYY");
                     const day2Date = !day1
                        ? undefined
                        : moment(day1.instrumentDate, "DD-MM-YYYY HH-mm-ss").add(1, "days").format("DD.MM.YYYY");
                     const isDay2Opened =
                        radRiskInfo.companyRucRadValues.companyRucList.find((x) => x.instrumentHour > 24) != null
                           ? false
                           : true;

                     const hourlyRuc = radRiskInfo.companyRucRadValues.companyRucList
                        .filter((a) => (selectedCompanyId === 0 ? true : a.companyId === selectedCompanyId))
                        .map((x) => {
                           const m: IGipPlanningCalculateRucHour = {
                              companyName:
                                 currentState.userInfo?.groupCompanies.find((a) => a.companyId == x.companyId)
                                    ?.companyName ?? "undefined",
                              companyId: x.companyId,
                              ruc: x.rucValue,
                              hour: x.instrumentHour,
                              totalNeedMatch: x.totalNeedMatchforRuc,
                              instrumentDate: x.instrumentDate,
                              remainingTransactionCount: x.remainingTransactionCount,
                              tgs: x?.tgs,
                           };
                           return m;
                        })
                        .sort((a, b) =>
                           moment(a.instrumentDate, "DD-MM-YYYY HH:mm:ss") >
                           moment(b.instrumentDate, "DD-MM-YYYY HH:mm:ss")
                              ? -1
                              : 1
                        );

                     const calculateRucRadBarProgress = (rad) => {
                        let _barProgress = 0;
                        const rucRange = currentState.liveSettings?.regionId == 1 ? 10 : 1;
                        const max_value = 100 * rucRange;
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const percentages: any[] = [];
                        for (let i = 1; i <= 8; i++) {
                           const b = 12.5 * i * rucRange;
                           percentages.push(b);
                        }
                        if (rad > max_value) {
                           _barProgress = 7;
                        } else {
                           for (let a = 0; a <= 7; a++) {
                              if (rad >= percentages[a] && rad <= percentages[a + 1]) {
                                 _barProgress = a;
                              }
                           }
                        }
                        return _barProgress;
                     };

                     const day1BarProgress = calculateRucRadBarProgress(radDay1);
                     const day2BarProgress = calculateRucRadBarProgress(radDay2);

                     return {
                        day1: {
                           dateString: day1Date,
                           isOpened: true,
                           pureRad: radDay1,
                           barProgress: day1BarProgress,
                           groupTotalNeedMatch: groupTotalNeedMatchDay1,
                        },
                        day2: {
                           dateString: day2Date,
                           isOpened: isDay2Opened,
                           pureRad: radDay2,
                           barProgress: day2BarProgress,
                           groupTotalNeedMatch: groupTotalNeedMatchDay2,
                        },
                        hourlyRucValues: hourlyRuc,
                        rucRange: currentState.liveSettings?.regionId == 1 ? 10 : 1,
                     };
                  }
                  return defaultRadValue;
               };

               const getSystemStatusOfCompanies = (systemServices: ISystemStatusServices[], serviceName: string) => {
                  const companies = CommonActions.getPermissions().companies;
                  const connectedCompanies: IGipPlanningWebSocketCompany[] = [];
                  const rejectedCompanies: IGipPlanningWebSocketCompany[] = [];
                  if (systemServices != null && systemServices.length > 0) {
                     const liveService = systemServices.find((query: { title: string }) => query.title == serviceName);

                     if (liveService && liveService.isConnected && liveService.additionalData) {
                        if (
                           liveService.additionalData.ConnectedCompanies != null &&
                           liveService.additionalData.ConnectedCompanies.length > 0
                        ) {
                           for (let i = 0; i < liveService.additionalData.ConnectedCompanies.length; i++) {
                              const company = companies?.find(
                                 (x) => x.id == liveService!.additionalData.ConnectedCompanies[i].Id
                              );
                              if (company) {
                                 connectedCompanies.push({
                                    id: company.id,
                                    name: company.name,
                                    fullName: company.fullname,
                                    powerPlants: company.powerplants,
                                    timeZone: company.timezone,
                                 });
                              }
                           }
                        }

                        if (
                           liveService.additionalData.RejectedCompanies != null &&
                           liveService.additionalData.RejectedCompanies.length > 0
                        ) {
                           for (let i = 0; i < liveService.additionalData.RejectedCompanies.length; i++) {
                              const company = companies?.find(
                                 (x) => x.id == liveService!.additionalData.RejectedCompanies[i].Id
                              );
                              if (company) {
                                 rejectedCompanies.push({
                                    id: company.id,
                                    name: company.name,
                                    fullName: company.fullname,
                                    powerPlants: company.powerplants,
                                    timeZone: company.timezone,
                                 });
                              }
                           }
                        }
                     }
                  }

                  return { ConnectedCompanies: connectedCompanies, RejectedCompanies: rejectedCompanies };
               };

               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketSystemStatusUpdate = async (incomingMessage: any) => {
                  const { data: currentState } = getCacheEntry();
                  const services: ISystemStatusServices[] =
                     incomingMessage.body.Services?.map((x) => {
                        return {
                           additionalData: x.AdditionalData != null ? JSON.parse(x.AdditionalData) : undefined,
                           isConnected: x.IsConnected,
                           isHealthy: x.IsHealthy,
                           regionId: currentState?.liveSettings?.regionId,
                           lastUpdateDate: x.LastUpdateDate,
                           message: x.Message ?? undefined,
                           title: x.Title,
                        };
                     }) ?? [];
                  const companyStatus = getSystemStatusOfCompanies(
                     services,
                     currentState?.liveSettings?.serviceName ?? ""
                  );
                  const systemStatus: ISystemStatus = {
                     isSystemHealthy: incomingMessage.body.IsSystemHealthy,
                     services: services,
                     connectedCompanies: companyStatus.ConnectedCompanies ?? [],
                     rejectedCompanies: companyStatus.RejectedCompanies ?? [],
                  };

                  await updateCachedData((draft) => {
                     if (!draft.systemStatus) {
                        draft.systemStatus = systemStatus;
                     } else {
                        if (draft.systemStatus.isSystemHealthy !== systemStatus.isSystemHealthy)
                           draft.systemStatus.isSystemHealthy = systemStatus.isSystemHealthy;
                        if (!_.isEqual(systemStatus.services, draft.systemStatus.services)) {
                           draft.systemStatus.services = systemStatus.services;
                        }
                        if (!_.isEqual(systemStatus.connectedCompanies, draft.systemStatus.connectedCompanies)) {
                           draft.systemStatus.connectedCompanies = systemStatus.connectedCompanies;
                        }
                        if (!_.isEqual(systemStatus.rejectedCompanies, draft.systemStatus.rejectedCompanies)) {
                           draft.systemStatus.rejectedCompanies = systemStatus.rejectedCompanies;
                        }
                     }
                  });
               };

               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketDynamicColumnEval = async (incomingMessage: any) => {
                  await updateCachedData((draft) => {
                     for (let i = 0; i < incomingMessage.body.length; i++) {
                        const contractDynamicColumns = incomingMessage.body[i];
                        const _draftContract = draft.contracts.find(
                           (x) => x.contractDataKey === contractDynamicColumns.ContractDataKey
                        );
                        if (!_draftContract) continue;
                        const contractColumns: IGipPlanningDynamicColumnDetail[] = (
                           contractDynamicColumns.Results ?? []
                        ).map((x) => {
                           const contractColumn: IGipPlanningDynamicColumnDetail = {
                              detailId: x.DetailId,
                              value: x.Value,
                              companyId: x.CompanyId,
                              portfolioId: x.PortfolioId,
                           };
                           return contractColumn;
                        });
                        if (contractColumns.length == 0) continue;
                        let contractChanged = false;
                        for (let i = 0; i < contractColumns.length; i++) {
                           const dynamicColumnResult = contractColumns[i];
                           const selectedWallKey = `_${dynamicColumnResult.companyId}_${dynamicColumnResult.portfolioId}`;
                           if (!_draftContract.dynamicColumn[selectedWallKey])
                              _draftContract.dynamicColumn[selectedWallKey] = {
                                 contractDataKey: _draftContract.contractDataKey,
                                 columns: [],
                              };
                           const _draftColumnDetail = _draftContract.dynamicColumn[selectedWallKey].columns.find(
                              (x) =>
                                 x.detailId === dynamicColumnResult.detailId &&
                                 x.companyId == dynamicColumnResult.companyId &&
                                 x.portfolioId == dynamicColumnResult.portfolioId
                           );
                           if (_draftColumnDetail) {
                              if (_draftColumnDetail.value !== dynamicColumnResult.value) {
                                 _draftColumnDetail.value = dynamicColumnResult.value;
                                 contractChanged = true;
                              }
                           } else {
                              _draftContract.dynamicColumn[selectedWallKey].columns.push({
                                 detailId: dynamicColumnResult.detailId,
                                 value: dynamicColumnResult.value,
                                 companyId: dynamicColumnResult.companyId,
                                 portfolioId: dynamicColumnResult.portfolioId,
                              });
                              contractChanged = true;
                           }
                        }
                        if (contractChanged) _draftContract.updatedStamp = moment().unix();
                     }
                  });
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketRucRiskData = async (incomingMessage: any) => {
                  try {
                     const rucRiskData = JSON.parse(incomingMessage.body);
                     await updateCachedData((draft) => {
                        draft.rucRiskData = {
                           unitData: [...rucRiskData.UnitData],
                           unitLabel: [...rucRiskData.UnitLabel],
                        };
                     });
                  } catch (error) {
                     /* empty */
                  }
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketRadRiskData = async (incomingMessage: any) => {
                  try {
                     const radRiskData = JSON.parse(incomingMessage.body);

                     await updateCachedData((draft) => {
                        draft.radRiskInfo = {
                           companyRucRadValues: {
                              companyRucList:
                                 radRiskData.CompanyRucRadValues?.CompanyRucList?.map((x) => {
                                    return {
                                       companyId: x.CompanyId,
                                       rucValue: x.RucValue,
                                       instrumentHour: x.InstrumentHour,
                                       totalNeedMatchforRuc: x.totalNeedMatchforRuc,
                                       instrumentDate: x.InstrumentDate,
                                       remainingTransactionCount: x.RemainingTransactionCount,
                                       tgs: x?.TGS,
                                    };
                                 }) ?? [],
                           },
                           groupRucRadValues: {
                              groupRadDay1: radRiskData.GroupRucRadValues.GroupRadDay1,
                              groupRadDay2: radRiskData.GroupRucRadValues.GroupRadDay2,
                              groupRemainingTransactionCountDay1:
                                 radRiskData.GroupRucRadValues.GroupRemainingTransactionCountDay1,
                              groupRemainingTransactionCountDay2:
                                 radRiskData.GroupRucRadValues.GroupRemainingTransactionCountDay2,
                              groupTotalNeedMatchDay1: radRiskData.GroupRucRadValues.GroupTotalNeedMatchDay1,
                              groupTotalNeedMatchDay2: radRiskData.GroupRucRadValues.GroupTotalNeedMatchDay2,
                           },
                        };
                        const timeZone =
                           draft.liveSettings?.regionTimeZone == "Europe/Lisbon"
                              ? "Europe/Madrid"
                              : draft.liveSettings?.regionTimeZone ?? "Europe/Istanbul";

                        const groupSelection = _(draft.selectedWall)
                           .groupBy((x) => x.selectedWallKey)
                           .map((v, k) => ({
                              selectedWallKey: k,
                              companyId: v[0].companyId,
                              portfolioId: v[0].portfolioId,
                           }))
                           .value();
                        if (groupSelection.length == 0)
                           groupSelection.push({ selectedWallKey: "_0_0", companyId: 0, portfolioId: 0 });

                        for (let i = 0; i < groupSelection.length; i++) {
                           const selectedWallKey = groupSelection[i].selectedWallKey;
                           const companyId = groupSelection[i].companyId;
                           const radValues = calculateRucRad(companyId, draft.radRiskInfo);
                           const ranges = [
                              {
                                 color: "red",
                                 min:
                                    draft.liveSettings?.regionId == 1
                                       ? 100 * radValues.rucRange - 10
                                       : 100 * radValues.rucRange,
                                 max: Number.MAX_SAFE_INTEGER,
                                 needMatchMin: Number.MIN_SAFE_INTEGER,
                                 needMatchMax: 150,
                                 tgsMatchMin: 9950,
                                 tgsMatchMax: Number.MAX_SAFE_INTEGER,
                              },
                              {
                                 color: "orange",
                                 min: 80 * radValues.rucRange,
                                 max: 100 * radValues.rucRange,
                                 needMatchMin: 150,
                                 needMatchMax: 300,
                                 tgsMatchMin: 8000,
                                 tgsMatchMax: 9950,
                              },
                              {
                                 color: "yellow",
                                 min: 40 * radValues.rucRange,
                                 max: 80 * radValues.rucRange,
                                 needMatchMin: 300,
                                 needMatchMax: 600,
                                 tgsMatchMin: 4000,
                                 tgsMatchMax: 8000,
                              },
                              {
                                 color: "green",
                                 min: 0,
                                 max: 40 * radValues.rucRange,
                                 needMatchMin: 600,
                                 needMatchMax: Number.MAX_SAFE_INTEGER,
                                 tgsMatchMin: 0,
                                 tgsMatchMax: 4000,
                              },
                           ];
                           for (let i = 0; i < draft.contracts.length; i++) {
                              const contract = draft.contracts[i];
                              if (!contract.contractDynamic.isOpenForTrade && contract.rucValue.companies.length > 0)
                                 continue;

                              const deliveryEndHour = moment(
                                 contract.contractConstant.deliveryStart,
                                 "DD/MM/YYYY HH:mm:ss"
                              )
                                 .utc(true)
                                 .tz(timeZone)
                                 .format("DD.MM.YYYY HH");

                              const hourlyValues = radValues.hourlyRucValues.filter(
                                 (x) =>
                                    moment.utc(x.instrumentDate, "DD-MM-YYYY HH-mm-ss").format("DD.MM.YYYY HH") ===
                                    deliveryEndHour
                              );

                              const newValue: IContractRucValue = {
                                 color: "gray",
                                 needMatchColor: "gray",
                                 min: 0,
                                 max: 0,
                                 companies: [],
                                 needMatchCompanies: [],
                                 tgsColor: "gray",
                                 tgsInfoOfCompanies: [],
                              };

                              const rucValues = hourlyValues.filter((x) => x.ruc > 0);

                              if (rucValues.length > 0) {
                                 const orderedRucValues = _.orderBy(rucValues, (x) => x.ruc, "desc");
                                 const highestValue = orderedRucValues.at(0)!;
                                 const range = ranges.find(
                                    (x) => x.min <= highestValue.ruc && x.max > highestValue.ruc
                                 )!;

                                 const orderedNeedMatchValues = _.orderBy(
                                    rucValues,
                                    (x) => x.remainingTransactionCount
                                 );
                                 const highestNeedMatchValue = orderedNeedMatchValues.at(0)!;
                                 const rangeOfNeedMatch = ranges.find(
                                    (x) =>
                                       x.needMatchMin < highestNeedMatchValue.remainingTransactionCount &&
                                       x.needMatchMax >= highestNeedMatchValue.remainingTransactionCount
                                 )!;

                                 newValue.color = range.color;
                                 newValue.min = range.min;
                                 newValue.max = range.max;
                                 newValue.companies = mapCalculatedRucHourToContractRucValue(orderedRucValues);
                                 newValue.needMatchColor = rangeOfNeedMatch.color;
                                 newValue.needMatchCompanies =
                                    mapCalculatedRucHourToContractRucValue(orderedNeedMatchValues);
                              }

                              if (draft.liveSettings?.regionId === ERegion.Tr) {
                                 const tgsValues = hourlyValues.filter((x) => x.tgs && x?.tgs > 0);
                                 if (tgsValues.length > 0) {
                                    const orderedTgs = _.orderBy(tgsValues, (x) => x.tgs, "desc");
                                    const highestTgs = orderedTgs.at(0)!;
                                    const rangeOfTgs = ranges.find(
                                       (x) =>
                                          x.tgsMatchMin <= (highestTgs.tgs ?? 0) &&
                                          x.tgsMatchMax > (highestTgs?.tgs ?? 0)
                                    )!;
                                    newValue.tgsColor = rangeOfTgs.color;
                                    newValue.tgsInfoOfCompanies = mapCalculatedRucHourToContractRucValue(orderedTgs);
                                 }
                              }

                              if (!equal(newValue, draft.contracts[i].rucValue)) {
                                 draft.contracts[i].rucValue = { ...newValue };
                                 draft.contracts[i].updatedStamp = moment().unix();
                              }
                           }
                           if (!draft.calculatedWallValues[selectedWallKey]?.radValue) {
                              draft.calculatedWallValues[selectedWallKey].radValue = radValues;
                           } else {
                              if (radValues) {
                                 if (
                                    !_.isEqual(
                                       radValues.day1,
                                       draft.calculatedWallValues[selectedWallKey].radValue.day1
                                    )
                                 ) {
                                    draft.calculatedWallValues[selectedWallKey].radValue.day1 = {
                                       ...draft.calculatedWallValues[selectedWallKey].radValue.day1,
                                       ...radValues.day1,
                                    };
                                 }
                                 if (
                                    !_.isEqual(
                                       radValues.day2,
                                       draft.calculatedWallValues[selectedWallKey].radValue.day2
                                    )
                                 ) {
                                    draft.calculatedWallValues[selectedWallKey].radValue.day2 = {
                                       ...draft.calculatedWallValues[selectedWallKey].radValue.day2,
                                       ...radValues.day2,
                                    };
                                 }
                                 const instrumentDateList = radValues.hourlyRucValues.map((x) => x.instrumentDate);
                                 const currentInstrumentDateList = draft.calculatedWallValues[
                                    selectedWallKey
                                 ].radValue.hourlyRucValues.map((x) => x.instrumentDate);
                                 draft.calculatedWallValues[selectedWallKey].radValue.hourlyRucValues =
                                    draft.calculatedWallValues[selectedWallKey].radValue.hourlyRucValues
                                       .filter((x) => instrumentDateList.includes(x.instrumentDate))
                                       .map((x) => {
                                          const r = radValues.hourlyRucValues.find(
                                             (y) => y.instrumentDate == x.instrumentDate
                                          );
                                          if (!r) return x;
                                          if (!_.isEqual(r, x)) return r;
                                          return x;
                                       })
                                       .concat(
                                          radValues.hourlyRucValues.filter(
                                             (x) => !currentInstrumentDateList.includes(x.instrumentDate)
                                          )
                                       );
                                 if (
                                    draft.calculatedWallValues[selectedWallKey].radValue.rucRange != radValues.rucRange
                                 )
                                    draft.calculatedWallValues[selectedWallKey].radValue.rucRange = radValues.rucRange;
                              } else {
                                 draft.calculatedWallValues[selectedWallKey].radValue = defaultRadValue;
                              }
                           }
                        }
                     });
                  } catch (error) {
                     /* empty */
                  }
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketMessages = async (incomingMessage: any) => {
                  await updateCachedData((draft) => {
                     draft.userMessage = {
                        parameters: JSON.parse(incomingMessage.localization.parameters),
                        localization: incomingMessage.localization,
                        messageType:
                           incomingMessage.subject == "smartpulse.idm.message?warning"
                              ? "SMARTPULSE_IDM_MESSAGE_WARNING"
                              : incomingMessage.subject == "intradayplanning.warning.submitoffererror"
                                ? "SMARTPULSE_IDM_MESSAGE_WARNING_SUBMITOFFERERROR"
                                : "SMARTPULSE_IDM_MESSAGE_INFO",
                     };

                     setGipPlanningTicker({
                        contractDataKey: draft.userMessage.parameters.ContractDataKey,
                        localization: incomingMessage.localization,
                        parameters: draft.userMessage.parameters,
                        tickerType: ETickerType.IdmMessage,
                        eventTime: moment().toDate(),
                        uuid: uuidv4(),
                     });
                  });
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketVersionListener = async (incomingMessage: any) => {
                  const alertRules: IAlertRule[] = incomingMessage.body?.map((x) => mapAlertRule(x)) ?? [];

                  await updateCachedData((draft) => {
                     const activeListeners = _.uniq(
                        alertRules.flatMap((x) => x.listeners.flatMap((y) => y.selectedContracts))
                     );
                     const oldActiveListeners = _.uniq(
                        draft.alertRules?.flatMap((x) => x.listeners.flatMap((y) => y.selectedContracts))
                     );

                     const closedBotsContracts = _.differenceBy(
                        oldActiveListeners,
                        activeListeners,
                        (x) => x.contractId
                     );

                     draft.alertRules = [...alertRules];

                     const groupSelection = _(draft.selectedWall)
                        .groupBy((x) => x.selectedWallKey)
                        .map((v, k) => ({
                           selectedWallKey: k,
                           companyId: v[0].companyId,
                           portfolioId: v[0].portfolioId,
                        }))
                        .value();
                     for (let i = 0; i < groupSelection.length; i++) {
                        const selectedWallKey = groupSelection[i].selectedWallKey;
                        const companyId = groupSelection[i].companyId;
                        const portfolioId = groupSelection[i].portfolioId;

                        for (let i = 0; i < activeListeners.length; i++) {
                           const listenerContractId = activeListeners[i].contractId;
                           const _draftContract = draft.contracts.find((x) => x.contractId == listenerContractId);
                           if (!_draftContract) continue;

                           const listenerInfo = alertRulesContractDetails(
                              companyId,
                              portfolioId,
                              _draftContract.contractId,
                              alertRules
                           );
                           if (_draftContract.smartBotListenerInfo[selectedWallKey]) {
                              if (
                                 _draftContract.smartBotListenerInfo[selectedWallKey].myListenerCount !=
                                    listenerInfo.myListenerCount ||
                                 _draftContract.smartBotListenerInfo[selectedWallKey].othersListenerCount !=
                                    listenerInfo.othersListenerCount
                              ) {
                                 _draftContract.smartBotListenerInfo[selectedWallKey] = { ...listenerInfo };
                                 _draftContract.updatedStamp = moment().unix();
                              }
                           } else {
                              _draftContract.smartBotListenerInfo[selectedWallKey] = listenerInfo;
                              _draftContract.updatedStamp = moment().unix();
                           }
                        }
                        for (let index = 0; index < closedBotsContracts.length; index++) {
                           const listenerContractId = closedBotsContracts[index].contractId;
                           const _draftContract = draft.contracts.find((x) => x.contractId === listenerContractId);
                           if (!_draftContract) continue;
                           _draftContract.smartBotListenerInfo[selectedWallKey] = alertRulesContractDetails(
                              companyId,
                              portfolioId,
                              _draftContract.contractId,
                              alertRules
                           );
                           _draftContract.updatedStamp = moment().unix();
                        }
                     }
                  });
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketContractDetails = async (incomingMessage: any) => {
                  if (incomingMessage.body.MyProposalList) {
                     const myProposals: IMyProposal[] = incomingMessage.body.MyProposalList.map((x) => {
                        const myProposal: IMyProposal = {
                           proposals: x.Proposals?.map((y) => mapProposal(y)) ?? [] ?? [],
                           productType: x.ProductType,
                           duration: x.Duration,
                           bestBuyPrice: x.BestBuyPrice,
                           bestSellPrice: x.BestSellPrice,
                           weightedAverageActiveBuyPrice: x.WeightedAverageActiveBuyPrice,
                           weightedAverageActiveSellPrice: x.WeightedAverageActiveSellPrice,
                           totalActiveBuyQuantity: x.TotalActiveBuyQuantity,
                           totalActiveSellQuantity: x.TotalActiveSellQuantity,
                           weightedAverageTradeBuyPrice: x.WeightedAverageTradeBuyPrice,
                           weightedAverageTradeSellPrice: x.WeightedAverageTradeSellPrice,
                           totalTradeBuyQuantity: x.TotalTradeBuyQuantity,
                           totalTradeSellQuantity: x.TotalTradeSellQuantity,
                           proposalsCount: x.ProposalsCount
                        };
                        return myProposal;
                     });
                     updateGipPlanningMyProposals(incomingMessage.body.ContractDataKey, myProposals);
                  }
                  if (incomingMessage.body.DepthInfo && incomingMessage.body.DepthInfo.Proposals) {
                     const depthInfo: IDepthInfo[] = [
                        ...incomingMessage.body.DepthInfo.Proposals.map((x) => {
                           const depthInfo: IDepthInfo = {
                              id: x.Id,
                              isMineItem: x.IsMineItem,
                              price: x.Price,
                              quantity: x.Quantity / quantityDivider,
                              proposalType: x.ProposalType,
                              xBidId: x.XBidId ?? undefined,
                           };
                           return depthInfo;
                        }),
                     ];
                     const [buyDepths, sellDepths] = _.partition(
                        incomingMessage.body.DepthInfo.Proposals,
                        (x) => x.ProposalType === EProposalType.Buy
                     );
                     const maxBuyCumulativeAmount =
                        (_.maxBy(buyDepths, (b) => b.CumulativeQuantity)?.CumulativeQuantity ?? 0) / quantityDivider;
                     const maxSellCumulativeAmount =
                        (_.maxBy(sellDepths, (b) => b.CumulativeQuantity)?.CumulativeQuantity ?? 0) / quantityDivider;
                     updateGipPlanningDepthInfo(incomingMessage.body.ContractDataKey, {
                        depths: depthInfo,
                        depthSummary: {
                           buyDepthCount: buyDepths?.length ?? 0,
                           sellDepthCount: sellDepths?.length ?? 0,
                           maxBuyCumulativeAmount,
                           maxSellCumulativeAmount,
                           maxCumulativeAmount: Math.max(maxBuyCumulativeAmount, maxSellCumulativeAmount),
                        },
                     });
                  }

                  if (incomingMessage.body.TradeInfo && incomingMessage.body.TradeInfo.Trades) {
                     const trades = incomingMessage.body.TradeInfo?.Trades?.map((x) => {
                        const trade: IContractTradeDetail = {
                           buyProposalId: x.BuyProposalId ?? undefined,
                           date: x.Date,
                           direction: x.Direction,
                           id: x.Id,
                           price: x.Price,
                           quantity: x.Quantity,
                           sellProposalId: x.SellProposalId ?? undefined,
                           spread: x.Spread,
                           isGroupTrade: x.IsGroupTrade??false
                        };
                        return trade;
                     });
                     updateGipPlanningTrades(incomingMessage.body.ContractDataKey, trades);
                  }

                  if (incomingMessage.body.RelatedGroupProposals) {
                     const relatedGroupProposals: Record<string, IRelatedGroupProposal> = {};

                     Object.keys(incomingMessage.body.RelatedGroupProposals).forEach((key) => {
                        const relatedGroupProposal = incomingMessage.body.RelatedGroupProposals[key];
                        relatedGroupProposals[key] = {
                           groupId: relatedGroupProposal.GroupId,
                           region: relatedGroupProposal.Region,
                           companyName: relatedGroupProposal.CompanyName,
                        };
                     });

                     updateGipPlanningRelatedGroupProposals(
                        incomingMessage.body.ContractDataKey,
                        relatedGroupProposals
                     );
                  }

                  await updateCachedData((draft) => {
                     const _draftContract = draft.contracts.find(
                        (x) => x.contractDataKey === incomingMessage.body.ContractDataKey
                     );
                     if (_draftContract) {
                        _draftContract.updatedStamp = moment().unix();
                        _draftContract.detailsLoadedStatus = ContractDetailsLoadedStatus.LOADED;
                     }
                  });
               };

               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketPowerPlantDataUpdate = async (incomingMessage: any) => {
                  const widgetId = incomingMessage.body.WidgetId;
                  const liveServicesConfiguration: IGipPlanningLiveSettings = {
                     allowedStatesForProposalUpdate:
                        incomingMessage.body.LiveIntradaySettings.AllowedStatesForProposalUpdate,
                     blockOfferVersion: incomingMessage.body.LiveIntradaySettings.BlockOfferVersion,
                     canCreatePassiveOffer: incomingMessage.body.LiveIntradaySettings.CanCreatePassiveOffer,
                     canEditClipSize: incomingMessage.body.LiveIntradaySettings.CanEditClipSize,
                     canEditIcebergOrder: incomingMessage.body.LiveIntradaySettings.CanEditIcebergOrder,
                     canEditIcebergPrice: incomingMessage.body.LiveIntradaySettings.CanEditIcebergPrice,
                     canEditPeakPrice: incomingMessage.body.LiveIntradaySettings.CanEditPeakPrice,
                     canEditTotalQuantity: incomingMessage.body.LiveIntradaySettings.CanEditTotalQuantity,
                     canSeeTradeHitSide: incomingMessage.body.LiveIntradaySettings.CanSeeTradeHitSide,
                     contractTitleDisplayType: incomingMessage.body.LiveIntradaySettings.ContractTitleDisplayType,
                     hideProductionPlanning: incomingMessage.body.LiveIntradaySettings.HideProductionPlanning,
                     currency: incomingMessage.body.LiveIntradaySettings.Currency,
                     currencySymbol: incomingMessage.body.LiveIntradaySettings.CurrencySymbol,
                     disableBlockProposals: incomingMessage.body.LiveIntradaySettings.DisableBlockProposals,
                     disableNetPositionTooltip: incomingMessage.body.LiveIntradaySettings.DisableNetPositionTooltip,
                     enableHibernatedProposals: incomingMessage.body.LiveIntradaySettings.EnableHibernatedProposals,
                     enableIcebergProposals: incomingMessage.body.LiveIntradaySettings.EnableIcebergProposals,
                     enableProposalTreeView: incomingMessage.body.LiveIntradaySettings.EnableProposalTreeView,
                     enableValidityDate: incomingMessage.body.LiveIntradaySettings.EnableValidityDate,
                     hideMcpMultiplierButtons: incomingMessage.body.LiveIntradaySettings.HideMcpMultiplierButtons,
                     hideNewProposalOptions: incomingMessage.body.LiveIntradaySettings.HideNewProposalOptions,
                     icebergExplanationId: incomingMessage.body.LiveIntradaySettings.IcebergExplanationId,
                     intradayPlanningColumns: incomingMessage.body.LiveIntradaySettings.IntradayPlanningColumns,
                     isUsingUtcForProposals: incomingMessage.body.LiveIntradaySettings.IsUsingUtcForProposals,
                     marketOperatorId: incomingMessage.body.LiveIntradaySettings.MarketOperatorId,
                     maxPrice: incomingMessage.body.LiveIntradaySettings.MaxPrice,
                     minimumOrderPriceSensitivity:
                        incomingMessage.body.LiveIntradaySettings.MinimumOrderPriceSensitivity,
                     minPrice: incomingMessage.body.LiveIntradaySettings.MinPrice,
                     proposalAmountType: incomingMessage.body.LiveIntradaySettings.ProposalAmountType,
                     quantityDecimalPlaces: incomingMessage.body.LiveIntradaySettings.QuantityDecimalPlaces,
                     quantityDecimal: incomingMessage.body.LiveIntradaySettings.QuantityDecimal,
                     quantityDivider: incomingMessage.body.LiveIntradaySettings.QuantityDivider,
                     quantityIncrement: incomingMessage.body.LiveIntradaySettings.QuantityIncrement,
                     quantityUnit: incomingMessage.body.LiveIntradaySettings.QuantityUnit,
                     regionId: incomingMessage.body.LiveIntradaySettings.RegionId,
                     serviceName: incomingMessage.body.LiveIntradaySettings.ServiceName,
                     showRucRad: incomingMessage.body.LiveIntradaySettings.ShowRucRad,
                     useQuarterMinuteProposals: incomingMessage.body.LiveIntradaySettings.UseQuarterMinuteProposals,
                     validityDateMinuteSteps: incomingMessage.body.LiveIntradaySettings.ValidityDateMinuteSteps,
                     regionTimeZone: CommonActions.getUserRegionTimezone(),
                     netPositionDisplay: incomingMessage.body.NetPositionDisplay,
                     showInstrumentAlerts: isEligible(2501, 1),
                     defaultActionCompany: incomingMessage.body.DefaultActionCompany,
                  };
                  quantityDivider = liveServicesConfiguration.quantityDivider ?? 1;
                  const contracts: IBoardContract[] =
                     incomingMessage.body.Instruments?.map((x) => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const c = mapInstrumentToBoardContract(x, liveServicesConfiguration!.contractTitleDisplayType);
                        c.detailsLoadedStatus = ContractDetailsLoadedStatus.NOT_LOADED;
                        c.rucValue = {
                           color: "gray",
                           needMatchColor: "gray",
                           min: 0,
                           max: 0,
                           companies: [],
                           needMatchCompanies: [],
                           tgsColor: "gray",
                           tgsInfoOfCompanies: [],
                        };
                        return c;
                     }) ?? [];

                  const powerPlants: IPowerPlant[] =
                     incomingMessage.body.PowerPlants?.map((x) => {
                        const powerPlant: IPowerPlant = {
                           powerPlantId: x.PowerPlantId,
                           powerPlantName: x.PowerPlantName,
                           instruments: x.Instruments?.map((y) => {
                              return {
                                 actualProduction: y.ActualProduction,
                                 delta: y.Delta,
                                 fdppDelta: y.FdppDelta,
                                 finalDailyProductionProgram: y.FinalDailyProductionProgram,
                                 firstFdpp: y.FirstFdpp,
                                 instrument: y.Instrument,
                                 productionPrediction: y.ProductionPrediction,
                              };
                           }),
                        };
                        return powerPlant;
                     }) ?? [];

                  if (contracts.length === 0 || powerPlants.every(x => x.instruments.length === 0)) return;
                  await updateCachedData((draft) => {
                     if (
                        draft.selectedWall[widgetId] &&
                        draft.selectedWall[widgetId].entryType === EIntradayPlanningTab.ProductionForecast
                     ) {
                        draft.selectedWall[widgetId].lastFetchWallData = moment().unix();
                        draft.selectedWall[widgetId].isLoaded = true;
                        draft.selectedWall[widgetId].contractKeys = [
                           ...(incomingMessage.body.Instruments?.map((x) => x.ContractDataKey) ?? []),
                        ];
                        draft.selectedWall[widgetId].loadingState = LoadingStateEnum.LOADED;
                        draft.selectedWall[widgetId].contractList = contracts;
                     }
                     draft.liveSettings = liveServicesConfiguration;
                     draft.contracts = draft.contracts.length > 0 ? draft.contracts : contracts;
                     draft.powerPlants = powerPlants;
                  });
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketWallUpdate = async (incomingMessage: any) => {
                  const { data: currentState } = getCacheEntry();
                  const contractTitleDisplayType = currentState!.liveSettings!.contractTitleDisplayType;
                  const updatedContracts = _.chain(incomingMessage.body.Instruments)
                     .groupBy("ContractDataKey")
                     .map((value, key) => {
                        const contract = value.reduce((a, b) => ({
                           ContractDataKey: key,
                           ContractInfo: {
                              ...a.ContractInfo,
                              ...b.ContractInfo,
                           },
                           MarketInfo: {
                              ...a.MarketInfo,
                              ...b.MarketInfo,
                           },
                           ConsumptionInfo: {
                              ...a.ConsumptionInfo,
                              ...b.ConsumptionInfo,
                           },
                           InstructionInfo: {
                              ...a.InstructionInfo,
                              ...b.InstructionInfo,
                           },
                           DepthSummary: {
                              ...a.DepthSummary,
                              ...b.DepthSummary,
                           },
                           PositionInfo: {
                              ...a.PositionInfo,
                              ...b.PositionInfo,
                           },
                           ProductionInfo: {
                              ...a.ProductionInfo,
                              ...b.ProductionInfo,
                           },
                           ProposalInfo: {
                              ...a.ProposalInfo,
                              ...b.ProposalInfo,
                           },
                           TradeInfo: {
                              ...a.TradeInfo,
                              ...b.TradeInfo,
                           },
                        }));

                        contract.ContractInfo = _.isNull(contract.ContractInfo) ? undefined : contract.ContractInfo;
                        contract.ConsumptionInfo = _.isNull(contract.ConsumptionInfo)
                           ? undefined
                           : contract.ConsumptionInfo;
                        contract.InstructionInfo = _.isNull(contract.InstructionInfo)
                           ? undefined
                           : contract.InstructionInfo;
                        contract.MarketInfo = _.isNull(contract.MarketInfo) ? undefined : contract.MarketInfo;
                        contract.DepthSummary = _.isNull(contract.DepthSummary) ? undefined : contract.DepthSummary;
                        contract.PositionInfo = _.isNull(contract.PositionInfo) ? undefined : contract.PositionInfo;
                        contract.ProductionInfo = _.isNull(contract.ProductionInfo)
                           ? undefined
                           : contract.ProductionInfo;
                        contract.ProposalInfo = _.isNull(contract.ProposalInfo) ? undefined : contract.ProposalInfo;
                        contract.TradeInfo = _.isNull(contract.TradeInfo) ? undefined : contract.TradeInfo;
                        contract.trades = [];
                        return contract;
                     })
                     .map((contract) => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const updateContract = mapInstrumentToBoardContract(contract, contractTitleDisplayType);
                        return updateContract;
                     })
                     .value();
                  const newTickers: IGipPlanningTickerInfo[] = [];
                  const newTickersPriority: IGipPlanningTickerInfo[] = [];

                  await updateCachedData((draft) => {
                     for (let i = 0; i < updatedContracts.length; i++) {
                        const updatedContract = updatedContracts[i];

                        if (
                           updatedContract.tradeInfo &&
                           !_.isEqual(updatedContract.tradeInfo, draft.lastTradeInfo ?? {})
                        ) {
                           const _draftContract = draft.contracts.find(
                              (x) => x.contractDataKey === updatedContract.contractDataKey
                           );
                           if (_draftContract) {
                              _draftContract.updatedStamp = moment().unix();
                           }
                           newTickersPriority.push({
                              contractDataKey: updatedContract.contractDataKey,
                              newValue: {
                                 lastTradePrice: updatedContract.tradeInfo.lastTradePrice,
                                 lastTradeQuantity: updatedContract.tradeInfo.lastTradeQuantity,
                              },
                              message: "",
                              tickerType: ETickerType.TradeLogs,
                              eventTime: moment().toDate(),
                              uuid: uuidv4(),
                           });

                           draft.lastTradeInfo = {
                              ...draft.lastTradeInfo,
                              ...updatedContract.tradeInfo,
                           };
                        }
                     }

                     for (let i = 0; i < updatedContracts.length; i++) {
                        const updatedContract = updatedContracts[i];
                        const _draftContract = draft.contracts.find(
                           (x) => x.contractDataKey === updatedContract.contractDataKey
                        );
                        let changedValues = false;
                        if (_draftContract) {
                           _draftContract.detailsLoadedStatus = ContractDetailsLoadedStatus.LOADED;
                           if (
                              updatedContract.contractDynamic &&
                              (_draftContract.contractDynamic.isOpenForTrade !=
                                 updatedContract.contractDynamic.isOpenForTrade ||
                                 _draftContract.contractDynamic.state != updatedContract.contractDynamic.state ||
                                 (updatedContract.contractDynamic.isOpenForTrade &&
                                    updatedContract.contractDynamic.remainingTimeSeconds <= 60) ||
                                 (updatedContract.contractDynamic.isOpenForTrade &&
                                    updatedContract.contractDynamic.remainingTime !=
                                       _draftContract.contractDynamic.remainingTime))
                           ) {
                              _draftContract.contractDynamic = {
                                 ..._draftContract.contractDynamic,
                                 ...updatedContract.contractDynamic,
                              };
                              changedValues = true;
                           }
                           if (
                              updatedContract.contractBests &&
                              !_.isEqual(
                                 updatedContract.contractBests,
                                 _draftContract.contractBests ? current(_draftContract.contractBests) : {}
                              )
                           ) {
                              if (
                                 _draftContract.contractBests.buyContractBestQuantity !=
                                 updatedContract.contractBests.buyContractBestQuantity
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.contractBests.buyContractBestQuantity,
                                    newValue: updatedContract.contractBests.buyContractBestQuantity,
                                    message: "",
                                    tickerType: ETickerType.BuyContractBestQuantity,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }

                              if (
                                 _draftContract.contractBests.buyContractBestPrice !=
                                 updatedContract.contractBests.buyContractBestPrice
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.contractBests.buyContractBestPrice,
                                    newValue: updatedContract.contractBests.buyContractBestPrice,
                                    message: "",
                                    tickerType: ETickerType.BuyContractBestPrice,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 _draftContract.contractBests.sellContractBestQuantity !=
                                 updatedContract.contractBests.sellContractBestQuantity
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.contractBests.sellContractBestQuantity,
                                    newValue: updatedContract.contractBests.sellContractBestQuantity,
                                    message: "",
                                    tickerType: ETickerType.SellContractBestQuantity,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 _draftContract.contractBests.sellContractBestPrice !=
                                 updatedContract.contractBests.sellContractBestPrice
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.contractBests.sellContractBestPrice,
                                    newValue: updatedContract.contractBests.sellContractBestPrice,
                                    message: "",
                                    tickerType: ETickerType.SellContractBestPrice,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              _draftContract.contractBests = {
                                 ..._draftContract.contractBests,
                                 ...updatedContract.contractBests,
                              };
                              changedValues = true;
                           }
                           if (updatedContract.depthSummary) {
                              if (!_draftContract.depthSummary) {
                                 _draftContract.depthSummary = updatedContract.depthSummary;
                              } else {
                                 const buyDepth = _draftContract.depthSummary?.buyDepth.map((x) => x.rating) ?? [];
                                 const sellDepth = _draftContract.depthSummary?.sellDepth?.map((x) => x.rating) ?? [];
                                 if (
                                    !_.isEqual(
                                       buyDepth,
                                       updatedContract.depthSummary.buyDepth?.map((x) => x.rating)
                                    )
                                 ) {
                                    _draftContract.depthSummary.buyDepth = updatedContract.depthSummary.buyDepth;
                                    changedValues = true;
                                 }
                                 if (
                                    !_.isEqual(
                                       sellDepth,
                                       updatedContract.depthSummary.sellDepth.map((x) => x.rating)
                                    )
                                 ) {
                                    _draftContract.depthSummary.sellDepth = updatedContract.depthSummary.sellDepth;
                                    changedValues = true;
                                 }
                              }
                           }
                           if (
                              updatedContract.marketInfo &&
                              !_.isEqual(
                                 updatedContract.marketInfo,
                                 _draftContract.marketInfo ? current(_draftContract.marketInfo) : {}
                              )
                           ) {
                              if (_draftContract.marketInfo.mcp != updatedContract.marketInfo.mcp) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.marketInfo.mcp,
                                    newValue: updatedContract.marketInfo.mcp,
                                    message: "",
                                    tickerType: ETickerType.MCP,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 _draftContract.marketInfo.systemDirection != updatedContract.marketInfo.systemDirection
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.marketInfo.systemDirection,
                                    newValue: updatedContract.marketInfo.systemDirection,
                                    message: "",
                                    tickerType: ETickerType.SystemDirection,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }

                              _draftContract.marketInfo = updatedContract.marketInfo;
                              changedValues = true;
                           }

                           const wallKeys = Object.keys(updatedContract.positionInfo??{});
                           wallKeys.forEach((wallKey)=>{
                              if(!updatedContract.positionInfo)updatedContract.positionInfo={};
                              const updatePositionInfo = updatedContract.positionInfo[wallKey];
                              const currentPositionInfo = _draftContract.positionInfo[wallKey];

                              if (
                                 !_.isEqual(
                                    updatePositionInfo,
                                    currentPositionInfo
                                 )
                              ) {
                                 
                                 if (
                                    currentPositionInfo != undefined &&
                                    currentPositionInfo?.netPosition != updatePositionInfo.netPosition
                                 ) {
                                    newTickers.push({
                                       contractDataKey: _draftContract.contractDataKey,
                                       oldValue: currentPositionInfo?.netPosition,
                                       newValue: updatePositionInfo.netPosition,
                                       message: "",
                                       tickerType: ETickerType.NetPosition,
                                       eventTime: moment().toDate(),
                                       uuid: uuidv4(),
                                    });
                                 }
                                 if (
                                    currentPositionInfo != undefined &&
                                    currentPositionInfo?.biliteralAggreementQuantity !=
                                       updatePositionInfo.biliteralAggreementQuantity
                                 ) {
                                    newTickers.push({
                                       contractDataKey: _draftContract.contractDataKey,
                                       oldValue: currentPositionInfo?.biliteralAggreementQuantity,
                                       newValue: updatePositionInfo.biliteralAggreementQuantity,
                                       message: "",
                                       tickerType: ETickerType.BiliteralAggreementQuantity,
                                       eventTime: moment().toDate(),
                                       uuid: uuidv4(),
                                    });
                                 }
                                 if (
                                    currentPositionInfo != undefined &&
                                    currentPositionInfo?.dayAheadDefaultQuantity !=
                                       updatePositionInfo.dayAheadDefaultQuantity
                                 ) {
                                    newTickers.push({
                                       contractDataKey: _draftContract.contractDataKey,
                                       oldValue: currentPositionInfo?.dayAheadDefaultQuantity,
                                       newValue: updatePositionInfo.dayAheadDefaultQuantity,
                                       message: "",
                                       tickerType: ETickerType.DayAheadDefaultQuantity,
                                       eventTime: moment().toDate(),
                                       uuid: uuidv4(),
                                    });
                                 }
                                 if (
                                    currentPositionInfo != undefined &&
                                    currentPositionInfo?.dayAheadBlockQuantity != updatePositionInfo.dayAheadBlockQuantity
                                 ) {
                                    newTickers.push({
                                       contractDataKey: _draftContract.contractDataKey,
                                       oldValue: currentPositionInfo?.dayAheadBlockQuantity,
                                       newValue: updatePositionInfo.dayAheadBlockQuantity,
                                       message: "",
                                       tickerType: ETickerType.DayAheadBlockQuantity,
                                       eventTime: moment().toDate(),
                                       uuid: uuidv4(),
                                    });
                                 }
   
                                 if (
                                    currentPositionInfo != undefined &&
                                    currentPositionInfo?.intradayDefaultQuantity !=
                                       updatePositionInfo.intradayDefaultQuantity
                                 ) {
                                    newTickers.push({
                                       contractDataKey: _draftContract.contractDataKey,
                                       oldValue: currentPositionInfo?.intradayDefaultQuantity,
                                       newValue: updatePositionInfo.intradayDefaultQuantity,
                                       message: "",
                                       tickerType: ETickerType.IntradayDefaultQuantity,
                                       eventTime: moment().toDate(),
                                       uuid: uuidv4(),
                                    });
                                 }
                                 if (
                                    currentPositionInfo != undefined &&
                                    currentPositionInfo?.intradayBlockQuantity != updatePositionInfo.intradayBlockQuantity
                                 ) {
                                    newTickers.push({
                                       contractDataKey: _draftContract.contractDataKey,
                                       oldValue: currentPositionInfo?.intradayBlockQuantity,
                                       newValue: updatePositionInfo.intradayBlockQuantity,
                                       message: "",
                                       tickerType: ETickerType.IntradayBlockQuantity,
                                       eventTime: moment().toDate(),
                                       uuid: uuidv4(),
                                    });
                                 }
                                 if (
                                    currentPositionInfo != undefined &&
                                    currentPositionInfo?.productionPrediction != updatePositionInfo.productionPrediction
                                 ) {
                                    newTickers.push({
                                       contractDataKey: _draftContract.contractDataKey,
                                       oldValue: currentPositionInfo?.productionPrediction,
                                       newValue: updatePositionInfo.productionPrediction,
                                       message: "",
                                       tickerType: ETickerType.ProductionPrediction,
                                       eventTime: moment().toDate(),
                                       uuid: uuidv4(),
                                    });
                                 }
                                 if (
                                    currentPositionInfo != undefined &&
                                    currentPositionInfo?.retailQuantity != updatePositionInfo.retailQuantity
                                 ) {
                                    newTickers.push({
                                       contractDataKey: _draftContract.contractDataKey,
                                       oldValue: currentPositionInfo?.retailQuantity,
                                       newValue: updatePositionInfo.retailQuantity,
                                       message: "",
                                       tickerType: ETickerType.RetailQuantity,
                                       eventTime: moment().toDate(),
                                       uuid: uuidv4(),
                                    });
                                 }
                                 _draftContract.positionInfo[updatePositionInfo.wallSelectionKey] = {
                                    ..._draftContract.positionInfo[updatePositionInfo.wallSelectionKey],
                                    ...updatePositionInfo,
                                 };
                                 changedValues = true;
                              }

                           });
                           if (
                              updatedContract.productionInfo &&
                              !_.isEqual(
                                 updatedContract.productionInfo,
                                 _draftContract.productionInfo ? current(_draftContract.productionInfo) : {}
                              )
                           ) {
                              if (
                                 Math.abs(
                                    (_draftContract.productionInfo?.actualProduction ?? 0) -
                                       (updatedContract.productionInfo?.actualProduction ?? 0)
                                 ) > twoDigitThreshold
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.productionInfo.actualProduction,
                                    newValue: updatedContract.productionInfo.actualProduction,
                                    message: "",
                                    tickerType: ETickerType.ActualProduction,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }

                              _draftContract.productionInfo = updatedContract.productionInfo;
                              changedValues = true;
                           }
                           if (
                              updatedContract.consumptionInfo &&
                              !_.isEqual(
                                 updatedContract.consumptionInfo,
                                 _draftContract.consumptionInfo ? current(_draftContract.consumptionInfo) : {}
                              )
                           ) {
                              _draftContract.consumptionInfo = updatedContract.consumptionInfo;
                              changedValues = true;
                           }
                           if (
                              updatedContract.instructionInfo &&
                              !_.isEqual(
                                 updatedContract.instructionInfo,
                                 _draftContract.instructionInfo ? current(_draftContract.instructionInfo) : {}
                              )
                           ) {
                              _draftContract.instructionInfo = updatedContract.instructionInfo;
                              changedValues = true;
                           }
                           if (
                              updatedContract.proposalInfo &&
                              !_.isEqual(
                                 updatedContract.proposalInfo,
                                 _draftContract.proposalInfo ? current(_draftContract.proposalInfo) : {}
                              )
                           ) {
                              if (
                                 _draftContract.proposalInfo.buyContractMyPrice !=
                                 updatedContract.proposalInfo.buyContractMyPrice
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.proposalInfo.buyContractMyPrice,
                                    newValue: updatedContract.proposalInfo.buyContractMyPrice,
                                    message: "",
                                    tickerType: ETickerType.BuyContractMyPrice,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 _draftContract.proposalInfo.sellContractMyPrice !=
                                 updatedContract.proposalInfo.sellContractMyPrice
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.proposalInfo.sellContractMyPrice,
                                    newValue: updatedContract.proposalInfo.sellContractMyPrice,
                                    message: "",
                                    tickerType: ETickerType.SellContractMyPrice,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              _draftContract.proposalInfo = updatedContract.proposalInfo;
                              changedValues = true;
                           }
                           if (
                              updatedContract.tradeInfo &&
                              !_.isEqual(
                                 updatedContract.tradeInfo,
                                 _draftContract.tradeInfo ? current(_draftContract.tradeInfo) : {}
                              )
                           ) {
                              _draftContract.tradeInfo = updatedContract.tradeInfo;
                              changedValues = true;
                           }
                           if (changedValues) {
                              const calculatedValue = getCalculatedValues([...draft.contracts]);
                              if (!equal(calculatedValue, draft.calculatedValues))
                                 draft.calculatedValues = {
                                    ...draft.calculatedValues,
                                    ...calculatedValue,
                                 };

                              const groupSelection = _(draft.selectedWall)
                                 .groupBy((x) => x.selectedWallKey)
                                 .map((v, k) => k)
                                 .value();

                              for (let i = 0; i < groupSelection.length; i++) {
                                 const key = groupSelection[i];
                                 const calculatedWallData = getCalculatedWallValues([...draft.contracts], key);
                                 if (!equal(calculatedWallData, draft.calculatedWallValues[key]))
                                    draft.calculatedWallValues = {
                                       ...draft.calculatedWallValues,
                                       [key]: { ...calculatedWallData },
                                    };
                              }
                              _draftContract.updatedStamp = moment().unix();
                           }
                        }
                        const state =
                           updatedContract.contractDynamic?.state ?? _draftContract?.contractDynamic?.state ?? -1;
                        const isOpenForTrade =
                           updatedContract.contractDynamic?.isOpenForTrade ??
                           _draftContract?.contractDynamic?.isOpenForTrade ??
                           false;

                        const draftListedContractInfo = draft.listedContractsInfo.find(
                           (x) => x.contractDataKey == updatedContract.contractDataKey
                        );
                        if (draftListedContractInfo) {
                           if (draftListedContractInfo.isOpenForTrade !== isOpenForTrade) {
                              draftListedContractInfo.isOpenForTrade = isOpenForTrade;
                           }
                           if (draftListedContractInfo.state !== state) {
                              draftListedContractInfo.state = state;
                           }
                        } else {
                           if (updatedContract && updatedContract.contractConstant && updatedContract.contractDynamic) {
                              draft.listedContractsInfo = _.orderBy(
                                 [
                                    ...draft.listedContractsInfo.filter((x) =>
                                       draft.contracts.map((y) => y.contractDataKey).includes(x.contractDataKey)
                                    ),
                                    {
                                       displayName: updatedContract.contractConstant.displayName,
                                       displayShortName: updatedContract.contractConstant.displayShortName,
                                       contractDataKey: updatedContract.contractDataKey,
                                       contractId: updatedContract.contractConstant.contractId,
                                       contractName: updatedContract.contractConstant.contractName,
                                       periodId: updatedContract.contractConstant.periodId,
                                       periodName: updatedContract.contractConstant.periodName,
                                       tradingPhaseEnd: updatedContract.contractConstant?.tradingPhaseEnd,
                                       deliveryStart: updatedContract.contractConstant.deliveryStart,
                                       deliveryEnd: updatedContract.contractConstant.deliveryEnd,
                                       isOpenForTrade: updatedContract.contractDynamic.isOpenForTrade,
                                       state: updatedContract.contractDynamic.state,
                                       contractType: updatedContract.contractConstant.contractType,
                                    },
                                 ],
                                 (x) => x.deliveryStart,
                                 "asc"
                              );
                           } else {
                              draft.listedContractsInfo = draft.listedContractsInfo.filter((x) =>
                                 draft.contracts.map((y) => y.contractDataKey).includes(x.contractDataKey)
                              );
                           }
                        }
                     }
                  });

                  if (newTickers.length > 0) {
                     setTickerMessage(newTickers);
                  }
                  if (newTickersPriority.length > 0) {
                     setTickerMessage(newTickersPriority);
                  }
               };

               // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
               const webSocketOnClose = async (event: any) => {
                  await updateCachedData((draft) => {
                     draft.connectionStatus = GipPlanningWebSocketConnectionStatus.CLOSED;
                  });

                  setTimeout(() => {
                     if (event.code !== 1006) {
                        if (tryingConnectCount < 10) {
                           console.log("webSocketOnClose- try reconnect");
                           connectSocket(true);
                           tryingConnectCount++;
                        } else {
                           location.reload();
                        }
                     } else {
                        if (tryingConnectCount < 5) {
                           console.log("webSocketOnClose- try reconnect");
                           connectSocket(true);
                           tryingConnectCount++;
                        } else {
                           location.reload();
                        }
                     }
                  }, 2000);
               };

               // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
               const wbSocketOnError = async (event: any) => {
                  await updateCachedData((draft) => {
                     console.log("wbSocketOnError", event);
                     draft.connectionStatus = GipPlanningWebSocketConnectionStatus.CLOSED;
                  });
               };
               const alertRulesContractDetails = (
                  companyId: number,
                  portfolioId: number,
                  contractDataKey: string,
                  alertRules: IAlertRule[]
               ): IBoardContractSmartBotListenerInfo => {
                  const portfolioCompanyIds: number[] =
                     CommonActions.getPermissions().portfolios?.find((x) => x.id == portfolioId)?.companies ?? [];

                  const result: IBoardContractSmartBotListenerInfo = {
                     myListenerCount: 0,
                     othersListenerCount: 0,
                     myListenerAlertRuleIds: [],
                     othersListenerAlertRuleIds: [],
                  };

                  const myListeners = alertRules
                     .filter(
                        (x) =>
                           ((companyId ?? 0) > 0
                              ? x.actions.findIndex((y) => y.companyId == companyId) > -1
                              : (portfolioId ?? 0) > 0
                                ? x.actions.findIndex((y) => portfolioCompanyIds.includes(y.companyId)) > -1 //portfolioCompanyIds.includes(x.actions.find((y) => y.companyId == companyId)?.companyId ?? 0)
                                : true) &&
                           x.createUser == activeUserId &&
                           x.listeners.filter((x) => x.selectedContracts?.find((s) => s.contractId == contractDataKey))
                              .length > 0
                     )
                     .map((x) => x.id);

                  const otherListeners = alertRules
                     .filter(
                        (x) =>
                           ((companyId ?? 0) > 0
                              ? x.actions.findIndex((y) => y.companyId == companyId) > -1
                              : (portfolioId ?? 0) > 0
                                ? x.actions.findIndex((y) => portfolioCompanyIds.includes(y.companyId)) > -1
                                : true) &&
                           x.createUser != activeUserId &&
                           x.listeners.filter((x) => x.selectedContracts?.find((s) => s.contractId == contractDataKey))
                              .length > 0
                     )
                     .map((x) => x.id);
                  result.myListenerCount = myListeners.length;
                  result.myListenerAlertRuleIds = myListeners;
                  result.othersListenerCount = otherListeners.length;
                  result.othersListenerAlertRuleIds = otherListeners;
                  return result;
               };

               const getMessageSubjectType = async (to: string, subject: string): Promise<GIP_WS_SUBJECTS> => {
                  if (to === "group") {
                     if (subject === GIP_WS_SUBJECTS.GROUP_ENTER_USER) {
                        return GIP_WS_SUBJECTS.GROUP_ENTER_USER;
                     }
                  } else if (to === "user") {
                     switch (subject) {
                        case GIP_WS_SUBJECTS.USER_GLOBAL_ERROR:
                           return GIP_WS_SUBJECTS.USER_GLOBAL_ERROR;
                        case GIP_WS_SUBJECTS.USER_GLOBAL_SUCCESS:
                           return GIP_WS_SUBJECTS.USER_GLOBAL_SUCCESS;
                        case GIP_WS_SUBJECTS.USER_GLOBAL_SESSIONID:
                           return GIP_WS_SUBJECTS.USER_GLOBAL_SESSIONID;
                        case GIP_WS_SUBJECTS.USER_SYSTEM_MESSAGE:
                           return GIP_WS_SUBJECTS.USER_SYSTEM_MESSAGE;
                        case GIP_WS_SUBJECTS.USER_WALL_DATA:
                           return GIP_WS_SUBJECTS.USER_WALL_DATA;
                        case GIP_WS_SUBJECTS.USER_WALL_UPDATE:
                           return GIP_WS_SUBJECTS.USER_WALL_UPDATE;
                        case GIP_WS_SUBJECTS.USER_POWER_PLANT_DATA:
                           return GIP_WS_SUBJECTS.USER_POWER_PLANT_DATA;
                        case GIP_WS_SUBJECTS.USER_CONTRACT_DETAILS:
                           return GIP_WS_SUBJECTS.USER_CONTRACT_DETAILS;
                        case GIP_WS_SUBJECTS.USER_SYSTEM_STATUS_UPDATE:
                           return GIP_WS_SUBJECTS.USER_SYSTEM_STATUS_UPDATE;
                        case GIP_WS_SUBJECTS.USER_TICKER:
                           return GIP_WS_SUBJECTS.USER_TICKER;
                        case GIP_WS_SUBJECTS.USER_DYNAMIC_COLUMN_EVAL:
                           return GIP_WS_SUBJECTS.USER_DYNAMIC_COLUMN_EVAL;
                        case GIP_WS_SUBJECTS.USER_VERSION_LISTENERS:
                           return GIP_WS_SUBJECTS.USER_VERSION_LISTENERS;
                        case GIP_WS_SUBJECTS.USER_MESSAGE_WARNING:
                           return GIP_WS_SUBJECTS.USER_MESSAGE_WARNING;
                        case GIP_WS_SUBJECTS.USER_SUBMIT_OFFER_ERROR:
                           return GIP_WS_SUBJECTS.USER_SUBMIT_OFFER_ERROR;
                        case GIP_WS_SUBJECTS.USER_MESSAGE_INFO:
                           return GIP_WS_SUBJECTS.USER_MESSAGE_INFO;
                        case GIP_WS_SUBJECTS.USER_SERVER_TIME:
                           return GIP_WS_SUBJECTS.USER_SERVER_TIME;
                        case GIP_WS_SUBJECTS.USER_EPIAS_KOPI_INFO:
                           return GIP_WS_SUBJECTS.USER_EPIAS_KOPI_INFO;
                        case GIP_WS_SUBJECTS.USER_RUC_RISK_DATA:
                           return GIP_WS_SUBJECTS.USER_RUC_RISK_DATA;
                        case GIP_WS_SUBJECTS.USER_RAD_RISK_DATA:
                           return GIP_WS_SUBJECTS.USER_RAD_RISK_DATA;
                        case GIP_WS_SUBJECTS.USER_PONG:
                           return GIP_WS_SUBJECTS.USER_PONG;
                        case GIP_WS_SUBJECTS.USER_CONTRACT_DETAILS_LOADED:
                           return GIP_WS_SUBJECTS.USER_CONTRACT_DETAILS_LOADED;
                     }
                  } else if (_.isNil(to)) {
                     if (subject === GIP_WS_SUBJECTS.USER_EXIT) {
                        return GIP_WS_SUBJECTS.USER_EXIT;
                     }
                  }

                  return GIP_WS_SUBJECTS.UNKNOWN;
               };

               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketWallDataUpdate = async (incomingMessage: any): Promise<void> => {
                  const widgetId = incomingMessage.body.WidgetId;
                  const liveServicesConfiguration: IGipPlanningLiveSettings = {
                     allowedStatesForProposalUpdate:
                        incomingMessage.body.LiveIntradaySettings.AllowedStatesForProposalUpdate,
                     blockOfferVersion: incomingMessage.body.LiveIntradaySettings.BlockOfferVersion,
                     canCreatePassiveOffer: incomingMessage.body.LiveIntradaySettings.CanCreatePassiveOffer,
                     canEditClipSize: incomingMessage.body.LiveIntradaySettings.CanEditClipSize,
                     canEditIcebergOrder: incomingMessage.body.LiveIntradaySettings.CanEditIcebergOrder,
                     canEditIcebergPrice: incomingMessage.body.LiveIntradaySettings.CanEditIcebergPrice,
                     canEditPeakPrice: incomingMessage.body.LiveIntradaySettings.CanEditPeakPrice,
                     canEditTotalQuantity: incomingMessage.body.LiveIntradaySettings.CanEditTotalQuantity,
                     canSeeTradeHitSide: incomingMessage.body.LiveIntradaySettings.CanSeeTradeHitSide,
                     contractTitleDisplayType: incomingMessage.body.LiveIntradaySettings.ContractTitleDisplayType,
                     hideProductionPlanning: incomingMessage.body.LiveIntradaySettings.HideProductionPlanning,
                     currency: incomingMessage.body.LiveIntradaySettings.Currency,
                     currencySymbol: incomingMessage.body.LiveIntradaySettings.CurrencySymbol,
                     disableBlockProposals: incomingMessage.body.LiveIntradaySettings.DisableBlockProposals,
                     disableNetPositionTooltip: incomingMessage.body.LiveIntradaySettings.DisableNetPositionTooltip,
                     enableHibernatedProposals: incomingMessage.body.LiveIntradaySettings.EnableHibernatedProposals,
                     enableIcebergProposals: incomingMessage.body.LiveIntradaySettings.EnableIcebergProposals,
                     enableProposalTreeView: incomingMessage.body.LiveIntradaySettings.EnableProposalTreeView,
                     enableValidityDate: incomingMessage.body.LiveIntradaySettings.EnableValidityDate,
                     hideMcpMultiplierButtons: incomingMessage.body.LiveIntradaySettings.HideMcpMultiplierButtons,
                     hideNewProposalOptions: incomingMessage.body.LiveIntradaySettings.HideNewProposalOptions,
                     icebergExplanationId: incomingMessage.body.LiveIntradaySettings.IcebergExplanationId,
                     intradayPlanningColumns: incomingMessage.body.LiveIntradaySettings.IntradayPlanningColumns,
                     isUsingUtcForProposals: incomingMessage.body.LiveIntradaySettings.IsUsingUtcForProposals,
                     marketOperatorId: incomingMessage.body.LiveIntradaySettings.MarketOperatorId,
                     maxPrice: incomingMessage.body.LiveIntradaySettings.MaxPrice,
                     minimumOrderPriceSensitivity:
                        incomingMessage.body.LiveIntradaySettings.MinimumOrderPriceSensitivity,
                     minPrice: incomingMessage.body.LiveIntradaySettings.MinPrice,
                     proposalAmountType: incomingMessage.body.LiveIntradaySettings.ProposalAmountType,
                     quantityDecimalPlaces: incomingMessage.body.LiveIntradaySettings.QuantityDecimalPlaces,
                     quantityDecimal: incomingMessage.body.LiveIntradaySettings.QuantityDecimal,
                     quantityDivider: incomingMessage.body.LiveIntradaySettings.QuantityDivider,
                     quantityIncrement: incomingMessage.body.LiveIntradaySettings.QuantityIncrement,
                     quantityUnit: incomingMessage.body.LiveIntradaySettings.QuantityUnit,
                     regionId: incomingMessage.body.LiveIntradaySettings.RegionId,
                     serviceName: incomingMessage.body.LiveIntradaySettings.ServiceName,
                     showRucRad: incomingMessage.body.LiveIntradaySettings.ShowRucRad,
                     useQuarterMinuteProposals: incomingMessage.body.LiveIntradaySettings.UseQuarterMinuteProposals,
                     validityDateMinuteSteps: incomingMessage.body.LiveIntradaySettings.ValidityDateMinuteSteps,
                     regionTimeZone: CommonActions.getUserRegionTimezone(),
                     netPositionDisplay: incomingMessage.body.NetPositionDisplay,
                     showInstrumentAlerts: isEligible(2501, 1),
                     defaultActionCompany: incomingMessage.body.DefaultActionCompany,
                  };
                  quantityDivider = liveServicesConfiguration.quantityDivider ?? 1;
                  const contracts: IBoardContract[] =
                     incomingMessage.body.Instruments?.map((x) => {
                        const mappedContract = mapInstrumentToBoardContract(
                           x,
                           liveServicesConfiguration.contractTitleDisplayType
                        );
                        const contract: IBoardContract = {
                           contractConstant: mappedContract.contractConstant!,
                           contractDynamic: mappedContract.contractDynamic!,
                           contractBests: mappedContract.contractBests!,
                           depthSummary: mappedContract.depthSummary!,
                           marketInfo: mappedContract.marketInfo!,
                           positionInfo: mappedContract.positionInfo!,
                           productionInfo: mappedContract.productionInfo!,
                           instructionInfo: mappedContract.instructionInfo,
                           consumptionInfo: mappedContract.consumptionInfo,
                           proposalInfo: mappedContract.proposalInfo!,
                           contractDataKey: mappedContract.contractDataKey,
                           updatedStamp: moment().unix(),
                           contractId: mappedContract.contractConstant?.contractId ?? mappedContract.contractDataKey,
                           tradeInfo: mappedContract.tradeInfo!,
                           dynamicColumn: {},
                           detailsLoadedStatus: ContractDetailsLoadedStatus.NOT_LOADED,
                           smartBotListenerInfo: {},
                           rucValue: {
                              color: "gray",
                              needMatchColor: "gray",
                              min: 0,
                              max: 0,
                              companies: [],
                              needMatchCompanies: [],
                              tgsColor: "gray",
                              tgsInfoOfCompanies: [],
                           },
                        };

                        return contract;
                     }) ?? [];

                  const contractProductTypes = [...new Set(contracts.map(c => c.contractConstant.productType))]
                     .map(p => EContractProductType[p as keyof typeof EContractProductType]);
   
                  const alertRules: IAlertRule[] = incomingMessage.body.AlertRules?.map((x) => mapAlertRule(x)) ?? [];

                  const libraryScripts: ILibraryScript[] =
                     incomingMessage.body.AlertRuleLibraryScriptList?.LibraryScripts?.map((x) => mapLibraryScript(x)) ??
                     [];

                  const services: ISystemStatusServices[] =
                     incomingMessage.body.SystemStatus?.Services?.map((x) => {
                        return {
                           additionalData: x.AdditionalData != null ? JSON.parse(x.AdditionalData) : undefined,
                           isConnected: x.IsConnected,
                           isHealthy: x.IsHealthy,
                           regionId: liveServicesConfiguration.regionId,
                           lastUpdateDate: x.LastUpdateDate,
                           message: x.Message ?? undefined,
                           title: x.Title,
                        };
                     }) ?? [];

                  const proposalLimits: IProposalLimit[] =
                     incomingMessage.body.ProposalLimits?.map((x) => {
                        return {
                           id: x.Id,
                           region: x.Region,
                           complexType: x.ComplexType,
                           orderType: x.OrderType,
                           messageId: x.MessageId,
                           defaultMessage: x.DefaultMessage,
                           limitationRule: x.LimitationRule,
                           rule: {
                              minPrice: x.Rule?.MinPrice,
                              maxPrice: x.Rule?.MaxPrice,
                              maxPeakPrice: x.Rule?.MaxPeakPrice,
                              minPeakPrice: x.Rule?.MinPeakPrice,
                              maxTotalQuantity: x.Rule?.MaxTotalQuantity,
                              minTotalQuantity: x.Rule?.MinTotalQuantity,
                              maxQuantity: x.Rule?.MaxQuantity,
                              minQuantity: x.Rule?.MinQuantity,
                           },
                        };
                     }) ?? [];
                  const companyStatus = getSystemStatusOfCompanies(services, liveServicesConfiguration.serviceName);
                  const systemStatus: ISystemStatus = {
                     isSystemHealthy: incomingMessage.body.SystemStatus?.IsSystemHealthy,
                     services: services,
                     connectedCompanies: companyStatus.ConnectedCompanies ?? [],
                     rejectedCompanies: companyStatus.RejectedCompanies ?? [],
                  };
                  const activeListeners = _.uniq(
                     alertRules.flatMap((x) => x.listeners.flatMap((y) => y.selectedContracts))
                  );
                  await updateCachedData((draft) => {
                     const socketManager = socketMap.get(socketConnectionType)!;
                     if (socketManager.heartbeatChecker) clearInterval(socketManager.heartbeatChecker);
                     console.log("heartbeatCheck started");
                     const heartbeatCheckerInterval = setInterval(() => {
                        heartbeatCheck();
                     }, 5000);

                     socketManager.heartbeatChecker = heartbeatCheckerInterval;
                     socketMap.set(socketConnectionType, socketManager);
                     draft.connectionStatus = GipPlanningWebSocketConnectionStatus.OPEN;
                     draft.lastPongTime = moment().toDate();
                     draft.heartbeat = true;
                     draft.auctionTypes = incomingMessage.body.AuctionTypes;

                     if (draft.selectedWall[widgetId]) {
                        draft.selectedWall[widgetId].lastFetchWallData = moment().unix();
                        draft.selectedWall[widgetId].isLoaded = true;
                        draft.selectedWall[widgetId].contractKeys = [
                           ...(incomingMessage.body.Instruments?.map((x) => x.ContractDataKey) ?? []),
                        ];
                        draft.selectedWall[widgetId].loadingState = LoadingStateEnum.LOADED;
                     }
                     for (let i = 0; i < contracts.length; i++) {
                        const contract = contracts[i];
                        const _draftContract = draft.contracts.find(
                           (x) => x.contractDataKey == contract.contractDataKey
                        );
                        if (_draftContract) {
                           _draftContract.positionInfo = {
                              ..._draftContract.positionInfo,
                              ...contract.positionInfo,
                           };
                           _draftContract.proposalInfo = contract.proposalInfo;
                           _draftContract.productionInfo = contract.productionInfo;
                           _draftContract.instructionInfo = contract.instructionInfo;
                           _draftContract.consumptionInfo = contract.consumptionInfo;
                        } else {
                           draft.contracts.push(contract);
                        }
                     }

                     if (!Object.keys(draft.selectedWall).find((x) => x != widgetId)) {
                        const contractDataKeys = contracts.map((x) => x.contractDataKey);
                        draft.contracts = [
                           ...draft.contracts.filter((x) => contractDataKeys.includes(x.contractDataKey)),
                        ];
                     }

                     const isMSeven = liveServicesConfiguration.serviceName === SERVICE_CONSTANTS.MSeven;

                     draft.contracts = [
                        ..._.orderBy(
                           draft.contracts,
                           [
                              isMSeven
                                 ? (c) => {
                                      if (
                                         c.contractDynamic.state === EContractState.Closed ||
                                         c.contractDynamic.state === EContractState.Inactive
                                      ) {
                                         return -1;
                                      }
                                      return 1;
                                   }
                                 : () => 0,
                              (c) => moment(c.contractConstant.deliveryStart, deliveryStartFormat),
                              (c) =>
                                 moment(c.contractConstant.deliveryEnd, deliveryStartFormat).diff(
                                    moment(c.contractConstant.deliveryStart, deliveryStartFormat)
                                 ),

                              (c) => contractTypeSortOrder[c.contractConstant.contractType],
                              (c) =>
                                 isMSeven
                                    ? moment(c.contractConstant.tradingPhaseEnd, Constants.intraday.deliveryStartFormat)
                                    : 0,
                           ],
                           ["asc", "asc", "desc", "asc", "asc"]
                        ),
                     ];
                     draft.listedContractsInfo = draft.contracts.map((x) => {
                        const contractShortInfo: IContractShortInfo = {
                           contractDataKey: x.contractDataKey,
                           contractId: x.contractConstant.contractId,
                           contractName: x.contractConstant.contractName,
                           periodId: x.contractConstant.periodId,
                           periodName: x.contractConstant.periodName,
                           tradingPhaseEnd: x.contractConstant?.tradingPhaseEnd,
                           deliveryStart: x.contractConstant.deliveryStart,
                           deliveryEnd: x.contractConstant.deliveryEnd,
                           isOpenForTrade: x.contractDynamic.isOpenForTrade,
                           state: x.contractDynamic.state,
                           contractType: x.contractConstant.contractType,
                           displayName: x.contractConstant.displayName,
                           displayShortName: x.contractConstant.displayShortName,
                        };
                        return contractShortInfo;
                     });

                     const oldActiveListeners = _.uniq(
                        draft.alertRules?.flatMap((x) => x.listeners.flatMap((y) => y.selectedContracts))
                     );

                     const closedBotsContracts = _.differenceBy(
                        oldActiveListeners,
                        activeListeners,
                        (x) => x.contractId
                     );
                     const groupSelection = _(draft.selectedWall)
                        .groupBy((x) => x.selectedWallKey)
                        .map((v, k) => ({
                           selectedWallKey: k,
                           companyId: v[0].companyId,
                           portfolioId: v[0].portfolioId,
                        }))
                        .value();

                     for (let i = 0; i < groupSelection.length; i++) {
                        const selectedWallKey = groupSelection[i].selectedWallKey;
                        const companyId = groupSelection[i].companyId;
                        const portfolioId = groupSelection[i].portfolioId;

                        for (let i = 0; i < activeListeners.length; i++) {
                           const listenerContractId = activeListeners[i].contractId;
                           const contract = draft.contracts.find((x) => x.contractId == listenerContractId);
                           if (!contract) continue;
                           contract.smartBotListenerInfo = {
                              ...contract.smartBotListenerInfo,
                              [selectedWallKey]: alertRulesContractDetails(
                                 companyId,
                                 portfolioId,
                                 contract.contractId,
                                 alertRules
                              ),
                           };
                        }

                        for (let index = 0; index < closedBotsContracts.length; index++) {
                           const listenerContractId = closedBotsContracts[index].contractId;
                           const contract = draft.contracts.find((x) => x.contractId === listenerContractId);
                           if (!contract) continue;
                           contract.smartBotListenerInfo = {
                              ...contract.smartBotListenerInfo,
                              [selectedWallKey]: alertRulesContractDetails(
                                 companyId,
                                 portfolioId,
                                 contract.contractId,
                                 alertRules
                              ),
                           };
                        }
                     }
                     const calculatedValue = getCalculatedValues(contracts);
                     if (!equal(calculatedValue, draft.calculatedValues))
                        draft.calculatedValues = {
                           ...draft.calculatedValues,
                           ...calculatedValue,
                        };
                     for (let i = 0; i < groupSelection.length; i++) {
                        const key = groupSelection[i].selectedWallKey;
                        const calculatedWallData = getCalculatedWallValues([...draft.contracts], key);
                        if (!equal(calculatedWallData, draft.calculatedWallValues[key]))
                           draft.calculatedWallValues = {
                              ...draft.calculatedWallValues,
                              [key]: { ...calculatedWallData },
                           };
                     }
                     draft.dynamicGroups =
                        incomingMessage.body.DynamicGroups?.map((x) => {
                           return {
                              id: x.Id,
                              name: x.Name,
                              type: x.Type,
                              enabled: x.Enabled,
                              displayOrder: x.DisplayOrder,
                              details: x.Details?.map((y) => {
                                 return {
                                    id: y.Id,
                                    name: y.Name,
                                    type: y.Type,
                                    enabled: y.Enabled,
                                    displayOrder: y.DisplayOrder,
                                    expression: y.Expression,
                                    expressionParsed: y.ExpressionParsed,
                                    groupId: y.GroupId,
                                    triggerType: y.TriggerType,
                                    calculationCategory: y.CalculationCategory,
                                    errorCount: y.ErrorCount,
                                 };
                              }),
                           };
                        }) ?? [];
                     draft.liveSettings = liveServicesConfiguration;
                     draft.systemStatus = systemStatus;
                     draft.alertRules = alertRules;
                     draft.libraryScripts = libraryScripts;
                     draft.proposalLimits = proposalLimits;
                     draft.userDefaultBuySellCompany = (incomingMessage.body.UserDefaultBuySellCompany ??
                        {}) as UserDefaultBuySellCompany;
                     draft.maxAllowedWorkspaceCount = incomingMessage.body.MaxAllowedWorkspaceCount;
                     draft.contractProductTypes = contractProductTypes;
                  });
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const webSocketOnMessageProcess = async (incomingMessage: any): Promise<void> => {
                  const wsSubjectType = await getMessageSubjectType(incomingMessage.to, incomingMessage.subject);
                  switch (wsSubjectType) {
                     case GIP_WS_SUBJECTS.GROUP_ENTER_USER: {
                        await updateCachedData((draft) => {
                           draft.userInfo = {
                              companyId: incomingMessage.body.companyId,
                              groupId: incomingMessage.body.groupId,
                              userId: incomingMessage.body.userId,
                              userFullName: incomingMessage.body.userFullName,
                              groupCompanies:
                                 incomingMessage.body.userInfo.groupCompanies?.map((x) => {
                                    return { companyId: x.companyId, companyName: x.companyName };
                                 }) ?? [],
                              groupPowerPlants:
                                 incomingMessage.body.userInfo.groupPowerPlants?.map((x) => {
                                    return {
                                       companyId: x.companyId,
                                       powerPlantId: x.powerplantId,
                                       powerPlantName: x.powerplantName,
                                    };
                                 }) ?? [],
                              userCompanies: incomingMessage.body.userInfo.userCompanies?.map((x) => x.companyId) ?? [],
                           };
                        });
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_GLOBAL_ERROR: {
                        await updateCachedData((draft) => {
                           draft.userMessage = {
                              parameters: JSON.parse(incomingMessage.localization.parameters),
                              localization: incomingMessage.localization,
                              messageType: "GLOBAL_ERROR",
                           };
                           setGipPlanningTicker({
                              contractDataKey: draft.userMessage.parameters.ContractDataKey,
                              localization: incomingMessage.localization,
                              parameters: draft.userMessage.parameters,
                              tickerType: ETickerType.GlobalError,
                              eventTime: moment().toDate(),
                              uuid: uuidv4(),
                           });
                        });
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_GLOBAL_SUCCESS: {
                        await updateCachedData((draft) => {
                           draft.userMessage = {
                              parameters: JSON.parse(incomingMessage.localization.parameters),
                              localization: incomingMessage.localization,
                              messageType: "GLOBAL_SUCCESS",
                           };
                        });
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_GLOBAL_SESSIONID: {
                        await updateCachedData((draft) => {
                           draft.socketSessionId = incomingMessage.body;
                        });
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_SYSTEM_MESSAGE: {
                        await updateCachedData((draft) => {
                           draft.userMessage = {
                              parameters: JSON.parse(incomingMessage.localization.parameters),
                              localization: incomingMessage.localization,
                              messageType: "SYSTEM_MESSAGE",
                           };
                        });
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_WALL_DATA: {
                        await socketWallDataUpdate(incomingMessage);
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_WALL_UPDATE: {
                        await socketWallUpdate(incomingMessage);
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_POWER_PLANT_DATA: {
                        await socketPowerPlantDataUpdate(incomingMessage);
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_CONTRACT_DETAILS: {
                        await socketContractDetails(incomingMessage);
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_CONTRACT_DETAILS_LOADED: {
                        socketContractDetails(incomingMessage);

                        setTimeout(() => {
                           updateCachedData((draft) => {
                              Object.entries(draft.selectedContractDetails).map(([key, value]) => {
                                 if (value.contractDataKey === incomingMessage.body.ContractDataKey) {
                                    draft.selectedContractDetails[key].loadingState = LoadingStateEnum.LOADED;
                                 }
                              });
                           });
                        }, 1000);

                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_SYSTEM_STATUS_UPDATE: {
                        await socketSystemStatusUpdate(incomingMessage);

                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_TICKER: {
                        if (incomingMessage.body.Tickers?.length > 0) {
                           await setTickerMessage(
                              incomingMessage.body.Tickers.map((x) => ({
                                 contractDataKey: x.ContractDataKey,
                                 message: x.Log,
                                 tickerType: ETickerType.SmartBot,
                                 eventTime: moment().toDate(),
                                 uuid: uuidv4(),
                                 botId: parseInt(x.Id?.split(":")[2] ?? 0),
                              }))
                           );
                        }
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_DYNAMIC_COLUMN_EVAL: {
                        await socketDynamicColumnEval(incomingMessage);
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_VERSION_LISTENERS: {
                        await socketVersionListener(incomingMessage);

                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_MESSAGE_WARNING:
                     case GIP_WS_SUBJECTS.USER_SUBMIT_OFFER_ERROR:
                     case GIP_WS_SUBJECTS.USER_MESSAGE_INFO: {
                        await socketMessages(incomingMessage);
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_SERVER_TIME:
                        await updateCachedData((draft) => {
                           draft.serverTime = {
                              offset: incomingMessage.body.offset,
                              serverTime: incomingMessage.body.serverTime,
                              timeZone: incomingMessage.body.timeZone,
                           };
                        });

                        break;
                     case GIP_WS_SUBJECTS.USER_EPIAS_KOPI_INFO: {
                        // TODO: remove jquery
                        $("#kopiCheck").show("slow");
                        $("#proposal-additional-warning").text(incomingMessage.body);
                        break;
                     }

                     case GIP_WS_SUBJECTS.USER_RUC_RISK_DATA: {
                        await socketRucRiskData(incomingMessage);
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_RAD_RISK_DATA: {
                        await socketRadRiskData(incomingMessage);
                        break;
                     }

                     case GIP_WS_SUBJECTS.USER_PONG: {
                        await updateCachedData((draft) => {
                           if (draft.connectionStatus != GipPlanningWebSocketConnectionStatus.OPEN) {
                              draft.connectionStatus = GipPlanningWebSocketConnectionStatus.OPEN;
                           }
                           draft.lastPongTime = moment().toDate();
                           draft.heartbeat = true;
                        });
                        setTimeout(() => {
                           socketSendMessage(
                              Constants.intraday.defaultWidgetId,
                              socketConnectionType,
                              "smartpulse/pinglistener",
                              "ping",
                              {}
                           );
                        }, 3000);
                        break;
                     }
                     case GIP_WS_SUBJECTS.USER_EXIT: {
                        console.log("user exit");
                        break;
                     }
                     default: {
                        console.log("invalid subject");
                        break;
                     }
                  }
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const webSocketOnMessage = async (event: MessageEvent<any>) => {
                  const incomingMessage = JSON.parse(event.data);
                  await queue.add(() => {
                     webSocketOnMessageProcess(incomingMessage);
                  }, {});
               };

               const connectSocket = async (_autoFetch = false) => {
                  try {
                     if (tryingConnect) {
                        console.log("already connection repair");
                        return;
                     }
                     const currentState = getPreviousModel();
                     setPreviousModel(currentState);
                     tryingConnect = true;
                     autoFetch = _autoFetch;
                     const socketUrl =
                        socketConnectionType == SocketConnectionType.ALL_CONTRACTS
                           ? Constants.intraday.intradayQuarterlyWebSocketUrl
                           : Constants.intraday.intradayWebSocketUrl;
                     await clearSocketConnection();
                     const socketManager = socketMap.get(socketConnectionType)!;
                     socketManager.socket = new WebSocket(window.location.origin.replace("http", "ws") + socketUrl);

                     socketManager.socket.onopen = webSocketOnOpen;
                     socketManager.socket.onclose = webSocketOnClose;
                     socketManager.socket.onerror = wbSocketOnError;
                     socketManager.socket.onmessage = webSocketOnMessage;

                     setTimeout(() => {
                        tryingConnect = false;
                     }, 2000);
                  } catch (error) {
                     console.log("connectSocket Error:", error);
                     setTimeout(() => {
                        connectSocket(true);
                     }, 2000);
                  }
               };

               await connectSocket();
               await cacheEntryRemoved;

               await clearSocketConnection();
               if (![...socketMap.keys()].some((x) => x != socketConnectionType)) await clearReduxStates();
               await socketMap.delete(socketConnectionType);
            } catch {
               /* empty */
            }
         },
      }),
   }),
});

export const { useGetMessagesQuery } = socketApi;
