import { CustomCellRendererProps, } from "@ag-grid-community/react";
import {
    CellClassParams,
    CellRendererSelectorFunc,
    CellRendererSelectorResult,
    CellValueChangedEvent,
    EditableCallback,
    EditableCallbackParams,
    GridColumnsChangedEvent,
    CellClassFunc,
    Column,
} from "@ag-grid-community/core";
import {
    ColDefinition,
    GridApiCore,
    GridCompExtenderServiceCtorContext,
    GridExtenderServicePipelineContext,
    GridFooterColumnDefinition,
} from "../../Types.ts";
import GridExtenderServiceBase from "src/components/grid/Extenders/GridExtenderServiceBase.ts";
import { AgGridColumnFooterComponent } from "./AgGridColumnFooterComponent.tsx";
import "./Styles.css";

export default class AgGridFooterExtender extends GridExtenderServiceBase {

    private footerDataContent: Array<object>;
    private footerLevelCountsMissingWarning: boolean = false;

    constructor(context: GridCompExtenderServiceCtorContext) {
        super(context);

        this.footerDataContent = [];
    }

    //#region maint base implementations

    get serviceName(): string {
        return "__footerExtenderService";
    }

    extend = (context: GridExtenderServicePipelineContext): GridExtenderServicePipelineContext => {
        const { gridColumns, gridColDefs, } = context;

        if (!this.isExistsExtendableColumns(gridColumns)) {
            return context;
        }

        this.extendColumnDefinitions(gridColDefs);

        this
            .buildFooterDataContent(gridColumns)
            .build();

        return context;
    }

    dispose = (reason: 'grid-destroy' | 'app' | undefined): void => {
        const gridApi = this.getGridApi();

        if (gridApi.isDestroyed() ?? true) {
            return;
        }

        if (reason !== 'grid-destroy') {
            gridApi.setGridOption("pinnedBottomRowData", []);

            this.unSubscribeGridEvents();
        }
    }

    //#endregion

    //#region initialization process

    private buildFooterDataContent = (gridColumns: Column[]) => {
        const footerInfos = this.getAllFooteredColumnsLevels(gridColumns);
        const footerLevelCounts = footerInfos.map(colDef => colDef.levels.length) ?? [];

        const rowModel = this.getGridRowModel(gridColumns);
        const maxLevel = Math.max(...footerLevelCounts);

        if (footerLevelCounts.some(l => l !== 0 && l !== maxLevel) && !this.footerLevelCountsMissingWarning) {
            this.footerLevelCountsMissingWarning = true;

            console.warn("Grid footer levels configurations may be incorrect: There are different numbers of level definitions.");
        }

        this.footerDataContent = [];

        const fakeRow: any = {};
        rowModel.forEach(field => fakeRow[field] = '');

        for (let i = 0; i < maxLevel; i++) {
            this.footerDataContent.push({ ...fakeRow });
        }

        return this;
    };

    private build = () => {
        const gridApi = this.getGridApi();
        gridApi.setGridOption("pinnedBottomRowData", this.footerDataContent);

        if (!gridApi.isDestroyed()) {
            gridApi.addEventListener("cellValueChanged", this.onGridEvents);
            gridApi.addEventListener("gridColumnsChanged", this.onGridEvents);

            //TODO: footer satırları için satır yüksekliğini kontrol etmek gerekebilir..
            // api.setGridOption("getRowHeight", (event: RowHeightParams): (number | null | undefined) => {
            //     if (event.node.rowPinned && event.node.rowPinned === 'bottom') {
            //         return 32;
            //     }
            //     return undefined;
            // });
        }
    }

    //#endregion

    protected isExistsExtendableColumns = (gridColumns: Column[]): boolean => {
        const footerInfos = this.getAllFooteredColumnsLevels(gridColumns);
        return footerInfos.some(i => i.levels.length > 0);
    }

    private getAllFooteredColumnsLevels = (gridColumns: Column[]): {
        colId?: string,
        levels: GridFooterColumnDefinition[]
    }[] => {
        return gridColumns
            .map(col => col.getUserProvidedColDef() as ColDefinition)
            .filter(colDef => Boolean(colDef) && this.isExtendableColumn(colDef))
            .map(colDef => {
                return {
                    colId: colDef.colId ?? colDef.field,
                    levels: colDef.footer?.levels ?? [],
                }
            }) ?? [];
    }

    private getGridRowModel = (gridColumns: Column[]): string[] => {
        return gridColumns
            .map(col => {
                return col.getColId();
            }) ?? [];
    }

    private unSubscribeGridEvents = () => {
        const gridApi = this.getGridApi();
        if (!gridApi.isDestroyed()) {
            gridApi.removeEventListener("cellValueChanged", this.onGridEvents);
            gridApi.removeEventListener("gridColumnsChanged", this.onGridEvents);
        }
    }

    private onGridEvents = (event: CellValueChangedEvent | GridColumnsChangedEvent) => {

        const forceRefreshCells = (gridApi: GridApiCore, _eventType: string, cols?: string[]) => {
            if (!gridApi.isDestroyed()) {
                gridApi
                    .refreshCells({
                        force: true,
                        columns: cols ? [...cols] : undefined
                    });
            }
        };

        if (event.type === "cellValueChanged") {
            const { column, colDef } = event;
            const { footer } = colDef as ColDefinition;

            const refreshCols = [column.getColId()];

            if (footer && footer.reflectedColumnIds) {
                footer.reflectedColumnIds.forEach(
                    colId => refreshCols.push(colId)
                );
            }

            forceRefreshCells(event.api, event.type, refreshCols);
        } else {
            if (event.type === "gridColumnsChanged") {
                if (!event.api.isDestroyed()) {
                    //TODO: check !
                    //this.buildFooteredColumnDefinitions();
                }
            }

            forceRefreshCells(event.api, event.type);
        }
    };

    public isExtendableColumn(_colDefinition: ColDefinition): boolean {
        // Üzerinde 'footer' tanımı olmasa bile cell bazında bazı davranışlarının (editable gibi) değiştirilmesi gerekiyor..
        // Bu sebeple tüm kolon tanımlarının 'extend' edilmesi gerekiyor.
        return true;
    }

    public getExtendableColumnProps(): (keyof ColDefinition | string)[] {
        return [
            "cellClass",
            "editable",
            "cellRendererSelector",
        ]
    }

    protected extendColumnDefinition = (colDefinition: ColDefinition): void => {

        colDefinition.cellClass = (cellClassParams: CellClassParams): (string | string[] | null | undefined) => {
            const { node } = cellClassParams;

            if (node.rowPinned && node.rowPinned === 'bottom') {
                return ['ag-grid-footer-cell'];
            }

            const originCellClass = this.getOriginalColumnProp(colDefinition, "cellClass") as
                (string | string[] | CellClassFunc);

            if (originCellClass !== undefined) {
                if (typeof originCellClass === "function") {
                    return originCellClass(cellClassParams);
                } else {
                    return originCellClass;
                }
            }

            return undefined;
        }

        //@ts-ignore
        colDefinition.editable = (params: EditableCallbackParams) => {
            if (params.node.rowPinned && params.node.rowPinned === 'bottom') {
                return false;
            }

            const originEditable = this.getOriginalColumnProp(colDefinition, "editable") as
                (boolean | EditableCallback | undefined);

            if (originEditable !== undefined) {
                if (typeof originEditable === "function") {
                    return originEditable(params);
                } else {
                    return originEditable;
                }
            }

            return undefined;
        };

        colDefinition.cellRendererSelector = (params: CustomCellRendererProps): (CellRendererSelectorResult | undefined) => {
            if (params.node.rowPinned && params.node.rowPinned === 'bottom') {
                return {
                    component: AgGridColumnFooterComponent,
                    params: { ...params },
                } as CellRendererSelectorResult;
            }

            const originalCellRendererSelector = this.getOriginalColumnProp(colDefinition,
                "cellRendererSelector") as CellRendererSelectorFunc;

            if (originalCellRendererSelector && typeof originalCellRendererSelector === 'function') {
                return originalCellRendererSelector(params);
            }

            return undefined;
        };
    }

}

