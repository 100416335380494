import { FormatDateOptions, FormatNumberOptions } from "react-intl";
import {
    CellEditorSelectorResult,
    ICellEditorParams,
    ValueFormatterParams,
    CellEditorSelectorFunc,
    ValueParserFunc,
    ValueParserParams,
    ValueFormatterFunc,
} from "@ag-grid-community/core";
import {
    GridCompExtenderServiceCtorContext,
    ColDefinition,
    GridExtenderServicePipelineContext,
} from "../../Types.ts";
import GridExtenderServiceBase from "src/components/grid/Extenders/GridExtenderServiceBase.ts";
import NumericCellEditor from "src/components/NumericInput/NumericCellEditor.tsx";
import { NullableNumber } from "src/components/NumericInput/Base/Types.ts";
import { numberColumnFormatter } from "../NumberColumnFormatter.ts";

export default class AgGridColumnTypeExtender extends GridExtenderServiceBase {

    constructor(context: GridCompExtenderServiceCtorContext) {
        super(context);
    }

    //#region base implementations

    get serviceName(): string {
        return "__columnTypeExtenderService";
    }

    extend = (context: GridExtenderServicePipelineContext): GridExtenderServicePipelineContext => {
        const { gridColDefs } = context;
        this.extendColumnDefinitions(gridColDefs);
        return context;
    }

    dispose = (_reason: 'grid-destroy' | 'app' | undefined): void => {
    }

    //#endregion

    public isExtendableColumn(colDefinition: ColDefinition): boolean {
        return Boolean(colDefinition?.columnDataType);
    }

    public getExtendableColumnProps(): (keyof ColDefinition | string)[] {
        return [
            "cellEditorSelector",
            "valueFormatter",
            "valueParser",
            "useValueFormatterForExport",
            "useValueParserForImport",
        ]
    }

    protected extendColumnDefinition(colDefinition: ColDefinition) {
        if (colDefinition.columnDataType === 'number' || colDefinition.columnDataType === 'currency') {
            colDefinition.useValueFormatterForExport = false;
            colDefinition.useValueParserForImport = false;
        }

        colDefinition.cellEditorSelector = (params: ICellEditorParams): (CellEditorSelectorResult | undefined) => {
            const { column, node } = params;
            const colDefinitionByColumn = column.getUserProvidedColDef() as ColDefinition | null;

            if (!colDefinitionByColumn || !colDefinitionByColumn.columnDataType) {
                return undefined;
            }

            const cellEditable = column.isCellEditable(node);

            if (!cellEditable) {
                return undefined;
            }

            switch (colDefinitionByColumn.columnDataType) {
                case 'number':
                case 'currency':
                    return {
                        // TODO: NumericCellEditor bunu saran ayrı bir function component olabilir
                        // TODO: tip-component map için Record tanımlaması olabilir.
                        component: NumericCellEditor,
                        params: { ...params }
                    };

                case 'time':
                case 'datetime':
                case 'boolean':
                    //TODO: datetime/time custom component implement
                    //TODO: boolean custom component implement
                    break;

                default:
                    break;
            }

            const originalCellEditorSelector = this.getOriginalColumnProp(
                colDefinitionByColumn, "cellEditorSelector") as CellEditorSelectorFunc;

            if (originalCellEditorSelector && typeof originalCellEditorSelector === 'function') {
                return originalCellEditorSelector(params);
            }

            return undefined;
        }

        colDefinition.valueFormatter = (params: ValueFormatterParams): string => {
            const { column, context, api, value: cellValue } = params;
            const colDefinitionByColumn = column.getUserProvidedColDef() as ColDefinition | null;

            if (!colDefinitionByColumn) {
                return "";
            }

            // öncelik kolon tanımındaki format tanımında..
            const originalValueFormatter = this.getOriginalColumnProp(
                colDefinitionByColumn, "valueFormatter") as (string | ValueFormatterFunc);

            if (originalValueFormatter) {
                if (typeof originalValueFormatter === 'string') {
                    return originalValueFormatter;
                } else {
                    return originalValueFormatter(params);
                }
            }

            if (cellValue === undefined || cellValue === null) {
                return "";
            }

            const {
                defaultCulture: defaultCultureByColumn,
            } = colDefinitionByColumn;

            switch (colDefinitionByColumn.columnDataType) {
                case 'number':
                case 'currency':
                    const numberFormatterService = this.getColumnFormatterService(
                        defaultCultureByColumn, api, context);

                    let cellValueAsNumber: NullableNumber = typeof cellValue === "number" ? cellValue : null;

                    if (cellValueAsNumber === null) {
                        if (typeof cellValue === "string") {
                            try {
                                const valueAsNumeric = numberFormatterService.parseToNumber(cellValue);

                                if (valueAsNumeric) {
                                    cellValueAsNumber = valueAsNumeric;
                                }
                            } catch (ex) {
                                console.log("An error occurred on number parsing!", ex);
                            }
                        } else {
                            console.log("Unknown cell value type. Attempting to convert to numeric value");

                            try {
                                const valueAsNumeric = numberFormatterService.parseToNumber(cellValue.toString());

                                if (valueAsNumeric) {
                                    cellValueAsNumber = valueAsNumeric;
                                }
                            } catch (ex) {
                                console.log("An error occurred on number parsing!", ex);
                            }
                        }
                    }

                    if (!cellValueAsNumber && cellValueAsNumber !== 0) {
                        return cellValue;
                    }

                    if (colDefinitionByColumn.columnDataType === 'number') {
                        let prefix: string | undefined;
                        let suffix: string | undefined;

                        if (typeof colDefinitionByColumn.columnDisplayFormat === 'object') {
                            const {
                                prefix: pfx,
                                suffix: sfx,
                            } = colDefinitionByColumn.columnDisplayFormat;

                            prefix = pfx;
                            suffix = sfx;
                        }

                        const formattedValue = numberColumnFormatter(
                            cellValueAsNumber, colDefinitionByColumn, numberFormatterService);

                        return `${prefix ?? ""}${formattedValue}${suffix ?? ""}`;
                    }

                    if (colDefinitionByColumn.columnDataType === 'currency') {
                        let currencyDisplayFormat: string | undefined;
                        let formatOptions: FormatNumberOptions = {};

                        if (typeof colDefinitionByColumn.columnDisplayFormat === 'object') {
                            const { displayFormat, currencyCode } = colDefinitionByColumn.columnDisplayFormat;

                            currencyDisplayFormat = displayFormat;
                            formatOptions = { currency: currencyCode, ...formatOptions };
                        } else {
                            currencyDisplayFormat = colDefinitionByColumn.columnDisplayFormat;
                        }

                        const maskRegex = /[0-9\-+#]/;
                        const hasPattern = currencyDisplayFormat && currencyDisplayFormat.search(maskRegex) >= 0;

                        if (hasPattern) {
                            const patternDesc = numberFormatterService.getNumberPatternParts(
                                cellValueAsNumber,
                                (currencyDisplayFormat ?? ''),
                                {
                                    cultureLocale: numberFormatterService.currentLocale,
                                }
                            );

                            if (patternDesc?.metaOfValue) {
                                let fractionDigits: number | undefined =
                                    patternDesc?.metaOfValue.fraction && patternDesc?.metaOfValue.fraction !== ''
                                        ? patternDesc?.metaOfValue.fraction.length
                                        : undefined;

                                formatOptions = {
                                    maximumFractionDigits: fractionDigits,
                                    minimumFractionDigits: fractionDigits,
                                    ...formatOptions
                                }
                            }
                        }

                        return numberFormatterService.formatCurrencyValue(cellValueAsNumber, formatOptions);
                    }

                    return cellValue;

                case 'datetime':
                case 'time':

                    if (typeof cellValue === 'string' && cellValue.length <= 0) {
                        return "";
                    }

                    const dateFormatter = this.getColumnFormatterService(
                        defaultCultureByColumn, api, context
                    );

                    // TODO: type check & parse cellValue type to Date
                    // TODO: detaylı format desteği için 'moment' fonksiyonları kullanılabilir..

                    if (colDefinitionByColumn.columnDataType === 'datetime') {
                        if (colDefinitionByColumn.columnDisplayFormat === 'short') {
                            return dateFormatter.formatDateValue(cellValue);
                        }
                        return dateFormatter.formatDateTimeValue(cellValue);
                    } else if (colDefinitionByColumn.columnDataType === 'time') {
                        let formatOptions: FormatDateOptions = {};

                        if (colDefinitionByColumn.columnDisplayFormat === 'long') {
                            formatOptions = {
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                ...formatOptions
                            };
                        }

                        return dateFormatter.formatTimeValue(cellValue, formatOptions);
                    }

                    return cellValue;

                case 'boolean':
                    break;

                default:
                    break;
            }

            return "";
        }

        colDefinition.valueParser = (params: ValueParserParams): any | null | undefined => {
            const { column, newValue } = params;
            const colDefinitionByColumn = column.getUserProvidedColDef() as ColDefinition | null;

            if (!colDefinitionByColumn) {
                return undefined;
            }

            // öncelik kolon tanımındaki parser tanımında..
            const originalValueParser = this.getOriginalColumnProp(
                colDefinitionByColumn, "valueParser") as (string | ValueParserFunc);

            if (originalValueParser) {
                if (typeof originalValueParser === 'string') {
                    return originalValueParser;
                } else {
                    return originalValueParser(params);
                }
            }

            switch (colDefinitionByColumn.columnDataType) {
                case 'number':
                case 'currency':
                    let parsingValue = String(newValue);

                    const { column, api, context } = params;
                    const colDefinitionByColumn = column.getUserProvidedColDef() as ColDefinition | null;

                    if (!colDefinitionByColumn) {
                        return undefined;
                    }

                    if (colDefinitionByColumn.columnDataType === 'number') {
                        if (typeof colDefinitionByColumn.columnDisplayFormat === 'object') {
                            const { suffix, prefix, } = colDefinitionByColumn.columnDisplayFormat;

                            if (suffix) {
                                parsingValue = parsingValue.replaceAll(suffix, '');
                            }

                            if (prefix) {
                                parsingValue = parsingValue.replaceAll(prefix, '');
                            }
                        }

                    } else if (colDefinitionByColumn.columnDataType === 'currency') {
                        if (typeof colDefinitionByColumn.columnDisplayFormat === 'object') {
                            const { currencyCode } = colDefinitionByColumn.columnDisplayFormat;

                            if (currencyCode) {
                                parsingValue = parsingValue.replaceAll(currencyCode, '');
                            }
                        }
                    }

                    const {
                        defaultCulture: defaultCultureByColumn,
                    } = colDefinitionByColumn;

                    const numberFormatter = this.getColumnFormatterService(
                        defaultCultureByColumn, api, context);

                    return numberFormatter.parseToNumber(parsingValue);

                default:
                    break;
            }

            return undefined;
        }
    }
}
