import React from "react";
import { FormattedMessage, } from "react-intl";
import {
    Popover,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableHead,
    TableRow
} from "@mui/material";
import { CellValueHistoryModel, CellValueHistoryViewColumn } from "src/components/grid/Types.ts";
import { ICellRendererParams } from "@ag-grid-community/core";

type HistoryViewerPopupProps = {
    onClose?: () => void,
    anchorElement?: Element,
    readonly historyValues?: Array<CellValueHistoryModel>,
    readonly gridColumnOptions: ICellRendererParams,
    onFormatValue?: (
        column: CellValueHistoryViewColumn,
        value: any,
        rowData: CellValueHistoryModel) => string | undefined,
    historyTableCols: CellValueHistoryViewColumn[],
}

export const HistoryViewerPopup = (props: HistoryViewerPopupProps) => {
    const {
        onFormatValue,
        anchorElement,
        onClose,
        historyValues,
        historyTableCols
    } = props;

    const open = Boolean(anchorElement);

    const existsHistoryValues = (historyValues || []).length > 0;
    const visibleCols = historyTableCols.filter(col => !col.hidden);

    const renderTableColumns = (cols: CellValueHistoryViewColumn[], rowData: CellValueHistoryModel,): React.JSX.Element => {
        const colElements = cols
            .map((col, index) => {
                let value: any = null;

                const { valueGetter, valueFormatter, cellRenderer } = col;

                // get value
                if (valueGetter && typeof valueGetter === 'function') {
                    value = valueGetter(rowData);
                } else if (col.field) {
                    value = rowData[col.field];
                }

                // format value
                let formattedValue: string | undefined;

                if (onFormatValue) {
                    formattedValue = onFormatValue(col, value, rowData);
                }

                if (formattedValue) {
                    value = formattedValue;
                } else if (valueFormatter) {
                    value = valueFormatter(rowData);
                }

                // render cell
                if (cellRenderer) {
                    const CellContent = cellRenderer(rowData, value, formattedValue);

                    if (CellContent) {
                        return (
                            <HistoryTableCell
                                key={"history-viewer-table-row-cell" + index.toString()}>
                                {CellContent}
                            </HistoryTableCell>
                        );
                    }
                }

                return (
                    <HistoryTableCell
                        key={"history-viewer-table-row-cell" + index.toString()}>
                        {(value ?? "").toString()}
                    </HistoryTableCell>
                );
            });
        return <>{colElements}</>;
    }

    return (
        <Popover
            open={open}
            onClose={() => {
                onClose && onClose();
            }}
            anchorEl={anchorElement}
            anchorPosition={{ top: 0, left: 0 }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left"
            }}>
            <Table>
                <TableHead>
                    <HistoryTableRow>
                        {
                            visibleCols
                                .map((col, idx) => {
                                    return <HistoryTableCell
                                        key={"history-viewer-table-header-cell" + idx.toString()}>
                                        {col.header}
                                    </HistoryTableCell>
                                })
                        }
                    </HistoryTableRow>
                </TableHead>
                <TableBody>
                    {
                        !existsHistoryValues &&
                        <HistoryTableRow>
                            <HistoryTableCell colSpan={3}>
                                <FormattedMessage id="global.datanotfound" defaultMessage="There is no data"/>
                            </HistoryTableCell>
                        </HistoryTableRow>
                    }
                    {
                        existsHistoryValues &&
                        (historyValues || [])
                            .map(
                                (historyRow, index) => {
                                    return (
                                        <HistoryTableRow
                                            key={"history-viewer-table-row" + index.toString()}>
                                            {renderTableColumns(visibleCols, historyRow)}
                                        </HistoryTableRow>);
                                })
                    }
                </TableBody>
            </Table>
        </Popover>
    )
}

const HistoryTableRow = styled(TableRow)(({}) => ({
    fontSize: "12px",
    color: "#6E727A",
    textAlign: "center",
    border: "2px solid #D9D9D9",
    "&:nth-of-type(odd)": {
        backgroundColor: "#F6F6F6",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#FFFFFF"
    },
    "&:hover": {
        backgroundColor: "#ECF1E5"
    },
    ":first-of-type td": {
        fontWeight: "900",
    },
    "&:is(:first-of-type .cancelled) td": {
        color: "#C62727"
    }
}));

const HistoryTableCell = styled(TableCell)(({}) => ({
    fontSize: "12px",
    color: "#6E727A",
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#EBEBED",
        boxShadow: "none",
    },
}))
