import { MouseEventHandler, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import {
  List,
  Collapse,
  Tooltip,
  Box,
  styled,
  listItemButtonClasses,
  SxProps,
  alpha,
  PaletteMode,
  useTheme,
  collapseClasses,
  ListItem,
  Link,
  Typography,
  listItemClasses,
  Menu,
  menuClasses,
  listClasses,
} from "@mui/material";
// type
import { NavListProps } from "../type";
//
import { ArrowIcon, NavItemRoot, NavItemSub } from "./NavItem";
import { getActive, isExternalLink } from "..";
import _ from "lodash";
import BaseMenu from "src/components/gip-planning/BaseMenu";
import { FormattedMessage, useIntl } from "react-intl";
import { BLACK_V2, GRAY_V2, PRIMARY_V2 } from "src/theme/palette";
import { StyledTooltip } from "src/components/shared/StyledComponents/StyledTooltip";
import { NavLink as RouterLink } from "react-router-dom";
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from "./style";
import { VerticalMenuViewOption } from "src/components/settings/type";
import useSettings from "src/hooks/useSettings";
import { usePlantMenuStyles as useBaseMenuStyles } from "src/pages/ProductionForecast/components/Toolbar/PlantPicker/StyledComponents";
// ----------------------------------------------------------------------

const RootModuleMenu = styled(Menu)(({ theme }) => ({
  [`& ${menuClasses.paper}`]: {
    backgroundColor: theme.palette.mode === "light" ? GRAY_V2[100] : BLACK_V2[500],
  },
  [`& .${listItemButtonClasses.root}`]: {
    "&.active": {
      backgroundColor: theme.palette.mode === "light" ? "rgba(208, 250, 178, 0.25)" : BLACK_V2[600],
      color: PRIMARY_V2[theme.palette.mode === "light" ? 500 : 400],
      fontWeight: theme.palette.mode === "light" ? 700 : "inherit",
    },
    "&:hover": {
      backgroundColor: theme.palette.mode === "light" ? "rgba(208, 250, 178, 0.25)" : BLACK_V2[600],
      // padding: "8px !important",
      color: PRIMARY_V2[theme.palette.mode === "light" ? 500 : 400],
      fontWeight: theme.palette.mode === "light" ? 700 : "inherit",
    } as SxProps,
  },
}));

type NavListRootProps = {
  list: NavListProps;
  isCollapse: boolean;
  isSearching: boolean;
};

const linearBackgroundCalculator = (childCount: number, selectedCountIndex: number, mode: PaletteMode) => {
  if (selectedCountIndex === -1)
    return `linear-gradient(
    to bottom,
    transparent 0px 17px,
    ${GRAY_V2[500]} 17px calc(100% - 17px),
    transparent calc(100% - 17px) 100%)
  `;

  const partitionStart = _.round((selectedCountIndex / childCount) * 100, 1);
  const partitonEnd = _.round(((selectedCountIndex + 1) / childCount) * 100, 1);
  const selectedPart = `${PRIMARY_V2[mode === "light" ? 500 : 400]} ${partitionStart}% min(${partitonEnd}%, calc(100% - 17px))`;
  const bg = `linear-gradient(
    to bottom,
    transparent 0px 17px,
    ${GRAY_V2[500]} 17px max(17px, ${partitionStart}%),
    ${selectedPart},
    ${GRAY_V2[500]} min(${partitonEnd}%, calc(100% - 17px)) calc(100% - 17px),
    transparent calc(100% - 17px) 100%
  )`;
  return bg;
};

export function NavListRoot({ list, isCollapse, isSearching }: NavListRootProps) {
  const { pathname } = useLocation();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const active = getActive(list.path, pathname);

  const hasChildren = list.children && list.children?.length > 0;

  const hasAnyChildrenActive = hasChildren && list.children!.some((x) => getActive(x.path, pathname));

  const isOpen = () => {
    if (isSearching || active) return true;
    if (hasChildren && !isSearching && list.children!.some((x) => getActive(x.path, pathname))) return true;
    return false;
  };

  const [open, setOpen] = useState(() => isOpen());

  const renderContent = (
    <>
      {list.icon && (
        <ListItemIconStyle
          sx={{
            margin: 0,
            color: active
              ? PRIMARY_V2[theme.palette.mode === "light" ? 500 : 400]
              : GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
          }}
        >
          {list.icon}
        </ListItemIconStyle>
      )}
      <ListItemTextStyle
        disableTypography
        primary={formatMessage({ id: list.title, defaultMessage: "" })}
        sx={{
          fontWeight: 400,
          marginLeft: "8px",
          color: active
            ? PRIMARY_V2[theme.palette.mode === "light" ? 500 : 400]
            : GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
        }}
        secondary={
          <Tooltip title={list.tooltip || ""} arrow>
            <Typography variant="caption" component="div" sx={{ textTransform: "initial", color: "text.secondary" }}>
              {list.caption}
            </Typography>
          </Tooltip>
        }
        isCollapse={isCollapse}
      />
      {list.info && list.info}
      {hasChildren && <ArrowIcon open={open} />}
    </>
  );
  useEffect(() => {
    setOpen(() => isOpen());
  }, [list]);

  if (!hasChildren) {
    return isExternalLink(list.path) ? (
      // <StyledTooltip
      //   title={isCollapsedView && formatMessage({ id: list.tooltip, defaultMessage: "" })}
      //   arrow
      //   placement="right"
      // >
      <ListItemStyle
        component={Link}
        href={list.path}
        target="_blank"
        rel="noopener"
        disabled={list.disabled}
        roles={list.roles}
      >
        {renderContent}
      </ListItemStyle>
    ) : (
      // </StyledTooltip>
      // <StyledTooltip
      //   title={isCollapsedView && formatMessage({ id: list.tooltip, defaultMessage: "" })}
      //   arrow
      //   placement="right"
      // >
      <ListItemStyle
        component={RouterLink}
        to={list.path}
        activeRoot={active}
        disabled={list.disabled}
        roles={list.roles}
      >
        {renderContent}
      </ListItemStyle>
      // </StyledTooltip>
    );
  }

  const selectedChildIndex = list.children!.findIndex((x) => getActive(x.path, pathname));

  const bgGradient = linearBackgroundCalculator(list.children!.length, selectedChildIndex, theme.palette.mode);

  return (
    <>
      <NavItemRoot
        item={list}
        isCollapse={isCollapse}
        active={active || selectedChildIndex !== -1}
        open={open}
        onOpen={() => setOpen(!open)}
      />
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{ paddingLeft: "32px", [`& .${collapseClasses.wrapperInner}`]: { position: "relative" } }}
      >
        <Box position={"absolute"} width={"2px"} height={"100%"} left={"-7px"} sx={{ background: bgGradient }}></Box>
        <List component="div" disablePadding sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          {(list.children || []).map((item) => (
            <NavListSub key={item.title + item.path} list={item} />
          ))}
        </List>
      </Collapse>
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  list: NavListProps;
};

export function NavListSub({ list }: NavListSubProps) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  return <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={active} />;
}
