import React, { CSSProperties, useState, MouseEvent, useMemo } from 'react';
import moment from "moment";
import { Box, IconButton, Stack } from '@mui/material';
import { Column, ICellRendererParams } from '@ag-grid-community/core';
import { useIntl } from "react-intl";
import { HistoryIcon, HourglassEmptyIcon } from "src/old/src/components/icons";
import { agGridColumnFormatterResolver } from "src/components/grid/Extenders/AgGridUtils.ts";
import { HistoryViewerPopup } from "./HistoryViewerPopup.tsx";
import {
    AgGridHistoryViewerCellRendererOptions,
    CellValueHistoryModel,
    CellValueHistoryViewColumn,
    ColDefinition
} from '../../Types.ts';

const CellRenderer = (props: ICellRendererParams) => {
    const {
        api,
        column,
        context,
        value: cellValue,
        valueFormatted,
        eGridCell,
        formatValue,
    } = props;

    const colDef = column?.getUserProvidedColDef() as ColDefinition;
    const { historyView: historyOptions } = colDef;

    if (!historyOptions || !historyOptions.historyValues) {
        return null;
    }

    const {
        historyValues: historyValuesSetting,
        onBeforePopUp,
        onAfterPopUp,
        maxRows,
        valueFormatter,
        mappedColumn,
        onGetColumns,
        cellRenderer,
    } = historyOptions;

    const [onProgress, setProgress] = useState<boolean>(false);
    const [popUpHistoryValues, setPopUpHistoryValues] = useState<CellValueHistoryModel[]>([]);
    const [anchorElement, setAnchorElement] = useState<Element | undefined>(undefined);
    const [originCellBgColor, setOriginCellBgColor] = useState<string>("");

    const formatterService = agGridColumnFormatterResolver({
        gridColumn: column as Column,
        gridContext: context,
        gridApi: api
    });

    const getFormattedValue = (): any => {
        if (valueFormatted)
            return valueFormatted;
        if (moment.isDate(cellValue))
            return formatterService.formatDateValue(cellValue);
        if (typeof cellValue === "number")
            return formatterService.formatNumericValue(cellValue);
        if (formatValue)
            return formatValue(cellValue);
        return cellValue;
    }

    const valueStyle: CSSProperties = {
        textAlign: (typeof props.value === "number" ? "end" : "start"),
    };

    const { formatMessage } = useIntl();

    const allAvailableHistoryCols = useMemo<CellValueHistoryViewColumn[]>(() => {
        const cols: CellValueHistoryViewColumn[] = [
            {
                field: 'entityDate',
                header: formatMessage({ id: "global.date", defaultMessage: "Date" }),
                valueFormatter: (data: CellValueHistoryModel) => {
                    const { valueDate } = data;
                    return formatterService.formatDateTimeValue(valueDate);
                }
            },
            {
                field: 'valueDate',
                header: formatMessage({ id: "global.activitytime", defaultMessage: "Activity Time" }),
                valueFormatter: (data: CellValueHistoryModel) => {
                    const { valueDate } = data;
                    return formatterService.formatDateTimeWithOffset(valueDate);
                }
            },
            {
                field: 'userName',
                header: formatMessage({ id: "global.user", defaultMessage: "User" }),
                cellRenderer: (data: CellValueHistoryModel, _value: any, formattedValue: string | undefined) => {
                    return <span>{formattedValue ?? data.userName}</span>;
                }
            },
            {
                field: 'value',
                header: formatMessage({ id: "global.value", defaultMessage: "Value" }),
                valueFormatter: (data: CellValueHistoryModel) => {
                    const { value } = data;
                    return formatterService.formatNumericValue(value);
                }
            },
            {
                field: 'desc',
                header: formatMessage({ id: "global.description", defaultMessage: "Description" }),
            }
        ];

        return cols;
    }, [formatterService.currentLocale]);

    // Geçmiş gösterim tablosundaki varsayılan görünüm, bu alan listesi ile sağlanıyor
    const defaultHistoryCols = useMemo<CellValueHistoryViewColumn[]>(() => {
        const defaultColNames: (keyof CellValueHistoryModel | string)[] = ['valueDate', 'userName', 'value'];

        return allAvailableHistoryCols.filter(col => col.field &&
            col.field && defaultColNames.includes(col.field));
    }, [formatterService.currentLocale]);

    let viewTableCols: CellValueHistoryViewColumn[] | undefined;

    try {
        viewTableCols = onGetColumns && onGetColumns(defaultHistoryCols, allAvailableHistoryCols);
    } catch (e) {
        console.error("History view columns building error", e);
    }

    if (!viewTableCols || viewTableCols.length <= 0) {
        viewTableCols = defaultHistoryCols;
    }

    const onClickHandle = async (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        let historyValues: CellValueHistoryModel[];

        setProgress(true);

        if (Array.isArray(historyValuesSetting)) {
            historyValues = [...historyValuesSetting];
        } else {
            let values: CellValueHistoryModel[] = [];
            try {
                const valuesPromise = historyValuesSetting(props);
                values = await valuesPromise;
            } catch (error) {
                console.error("History values loading error.", error);
            }

            historyValues = [...values];
        }

        if (maxRows && maxRows > 0 && historyValues.length > maxRows) {
            historyValues = historyValues.slice(0, maxRows);
        }

        if (onBeforePopUp) {
            const beforeContext = onBeforePopUp({ ...props }, historyValues);
            const { cancelOpen } = beforeContext ?? {};

            if (cancelOpen) {
                setAnchorElement(undefined);
                setProgress(false);

                return;
            }
        }

        // TODO: geçerli tema ya göre belirlenmeli..
        setOriginCellBgColor(eGridCell.style.backgroundColor);
        eGridCell.style.backgroundColor = "#d2eacc";

        setAnchorElement(eGridCell);
        setPopUpHistoryValues(historyValues);
        setProgress(false);
    }

    const renderCellContent = (): React.JSX.Element => {
        const params: AgGridHistoryViewerCellRendererOptions = {
            ...props,
            showHistoryPopUp: (event) => {
                //@ts-ignore
                onClickHandle(event);
            }
        };

        if (cellRenderer) {
            return cellRenderer(params);
        } else if (colDef.cellRenderer && typeof colDef.cellRenderer === 'function') {
            return colDef.cellRenderer(params);
        }

        return (
            <Stack
                width={"100%"}
                maxWidth={"100%"}
                direction={"row"}
                spacing={0.5}>
                <Box
                    flexGrow={1}
                    component={"span"}
                    style={valueStyle}>
                    {getFormattedValue()}
                </Box>
                <Box component={"div"}>
                    <IconButton
                        centerRipple={true}
                        sx={{
                            width: '22px',
                            height: '22px',
                        }}
                        disabled={onProgress}
                        onClick={onClickHandle}>
                        {
                            !onProgress &&
                            <HistoryIcon
                                sx={{
                                    fontSize: '16px',
                                }}
                            />
                        }
                        {
                            onProgress &&
                            <HourglassEmptyIcon
                                sx={{
                                    fontSize: '16px',
                                }}
                            />
                        }
                    </IconButton>
                </Box>
            </Stack>
        );
    }

    return (
        <>
            {renderCellContent()}

            {/*history popup*/}
            {
                anchorElement &&
                <HistoryViewerPopup
                    anchorElement={anchorElement}
                    historyValues={popUpHistoryValues}
                    gridColumnOptions={{ ...props }}
                    historyTableCols={viewTableCols as CellValueHistoryViewColumn[]}
                    onFormatValue={(column: CellValueHistoryViewColumn, value: any, rowData: CellValueHistoryModel) => {
                        // Kolon tanımı (mappedColumn) üzerindeki format fonksiyonu kullanılıyor
                        if (mappedColumn && mappedColumn === column.field && formatValue) {
                            return formatValue(value);
                        }
                        if (!valueFormatter) {
                            return undefined;
                        }
                        return valueFormatter(column, value, rowData, formatterService);
                    }}
                    onClose={() => {
                        eGridCell.style.backgroundColor = originCellBgColor || "";
                        setAnchorElement(undefined);

                        onAfterPopUp && onAfterPopUp({ ...props });
                    }}
                />
            }
        </>
    )
}

export default CellRenderer;



