import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef } from "react";
import * as CommonActions from "src/old/src/redux/common/action";

export const GlobalStateChannelName = "global-state-channel";

export enum EGlobalStateMessageType {
   SetPortolios = "SetPortolios",
}

interface IBroadcastListenerContext {}

const deafultBroadcastListenerContext = {};

const BroadcastListenerContext = createContext<IBroadcastListenerContext>(deafultBroadcastListenerContext);

const BroadcastListenerProvider = (props: PropsWithChildren<unknown>) => {
   const globalStateChannelRef = useRef(new BroadcastChannel(GlobalStateChannelName));

   useEffect(() => {
      if (!globalStateChannelRef.current) return;

      globalStateChannelRef.current.onmessage = (event: MessageEvent) => {
         switch (event.data.type) {
            case EGlobalStateMessageType.SetPortolios:
               CommonActions.setPortfolios(event.data.data);
               break;
            default:
               break;
         }
      };

      return () => globalStateChannelRef.current.close();
   }, [globalStateChannelRef.current]);

   const values = useMemo(() => ({}), []);

   return <BroadcastListenerContext.Provider value={values}>{props.children}</BroadcastListenerContext.Provider>;
};

const useBroadcastListener = () => useContext(BroadcastListenerContext);

export { useBroadcastListener, BroadcastListenerProvider };
