// based on https://stackoverflow.com/a/67856765, and https://observablehq.com/@mbostock/localized-number-parsing
class NumberParser {
    private readonly _group: RegExp;
    private readonly _decimal: RegExp;
    private readonly _numeral: RegExp;
    private readonly _index: (d: string, ...args: any[]) => string;

    /**
     * Builds a new NumberParser for given locale.
     * @param locale Locale in RFC-4646 format
     * @see https://datatracker.ietf.org/doc/html/rfc4646
     */
    constructor(locale: string) {
        const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
        const numerals = new Intl.NumberFormat(locale, {useGrouping: false}).format(9876543210).split('').reverse();
        const index = new Map(numerals.map((digit, index) => [digit, index]));
        // @ts-ignore
        this._group = new RegExp(`[${parts.find(d => d.type === "group").value}]`, "g");
        // @ts-ignore
        this._decimal = new RegExp(`[${parts.find(d => d.type === "decimal").value}]`);
        this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
        this._index = d => index.get(d)!.toString();
    }

    /**
     * parse given string to a number according to given locale's format
     * @param s string to be parsed
     * @returns a number if format is ok or NaN
     */
    parse(s: string) {
        return (s = s.trim()
                     .replace(this._group, "")
                     .replace(this._decimal, ".")
                     .replace(this._numeral, this._index)) ? +s : NaN;
    }

    /**
     * Prebuilt NumberParser for `tr-TR`
     */
    public static readonly tr: NumberParser = new NumberParser("tr-TR");
    /**
     * Prebuilt NumberParser for `en-US`, `English (United States)`
     */
    public static readonly en: NumberParser = new NumberParser("en-US");
    /**
     * Prebuilt NumberParser for `es-ES`, `Spanish (Spain)`
     */
    public static readonly es: NumberParser = new NumberParser("es-ES");

    /**
     * A dictionary accessor to easily reach prebuild number parsers
     */
    static [key: string]: NumberParser | undefined;
}

export default NumberParser;