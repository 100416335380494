import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// type
import { NavListProps } from "../type";
//
import { NavItemRoot, NavItemSub } from "./NavItem";
import { PaperStyle } from "./style";
import { getActive } from "..";
import { Stack } from "@mui/material";

// ----------------------------------------------------------------------

type NavListRootProps = {
  list: NavListProps;
  hideMenu?: boolean;
};

export function NavListRoot({ list, hideMenu }: NavListRootProps) {
  const menuRef = useRef(null);

  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(false);

  const hasChildren = list.children;

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive((prev) => {
      if (!list.children) return getActive(list.path, pathname);
      const isActive = list.children!.some((child) => getActive(child.path, pathname)) || open;
      if (prev !== isActive) return isActive;
      return prev;
    });
  }, [list, pathname]);

  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          open={open}
          item={list}
          active={isActive}
          ref={menuRef}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          sx={{ display: hideMenu ? "none" : "initial" }}
        />

        <PaperStyle
          open={open}
          anchorEl={menuRef.current}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          slotProps={{
            paper: {
              onMouseEnter: handleOpen,
              onMouseLeave: handleClose,
            },
            sx: { display: hideMenu ? "none" : "initial" },
          }}
        >
          <Stack direction="column">
            {(list.children || []).map((item) => (
              <NavListSub key={item.title + item.path} list={item} />
            ))}
          </Stack>
        </PaperStyle>
      </>
    );
  }

  return <NavItemRoot item={list} active={active} open={open}/>;
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  list: NavListProps;
};

function NavListSub({ list }: NavListSubProps) {
  const menuRef = useRef(null);

  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub
          ref={menuRef}
          open={open}
          item={list}
          active={active}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
        />

        <PaperStyle
          open={open}
          anchorEl={menuRef.current}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          className="none-padding"
          slotProps={{
            paper: {
              onMouseEnter: handleOpen,
              onMouseLeave: handleClose,
            },
          }}
        >
          {(list.children || []).map((item) => (
            <NavListSub key={item.title + item.path} list={item} />
          ))}
        </PaperStyle>
      </>
    );
  }

  return <NavItemSub item={list} active={active} />;
}
