import { Box, styled, SvgIcon, svgIconClasses, SxProps, Typography } from "@mui/material";
import React, { CSSProperties, memo, useCallback } from "react";
import useSettings from "src/hooks/useSettings";
import { BLACK_V2, GRAY_V2 } from "src/theme/palette";
import WbSunnyIcon from "@mui/icons-material/WbSunnyOutlined";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import { FormattedMessage } from "react-intl";
import { ThemeMode, VerticalMenuViewOption } from "src/components/settings/type";
import useResponsive from "src/hooks/useResponsive";

const IconOnlyChangeButton = ({ mode }) => {
  if (mode === "light") return <WbSunnyIcon />;
  return <BedtimeIcon />;
};

const ThemeChangeContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "menuOption" && prop !== "isShownFullWidth",
})<{ menuOption: VerticalMenuViewOption; isShownFullWidth: boolean }>(({ theme, menuOption, isShownFullWidth }) => {
  const isLight = theme.palette.mode === "light";
  const currentColor = GRAY_V2[isLight ? 900 : 500];
  const bgColor = isShownFullWidth ? (isLight ? GRAY_V2[200] : BLACK_V2[500]) : isLight ? "white" : BLACK_V2[600];
  const padding = isShownFullWidth ? 2 : 8;
  const svgSize = isShownFullWidth ? 16 : 24;
  return {
    height: 34,
    borderRadius: 8,
    lineHeight: "18px",
    fontSize: 14,
    fontWeight: 400,
    color: currentColor,
    display: "flex",
    alignItems: "center",
    padding,
    gap: 4,
    backgroundColor: bgColor,
    ["& ." + svgIconClasses.root]: {
      fontSize: svgSize,
      color: currentColor,
    } as SxProps,
    ...(!isShownFullWidth && {
      width: "40px",
      height: "40px",
    }),
  };
});

const ThemeItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "menuOption" && prop !== "isShownFullWidth",
})<{ isSelected: boolean; menuOption: VerticalMenuViewOption; isShownFullWidth: boolean }>(({
  theme,
  isSelected,
  menuOption,
  isShownFullWidth,
}) => {
  const isLight = theme.palette.mode === "light";
  const bgColor = isSelected ? (isLight ? "white" : BLACK_V2[600]) : "transparent";
  const flex = isShownFullWidth ? 1 : 0;
  const selectedItemBorderRadius = {
    borderTopRightRadius: isShownFullWidth ? 6 : theme.palette.mode === "dark" ? 6 : 2,
    borderBottomRightRadius: isShownFullWidth ? 6 : theme.palette.mode === "dark" ? 6 : 2,
    borderTopLeftRadius: isShownFullWidth ? 6 : theme.palette.mode === "light" ? 6 : 2,
    borderBottomLeftRadius: isShownFullWidth ? 6 : theme.palette.mode === "light" ? 6 : 2,
  } as CSSProperties;
  return {
    backgroundColor: bgColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
    cursor: "pointer",
    flex,
    height: "100%",
    ...selectedItemBorderRadius,
  };
});

const ThemeChangeButton = () => {
  const isDesktop = !!useResponsive("up", "lg");
  const { themeMode, onToggleMode, verticalMenuViewOption } = useSettings();
  const isShownFullWidth = (verticalMenuViewOption === VerticalMenuViewOption.Open && isDesktop) || !isDesktop;
  const handleThemeChange = (newMode: ThemeMode) => {
    const newValue = isShownFullWidth ? newMode : newMode === "light" ? "dark" : "light";
    if (newValue === themeMode) return;
    onToggleMode();
  };

  return (
    <ThemeChangeContainer menuOption={verticalMenuViewOption} isShownFullWidth={isShownFullWidth}>
      {(isShownFullWidth || themeMode === "light") && (
        <ThemeItem
          key={"light"}
          isSelected={themeMode === "light"}
          onClick={() => handleThemeChange("light")}
          menuOption={verticalMenuViewOption}
          isShownFullWidth={isShownFullWidth}
        >
          <IconOnlyChangeButton mode="light" />
          {isShownFullWidth && (
            <Typography>
              <FormattedMessage id="global.ligthMode" />
            </Typography>
          )}
        </ThemeItem>
      )}
      {(isShownFullWidth || themeMode === "dark") && (
        <ThemeItem
          key="dark"
          isSelected={themeMode === "dark"}
          onClick={() => handleThemeChange("dark")}
          menuOption={verticalMenuViewOption}
          isShownFullWidth={isShownFullWidth}
        >
          <IconOnlyChangeButton mode="dark" />
          {isShownFullWidth && (
            <Typography>
              <FormattedMessage id="global.darkMode" />
            </Typography>
          )}
        </ThemeItem>
      )}
    </ThemeChangeContainer>
  );
};

export default memo(ThemeChangeButton);
