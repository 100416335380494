import { CSSProperties, ReactNode } from "react";
// @mui
import { alpha, styled } from "@mui/material/styles";
import {
  LinkProps,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  ListItemButtonProps,
  SxProps,
  listClasses,
} from "@mui/material";
// config
import { ICON, NAVBAR } from "../../../config";
import { BLACK_V2, GRAY_V2, PRIMARY_V2 } from "src/theme/palette";
import { VerticalMenuViewOption } from "src/components/settings/type";

// ----------------------------------------------------------------------

type IProps = LinkProps & ListItemButtonProps;

export interface ListItemStyleProps extends IProps {
  component?: ReactNode;
  to?: string;
  activeRoot?: boolean;
  activeSub?: boolean;
  subItem?: boolean;
  roles?: string[];
  isCollapsed?: boolean;
}

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== "activeRoot" && prop !== "activeSub" && prop !== "subItem" && prop !== "isCollapsed",
})<ListItemStyleProps>(({ activeRoot, activeSub, subItem, theme, isCollapsed }) => ({
  ...theme.typography.body2,
  position: "relative",
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  textTransform: "capitalize",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  color: GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
  borderRadius: theme.shape.borderRadius,
  ...(activeRoot && {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.mode === "light" ? "#EBF7E7" : "#2A3A35",
  }),
  "&:hover": {
    backgroundColor: theme.palette.mode === "light"
          ? GRAY_V2[200]
          : BLACK_V2[600],
  } as SxProps,
  // activeSub
  ...(activeSub && {
    ...theme.typography.subtitle2,
    backgroundColor: theme.palette.mode === "light" ? GRAY_V2[200] : BLACK_V2[500],
  }),
  // subItem
  ...(subItem && {
    height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
  }),
}));

interface ListItemTextStyleProps extends ListItemButtonProps {
  isCollapse?: boolean;
}

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== "isCollapse",
})<ListItemTextStyleProps>(({ isCollapse, theme }) => ({
  whiteSpace: "normal",
  transition: theme.transitions.create(["width", "opacity"], {
    duration: theme.transitions.duration.shorter,
  }),
  // ...(isCollapse && {
  //   width: 0,
  //   opacity: 0,
  // }),
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": { width: "100%", height: "100%" },
});
