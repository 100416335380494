import { Box, FormControlLabel, Radio } from "@mui/material";
import { useIntl } from "react-intl";
import { ThemeLayout, ThemeMode } from "src/components/settings/type";
import useSettings from "src/hooks/useSettings";
import { BLACK_V2, DARKMODE_V2, GRAY_V2, PRIMARY_V2 } from "src/theme/palette";

const modes: ThemeLayout[] = ["horizontal", "vertical"];
const themes: ThemeMode[] = ["light", "dark"];

function ViewArea() {
   const { formatMessage } = useIntl();
   const { themeLayout, themeMode, onChangeLayout, onChangeMode } = useSettings();

   return (
      <Box display="flex" flexDirection="column" gap="32px">
         <Box display="flex" gap="32px">
            {themes.map((theme) => (
               <Box
                  key={theme}
                  bgcolor={theme === "light" ? GRAY_V2[200] : BLACK_V2[700]}
                  border={`1px solid ${theme == themeMode ? PRIMARY_V2[500] : theme === "light" ? GRAY_V2[200] : BLACK_V2[700]}`}
                  borderRadius="8px"
                  sx={{ cursor: "pointer" }}
                  onClick={() => onChangeMode({ target: { value: theme } } as React.ChangeEvent<HTMLInputElement>)}
               >
                  <Box
                     display="flex"
                     gap="10px"
                     m="12px 16px"
                     p="10px"
                     bgcolor={theme === "light" ? GRAY_V2[50] : DARKMODE_V2[800]}
                     borderRadius="8px"
                  >
                     <Box
                        width="30px"
                        height="120px"
                        bgcolor={theme === "light" ? GRAY_V2[200] : DARKMODE_V2[400]}
                        border={`1px solid ${theme === "light" ? GRAY_V2[500] : DARKMODE_V2[400]}`}
                        borderRadius="8px"
                     />
                     <Box>
                        <Box
                           width="180px"
                           height="30px"
                           bgcolor={theme === "light" ? GRAY_V2[200] : DARKMODE_V2[400]}
                           border={`1px solid ${theme === "light" ? GRAY_V2[500] : DARKMODE_V2[400]}`}
                           borderRadius="8px"
                           mb="10px"
                        />
                        <Box
                           width="180px"
                           height="80px"
                           bgcolor={theme === "light" ? GRAY_V2[200] : DARKMODE_V2[400]}
                           border={`1px solid ${theme === "light" ? GRAY_V2[500] : DARKMODE_V2[400]}`}
                           borderRadius="8px"
                        />
                     </Box>
                  </Box>
                  <Box
                     p="4px 16px"
                     borderRadius="0 0 8px 8px"
                     bgcolor={GRAY_V2[themeMode === "light" ? 50 : 500]}
                     color={GRAY_V2[900]}
                  >
                     <FormControlLabel
                        checked={themeMode === theme}
                        control={<Radio />}
                        label={formatMessage({ id: theme === "light" ? "global.ligthMode" : "global.darkMode" })}
                     />
                  </Box>
               </Box>
            ))}
         </Box>
         <Box display="flex" gap="32px">
            {modes.map((mode) => (
               <Box
                  key={mode}
                  bgcolor={themeMode === "light" ? GRAY_V2[200] : BLACK_V2[700]}
                  border={themeLayout == mode ? `1px solid ${PRIMARY_V2[500]}` : "none"}
                  borderRadius="8px"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                     const value = { target: { value: mode } };
                     onChangeLayout(value as React.ChangeEvent<HTMLInputElement>);
                  }}
               >
                  <Box
                     display="flex"
                     flexDirection={mode === "horizontal" ? "row" : "column"}
                     gap="10px"
                     m="12px 16px"
                     p="10px"
                     bgcolor={themeMode === "light" ? GRAY_V2[50] : DARKMODE_V2[800]}
                     borderRadius="8px"
                  >
                     <Box
                        width={mode === "horizontal" ? "30px" : "220px"}
                        height={mode === "horizontal" ? "120px" : "30px"}
                        bgcolor={themeMode === "light" ? GRAY_V2[200] : DARKMODE_V2[400]}
                        border={`1px solid ${themeMode === "light" ? GRAY_V2[500] : DARKMODE_V2[400]}`}
                        borderRadius="8px"
                     />
                     <Box
                        width={mode === "horizontal" ? "180px" : "220px"}
                        height={mode === "horizontal" ? "120px" : "80px"}
                        bgcolor={themeMode === "light" ? GRAY_V2[200] : DARKMODE_V2[400]}
                        border={`1px solid ${themeMode === "light" ? GRAY_V2[500] : DARKMODE_V2[400]}`}
                        borderRadius="8px"
                     />
                  </Box>
                  <Box
                     p="4px 16px"
                     borderRadius="0 0 8px 8px"
                     bgcolor={GRAY_V2[themeMode === "light" ? 50 : 500]}
                     color={GRAY_V2[900]}
                  >
                     <FormControlLabel
                        checked={themeLayout === mode}
                        control={<Radio />}
                        label={formatMessage({ id: mode === "vertical" ? "global.horizontal" : "global.vertical" })}
                     />
                  </Box>
               </Box>
            ))}
         </Box>
      </Box>
   );
}

export default ViewArea;
