import { memo, useEffect, useMemo, useRef, useState } from "react";
// @mui
import { Stack, styled, svgIconClasses, useTheme } from "@mui/material";
// type
import { NavSectionProps } from "../type";
//
import { NavListRoot } from "./NavList";
import { isMobileDevice } from "src/utils";
import { Button } from "@mui/material";
import { GRAY_V2, BLACK_V2 } from "src/theme/palette";
import ArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

// ----------------------------------------------------------------------

const S_SO = 48; // scroll start offset
const S_S = 10; // scroll speed

const hideScrollbar = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowY: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
} as const;

const ScrollSidesButtonIcon = styled(Button)(({ theme }) => ({
  borderRadius: 4,
  padding: 6,
  margin: 0,
  backgroundColor: theme.palette.mode === "light" ? GRAY_V2[50] : BLACK_V2[500],
  height: 24,
  minWidth: 24,
  maxWidth: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position:"absolute",
  right: 4,
  top: 2,
  "&:hover": {
    backgroundColor: theme.palette.mode === "light" ? GRAY_V2[50] : BLACK_V2[500],
  },
  ["& ." + svgIconClasses.root]: {
    color: GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
    width: 16,
    height: 16,
  },
}));

const NavListContainer = styled(Stack)(({ theme }) => ({
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowY: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  paddingLeft: 32,
  paddingRight: 32,
  marginBottom: "-1px",
  boxShadow: "unset",
  scrollBehavior: "smooth",
  width: "100%"
}));

function NavSectionHorizontal({ navConfig }: NavSectionProps) {
  const isMobile = useMemo(() => isMobileDevice(true), []);
  const theme = useTheme();

  const navListRef = useRef<HTMLDivElement | null>(null);

  const [scrolledEnd, setScrolledEnd] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);

  useEffect(() => {
    const handleOnResize = () => {
      if (!navListRef.current) return;
      setCurrentWidth(navListRef.current.clientWidth);
    };

    handleOnResize();
    window.addEventListener("resize", handleOnResize);
    return () => {
      window.removeEventListener("resize", handleOnResize);
    };
  }, [navListRef.current]);

  useEffect(() => {
    if (!currentWidth) return;
    setShowScrollButton((prevScrollState) => {
      const isNeedScrolling = currentWidth < navListRef.current!.scrollWidth;
      if (prevScrollState !== isNeedScrolling) return isNeedScrolling;
      return prevScrollState;
    });
  }, [currentWidth]);

  const scrollSidesButton = showScrollButton && (
    <ScrollSidesButtonIcon
      disableRipple
      disableTouchRipple
      onClick={() => {
        if (!navListRef.current) return;
        if (!scrolledEnd) navListRef.current.scrollLeft = navListRef.current.scrollWidth;
        else navListRef.current.scrollLeft = 0;
        setScrolledEnd(!scrolledEnd);
      }}
    >
      {scrolledEnd ? <ArrowLeftIcon /> : <ArrowRightIcon />}
    </ScrollSidesButtonIcon>
  );

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      sx={{
        borderBottom: "2px solid " + GRAY_V2[theme.palette.mode === "light" ? 300 : 900],
        boxShadow: "unset",
        // paddingBottom: "8px",
        paddingRight: "8px",
        position: "relative",
        width: "100%"
      }}
    >
      <NavListContainer direction={"row"} ref={navListRef}>
        {navConfig.map((group) => (
          <Stack key={group.subheader} direction="row" flexShrink={0} spacing={"8px"} justifyContent={"space-between"} flexGrow={1}>
            {group.items.map((list) => (
              <NavListRoot key={list.title + list.path} list={list} />
            ))}
          </Stack>
        ))}
      </NavListContainer>
      {scrollSidesButton}
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
