import { GridPreDestroyedEvent } from "@ag-grid-community/core";
import { isEqualWith } from "lodash";
import { WrappedGridRef } from "../index.tsx";
import {
    ColDefinition, ColGroupDefinition, GridApiCore,
    GridCompExtenderServiceCtor, GridExtenderServicePipelineContext,
    IGridCustomProps,
} from "../Types.ts";
import {
    OriginalColumnDefinitionPropName,
    getExtenderContext,
    isColGroupDefinition,
    registerExtenderServices,
    removeAllExtenderServicesContext,
    removeExtenderContext,
    setExtenderCustomOptionsContext
} from "src/components/grid/Extenders/AgGridUtils.ts";
import AgGridColumnTypeExtender from "./ColumnType/AgGridColumnTypeExtender.ts"
import AgGridFooterExtender from "./Footer/AgGridColumnFooterExtender.ts";
import AgGridClipboardExtender from "./AgGridClipboardExtender.ts";
import AgGridCellValueHistoryViewerExtender from "./HistoryViewer/Extender.ts";
import GridExtenderServiceBase from "src/components/grid/Extenders/GridExtenderServiceBase.ts";

export const injectExtenders = (context: {
    gridRef: WrappedGridRef,
    userCultureLocale: string,
    readonly customGridOptions: IGridCustomProps,
    readonly prevGridOptions?: IGridCustomProps
}) => {
    const {
        gridRef,
        customGridOptions,
        prevGridOptions,
        userCultureLocale,
    } = context;

    const { useExtenders = false } = customGridOptions;
    const firstInit = !Boolean(prevGridOptions);

    if (firstInit) {
        if (!useExtenders) {
            return;
        }

        if (!gridRef.api) {
            console.error("Grid extender is not initialized! Unknown instance.");
        }

        initializeExtenders(gridRef, customGridOptions, userCultureLocale);
    } else if (prevGridOptions) {
        const updatedOptions = isEqualWith(
            prevGridOptions,
            customGridOptions,
            (current: IGridCustomProps, other: IGridCustomProps) => {
                return current.defaultCulture !== other.defaultCulture ||
                    current.defaultTimeZone !== other.defaultTimeZone ||
                    current.useExtenders !== other.useExtenders
                    ;
            });

        const { useExtenders: currentUseExtenders } = customGridOptions;
        const { useExtenders: prevUseExtenders } = prevGridOptions;

        // open/close extenders
        if (updatedOptions && currentUseExtenders !== prevUseExtenders) {
            if (currentUseExtenders === false) {
                destroyExtenders(gridRef, false);
            } else {
                initializeExtenders(gridRef, customGridOptions, userCultureLocale, true);
            }
        } else if (currentUseExtenders) {
            updateExtenders(gridRef, customGridOptions, userCultureLocale);
        }
    }
}

const initializeExtenders = (
    gridInstance: WrappedGridRef,
    customOptions: IGridCustomProps,
    userCulture: string,
    reInit: boolean = false): void => {

    console.log(`${reInit ? 'Re-' : ''}Initializing ag-grid extenders (#${gridInstance.api?.getGridId()})`);

    try {
        const services: GridExtenderServiceBase[] = [
            createExtenderService(AgGridClipboardExtender, gridInstance, customOptions),
            createExtenderService(AgGridFooterExtender, gridInstance, customOptions),
            createExtenderService(AgGridColumnTypeExtender, gridInstance, customOptions),
            createExtenderService(AgGridCellValueHistoryViewerExtender, gridInstance, customOptions),
        ];

        extend(
            'init',
            gridInstance,
            services,
            customOptions,
            userCulture,
        );

        const { api: gridCoreApi } = gridInstance;

        registerExtenderServices(gridInstance, services);
        subscribeGridEvents(gridCoreApi as GridApiCore);
    } catch (error: any) {
        let errorMessage: string;
        if (typeof error === "object") {
            errorMessage = `${error.name}: ${error.message}`;
        } else {
            errorMessage = error;
        }
        console.error(`Ag-grid extender services initialization error. ${errorMessage}`, error);
    }
}

const updateExtenders = (
    gridInstance: WrappedGridRef,
    customOptions: IGridCustomProps,
    userCulture: string): void => {

    console.log(`Updating ag-grid extenders (#${gridInstance.api?.getGridId()})`);

    try {
        extend(
            'update',
            gridInstance,
            getExtenderContext(gridInstance).services ?? [],
            customOptions,
            userCulture,
        );
    } catch (error: any) {
        let errorMessage: string;
        if (typeof error === "object") {
            errorMessage = `${error.name}: ${error.message}`;
        } else {
            errorMessage = error;
        }
        console.error(`Ag-grid extender services update error. ${errorMessage}`, error);
    }
}

const extend = (
    reason: 'init' | 'update',
    gridInstance: WrappedGridRef,
    services: GridExtenderServiceBase[],
    customOptions: IGridCustomProps,
    userCulture: string): void => {

    const { api: gridCoreApi } = gridInstance;
    const gridColDefs = (gridCoreApi?.getColumnDefs() ?? []) as (ColDefinition | ColGroupDefinition)[];
    const gridColumns = gridCoreApi?.getAllGridColumns() ?? [];

    let pipelineContext: GridExtenderServicePipelineContext = {
        gridColDefs: gridColDefs,
        gridColumns: gridColumns,
        reason: reason,
    };

    setExtenderCustomOptionsContext(gridInstance, customOptions, userCulture);
    saveColumnsOriginalProps(gridColDefs, services);

    services.forEach(service => {
        console.log(`Extending ag-grid by [${service.serviceName}] (#${service.gridId})`);

        try {
            pipelineContext = service.extend(pipelineContext);
        } catch (error) {
            console.log(`Extender service error [${service.serviceName}] (#${service.gridId})`);
        }
    });

    gridCoreApi?.setGridOption('columnDefs', gridColDefs);
}

const subscribeGridEvents = (gridApi: GridApiCore) => {
    gridApi.addEventListener("gridPreDestroyed", onGridPreDestroyed);
}

const onGridPreDestroyed = (event: GridPreDestroyedEvent): void => {
    destroyExtenders({ api: event.api }, true);
}

const saveColumnsOriginalProps = (
    colDefs: (ColDefinition | ColGroupDefinition)[],
    services: GridExtenderServiceBase[]): void => {

    const extendableColKeys: string[] = [];

    services.forEach(srv => {
        const colKeys = (srv.getExtendableColumnProps && srv.getExtendableColumnProps()) ?? [];

        colKeys.forEach(key => {
            extendableColKeys.push(key);
        });
    });

    if (extendableColKeys.length <= 0) {
        return;
    }

    const isExtendableColumn = (colDef: ColDefinition): boolean => {
        return services.some(srv => srv.isExtendableColumn && srv.isExtendableColumn(colDef));
    }

    for (const colDef of (colDefs ?? [])) {
        if (isColGroupDefinition(colDef)) {
            saveColumnsOriginalProps(colDef.children, services);
        } else {
            const extendable = isExtendableColumn(colDef);

            if (!extendable) {
                continue;
            }

            extendableColKeys
                .forEach((colKey) => {
                    if (!colDef.hasOwnProperty(OriginalColumnDefinitionPropName)) {
                        colDef[OriginalColumnDefinitionPropName] = {};
                    }

                    const hasOriginProp = colDef[OriginalColumnDefinitionPropName].hasOwnProperty[colKey];

                    if (!hasOriginProp) {
                        colDef[OriginalColumnDefinitionPropName][colKey] = colDef[colKey];
                    }
                });
        }
    }
}


export const destroyExtenders = (gridInstance: WrappedGridRef, byGridEvent: boolean, gridContext?: any): void => {
    console.log(`Destroying ag-grid extenders (#${gridInstance.api?.getGridId()})`);

    const { api: gridApi } = gridInstance;
    gridApi?.removeEventListener("gridPreDestroyed", onGridPreDestroyed);

    const extendedColumnProps: string[] = [];
    const extenderContext = getExtenderContext(gridInstance, gridContext);

    (extenderContext.services ?? [])
        .reverse()
        .forEach(service => {
            if (service) {
                try {
                    console.log(`Disposing ag-grid extender service [${service.serviceName}] (#${service.gridId})`);

                    (service.getExtendableColumnProps &&
                        service.getExtendableColumnProps() || [])
                        .forEach(colKey => {
                            if (!extendedColumnProps.includes(colKey)) {
                                extendedColumnProps.push(colKey);
                            }
                        });

                    service.dispose(byGridEvent ? 'grid-destroy' : 'app');
                } catch (error) {
                    console.log(`Extender service disposing error [${service.serviceName}] (#${service.gridId})`);
                }
            }
        });

    removeAllExtenderServicesContext(gridInstance);
    removeExtenderContext(gridInstance);

    if (!byGridEvent) {
        const { api: gridApi } = gridInstance;

        if (gridApi) {
            const currentColDefs = (gridApi.getColumnDefs() ?? []) as (ColDefinition | ColGroupDefinition)[]

            revertColumnDefinitions(currentColDefs, extendedColumnProps);

            if (!gridApi.isDestroyed()) {
                gridApi.setGridOption("columnDefs", currentColDefs);
            }
        }
    }
}

const revertColumnDefinitions = (
    currentColDefs: (ColDefinition | ColGroupDefinition)[],
    extendedProps: (keyof ColDefinition | string)[]
): void => {
    const colKeys = new Set(extendedProps ?? []);

    for (const colDef of (currentColDefs ?? [])) {
        if (isColGroupDefinition(colDef)) {
            revertColumnDefinitions(colDef.children, extendedProps)
        } else {
            colKeys
                .forEach(propName => {
                    if (colDef[OriginalColumnDefinitionPropName]) {
                        colDef[propName] = { ...colDef[OriginalColumnDefinitionPropName][propName] };
                    }
                });
        }
    }
}

const createExtenderService = (
    ctor: GridCompExtenderServiceCtor,
    gridInstance: WrappedGridRef,
    customOptions: IGridCustomProps)
    : GridExtenderServiceBase => {
    return new ctor({ gridInstance, customOptions });
}
