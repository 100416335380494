import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Typography, MenuItem, IconButton, Tooltip, Popover, useTheme } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from '@mui/icons-material/Settings';
import * as AuthService from "../../../services/auth/auth.service";
import useLocales from "../../../hooks/useLocales";
import { Flags } from "../../../utils/Flags";
import useResponsive from "src/hooks/useResponsive";
import * as CommonActions from 'src/old/src/redux/common/action';
import { DARKMODE_V2, GRAY_V2 } from "src/theme/palette";

function AccountPopover() {
   const navigate = useNavigate();
   const { translate } = useLocales();
   const { palette } = useTheme();
   const isMobile = useResponsive("down", "md");

   const [open, setOpen] = useState<HTMLElement | null>(null);

   const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
      setOpen(event.currentTarget);
   };

   const handleClose = () => {
      setOpen(null);
   };

   const goToPage = (page: string) => {
      setOpen(null);
      navigate(page, { replace: true });
   };

   const handleLogout = () => {
      if (Flags.isNewSsoFlow() && document.cookie.indexOf("id_token=") >= 0) {
         location.href = "/Login/PassportLogout";
      } else {
         AuthService.logout().finally(() => {
            navigate("/auth/login", { replace: true });
            AuthService.resetAllUserSettings();
         });
      }
   };

   const styles = useMemo(() => ({
      iconButton: {
         borderRadius: 0.75,
         justifyContent: "center",
      },
      personIcon: { mx: 0.5 },
      popover: {
         mt: 1.5,
         ml: 0.75,
         "& .MuiMenuItem-root": {
            px: 2, py: 1, display: "flex", gap: "8px",
         },
      },
      paper: {
         backgroundColor: palette.mode === 'light' ? GRAY_V2[50] : DARKMODE_V2[1000],
         overflow: 'inherit',
         minWidth: 200,
         pb: 1,
         pt: 1,
         borderRadius: "8px",
      }, 
   }), [palette.mode])

   return (
      <>
         <Tooltip title={translate("login.accountpreferences")}>
            <IconButton onClick={handleOpen} sx={styles.iconButton}>
               <PersonIcon sx={styles.personIcon} />
               {!isMobile && (
                  <Typography variant="subtitle2" noWrap>
                     {CommonActions.getUserInformation().userName}
                  </Typography>
               )}
            </IconButton>
         </Tooltip>
         <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={styles.popover}
            slotProps={{
               paper: {
                  sx: styles.paper
               }
            }}
         >
            <MenuItem onClick={() => window?.open("https://docs.smartpulse.io/login", "_blank")?.focus()}>
               <MenuBookIcon  />
               <Typography fontSize="14px">{translate("general.docs")}</Typography>
               <OpenInNewIcon />
            </MenuItem>
            <Divider sx={{ borderStyle: "solid", }} />
            <MenuItem onClick={() => goToPage("/profile/settings")}>
               <SettingsIcon />
               <Typography fontSize="14px">{translate("pages.settings")}</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleLogout()}>
               <LogoutIcon />
               <Typography fontSize="14px">{translate("general.logout")}</Typography>
            </MenuItem>
         </Popover>
      </>
   );
}

export default AccountPopover;
