import { configureStore } from "@reduxjs/toolkit";
import appReducers from "../old/src/redux/reducers";
import { socketApi } from "src/old/src/redux/webSocket/gipPlanningWebSocket";
import {
   batchUpdateDepthMiddleware,
   batchMyProposalUpdaterMiddleware,
   batchRelatedGroupProposalUpdaterMiddleware,
   batchTradesUpdaterMiddleware,
} from "src/old/src/redux/reducers/gipPlanning/gipPlanningReducers";

const store = configureStore({
   // @ts-expect-error ignore
   reducer: appReducers,
   devTools: process.env.NODE_ENV !== "production",
   // @ts-expect-error ignore
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
         .concat(socketApi.middleware)
         .concat(batchUpdateDepthMiddleware)
         .concat(batchMyProposalUpdaterMiddleware)
         .concat(batchRelatedGroupProposalUpdaterMiddleware)
         .concat(batchTradesUpdaterMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
