import { ProcessCellForExportParams } from "@ag-grid-community/core";
import {
    ColDefinition,
    GridCompExtenderServiceCtorContext,
    GridExtenderServicePipelineContext
} from "src/components/grid/Types.ts";
import GridExtenderServiceBase from "src/components/grid/Extenders/GridExtenderServiceBase.ts";
import { numberColumnFormatter } from "./NumberColumnFormatter.ts";

export default class AgGridClipboardExtender extends GridExtenderServiceBase {

    constructor(context: GridCompExtenderServiceCtorContext) {
        super(context);
    }

    //#region base implementations

    get serviceName(): string {
        return "__clipBoardExtenderService";
    }

    extend = (context: GridExtenderServicePipelineContext): GridExtenderServicePipelineContext => {
        const { reason } = context;
        const gridApi = this.getGridApi();

        if (reason === 'init') {
            gridApi.setGridOption("processCellForClipboard", this.onProcessForClipboard);
            gridApi.setGridOption("processCellFromClipboard", this.onProcessFromClipboard);
        }

        return context;
    }

    dispose = (reason: 'grid-destroy' | 'app' | undefined): void => {
        const gridApi = this.getGridApi();

        if (gridApi.isDestroyed() ?? true) {
            return;
        }

        if (reason !== 'grid-destroy') {
            gridApi.setGridOption("processCellForClipboard", undefined);
            gridApi.setGridOption("processCellFromClipboard", undefined);
        }
    }

    //#endregion

    private onProcessForClipboard = (params: ProcessCellForExportParams): any => {
        const { column, context, api, value: cellValue, formatValue } = params;
        const colDefinitionByColumn = column.getUserProvidedColDef() as ColDefinition | null;

        if (!colDefinitionByColumn) {
            return cellValue;
        }

        const {
            defaultCulture: defaultCultureByColumn,
            columnDataType,
        } = colDefinitionByColumn;

        if (columnDataType === 'number' || columnDataType === 'currency') {
            const numberFormatter = this.getColumnFormatterService(
                defaultCultureByColumn, api, context);

            return numberColumnFormatter(cellValue, colDefinitionByColumn, numberFormatter, true);
        }

        return formatValue(cellValue);
    }

    private onProcessFromClipboard = (params: ProcessCellForExportParams): any => {
        const { column, context, api, value: cellValue, parseValue } = params;
        const colDefinitionByColumn = column.getUserProvidedColDef() as ColDefinition | null;

        if (!colDefinitionByColumn) {
            return cellValue;
        }

        const {
            defaultCulture: defaultCultureByColumn,
            columnDataType,
        } = colDefinitionByColumn;

        if (columnDataType === 'number' || columnDataType === 'currency') {
            const numberFormatter = this.getColumnFormatterService(
                defaultCultureByColumn, api, context);

            return numberFormatter.parseToNumber(cellValue);
        }

        return parseValue(cellValue);
    }
}
