import { Switch, SwitchProps } from "@mui/material";
import { styled } from "@mui/material";
import { GRAY_V2, PRIMARY_V2 } from "src/theme/palette";

const IOSSwitch = styled((props: SwitchProps) => (
   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, size }) => ({
   width: size === "small" ? 27 : 35,
   height: size === "small" ? 12 : 20,
   padding: 0,
   "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
         transform: "translateX(15px)",
         color: "#fff",
         "& + .MuiSwitch-track": {
            backgroundColor: PRIMARY_V2[theme.palette.mode === "light" ? 500 : 400],
            opacity: 1,
            border: 0,
         },
         "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
         },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
         color: "#33cf4d",
         border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
         color: GRAY_V2[theme.palette.mode === "light" ? 50 : 500],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
         opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
   },
   "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: size === "small" ? 8 : 16,
      height: size === "small" ? 8 : 16,
   },
   "& .MuiSwitch-track": {
      borderRadius: (size === "small" ? 12 : 20) / 2,
      backgroundColor: theme.palette.mode === "light" ? GRAY_V2[600] : GRAY_V2[900],
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
         duration: 500,
      }),
   },
}));

export default IOSSwitch;