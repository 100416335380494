export enum ERegion {
   Tr = 1,
   Es,
   Pt,
   Pl,
   Bg,
   Hr,
   At,
   Fr,
   Ro,
   Global,
   Uk,
   HZ50,
   ALBE,
   ALDE,
   AMP,
   BE,
   BY,
   CEPS,
   CH,
   CNOR,
   COAC,
   CORS,
   CSUD,
   DEA2,
   DELU,
   DK1,
   DK1A,
   DK1CO,
   DK1DE,
   DK1KS,
   DK1SB,
   DK1SK,
   DK2,
   DK2KO,
   DK2SB,
   EE,
   ELE,
   ELES,
   ELI,
   ERI,
   FI,
   FIEL,
   FIFS,
   FRE,
   GB,
   GB1A,
   GB2,
   GE,
   GER,
   GREE,
   IE,
   ITAT,
   ITCAL,
   ITCH,
   ITCP,
   ITFR,
   ITGR,
   ITME,
   ITSI,
   LBE,
   LBI,
   LKAL,
   LRE,
   LRI,
   LT,
   LV,
   MALTA,
   MAVIR,
   MO,
   NL,
   NO1,
   NO1A,
   NO2,
   NO2A,
   NO2ND,
   NO2NK,
   NO2SK,
   NO3,
   NO4,
   NO5,
   NORD,
   PLA,
   PLC,
   RU,
   SARD,
   SE1,
   SE2,
   SE3,
   SE3FS,
   SE3KS,
   SE3SW,
   SE4,
   SE4BC,
   SE4NB,
   SE4SP,
   SE4SW,
   SICI,
   SK,
   SUD,
   TBW,
   TTG,
}
