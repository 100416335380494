import React from 'react';
import {
    ICellEditorParams,
    ICellEditor,
} from '@ag-grid-community/core';
import { CustomCellEditorProps, } from '@ag-grid-community/react';
import {
    NullableNumber,
    NumericTextFieldChangedEventArguments,
} from "src/components/NumericInput/Base/Types.ts";
import { NumericBase, NumericBaseProps, KeyCodes } from "./NumericBase.tsx";
import { debugError, } from "../Utils.ts";

type NumericCustomCellEditorProps = Omit<CustomCellEditorProps<any, NullableNumber, any>, 'value' | 'onValueChange'>;

export type NumericCellBaseElementProps =
    & NumericBaseProps<NumericCustomCellEditorProps>

type NumericCellBaseElementStates = {
    numericValue: NullableNumber,
    typedValue: string,
};

export default class NumericCellBaseElement
    extends NumericBase<NumericCellBaseElementProps, NumericCellBaseElementStates>
    implements ICellEditor<NullableNumber> {

    highlightAllOnFocus: boolean;
    cancelBeforeStart: boolean;
    valueChangedOnInitializing: boolean;

    constructor(props: NumericCellBaseElementProps) {
        super(props);

        this.highlightAllOnFocus = true;
        this.cancelBeforeStart = false;
        this.valueChangedOnInitializing = false;

        this.state = {
            ...this.initializeState(),
        }
    }

    protected initializeState(): Readonly<NumericCellBaseElementStates> {
        const { baseElementProps } = this.props;

        let typedValue = "";
        let initialValue: NullableNumber = typeof baseElementProps.initialValue === "string"
            ? this.parseToNumber(baseElementProps.initialValue)
            : baseElementProps.initialValue ?? null;

        const eventKey = baseElementProps.eventKey ?? "";

        if (eventKey !== "") {
            if (this.isKeyPressedDeleteOrBackspace(eventKey)) {
                initialValue = null;
                this.highlightAllOnFocus = false;
            } else if (this.validInputChars.includes(eventKey)) {
                initialValue = this.parseToNumber(eventKey);
                typedValue = eventKey;

                this.valueChangedOnInitializing = true;
                this.highlightAllOnFocus = false;
            } else {
                typedValue = this.formatTypedValue(initialValue);

                if (eventKey === KeyCodes.KEY_F2) {
                    this.highlightAllOnFocus = false;
                } else if (eventKey === KeyCodes.KEY_ENTER) {
                    this.highlightAllOnFocus = true;
                } else {
                    if (typedValue === "") {
                        this.cancelBeforeStart = true;
                    }
                }
            }
        } else {
            typedValue = this.formatTypedValue(initialValue);
        }

        return {
            numericValue: initialValue,
            typedValue: typedValue,
        };
    }

    componentDidMount(): void {
        if (this.valueChangedOnInitializing) {
            this.valueChangedOnInitializing = false;

            const args: NumericTextFieldChangedEventArguments = {
                value: this.state.numericValue,
                formattedValue: this.formatNumericValue(this.state.numericValue),
                typedValue: this.state.typedValue,
            };

            const { baseElementProps: { onValueChanged } } = this.props;
            onValueChanged?.call(this, {
                ...args
            });
        }

        this.afterGuiAttached();
    }

    componentDidUpdate(
        _prevProps: Readonly<NumericCellBaseElementProps>,
        prevState: Readonly<NumericCellBaseElementStates>,
        _snapshot?: any) {

        let valueChanged = false;

        let numericValue: NullableNumber = null;
        let typedValue = "";

        if (this.state.typedValue !== prevState.typedValue) {
            valueChanged = true;

            typedValue = this.state.typedValue;
            numericValue = this.parseToNumber(typedValue);
        }

        if (valueChanged) {
            this.setState({
                numericValue: numericValue,
                typedValue: typedValue,
            }, () => {
                try {
                    const args: NumericTextFieldChangedEventArguments = {
                        value: this.state.numericValue,
                        formattedValue: this.formatNumericValue(numericValue),
                        typedValue: this.state.typedValue,
                    };

                    const { baseElementProps: { onValueChanged } } = this.props;
                    onValueChanged?.call(this, {
                        ...args
                    });
                } catch (e) {
                    debugError("NumericInputByCell.onChange", e);
                }
            });

            return;
        }
    }

    //#region base overrided

    protected override onGetInputValue(): string {
        return this.state.typedValue;
    }

    protected override onInputValueChanged(
        _event: React.ChangeEvent<HTMLInputElement>,
        inputValue: string): void {
        this.setState({
            typedValue: inputValue,
        });
    }

    //#endregion

    //#region Implementation of ICellEditor


    /**
     * Return the final value - called by the grid once after editing is complete
     */
    getValue(): NullableNumber | undefined {
        return this.state.numericValue;
    }

    /**
     * Optional: Gets called with the latest cell editor params every time they update
     */
    refresh(_params: ICellEditorParams<any, NullableNumber, any>): void {
        const { numericValue, } = this.state;
        const correctTypedValue = numericValue === null
            ? ""
            : this.formatTypedValue(numericValue);

        this.setState({
            typedValue: correctTypedValue,
        });
    }

    /**
     * Optional: A hook to perform any necessary operation just after the GUI for this component has been rendered on the screen.
     * This method is called each time the edit component is activated.
     * This is useful for any logic that requires attachment before executing, such as putting focus on a particular DOM element.
     */
    afterGuiAttached(): void {
        const { baseElementProps: { stopEditing } } = this.props;

        if (this.isCancelBeforeStart()) {
            stopEditing && stopEditing(true);
            return;
        }

        this.inputInstance?.focus();

        if (this.highlightAllOnFocus) {
            this.inputInstance?.select();

            this.highlightAllOnFocus = false;
        } else {
            const length = this.state.typedValue.length;

            if (length > 0) {
                this.inputInstance?.setSelectionRange(length, length);
            }
        }
    }

    /** Optional: Gets called once after initialised. If you return true, the editor will
     * appear in a popup, so is not constrained to the boundaries of the cell.
     * This is great if you want to, for example, provide you own custom dropdown list
     * for selection. Default is false (ie if you don't provide the method).
     */
    isPopup(): boolean {
        return false;
    }

    /** Optional: Gets called once, only if isPopup() returns true. Return "over" if the popup
     * should cover the cell, or "under" if it should be positioned below leaving the
     * cell value visible. If this method is not present, the default is "over".
     */
    getPopupPosition(): 'over' | 'under' | undefined {
        return undefined;
    }

    /** Optional: Gets called once after initialised. If you return true, the editor will not be
     * used and the grid will continue editing. Use this to make a decision on editing
     * inside the init() function, eg maybe you want to only start editing if the user
     * hits a numeric key, but not a letter, if the editor is for numbers.
     */
    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    /** Optional: Gets called once after editing is complete. If your return true, then the new
     * value will not be used. The editing will have no impact on the record. Use this
     * if you do not want a new value from your gui, i.e. you want to cancel the editing.
     */
    isCancelAfterEnd(): boolean {
        return false;
    }

    /**
     * Optional: If doing full line edit, then gets called when focus should be put into the editor
     */
    focusIn(): void {
        ;
    }

    /**
     * Optional: If doing full line edit, then gets called when focus is leaving the editor
     */
    focusOut(): void {
        ;
    }

    //#endregion
}