import { styled, SxProps, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { PRIMARY_V2, GRAY_V2 } from "src/theme/palette";

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    height: 36,
    padding: 8,
    backgroundColor: PRIMARY_V2[500],
    color: GRAY_V2[50],
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: 4
  } as SxProps,
  [`& .${tooltipClasses.arrow}`]: {
    color: PRIMARY_V2[500],
  } as SxProps,
}));
