import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, Link, ListItemText, Typography, Tooltip, useTheme, alpha } from "@mui/material";
// type
import { NavItemProps } from "../type";
//
import Iconify from "../../Iconify";
import { ListItemStyle as ListItem, ListItemTextStyle, ListItemIconStyle } from "./style";
import { isExternalLink } from "..";
import useLocales from "../../../hooks/useLocales";
import { useIntl } from "react-intl";
import { BLACK_V2, GRAY_V2, PRIMARY_V2 } from "src/theme/palette";
import _ from "lodash";
import { StyledTooltip } from "src/components/shared/StyledComponents/StyledTooltip";

// ----------------------------------------------------------------------

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }: NavItemProps) {
  const { title, path, icon, info, children, disabled, caption, roles, tooltip } = item;
  const { translate } = useLocales();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const hasChildren = children && children.length > 0;

  const renderContent = (
    <>
      {icon && (
        <ListItemIconStyle
          sx={{
            margin: 0,
            color: active
              ? PRIMARY_V2[theme.palette.mode === "light" ? 500 : 400]
              : GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
          }}
        >
          {icon}
        </ListItemIconStyle>
      )}
      <ListItemTextStyle
        disableTypography
        primary={formatMessage({ id: title, defaultMessage: "" })}
        sx={{
          fontWeight: 400,
          marginLeft: "8px",
          color: active
            ? PRIMARY_V2[theme.palette.mode === "light" ? 500 : 400]
            : GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
        }}
        secondary={
          <Tooltip title={tooltip || ""} arrow>
            <Typography variant="caption" component="div" sx={{ textTransform: "initial", color: "text.secondary" }}>
              {caption}
            </Typography>
          </Tooltip>
        }
        isCollapse={isCollapse}
      />
      {info && info}
      {hasChildren && <ArrowIcon open={open} />}
    </>
  );

  if (hasChildren) {
    return (
      // <StyledTooltip
      //   title={isCollapsedView && <FormattedMessage id={tooltip} defaultMessage={""} />}
      //   arrow
      //   placement="right"
      // >
      <ListItem
        onClick={(e) => {
          onOpen && onOpen(e);
        }}
        activeRoot={active}
        disabled={disabled}
        roles={roles}
        isCollapsed={isCollapse}
      >
        {renderContent}
      </ListItem>
      // </StyledTooltip>
    );
  }

  return isExternalLink(path) ? (
    // <StyledTooltip
    //   title={isCollapsedView && formatMessage({ id: tooltip, defaultMessage: "" })}
    //   arrow
    //   placement="right"
    // >
    <ListItem component={Link} href={path} target="_blank" rel="noopener" disabled={disabled} roles={roles}>
      {renderContent}
    </ListItem>
  ) : (
    // </StyledTooltip>
    // <StyledTooltip
    //   title={isCollapsedView && formatMessage({ id: tooltip, defaultMessage: "" })}
    //   arrow
    //   placement="right"
    // >
    <ListItem component={RouterLink} to={path} activeRoot={active} disabled={disabled} roles={roles}>
      {renderContent}
    </ListItem>
    // </StyledTooltip>
  );
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, "isCollapse">;

export function NavItemSub({ item, open = false, active = false, onOpen, onShownMenu }: NavItemSubProps) {
  const { title, path, info, children, disabled, caption, roles, tooltip } = item;
  const { formatMessage } = useIntl();
  // const isCollapsedView = verticalMenuViewOption === VerticalMenuViewOption.Collapsed;

  const renderContent = (
    <>
      {/* <SubItemVerticalLine active={active} /> */}
      <ListItemText
        disableTypography
        sx={{
          textTransform: "initial",
        }}
        primary={formatMessage({ id: title, defaultMessage: "" })}
        secondary={
          <StyledTooltip title={tooltip || ""} arrow>
            <Typography variant="caption" component="div" sx={{ textTransform: "initial", color: "text.secondary" }}>
              {caption}
            </Typography>
          </StyledTooltip>
        }
      />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      // <StyledTooltip
      //   title={isCollapsedView && formatMessage({ id: tooltip, defaultMessage: "" })}
      //   arrow
      //   placement="right"
      // >
      <ListItem onClick={onOpen} activeSub={active} subItem disabled={disabled} roles={roles}>
        {renderContent}
      </ListItem>
      // </StyledTooltip>
    );
  }

  return isExternalLink(path) ? (
    // <StyledTooltip title={isCollapsedView && formatMessage({ id: tooltip, defaultMessage: "" })} arrow placement="right">
    <ListItem
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      subItem
      disabled={disabled}
      roles={roles}
      className={[onShownMenu ? onShownMenu.toString() : "", active ? "isSelected" : ""].join("")}
    >
      {renderContent}
    </ListItem>
  ) : (
    // </StyledTooltip>
    // <StyledTooltip title={isCollapsedView && formatMessage({ id: tooltip, defaultMessage: "" })} arrow placement="right">
    <ListItem
      component={RouterLink}
      to={path}
      activeSub={active}
      subItem
      disabled={disabled}
      roles={roles}
      className={[onShownMenu ? onShownMenu.toString() : "", active ? "isSelected" : ""].join("")}
    >
      {renderContent}
    </ListItem>
    // </StyledTooltip>
  );
}
// ----------------------------------------------------------------------

type ArrowIconProps = {
  open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return (
    <Iconify
      icon={open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
