// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import { IconButtonAnimate } from '../../../components/animate';

import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';

import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import useLocales from '../../../hooks/useLocales';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

import ApplicationDefinitions, {
  IApplicationDefinition,
} from 'src/old/src/containers/authenticated/applicationDefinitions';
import { IntlShape, injectIntl } from 'react-intl';
import { IBasePageProps } from 'src/old/src/base/basepage';
import { TBreadcrumbLink } from 'src/components/Breadcrumbs';
import SystemHealth from 'src/old/src/components/SystemHealth';
import Announcement from 'src/old/src/pages/intraday/widget/announcement';
import stores from 'src/old/src/redux/stores';
import MasterMenu, { IMenuItem } from 'src/old/src/containers/authenticated/menuDefinitions';
import useSettings from 'src/hooks/useSettings';
import { VerticalMenuViewOption } from 'src/components/settings/type';
import { getHostEnvironmentOverrides } from 'src/utils/hostEnvironment';

// ----------------------------------------------------------------------

const HOMEPAGE_APPID = 1001;

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
  viewOption: VerticalMenuViewOption;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' &&
    prop !== 'isOffset' &&
    prop !== 'verticalLayout' &&
    prop !== 'viewOption',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, viewOption, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${viewOption === VerticalMenuViewOption.Collapsed ? 0 : NAVBAR.DASHBOARD_COLLAPSE_WIDTH
        }px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onChangeSidebarViewOption: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
} & IBasePageProps;

const getMenuHierarchy = (
  rootMenu: IMenuItem[],
  pathname: string,
  intl: IntlShape,
  initialHierarchy: TBreadcrumbLink[] = []
): TBreadcrumbLink[] => {
  for (const menu of rootMenu) {
    if (menu.type === 'node') {
      const nodeHierarchy = getMenuHierarchy(menu.details as IMenuItem[], pathname, intl, [
        ...initialHierarchy,
        { name: intl.formatMessage({ id: menu.textResourceId }) },
      ]);
      if (nodeHierarchy.length > 0) return nodeHierarchy;
    } else if (
      (menu.details as IApplicationDefinition).routeInfos.some((route) => route.path === pathname)
    ) {
      return [...initialHierarchy, { name: intl.formatMessage({ id: menu.textResourceId }) }];
    }
  }
  return [];
};

const DashboardHeader = ({
  onChangeSidebarViewOption,
  isCollapse = false,
  verticalLayout = false,
  intl,
}: Props) => {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'lg');
  const { pathname } = useLocation();
  const { verticalMenuViewOption } = useSettings();
  const [menuHierarchy, setMenuHierarchy] = useState<TBreadcrumbLink[] | null>(null);
  // const [showBC, setShowBC] = useState(false);

  useEffect(() => {
    setMenuHierarchy(getMenuHierarchy(MasterMenu, pathname, intl));
  }, [pathname, intl]);

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
      viewOption={verticalMenuViewOption}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 1 },
          backgroundColor: (theme) =>
            getHostEnvironmentOverrides().palette?.[theme.palette.mode].headerColor,
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <Tooltip title={intl.formatMessage({ id: 'global.expandshrink' })}>
            <IconButtonAnimate
              onClick={onChangeSidebarViewOption}
              sx={{ mr: 1, color: 'text.primary' }}
            >
              <MenuIcon />
            </IconButtonAnimate>
          </Tooltip>
        )}

        {/* {showBC && ( */}
        <HeaderBreadcrumbs style={{ marginTop: '60px', marginLeft: (!isDesktop || !isCollapse) ? 0 : NAVBAR.DASHBOARD_COLLAPSE_WIDTH }} heading={''} links={menuHierarchy ?? []} />
        {/* )} */}

        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          height="100%"
          padding={0.5}
          display="flex"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <NotificationsPopover />
          {/* @ts-ignore */}
          {stores.getState().common.roles?.includes('Announcement') && <Announcement />}
          <SystemHealth buttonProps={{ size: 'small' }} />
          <LanguagePopover />
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
};

export default injectIntl(DashboardHeader);
