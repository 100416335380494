import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { Box, Button, IconButton, styled, svgIconClasses, Typography, TypographyProps } from "@mui/material";
import equal from "fast-deep-equal";
import _ from "lodash";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import useResponsive from "src/hooks/useResponsive";
import { IBanner, IBannerDetail } from "src/models/screen-banner-model";
import DynamicFontSizeProvider from "src/new_theme/DynamicFontSizeProvider";
import { FONT_WEIGHT } from "src/new_theme/typography";
import { CloseIcon } from "src/old/src/components/icons";
import ApplicationDefinitions from "src/old/src/containers/authenticated/applicationDefinitions";
import { getScreenBanners } from "src/old/src/redux/common/action";
import { BLACK_V2, GRAY_V2, PRIMARY_V2 } from "src/theme/palette";
import { getClosedBannerIdsFromLocalStorage, setClosedBannerIdOnLocalStorage } from "src/utils";
import { StyledTooltip } from "./shared/StyledComponents/StyledTooltip";

//#region Interfaces
interface IBannerItem extends IBannerDetail, IBanner {
   ApplicationId: number;
}
//#endregion

//#region Styled Components
const BannerContainer = styled(Box)(({ theme }) => {
   const isLight = theme.palette.mode === "light";
   const isMobile = useResponsive("down", "lg");

   return {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      width: "100%",
      minHeight: 48,
      backgroundColor: PRIMARY_V2[isLight ? 50 : 900],
      color: isLight ? BLACK_V2[900] : GRAY_V2[50],
      ["& ." + svgIconClasses.root]: {
         width: isMobile ? 16 : 24,
         height: isMobile ? 16 : 24,
         color: isLight ? BLACK_V2[900] : GRAY_V2[50],
      },
   };
});

const BannerListItem = styled(Box)(({}) => {
   const isMobile = useResponsive("down", "lg");

   return {
      padding: "12px  24px 12px 8px",
      display: "flex",
      justifyContent: isMobile ? "flex-start" : "center",
      alignItems: "center",
      width: "100%",
      gap: "8px",
      position: "relative",
   };
});

const CloseIconButton = styled(IconButton)(({}) => {
   const isMobile = useResponsive("down", "lg");

   return {
      padding: 0,
      float: "right",
      right: isMobile ? 8 : 24,
      position: "absolute",
      ...(isMobile && { top: 8 }),
   };
});

const HrefButton = styled(Button)(() => {
   return {
      padding: 0,
      minWidth: 24
   };
});

const BannerText = styled((props: TypographyProps & { listCount: number }) => {
   const isMobile = useResponsive("down", "lg");
   return <Typography fontWeight={FONT_WEIGHT.MEDIUM} variant={isMobile ? "body2" : "h6"} {...props} />;
})(({ listCount }) => {
   return {
      "&:before": {
         content: listCount > 1 ? '"• "' : '""',
      },
   };
});
//#endregion

//#region Component
const Banner: React.FunctionComponent = () => {
   const isMobile = useResponsive("down", "lg");
   const { pathname } = useLocation();
   const { locale } = useIntl();
   const navigate = useNavigate();
   const application = useMemo(
      () =>
         ApplicationDefinitions.find((app) =>
            app.routeInfos.some((i) => i.path.toLowerCase() === pathname.toLowerCase())
         ),
      [pathname]
   );

   const [closedBannerIds, setClosedBannerIds] = useState<number[]>(getClosedBannerIdsFromLocalStorage());
   const [showMoreListItem, setShowMoreListItem] = useState(false);

   useEffect(() => {
      setClosedBannerIds((prev) => {
         const newValue = getClosedBannerIdsFromLocalStorage();
         if (equal(prev, newValue)) return prev;
         return newValue;
      });
   }, [pathname]);

   const banners = getScreenBanners();

   const showedBanners = useMemo(
      () =>
         (banners ?? [])
            .flatMap((b) => {
               return (b?.BannerDetails ?? []).map<IBannerItem>((bd) => ({
                  ...b.Banner,
                  ...bd,
                  ApplicationId: b.ApplicationId,
                  Language: bd.Language.toLowerCase(),
               }));
            })
            .filter(
               (detail) =>
                  detail.ApplicationId === application?.id &&
                  detail.Language === locale &&
                  !closedBannerIds.includes(detail.BannerId)
            ),
      [locale, application, closedBannerIds]
   );

   const onNavigating = useCallback(
      (item: IBannerItem) => (_) => {
         navigate(item.Target, { replace: true });
      },
      []
   );

   const onClosedBanner = useCallback(
      (id: number) => () => {
         const newValue = _.uniq([...closedBannerIds, id]);
         setClosedBannerIds(newValue);
         setClosedBannerIdOnLocalStorage(JSON.stringify(newValue));
      },
      [closedBannerIds]
   );

   const onSeeMoreBanner = useCallback(() => {
      setShowMoreListItem(true);
   }, []);

   if (showedBanners.length === 0) return null;

   return (
      <DynamicFontSizeProvider>
         <BannerContainer id="banner-container">
            {showedBanners
               .filter(
                  (_, index) =>
                     !isMobile || (isMobile && showMoreListItem) || (isMobile && !showMoreListItem && index < 1)
               )
               .map((item) => (
                  <BannerListItem key={item.Id}>
                     <CelebrationIcon />
                     <BannerText listCount={showedBanners.length}>{item.Detail}</BannerText>
                     <StyledTooltip title={item.Tooltip} arrow>
                        {item.IsExternal ? (
                           // @ts-ignore
                           <HrefButton href={item.Target} target="_blank" rel="noopener noreferrer">
                              <ArrowRightAltIcon />
                           </HrefButton>
                        ) : (
                           <HrefButton onClick={onNavigating(item)}>
                              <ArrowRightAltIcon />
                           </HrefButton>
                        )}
                     </StyledTooltip>
                     <CloseIconButton onClick={onClosedBanner(item.Id)}>
                        <CloseIcon />
                     </CloseIconButton>
                  </BannerListItem>
               ))}
            {isMobile && showedBanners.length > 1 && !showMoreListItem && (
               <BannerListItem key="see-more" onClick={onSeeMoreBanner}>
                  <Typography variant="body2" marginLeft={"24px"}>
                     <FormattedMessage id="banner.item.showmore" />
                  </Typography>
               </BannerListItem>
            )}
         </BannerContainer>
      </DynamicFontSizeProvider>
   );
};
//#endregion

export default memo(Banner);
