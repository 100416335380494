import { ThemeOptions, TypographyVariant, useTheme } from "@mui/material";
import { pxToRem, responsiveFontSizes } from "../utils/getFontValue";
import { Typography, TypographyOptions } from "@mui/material/styles/createTypography";

/**
 * Keys of useable fontweight constants.
 * It can be using with directly `Typography` element.
 * @example
 * <Typography variant="h1" fontWeight={FONT_WEIGHT.REGULAR}>
 *    {...}
 * </Typography>
 */
export const FONT_WEIGHT = {
   REGULAR: "fontWeightLight",
   MEDIUM: "fontWeightRegular",
   SEMIBOLD: "fontWeightMedium",
   BOLD: "fontWeightBold",
};

const FONT_PRIMARY = "Mulish, sans-serif";

const typography: TypographyOptions = {
   fontFamily: FONT_PRIMARY,
   [FONT_WEIGHT.REGULAR]: 400,
   [FONT_WEIGHT.MEDIUM]: 500,
   [FONT_WEIGHT.SEMIBOLD]: 600,
   [FONT_WEIGHT.BOLD]: 700,
   h1: {
      fontWeight: 400,
      fontSize: pxToRem(40),
      lineHeight: pxToRem(48),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 40, md: 40, lg: 40 }),
   },
   h2: {
      fontWeight: 400,
      fontSize: pxToRem(32),
      lineHeight: pxToRem(40),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 32, md: 32, lg: 32 }),
   },
   h3: {
      fontWeight: 400,
      fontSize: pxToRem(24),
      lineHeight: pxToRem(32),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 24, md: 24, lg: 24 }),
   },
   h4: {
      fontWeight: 400,
      fontSize: pxToRem(20),
      lineHeight: pxToRem(28),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 20, md: 20, lg: 20 }),
   },
   h5: {
      fontWeight: 400,
      fontSize: pxToRem(18),
      lineHeight: pxToRem(26),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
   },
   h6: {
      fontWeight: 400,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
   },
   subtitle1: {
      fontWeight: 400,
      fontSize: pxToRem(15),
      lineHeight: pxToRem(22),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 15, md: 15, lg: 15 }),
   },
   subtitle2: {
      fontWeight: 400,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 14, md: 14, lg: 14 }),
   },
   body1: {
      fontWeight: 400,
      fontSize: pxToRem(13),
      lineHeight: pxToRem(16),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 13, md: 13, lg: 13 }),
   },
   body2: {
      fontWeight: 400,
      fontSize: pxToRem(12),
      lineHeight: pxToRem(14),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 12, md: 12, lg: 12 }),
   },
   caption: {
      fontWeight: 400,
      fontSize: pxToRem(10),
      lineHeight: pxToRem(12),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 10, md: 10, lg: 10 }),
   },
   overline: {
      fontWeight: 400,
      fontSize: pxToRem(8),
      lineHeight: pxToRem(10),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 8, md: 8, lg: 8 }),
   },
   button: {
      fontWeight: 400,
      fontSize: pxToRem(11),
      lineHeight: pxToRem(13),
      fontStyle: "normal",
      ...responsiveFontSizes({ sm: 11, md: 11, lg: 11 }),
   },
};

export default typography;
