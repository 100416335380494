import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, SxProps, useTheme } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { HEADER, NAVBAR } from '../../config';
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import { VerticalMenuViewOption } from 'src/components/settings/type';
import Banner from 'src/components/Banner';

const verticalMainStyle: SxProps = {
  px: { lg: 2 },
  pt: {
    xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
    lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
  },
  pb: {
    xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
    lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
  },
};

const containerStyle: SxProps = {
  display: { lg: 'flex' },
  minHeight: { lg: 1 },
};

function DashboardLayout() {
  const { isCollapse, collapseClick, changeCollapse } = useCollapseDrawer();
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  const {
    themeLayout,
    verticalMenuViewOption,
    onChangeVerticalMenuViewOption,
  } = useSettings();

  const verticalLayout = themeLayout === 'vertical';
  const showMenu = window.location.href.indexOf('nomenu=true') < 0;

  useEffect(() => {
    changeCollapse(verticalMenuViewOption === VerticalMenuViewOption.Collapsed);
  }, [verticalMenuViewOption, isDesktop]);

  const handleChangeMenuViewOption = () => {
    const newValue = verticalMenuViewOption === VerticalMenuViewOption.Open ? VerticalMenuViewOption.Collapsed : VerticalMenuViewOption.Open;
    return onChangeVerticalMenuViewOption(newValue);
  }

  const handleVerticalMenuClose = () =>
    onChangeVerticalMenuViewOption(VerticalMenuViewOption.Collapsed);

  const horizontalMainStyle: SxProps = {
    flexGrow: 1,
    paddingTop: `${HEADER.MOBILE_HEIGHT + 24}px`,
    paddingBottom: `${HEADER.MOBILE_HEIGHT + 24}px`,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: "5px",
      paddingRight: "5px",
      paddingTop: "60px",
      paddingBottom: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
      width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
      transition: theme.transitions.create('margin-left', {
        duration: theme.transitions.duration.shorter,
      }),
      ...(collapseClick && {
        marginLeft: `${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px`,
      }),
    },
  };

  return (
    <Box sx={verticalLayout ? undefined : containerStyle}>
      {showMenu && (
        <>
          <DashboardHeader
            isCollapse={verticalLayout ? undefined : isCollapse}
            onChangeSidebarViewOption={handleChangeMenuViewOption}
            verticalLayout={verticalLayout ? verticalLayout : undefined}
          />

          {isDesktop && verticalLayout ? (
            <NavbarHorizontal />
          ) : (
            <NavbarVertical onCloseSidebar={handleVerticalMenuClose} />
          )}
        </>
      )}

      <Box component="main" sx={verticalLayout ? verticalMainStyle : horizontalMainStyle}>
        <Banner />
        <Outlet />
      </Box>
    </Box>
  );
}

export default DashboardLayout;
