import { Box, Button, styled, TextField, TextFieldProps } from "@mui/material";
import { BLACK_V2, GRAY_V2, PRIMARY_V2 } from "src/theme/palette";

export const TabContainer = styled(Box)(({ theme }) => ({
   display: "inline-flex",
   height: "34px",
   padding: "2px",
   marginBottom: "32px",
   borderRadius: "8px",
   backgroundColor: theme.palette.mode === "light" ? GRAY_V2[100] : BLACK_V2[600],
}));

export const TabItem = styled(Box)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
   padding: "6px 12px",
   fontSize: "16px",
   borderRadius: "8px",
   height: "30px",
   cursor: "pointer",
   color: GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
   backgroundColor:
      theme.palette.mode === "light" ? (isSelected ? "#fff" : GRAY_V2[100]) : BLACK_V2[isSelected ? 500 : 600],

   display: "flex",
   justifyContent: "center",
   alignItems: "center",
}));

export const CustomTextField = styled((props: TextFieldProps) => <TextField variant="outlined" fullWidth {...props} />)(
   ({ theme }) => ({
      backgroundColor: theme.palette.mode === "light" ? GRAY_V2[100] : BLACK_V2[600],
      borderRadius: "8px",
      "& .MuiInputBase-input.Mui-disabled": {
         WebkitTextFillColor: GRAY_V2[theme.palette.mode === "light" ? 500 : 900],
      },
      "& .MuiOutlinedInput-input::placeholder": {
         color: GRAY_V2[theme.palette.mode === "light" ? 500 : 900],
         opacity: 1,
      },
      "& .MuiSelect-icon": { marginRight: "12px" },
      "& .MuiOutlinedInput-root": {
         "& .MuiOutlinedInput-input": {
            padding: 0,
         },
         "& .MuiOutlinedInput-input:focus": {
            backgroundColor: "transparent",
         },
         "& fieldset": {
            border: theme.palette.mode === "light" ? `1px solid ${GRAY_V2[300]}` : "none",
         },
         "&:hover fieldset": {
            border: theme.palette.mode === "light" ? `1px solid ${GRAY_V2[300]}` : "none",
         },
         "&.Mui-focused fieldset": {
            border: theme.palette.mode === "light" ? `1px solid ${GRAY_V2[300]}` : "none",
         },
      },
      "& input:-webkit-autofill": {
         boxShadow: `0 0 0 100px ${theme.palette.mode === "light" ? GRAY_V2[100] : BLACK_V2[600]} inset`,
      },
   })
);

export const ModalContainer = styled(Box)(({ theme }) => ({
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 550,
   padding: "24px 32px",
   borderRadius: "12px",
   backgroundColor: theme.palette.mode === "light" ? GRAY_V2[50] : BLACK_V2[500],
}));

export const CancelModalButton = styled(Button)(() => ({
   boxShadow: "none",
   height: "56px",
   borderRadius: "48px",
   backgroundColor: GRAY_V2[200],
   color: "black",
   textTransform: "none",
   fontSize: "18px",
   fontWeight: 400,
   "&:hover": {
      backgroundColor: GRAY_V2[200],
   },
}));

export const OkModalButton = styled(Button)(() => ({
   boxShadow: "none",
   height: "56px",
   borderRadius: "48px",
   backgroundColor: PRIMARY_V2[500],
   color: GRAY_V2[50],
   textTransform: "none",
   fontSize: "18px",
   fontWeight: 400,
   "&:hover": {
      backgroundColor: PRIMARY_V2[500],
   },
}));
