import { ReactNode } from "react";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { Button, Popover, ButtonProps, LinkProps } from "@mui/material";
// config
import { NAVBAR } from "../../../config";
import { BLACK_V2, GRAY_V2, PRIMARY_V2 } from "src/theme/palette";

// ----------------------------------------------------------------------

type IProps = LinkProps & ButtonProps;

export interface ListItemStyleProps extends IProps {
  component?: ReactNode;
  to?: string;
  activeRoot?: boolean;
  activeSub?: boolean;
  subItem?: boolean;
  open?: boolean;
  roles?: string[];
}

export const ListItemStyle = styled(Button, {
  shouldForwardProp: (prop) => prop !== "activeRoot" && prop !== "activeSub" && prop !== "subItem" && prop !== "open",
})<ListItemStyleProps>(({ activeRoot, activeSub, subItem, open, theme }) => {
  const isLight = theme.palette.mode === "light";

  return {
    textTransform: "capitalize",
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0, 1),
    color: GRAY_V2[isLight ? 900 : 500],
    height: NAVBAR.DASHBOARD_ITEM_HORIZONTAL_HEIGHT,
    backgroundColor: activeSub
      ? theme.palette.mode === "light"
        ? "rgba(208, 250, 178, 0.25)"
        : BLACK_V2[600]
      : "transparent",
    borderRadius: activeSub ? 4 : 0,
    ...(open && {
      color: isLight ? GRAY_V2[900] : "#FCFCFD",
      borderBottom: !subItem
        ? "2px solid " + (activeRoot ? PRIMARY_V2[isLight ? 500 : 400] : isLight ? GRAY_V2[900] : "#fcfcfd")
        : "unset",
    }),
    "&:hover": {
      backgroundColor: subItem
        ? theme.palette.mode === "light"
          ? "rgba(208, 250, 178, 0.25)"
          : BLACK_V2[600]
        : "inherit",
      color: subItem ? PRIMARY_V2[isLight ? 500 : 400] : GRAY_V2[isLight ? 900 : 500],
      borderBottom: !subItem
        ? "2px solid " + (activeRoot ? PRIMARY_V2[isLight ? 500 : 400] : isLight ? GRAY_V2[900] : "#fcfcfd")
        : "unset",
      borderRadius: activeSub || subItem ? 4 : 0,
    },
    // // activeRoot
    ...((activeRoot || activeSub) && {
      borderBottom: activeRoot ? "2px solid " + PRIMARY_V2[isLight ? 500 : 400] : "unset",
      backgroundColor: subItem ? (isLight ? GRAY_V2[200] : BLACK_V2[600]) : "inherit",
    }),
    ...(subItem && {
      margin: 0,
      paddingRight: 0,
      paddingLeft: theme.spacing(1),
      justifyContent: "space-between",
    }),
    ...(activeRoot && {
      color: PRIMARY_V2[isLight ? 500 : 400]
    })
  };
});

// ----------------------------------------------------------------------

export const PaperStyle = styled(Popover)(({ theme }) => ({
  pointerEvents: "none",
  "& .MuiPopover-paper": {
    backgroundColor: theme.palette.mode === "light" ? GRAY_V2[100] : BLACK_V2[500],
    minWidth: 160,
    maxWidth: "50vw",
    pointerEvents: "auto",
    padding: theme.spacing(1),
    "&.none-padding": {
      padding: 0,
      borderBottom: "unset",
    },
  },
}));
