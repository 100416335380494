import {
   IEnableMultiFactorAuthRequest,
   IEnableMultiFactorAuthResponse,
   IValidateMultiFactorCodeRequest,
} from "src/models/customerManagements.model";
import baseService from "../base.service";

export const enableMultiFactorAuth = async (request: IEnableMultiFactorAuthRequest) => {
   const url = `CustomerManagement/EnableMultiFactorAuth`;
   const response = await baseService.post<IEnableMultiFactorAuthResponse>(url, request);
   return response.data;
};

export const validateMultiFactorCode = async (request: IValidateMultiFactorCodeRequest) => {
   const url = `CustomerManagement/ValidateMultiFactorCode`;
   const response = await baseService.post<boolean>(url, request);
   return response.data;
};

export const sendCodeMail = async (request: IEnableMultiFactorAuthRequest) => {
   const url = `CustomerManagement/SendCodeMail`;
   const response = await baseService.post<boolean>(url, request);
   return response.data;
};
