import React, { JSX, useCallback, useImperativeHandle, useMemo, } from "react";
import { FilledInputProps, OutlinedInputProps, TextField, TextFieldProps, Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { ClearIcon } from "src/old/src/components/icons";
import useUserCulture from "../../../hooks/useUserCulture.ts";
import {
    NumericTextFieldChangedEventArguments,
    NumericValueEntryOptions,
    NumericTextFieldElement,
    NumericTextFieldCoreElement,
    PreDefinedCultureTypes,
    NullableNumber,
} from "src/components/NumericInput/Base/Types.ts";
import UserCultureFormatterProvider, {
    UserCultureFormatterService
} from "../../culture/UserCultureFormatterService.ts";
import { createIntl, createIntlCache } from "react-intl";
import { NumericBaseElementProps, NumericBaseProps } from "./NumericBase.tsx";

export interface NumericInputComponentOptions
    extends Partial<NumericValueEntryOptions> {
    defaultCulture?: PreDefinedCultureTypes | string | null,
    clearButtonPosition?: 'start' | 'end' | null,
    showClearOnlyFocus?: boolean, //TODO: implements !
    onValueChanged?: (args: NumericTextFieldChangedEventArguments) => void,
};

type NumericInputBaseProps = Omit<TextFieldProps,
    "InputProps" |
    "inputProps" |
    "type" |
    "inputMode" |
    "multiline" |
    "autoComplete" |
    "value" |
    "onChange" |
    "onKeyDown"
>;

export interface NumericInputProps extends Partial<NumericInputBaseProps> {
    value?: NullableNumber,
    inputComponent: React.ComponentType<NumericBaseProps>,
    inputComponentProps: NumericInputComponentOptions,
    inputRef?: React.RefObject<NumericTextFieldElement>,
    InputProps?: Omit<Partial<StandardInputProps>, 'inputRef' | 'inputComponent' | 'inputMode' | 'autoComplete' | 'multiline' | 'type' | 'value'>
        | Omit<Partial<FilledInputProps>, 'inputRef' | 'inputComponent' | 'inputMode' | 'autoComplete' | 'multiline' | 'type' | 'value'>
        | Omit<Partial<OutlinedInputProps>, 'inputRef' | 'inputComponent' | 'inputMode' | 'autoComplete' | 'multiline' | 'type' | 'value'>,
    onChange?: (args: NumericTextFieldChangedEventArguments, inputElement?: NumericTextFieldElement | null) => void,
};

export const NumericInput = React.forwardRef<HTMLDivElement, NumericInputProps>(
    (props, ref) => {
        const {
            value,
            onChange,
            inputComponent: CoreComponent, // input core component
            inputComponentProps,// input core component props
            InputProps = {},
            inputRef,
            ...others
        } = props;

        const { defaultCulture, clearButtonPosition } = inputComponentProps;

        // TODO: grid için sağlanan element e ICellEditor ile erişmek gerek..
        const inputCoreRef = React.useRef<NumericTextFieldCoreElement>(null);

        const cultureLocale = UserCultureFormatterService.getValidNumberFormatLocale(
            defaultCulture ?? useUserCulture().UserCulture
        );

        const formatterService = useMemo<UserCultureFormatterProvider>(() => {
            const injectIntl = createIntl({
                locale: cultureLocale,
                defaultLocale: cultureLocale
            }, createIntlCache());

            return new UserCultureFormatterService(cultureLocale, injectIntl);
        }, [cultureLocale]);

        useImperativeHandle(
            inputRef,
            () => {
                return {
                    value: inputCoreRef?.current?.value ?? null,
                    numericValue: inputCoreRef?.current?.numericValue ?? null,
                    inputValue: inputCoreRef?.current?.inputValue ?? "",
                    formattedValue: inputCoreRef?.current?.formattedValue ?? "",
                    currentCulture: cultureLocale,
                    focus: () => inputCoreRef?.current?.focus(),
                    select: () => inputCoreRef?.current?.select(),
                    clearValue: () => inputCoreRef?.current?.clearValue() ?? Promise.resolve(),
                    refresh: () => inputCoreRef?.current?.refresh(),
                };
            });

        const clearValueElementOptions = useCallback((): any => {
            return {
                startAdornment: clearButtonPosition === 'start'
                    ? <NumericInputClearValueButtonElement
                        key={"clear-numeric-value"}
                        position={'start'}
                        onClick={() => {
                            inputCoreRef?.current?.clearValue();
                        }}
                    />
                    : undefined,
                endAdornment: clearButtonPosition === 'end'
                    ? <NumericInputClearValueButtonElement
                        key={"clear-numeric-value"}
                        position={'end'}
                        onClick={() => {
                            inputCoreRef?.current?.clearValue();
                        }}
                    />
                    : undefined
            };
        }, []);

        const buildInputProps = useCallback((): StandardInputProps => {
            const numericValueOptions = UserCultureFormatterService.getLocaleNumberOptions(cultureLocale);

            const { inputProps = {}, ...rest } = InputProps;
            const { inputMode: inputModeBase, ...restInput } = inputProps;
            const { decimalSeparator, groupSeparator, minusNumberSign, ...restValueOptions } = numericValueOptions;
            const { allowDecimal, allowMinusValue, onValueChanged, ...customComponentProps } = inputComponentProps;

            const baseElementProps: NumericBaseElementProps = {
                allowDecimal: Boolean(allowDecimal),
                allowMinusValue: Boolean(allowMinusValue),
                decimalSeparator: decimalSeparator,
                groupSeparator: groupSeparator,
                minusNumberSign: minusNumberSign,
                formatterService: formatterService,
                onValueChanged: onValueChanged,
                value: value ?? null,
                maxValue: null,
                minValue: null,
                ...restValueOptions,
                ...customComponentProps,
            };

            return {
                type: "text",
                inputMode: "text",
                autoComplete: "off",
                multiline: false,
                inputRef: inputCoreRef,
                // TODO: type check !
                // TODO: react-number-format -> https://github.com/s-yadav/react-number-format 
                //@ts-ignore
                inputComponent: CoreComponent,
                inputProps: {
                    inputMode: "text",
                    ...restInput, // InputBaseComponentProps 
                    baseElementProps: { ...baseElementProps },
                },
                ...clearValueElementOptions(),
                ...rest,
            };
        }, [
            cultureLocale,
            value,
            onChange,
            CoreComponent,
            inputComponentProps,
            InputProps,
            inputRef,
        ]);

        const inputProps = buildInputProps();

        return (
            <TextField
                ref={ref}
                type={"text"}
                inputMode={"text"}
                multiline={false}
                autoComplete="off"
                InputProps={{ ...inputProps }}
                {...others}
            />
        );
    });

const NumericInputClearValueButtonElement = (props: {
    position: 'start' | 'end',
    onClick: () => void
}): JSX.Element => {
    return (
        // TODO: localization
        <Tooltip title={"Clear"}>
            <InputAdornment
                position={props.position}>
                <IconButton
                    size={'small'}
                    onClick={props.onClick}
                    edge={'start'}>
                    <ClearIcon/>
                </IconButton>
            </InputAdornment>
        </Tooltip>
    );
}

