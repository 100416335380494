export enum EContractProductType {
   P15MIN,
   P30MIN,
   P60MIN,
   BLOCK_2H,
   BLOCK_4H,
   DON,
   DB34,
   DP,
   DEP,
   DB,
   CUSTOM_BLOCK,
   LOCAL_60MIN,
   LOCAL_30MIN,
   LOCAL_15MIN,
}
