// @mui
import { styled } from "@mui/material/styles";
import {
  List,
  Box,
  ListSubheader,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  useTheme,
  inputBaseClasses,
  svgIconClasses,
} from "@mui/material";
// type
import { NavSectionProps } from "../type";
//
import { NavListRoot } from "./NavList";
import useLocales from "../../../hooks/useLocales";
import { ChangeEvent, useState } from "react";
import { Close, Search } from "@mui/icons-material";
import { useIntl, defineMessages } from "react-intl";
import { BLACK_V2, GRAY_V2 } from "src/theme/palette";
import _ from "lodash";
import useSettings from "src/hooks/useSettings";
import { VerticalMenuViewOption } from "src/components/settings/type";
import RootModulesViewer from "src/layouts/dashboard/navbar/RootModulesViewer";
import useResponsive from "src/hooks/useResponsive";

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

const messages = defineMessages({
  search: {
    id: "global.search",
    defaultMessage: "Search",
  },
  delete: {
    id: "global.clear",
    defaultMessage: "Clear",
  },
});

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }: NavSectionProps) {
  const { translate } = useLocales();
  const { formatMessage } = useIntl();
  const { verticalMenuViewOption, onChangeVerticalMenuViewOption } = useSettings();

  const [search, setSearch] = useState<string>("");

  const turkishToEnglish = (turkishText) => {
    return turkishText
      .replace("Ğ", "g")
      .replaceAll("Ü", "u")
      .replaceAll("Ş", "s")
      .replaceAll("I", "i")
      .replaceAll("İ", "i")
      .replaceAll("Ö", "o")
      .replaceAll("Ç", "c")
      .replaceAll("ğ", "g")
      .replaceAll("ü", "u")
      .replaceAll("ş", "s")
      .replaceAll("ı", "i")
      .replaceAll("ö", "o")
      .replaceAll("ç", "c");
  };

  const searchRegex = new RegExp(turkishToEnglish(search), "i");
  const theme = useTheme();

  const filtersMenuHandler = (menu) => {
    if (search === "" || search === null) {
      return menu;
    }
    return menu
      .map((x) => {
        if (x.children === undefined) {
          const hasSearchParams = searchRegex.test(formatMessage({ id: x.title }));
          if (hasSearchParams) {
            return x;
          }
          return null;
        } else {
          const children = x.children.filter((item) =>
            searchRegex.test(turkishToEnglish(formatMessage({ id: item.title })))
          );
          if (children.length > 0) {
            return { ...x, children };
          }
          return null;
        }
      })
      .filter((x) => x);
  };

  const menuItems = navConfig.map((x) => {
    return {
      subheader: x.subheader,
      items: filtersMenuHandler(x.items),
    };
  });

  const isDesktop = !!useResponsive("up", "lg");
  const isSearching = search.length > 0;
  const isShownFullWidth = (verticalMenuViewOption === VerticalMenuViewOption.Open && isDesktop) || !isDesktop;
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"16px"} {...other}>
      {!isShownFullWidth && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          margin={"auto"}
          width={"40px"}
          height={"40px"}
          onClick={() => {
            onChangeVerticalMenuViewOption(VerticalMenuViewOption.Open);
          }}
          sx={{
            ["& ." + svgIconClasses.root]: {
              color: GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
            },
            cursor: "pointer",
            backgroundColor: theme.palette.mode === "light" ? "white" : BLACK_V2[600],
            borderRadius: "4px",
            border: theme.palette.mode === "dark" ? "unset" : "1px solid " + GRAY_V2[300],
          }}
        >
          <Search />
        </Box>
      )}
      {isShownFullWidth && (
        <Box component="form" p={"0 16px"}>
          <TextField
            placeholder={formatMessage(messages.search)}
            value={search}
            onClick={() => {
              if (verticalMenuViewOption !== VerticalMenuViewOption.Open)
                onChangeVerticalMenuViewOption(VerticalMenuViewOption.Open);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
              }
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              setSearch(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: !isCollapse && (
                <InputAdornment
                  position="end"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {search.length > 0 && (
                    <Tooltip title={formatMessage(messages.delete)}>
                      <IconButton
                        onClick={() => {
                          setSearch("");
                        }}
                        sx={{ padding: 0.5 }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            autoComplete="off"
            autoFocus={false}
            role="presentation"
            sx={{
              ["& ." + inputBaseClasses.root]: {
                padding: "10px 12px",
                borderRadius: "4px",
                backgroundColor: theme.palette.mode === "light" ? "white" : BLACK_V2[600],
                color: GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
                border: theme.palette.mode === "dark" ? "unset" : "1px solid " + GRAY_V2[300],
                ["& ." + svgIconClasses.root]: {
                  color: GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
                },
                "&:placeholder": {
                  color: GRAY_V2[theme.palette.mode === "light" ? 900 : 500],
                },
                height: "40px",
              },
            }}
            fullWidth
          />
        </Box>
      )}

      {menuItems.map((group) => {
        return (
          <List key={group.subheader} disablePadding sx={{ px: 2, gap: "8px", display: "flex", flexDirection: "column" }}>
            {!_.isNil(group.subheader) && (
              <ListSubheaderStyle
                sx={{
                  ...(isCollapse && {
                    opacity: 0,
                  }),
                }}
              >
                {translate(group.subheader)}
              </ListSubheaderStyle>
            )}
            {!isShownFullWidth && (
              <RootModulesViewer isSearching={isSearching} list={group.items} />
            )}
            {isShownFullWidth &&
              group.items.map((list) => (
                <NavListRoot
                  key={list.title + list.path}
                  list={list}
                  isCollapse={isCollapse}
                  isSearching={isSearching}
                />
              ))}
          </List>
        );
      })}
    </Box>
  );
}
