import { Column, GridApi } from "@ag-grid-community/core";
import { createIntl, createIntlCache } from "react-intl";
import { WrappedGridRef } from "src/components/grid/index.tsx";
import {
    ColDefinition,
    ColGroupDefinition,
    GridCompExtenderService,
    GridCompExtenderServiceCtorContext,
    GridApiCore, GridExtenderServicePipelineContext,
} from "src/components/grid/Types.ts";
import {
    getExtenderContext,
    isColGroupDefinition,
    OriginalColumnDefinitionPropName
} from "./AgGridUtils.ts";
import { uuidv4 } from "src/old/src/pages/intraday/constants";
import UserCultureFormatterProvider, {
    UserCultureFormatterService
} from "src/components/culture/UserCultureFormatterService.ts";

export default abstract class GridExtenderServiceBase implements GridCompExtenderService {
    private readonly gridElement: WrappedGridRef;
    private localeFormattersCache: Map<string, UserCultureFormatterProvider>;
    protected readonly runtimeId: string;

    constructor(context: GridCompExtenderServiceCtorContext) {
        const { gridInstance } = context;
        this.gridElement = gridInstance;
        this.runtimeId = uuidv4();
        this.localeFormattersCache = new Map<string, UserCultureFormatterProvider>();
    }

    get gridId(): string | undefined {
        return this.getGridApi().getGridId();
    }

    protected getGridApi<TData = any>(): GridApiCore<TData> {
        const { api: gridApi } = this.gridElement;
        return gridApi as GridApiCore<TData>;
    }

    abstract get serviceName(): string ;

    abstract extend: (context: GridExtenderServicePipelineContext) => GridExtenderServicePipelineContext;
    abstract dispose: (reason: 'grid-destroy' | 'app' | undefined) => void;

    protected getOriginalColumnProp<T = any>(colDef: T, propName: keyof T | string): any {
        if (!colDef[OriginalColumnDefinitionPropName]) {
            return undefined;
        }
        return colDef[OriginalColumnDefinitionPropName][propName];
    }

    protected extendColumnDefinitions = (
        currentColDefs: (ColDefinition | ColGroupDefinition)[] | undefined,
        columnExtenderCallback?: (cd: ColDefinition) => void): void => {
        for (const colDef of (currentColDefs ?? [])) {
            if (isColGroupDefinition(colDef)) {
                this.extendColumnDefinitions(colDef.children, columnExtenderCallback);
            } else {
                const extendable = this.isExtendableColumn?.call(this, colDef) ?? false;

                if (extendable) {
                    const columnExtender = columnExtenderCallback ?? this.extendColumnDefinition;

                    columnExtender && columnExtender.call(this, colDef);
                }
            }
        }
    }

    protected extendColumnDefinition?(colDefinition: ColDefinition): void;

    public isExtendableColumn?(colDefinition: ColDefinition): boolean;

    public getExtendableColumnProps?(): (keyof ColDefinition | string)[];

    protected getColumnFormatterService = (
        columnLocale: string | undefined,
        api: GridApi,
        gridContext: any,
    ): UserCultureFormatterProvider => {
        const extenderContext = getExtenderContext({ api: api, }, gridContext);

        const { userCultureLocale, customOptions = {} } = extenderContext;
        const defaultCultureByGrid = customOptions.defaultCulture;

        let cultureLocale = (columnLocale ?? defaultCultureByGrid ?? userCultureLocale) as string;

        cultureLocale = UserCultureFormatterService.getValidNumberFormatLocale(cultureLocale);

        if (this.localeFormattersCache.has(cultureLocale)) {
            return this.localeFormattersCache.get(cultureLocale) as UserCultureFormatterProvider;
        }

        return this.localeFormattersCache
            .set(
                cultureLocale,
                new UserCultureFormatterService(
                    cultureLocale,
                    createIntl({
                        locale: cultureLocale,
                        defaultLocale: cultureLocale
                    }, createIntlCache())
                )
            )
            .get(cultureLocale) as UserCultureFormatterProvider;
    }

    protected isExistsExtendableColumns = (gridColumns: Column[]): boolean => {
        return gridColumns
                .map(col => col.getUserProvidedColDef() as ColDefinition)
                .some(colDef => Boolean(colDef) && this.isExtendableColumn && this.isExtendableColumn(colDef))
            ?? false;
    }
}
