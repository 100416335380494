import { SxProps, Tooltip, Typography } from "@mui/material";

interface IEllipsisTypographyProps {
   text: string | JSX.Element;
   hideTooltip?: boolean;
   sx?: SxProps;
}

const EllipsisTypography = (props: IEllipsisTypographyProps) => {
   return (
      <Tooltip title={!props.hideTooltip ? props.text : ""}>
         <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" sx={props.sx}>
            {props.text}
         </Typography>
      </Tooltip>
   );
};

export default EllipsisTypography;
