import { useMemo } from "react";
import { createIntl, createIntlCache } from "react-intl";
import { ColDef, ColGroupDef, Column, GridApi } from "@ag-grid-community/core";
import { defaultUserCulture } from "src/contexts/UserCultureContext.tsx";
import useUserCulture from "src/hooks/useUserCulture.ts";
import UserCultureFormatterProvider, {
    UserCultureFormatterService
} from "src/components/culture/UserCultureFormatterService.ts";
import { WrappedGridRef, } from "../index.tsx";
import {
    ColDefinition,
    ColGroupDefinition,
    GridCompExtenderContext,
    IGridCustomProps,
} from "../Types.ts";
import GridExtenderServiceBase from "src/components/grid/Extenders/GridExtenderServiceBase.ts";

const GridExtenderContextKey: string = "__extenderContext";

export const OriginalColumnDefinitionPropName = "__originalColDef";

export const getAgGridContext = (gridRef: WrappedGridRef) => {
    const { api: gridApi } = gridRef;
    return gridApi?.getGridOption("context") ?? {};
}

const setExtenderContext = (gridRef: WrappedGridRef, extenderContext: GridCompExtenderContext) => {
    const { api: gridApi } = gridRef;
    const gridCurrentContext = getAgGridContext(gridRef);
    gridCurrentContext[GridExtenderContextKey] = { ...extenderContext };
    gridApi?.updateGridOptions({ context: { ...gridCurrentContext } });
}

export const removeExtenderContext = (gridRef: WrappedGridRef) => {
    const { api: gridApi } = gridRef;
    const gridCurrentContext = getAgGridContext(gridRef);
    delete gridCurrentContext[GridExtenderContextKey];
    gridApi?.updateGridOptions({ context: { ...gridCurrentContext } });
}

export const getExtenderContext = (gridRef: WrappedGridRef, gridContext?: any): GridCompExtenderContext => {
    let gridCurrentContext: any;

    if (gridContext) {
        gridCurrentContext = { ...gridContext }
    } else {
        gridCurrentContext = getAgGridContext(gridRef);
    }

    return gridCurrentContext[GridExtenderContextKey] as GridCompExtenderContext ?? {
        services: [],
        customOptions: {},
        userCultureLocale: defaultUserCulture
    };
}

export const setExtenderCustomOptionsContext = (
    gridRef: WrappedGridRef,
    customOptions: IGridCustomProps,
    userCulture: string): void => {

    const extenderContext = getExtenderContext(gridRef);
    extenderContext.customOptions = { ...customOptions };
    extenderContext.userCultureLocale = userCulture;

    setExtenderContext(gridRef, extenderContext);
}

export const registerExtenderServices = (
    gridRef: WrappedGridRef,
    services: GridExtenderServiceBase[]): void => {
    const extenderContext = getExtenderContext(gridRef);
    extenderContext.services = [...services];

    setExtenderContext(gridRef, extenderContext);
}

export const removeAllExtenderServicesContext = (gridRef: WrappedGridRef): void => {
    const extenderContext = getExtenderContext(gridRef);
    extenderContext.services = [];

    setExtenderContext(gridRef, extenderContext);
}

export const isColGroupDefinition = (colDef: (ColDef | ColGroupDef | ColDefinition | ColGroupDefinition))
    : colDef is ColGroupDefinition => {
    return colDef["children"] !== undefined;
}


export const agGridColumnFormatterResolver = (props: {
    gridColumn: Column,
    gridContext: any,
    gridApi: GridApi
}): UserCultureFormatterProvider => {
    const { gridColumn, gridContext, gridApi } = props;
    const colDef = gridColumn.getUserProvidedColDef() as ColDefinition;

    // TODO: Kolon tanımı (colDef) üzerinden özel verilerin okunması merkezi bir yerden yapılmalı;
    //  ag-grid güncellemeleri burayı etkileyebilir !
    const {
        defaultCulture: defaultCultureByColumn,
    } = colDef;

    const userCulture = useUserCulture();

    const extenderContext = getExtenderContext({ api: gridApi, }, gridContext);
    const { customOptions = {} } = extenderContext;
    const defaultCultureByGrid = customOptions.defaultCulture;

    let cultureLocale = (defaultCultureByColumn ?? defaultCultureByGrid ?? userCulture.UserCulture) as string;

    cultureLocale = UserCultureFormatterService.getValidNumberFormatLocale(cultureLocale);

    return useMemo<UserCultureFormatterProvider>(() => {
        const injectIntl = createIntl({
                locale: cultureLocale,
                defaultLocale: cultureLocale
            }, createIntlCache()
        );

        return new UserCultureFormatterService(cultureLocale, injectIntl);
    }, [cultureLocale]);
}
