import {
  buttonBaseClasses,
  ButtonProps,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  listItemButtonClasses,
  Menu,
  menuClasses,
  Paper,
  paperClasses,
  Popover,
  Popper,
  styled,
  svgIconClasses,
  SxProps,
  Tooltip,
} from "@mui/material";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import React, { CSSProperties, memo, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router";
import { NavListProps } from "src/components/nav-section/type";
import { NavListRoot, NavListSub } from "src/components/nav-section/vertical/NavList";
import { StyledTooltip } from "src/components/shared/StyledComponents/StyledTooltip";
import { BLACK_V2, GRAY_V2, PRIMARY_V2 } from "src/theme/palette";
import { Link, NavLink as RouterLink } from "react-router-dom";
import { ListItemStyle } from "src/components/nav-section/vertical/style";
import { getActive, isExternalLink } from "src/components/nav-section";
import equal from "fast-deep-equal";

interface IRootModulesViewer {
  list: NavListProps[];
  isSearching: boolean;
}

const RootModulePopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
  ["& ." + paperClasses.root]: {
    backgroundColor: theme.palette.mode === "light" ? GRAY_V2[50] : BLACK_V2[500],
    marginBottom: 45,
    marginTop: 45,
    marginLeft: 16,
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)"
  } as CSSProperties,
  [`& .${listItemButtonClasses.root}`]: {
    "&.active": {
      backgroundColor: theme.palette.mode === "light" ? "rgba(208, 250, 178, 0.25)" : BLACK_V2[700],
      color: PRIMARY_V2[theme.palette.mode === "light" ? 500 : 400],
      fontWeight: theme.palette.mode === "light" ? 700 : "inherit",
    },
    "&:hover": {
      backgroundColor: theme.palette.mode === "light" ? GRAY_V2[200] : BLACK_V2[600],
      // padding: "8px !important",
      // color: PRIMARY_V2[theme.palette.mode === "light" ? 500 : 400],
      // fontWeight: theme.palette.mode === "light" ? 700 : "inherit",
    } as SxProps,
  },
}));

const Container = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    [`& .${buttonBaseClasses.root}`]: {
      margin: 0,
      padding: 0,
      width: 40,
      height: 40,
      justifyContent: "center",
    } as CSSProperties,
  };
});

const RootModuleItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected: boolean }>(({ theme, isSelected }) => {
  const isLight = theme.palette.mode === "light";
  const activeStyle = {
    backgroundColor: isLight ? "rgba(208, 250, 178, 0.25)" : "#2A3A35",
    color: PRIMARY_V2[isLight ? 500 : 400],
    [`& .${svgIconClasses.root}`]: {
      width: 24,
      height: 24,
      color: PRIMARY_V2[isLight ? 500 : 400],
    },
  };
  return {
    width: 24,
    height: 24,
    borderRadius: 4,
    color: GRAY_V2[isLight ? 900 : 500],
    "&:hover": {
      ...activeStyle,
    } as CSSProperties,
    [`& .${svgIconClasses.root}`]: {
      width: 24,
      height: 24,
    },
    ...(isSelected && activeStyle),
  };
});

const RootModulesViewer = (props: IRootModulesViewer) => {
  const { pathname } = useLocation();
  const [selectedItem, setSelectedItem] = useState<NavListProps>();
  const [anchorModule, setAnchorModule] = useState<HTMLDivElement | null>(null);
  const [openTooltipForIndex, setOpenTooltipForIndex] = useState(-1);
  const onModuleSelected = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item, index) => {
    e.stopPropagation();
    setAnchorModule(e.currentTarget);
    setSelectedItem(item);
    setOpenTooltipForIndex(index);
  };

  const onCloseForAllInfo = () => {
    setAnchorModule(null);
    setSelectedItem(undefined);
    setOpenTooltipForIndex(-1);
  };

  useEffect(() => {
    onCloseForAllInfo();
  }, [pathname]);

  return (
    <Container>
      <List sx={{ px: "12px", display: "flex", flexDirection: "column", gap: "16px" }}>
        {props.list
          .map((item, index) => {
            const isActive =
              getActive(item.path, pathname) ||
              (item.children ?? []).some((x) => getActive(x.path, pathname)) ||
              index === openTooltipForIndex ||
              equal(selectedItem, item);
            if (!item.children || item.children.length === 0)
              return (
                <LinkedButton
                  disabled={item.disabled}
                  path={item.path}
                  roles={item.roles}
                  tooltip={item.tooltip}
                  active={isActive}
                  icon={
                    <RootModuleItem disableTouchRipple disableRipple isSelected={isActive}>
                      {item.icon}
                    </RootModuleItem>
                  }
                  key={index}
                />
              );
            return (
              <StyledTooltip
                title={<FormattedMessage id={item.tooltip} />}
                arrow
                placement="right"
                onOpen={() => {
                  if (anchorModule) return;
                  setOpenTooltipForIndex(index);
                }}
                onClose={() => {
                  setOpenTooltipForIndex(-1);
                }}
                open={openTooltipForIndex === index || (equal(selectedItem, item) && !!anchorModule)}
                key={index}
              >
                <RootModuleItem
                  disableTouchRipple
                  disableRipple
                  key={item.path + item.tooltip}
                  isSelected={isActive}
                  onClick={(e) => onModuleSelected(e, item, index)}
                >
                  {item.icon}
                </RootModuleItem>
              </StyledTooltip>
            );
          })}
      </List>
      {anchorModule && selectedItem && (
        <RootModulePopper anchorEl={anchorModule} open={!!anchorModule} placement="right-start">
          <ClickAwayListener mouseEvent="onClick" onClickAway={onCloseForAllInfo}>
            <Paper>
              <List component="div" disablePadding style={{ padding: "8px" }}>
                {(selectedItem.children || []).map((item) => (
                  <NavListSub key={item.title + item.path} list={item} />
                ))}
              </List>
            </Paper>
          </ClickAwayListener>
        </RootModulePopper>
      )}
    </Container>
  );
};

const LinkedButton = ({ path, tooltip, disabled, roles, icon, active }) => {
  const { formatMessage } = useIntl();
  return isExternalLink(path) ? (
    <StyledTooltip title={formatMessage({ id: tooltip, defaultMessage: "" })} arrow placement="right">
      <ListItemStyle
        disableRipple
        component={Link}
        href={path}
        target="_blank"
        rel="noopener"
        disabled={disabled}
        roles={roles}
        activeRoot={active}
      >
        {icon}
      </ListItemStyle>
    </StyledTooltip>
  ) : (
    <StyledTooltip title={formatMessage({ id: tooltip, defaultMessage: "" })} arrow placement="right">
      <ListItemStyle
        disableRipple
        component={RouterLink}
        to={path}
        activeRoot={active}
        disabled={disabled}
        roles={roles}
      >
        {icon}
      </ListItemStyle>
    </StyledTooltip>
  );
};

export default memo(RootModulesViewer, equal);
