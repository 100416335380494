import {
    CellRendererSelectorFunc,
    CellRendererSelectorResult,
    ICellRendererParams
} from "@ag-grid-community/core";
import {
    ColDefinition,
    GridCompExtenderServiceCtorContext,
    GridExtenderServicePipelineContext,
} from "../../Types.ts";
import GridExtenderServiceBase from "src/components/grid/Extenders/GridExtenderServiceBase.ts";
import CellRenderer from "./AgGridHistoryViewerComponent.tsx";

export default class AgGridCellValueHistoryViewerExtender extends GridExtenderServiceBase {

    constructor(context: GridCompExtenderServiceCtorContext) {
        super(context);
    }

    get serviceName(): string {
        return "__historyViewerExtenderService";
    }

    extend = (context: GridExtenderServicePipelineContext): GridExtenderServicePipelineContext => {
        const { gridColumns, gridColDefs, } = context;

        if (!this.isExistsExtendableColumns(gridColumns)) {
            return context;
        }

        this.extendColumnDefinitions(gridColDefs);

        return context;
    }

    dispose = (_reason: 'grid-destroy' | 'app' | undefined): void => {
    }

    public isExtendableColumn({ historyView }: ColDefinition): boolean {
        return Boolean(historyView);
    }

    public getExtendableColumnProps(): (keyof ColDefinition | string)[] {
        return [
            "cellRendererSelector",
            "cellRenderer",
        ]
    }

    protected extendColumnDefinition = (colDefinition: ColDefinition): void => {
        colDefinition.cellRendererSelector = (params: ICellRendererParams): (CellRendererSelectorResult | undefined) => {
            const { node, column } = params;
            const colDefinition = column?.getUserProvidedColDef() as ColDefinition;

            if (colDefinition.historyView && !node.rowPinned) {
                return {
                    component: CellRenderer,
                    params: { ...params },
                } as CellRendererSelectorResult;
            }

            const originalCellRendererSelector = this.getOriginalColumnProp(colDefinition,
                "cellRendererSelector") as CellRendererSelectorFunc;

            if (originalCellRendererSelector && typeof originalCellRendererSelector === 'function') {
                return originalCellRendererSelector(params);
            }

            return undefined;
        };
    }

}

