import { useState, useEffect, SetStateAction, Dispatch } from "react";
import {
   Box,
   Tooltip,
   IconButton,
   Typography,
   Button,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   CircularProgress,
   Checkbox,
   TableHead,
   TableRow,
   TableCell,
   TableBody,
   Paper,
   Table,
   TableContainer,
   Menu,
   MenuItem,
   SxProps,
   Theme,
   Accordion,
   AccordionSummary,
   AccordionDetails,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import * as NotificationService from "../../../services/notifications/notifications.service";
import * as Utils from "../../../utils";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SaveIcon from "@mui/icons-material/Save";
import { AddIcon, DeleteIcon } from "src/old/src/components/icons";
import { INotificationSettingTypeModel, ISettingTypesModel } from "src/models/notifications.model";
import { getUserInformation } from "src/old/src/redux/common/action";
import { ENotificationTypes } from "./NotificationsPopover";

const styles = {
   accordion: {
      border: (theme) => `1px solid ${theme.palette.divider}`,
      "&:not(:last-child)": {
         borderBottom: 0,
      },
      "&::before": { display: "none" },
   } as SxProps<Theme>,
   accordionSummary: (theme) => ({
      backgroundColor: "rgba(0, 0, 0, .03)",
      flexDirection: "row",
      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
         transform: "rotate(90deg)",
      },
      "& .MuiAccordionSummary-content": {
         marginLeft: theme.spacing(1),
      },
      ...theme.applyStyles?.("dark", {
         backgroundColor: "rgba(255, 255, 255, .05)",
      }),
   }),
   accordionDetails: {
      padding: (theme) => theme.spacing(1),
      borderTop: "1px solid rgba(0, 0, 0, .125)",
   } as SxProps<Theme>,
};

function NotificationSounds() {
   const { formatMessage } = useIntl();

   const [expanded, setExpanded] = useState<string | false>();
   const [notificationSettings, setNotificationSettings] = useState<INotificationSettingTypeModel[]>([]);
   const [settingsType, setSettingsType] = useState<ISettingTypesModel>();
   const [loading, setLoading] = useState(true);
   const [openDialog, setOpenDialog] = useState(false);

   useEffect(() => {
      const openSoundSettingsDialog = async () => {
         await Promise.all([getSettingTypes(), getUserNotificationSettings()]);
         setLoading(false);
      };

      openSoundSettingsDialog();
   }, []);

   const getSettingTypes = async () => {
      try {
         const response = await NotificationService.getSettingTypes();
         if (response?.Success) {
            setSettingsType(response.Data);
         } else {
            throw new Error(response?.ResultMessage);
         }
      } catch (error) {
         Utils.showErrorMessage(error?.toString() ?? "Error");
      }
   };

   const getUserNotificationSettings = async () => {
      try {
         const response = await NotificationService.getUserNotificationSettings();
         if (response?.Success) {
            setNotificationSettings(response.Data);
         } else {
            throw new Error(response?.ResultMessage);
         }
      } catch (error) {
         Utils.showErrorMessage(error?.toString() ?? "Error");
      }
   };

   const updateUserNotificationSettings = async () => {
      try {
         const response = await NotificationService.updateUserNotificationSettings(notificationSettings);
         if (response?.Success) {
            Utils.showSuccessMessage(formatMessage({ id: "global.warning.saved" }));
         } else {
            throw new Error(response?.ResultMessage);
         }
      } catch (error) {
         Utils.showErrorMessage(error?.toString() ?? "Error");
      }
   };

   const saveUserSettings = async () => {
      Utils.ShowLoadingOverlay();
      await updateUserNotificationSettings();
      setOpenDialog(false);
      Utils.HideLoadingOverlay();
   };

   return (
      <>
         <Button
            sx={{ fontSize: 12 }}
            onClick={() => setOpenDialog(true)}
            startIcon={<SettingsIcon width={20} height={20} />}
         >
            <FormattedMessage id="global.manageSounds" />
         </Button>
         <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="manage-sounds-dialog-title">
            <DialogTitle id="manage-sounds-dialog-title">
               <FormattedMessage id="global.manageSounds" />
            </DialogTitle>
            <DialogContent>
               <Box style={{ marginTop: 10, width: "450px", maxHeight: "500px" }}>
                  {loading ? (
                     <Box className="flex-center">
                        <CircularProgress color="primary" />
                     </Box>
                  ) : (
                     <>
                        <NotificationTypeAccordion
                           type={ENotificationTypes.smartbotnotification}
                           expanded={expanded}
                           setExpanded={setExpanded}
                           summaryMessageId="menu.group.text.smartbot"
                           allNotificationSettings={settingsType?.SmartbotTypes ?? []}
                           notificationSettings={notificationSettings}
                           setNotificationSettings={setNotificationSettings}
                        />

                        <NotificationTypeAccordion
                           type={ENotificationTypes.questionchangenotification}
                           expanded={expanded}
                           setExpanded={setExpanded}
                           summaryMessageId="global.questions"
                           allNotificationSettings={settingsType?.QuestionTypes ?? []}
                           notificationSettings={notificationSettings}
                           setNotificationSettings={setNotificationSettings}
                        />

                        <NotificationTypeAccordion
                           type={ENotificationTypes.executecontractscomplete}
                           expanded={expanded}
                           setExpanded={setExpanded}
                           summaryMessageId="menu.group.text.contracts"
                           allNotificationSettings={settingsType?.ContractTypes ?? []}
                           notificationSettings={notificationSettings}
                           setNotificationSettings={setNotificationSettings}
                           disabled={true}
                        />

                        <NotificationTypeAccordion
                           type={ENotificationTypes.excelimportcomplete}
                           expanded={expanded}
                           setExpanded={setExpanded}
                           summaryMessageId="menu.app.button.uploaddata"
                           allNotificationSettings={settingsType?.ExcelImportTypes ?? []}
                           notificationSettings={notificationSettings}
                           setNotificationSettings={setNotificationSettings}
                           disabled={true}
                        />
                     </>
                  )}
               </Box>
            </DialogContent>
            <DialogActions>
               <Button onClick={saveUserSettings} color="primary" startIcon={<SaveIcon />}>
                  <FormattedMessage id="global.save" />
               </Button>
            </DialogActions>
         </Dialog>
      </>
   );
}

interface INotificationTypeAccordionProps {
   type: ENotificationTypes;
   expanded: string | false | undefined;
   setExpanded: Dispatch<SetStateAction<string | false | undefined>>;
   summaryMessageId: string;
   allNotificationSettings: INotificationSettingTypeModel[];
   notificationSettings: INotificationSettingTypeModel[];
   setNotificationSettings: Dispatch<SetStateAction<INotificationSettingTypeModel[]>>;
   disabled?: boolean;
}

function NotificationTypeAccordion(props: INotificationTypeAccordionProps) {
   const userInfo = getUserInformation();

   const [anchorElAddMenu, setAnchorElAddMenu] = useState<null | HTMLElement>(null);
   const [selectedItems, setSelectedItems] = useState<INotificationSettingTypeModel[]>(() => {
      const savedData = localStorage.getItem(`${props.type}-${userInfo.userId}`);
      return savedData ? JSON.parse(savedData) : [];
   });

   useEffect(() => {
      localStorage.setItem(`${props.type}-${userInfo.userId}`, JSON.stringify(selectedItems));
   }, [selectedItems]);

   const areAllSmartbotsActive = () => {
      return selectedItems.length == 0
         ? false
         : selectedItems.every((x) => props.notificationSettings.some((y) => isMatchingNotificationSetting(y, x)));
   };

   const handleAllOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newNotificationSettings = props.notificationSettings.filter((x) => x.NotificationTypeName !== props.type);

      props.setNotificationSettings(
         event.target.checked ? newNotificationSettings.concat(selectedItems) : newNotificationSettings
      );
   };

   const handleDeleteItem = (item: INotificationSettingTypeModel) => {
      props.setNotificationSettings((prev) => prev.filter((x) => !isMatchingNotificationSetting(x, item)));
      setSelectedItems((prev) => prev.filter((x) => x.TargetEntityId !== item.TargetEntityId));
   };

   const handleMenuItemClick = (item: INotificationSettingTypeModel) => {
      const isExit = selectedItems.some((i) => i.Name === item.Name);
      if (!isExit) {
         setSelectedItems((prev) => [...prev, item]);
         props.setNotificationSettings((prev) => [...prev, item]);
      }
      setAnchorElAddMenu(null);
   };

   const isMatchingNotificationSetting = (a: INotificationSettingTypeModel, b: INotificationSettingTypeModel) => {
      return a.NotificationTypeName == b.NotificationTypeName && a.TargetEntityId == b.TargetEntityId;
   };

   return (
      <Accordion
         key={props.type}
         disableGutters
         elevation={0}
         square
         disabled={props.disabled}
         expanded={props.expanded === props.type}
         onChange={(_, newExpanded) => props.setExpanded(newExpanded ? props.type : false)}
         slotProps={{ transition: { unmountOnExit: true } }}
         sx={styles.accordion}
      >
         <AccordionSummary
            sx={styles.accordionSummary}
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
         >
            <Box display="flex" alignItems="center">
               <Checkbox
                  checked={props.disabled ? false : areAllSmartbotsActive()}
                  onChange={handleAllOnChange}
                  onClick={(event) => event.stopPropagation()}
                  color="primary"
               />
               <Typography>
                  <FormattedMessage id={props.summaryMessageId} />
               </Typography>
            </Box>
         </AccordionSummary>
         <AccordionDetails sx={styles.accordionDetails}>
            <TableContainer component={Paper}>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableCell>
                           <FormattedMessage id="global.active" />
                        </TableCell>
                        <TableCell>
                           <FormattedMessage id="global.name" />
                        </TableCell>
                     </TableRow>
                  </TableHead>

                  <TableBody>
                     {selectedItems?.map((selectedItem) => (
                        <TableRow key={selectedItem.Name}>
                           <TableCell>
                              <Checkbox
                                 checked={props.notificationSettings.some((x) =>
                                    isMatchingNotificationSetting(x, selectedItem)
                                 )}
                                 onChange={(event) => {
                                    props.setNotificationSettings((prev) =>
                                       event.target.checked
                                          ? [...prev, selectedItem]
                                          : prev.filter((x) => !isMatchingNotificationSetting(x, selectedItem))
                                    );
                                 }}
                                 color="primary"
                                 size="small"
                              />
                           </TableCell>
                           <TableCell>
                              <Typography>{selectedItem.Name}</Typography>
                           </TableCell>
                           <TableCell>
                              <Tooltip title={<FormattedMessage id="global.delete" />}>
                                 <IconButton
                                    onClick={() => handleDeleteItem(selectedItem)}
                                    sx={{ width: 40, height: 40 }}
                                    color="error"
                                 >
                                    <DeleteIcon width={20} height={20} />
                                 </IconButton>
                              </Tooltip>
                           </TableCell>
                        </TableRow>
                     ))}
                     <TableRow sx={{ borderBottom: "none" }}>
                        <TableCell sx={{ borderBottom: "none" }}>
                           <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              startIcon={<AddIcon />}
                              onClick={(event) => setAnchorElAddMenu(event.currentTarget)}
                              disabled={props.allNotificationSettings.length === 0}
                           >
                              <FormattedMessage id="global.add" />
                           </Button>
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}></TableCell>
                     </TableRow>
                  </TableBody>
               </Table>
            </TableContainer>
         </AccordionDetails>

         <Menu anchorEl={anchorElAddMenu} open={Boolean(anchorElAddMenu)} onClose={() => setAnchorElAddMenu(null)}>
            {props.allNotificationSettings.map((item) => (
               <MenuItem key={item.Name} onClick={() => handleMenuItemClick(item)}>
                  <Typography maxWidth="500px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                     {item.Name}
                  </Typography>
               </MenuItem>
            ))}
         </Menu>
      </Accordion>
   );
}

export default NotificationSounds;
