import { useCallback, useEffect, useMemo, useState } from "react";
import { max, mean, min, sum } from "lodash";
import { Column } from "@ag-grid-community/core";
import { ColDefinition, GridFooterCalculationType, GridFooterColumnComponentProps } from "src/components/grid/Types.ts";
import { agGridColumnFormatterResolver } from "../AgGridUtils.ts";
import { numberColumnFormatter } from "../NumberColumnFormatter.ts";

export const AgGridColumnFooterComponent = (props: GridFooterColumnComponentProps) => {
    const [footerValue, setFooterValue] = useState<string>("");
    const {
        api,
        column,
        colDef = {},
        node: { rowIndex },
        context,
    } = props;

    const {
        footer: footerOptions,
    } = colDef as ColDefinition;

    if (!footerOptions) {
        return null;
    }

    const formatterService = agGridColumnFormatterResolver({
        gridColumn: column as Column,
        gridContext: context,
        gridApi: api
    });

    const footerCalculationTypes = useMemo<GridFooterCalculationType[]>(() => {
        const AgGridFooterCalculationValues: Record<GridFooterCalculationType, true> = {
            SUM: true,
            MIN: true,
            MAX: true,
            AVG: true,
        };

        return Object.keys(AgGridFooterCalculationValues) as GridFooterCalculationType[];
    }, []);

    const isMathCalculationType = useCallback((value: string): value is GridFooterCalculationType => {
        return footerCalculationTypes.includes(value as GridFooterCalculationType);
    }, []);

    const calculateFooterMathTypeValue = useCallback((values: number[], footerOps: GridFooterCalculationType): number | null => {
        if ((values || []).length <= 0) {
            return null;
        }
        switch (footerOps) {
            case "SUM":
                return sum(values);
            case "MIN":
                return min(values) ?? null;
            case "MAX":
                return max(values) ?? null;
            case "AVG":
                return mean(values);
            default:
                console.error(`Grid summary function is not supported (${footerOps})!`);
                break;
        }
        return null;
    }, []);

    useEffect(() => {
        let footerCalculatedValue: string | number | null = "";
        const fixedRowIndex = rowIndex ?? Number.MAX_VALUE;

        const existsFooterOptions =
            footerOptions.levels &&
            (footerOptions.levels || []).length > (fixedRowIndex);

        const footerDef = existsFooterOptions
            ? (footerOptions.levels || [])[fixedRowIndex]
            : undefined;

        if (footerDef && Boolean(footerDef.valueOrCalculationOption)) {
            try {
                if (typeof footerDef.valueOrCalculationOption === "string" && isMathCalculationType(footerDef.valueOrCalculationOption)) {
                    const calcType = footerDef.valueOrCalculationOption as GridFooterCalculationType;

                    const rowValues: number[] = [];

                    api.forEachNode(
                        (node) => {
                            const cellValue = api.getCellValue({
                                rowNode: node,
                                colKey: column as Column,
                            });

                            if (cellValue || cellValue === 0) {
                                if (typeof cellValue === "number") {
                                    rowValues.push(cellValue);
                                } else if (typeof cellValue === "string") {
                                    try {
                                        const valueAsNumeric = formatterService.parseToNumber(cellValue);

                                        if (valueAsNumeric) {
                                            rowValues.push(valueAsNumeric);
                                        }
                                    } catch (ex) {
                                        console.log("An error raised at number parsing!", ex);
                                    }
                                }
                            }
                        });

                    footerCalculatedValue = calculateFooterMathTypeValue(rowValues, calcType);
                } else if (typeof footerDef.valueOrCalculationOption === "function") {
                    footerCalculatedValue = footerDef.valueOrCalculationOption({ ...props });
                } else {
                    footerCalculatedValue = footerDef.valueOrCalculationOption ?? "";
                }
            } catch (error: any) {
                let errorMessage: string;
                if (typeof error === "object") {
                    errorMessage = `${error.name}: ${error.message}`;
                } else {
                    errorMessage = error;
                }
                console.error(`Could not calculated grid column footer value. ${errorMessage}`, props);
            }
        }

        let resultValue = "";

        if (typeof footerCalculatedValue === "number") {
            if (colDef.valueFormatter && typeof colDef.valueFormatter === "function") {
                resultValue = colDef.valueFormatter({
                    api: api,
                    colDef: colDef,
                    column: column as Column,
                    context: props.context,
                    data: props.data,
                    node: props.node,
                    value: footerCalculatedValue,
                });
            } else {
                resultValue = numberColumnFormatter(
                    footerCalculatedValue,
                    colDef as ColDefinition,
                    formatterService
                );
            }
        } else if (typeof footerCalculatedValue === "string") {
            resultValue = footerCalculatedValue;
        } else {
            //TODO: implement other types..
        }

        setFooterValue(resultValue);
    });

    return (<span>{footerValue}</span>);
}
